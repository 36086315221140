import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '../../../../../componentsV2/DesignSystem/Inputs/IconButton';
import PlanBar from '../../../../../componentsV2/DesignSystem/PlanBar';
import PlanIcon from '../../../../../componentsV2/DesignSystem/PlanIcon';
import SpinnerBox from '../../../../../componentsV2/DesignSystem/SpinnerBox';
import { FontWeight, Heading, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import { FormatNumberComma } from '../../../../../componentsV2/DesignSystem/Utils/Format';
import { REACT_APP_TERMS_URL } from '../../../../../config/Env';
import useAuth from '../../../../../hooks/useAuth';
import usePostHog from '../../../../../hooks/usePostHog';
import useToast from '../../../../../hooks/useToast';
import styleModuleClasses from '../../../../../utils/styleModuleClasses';
import useSwitchAccount from '../SwitchAccountContextProvider';
import { AccountItemProps } from './interface';
import styles from './styles.module.scss';
import { useSetSwitchAccountMutation } from './useSetSwitchAccountMutation';

export default function AccountItem({ id, name, allowance, type, favorite }: AccountItemProps): JSX.Element {
  const { data, setSwitchAccount, error, loading } = useSetSwitchAccountMutation();
  const { accountIdFromCookie, accepted, highlight, setDisabledAll, disabledAll } = useSwitchAccount();
  const [clicked, setClicked] = useState<boolean>(false);
  const { getMe } = useAuth();
  const { fetchAccountDataLog } = usePostHog();
  const { toast } = useToast();
  const history = useHistory();

  const setAccount = () => {
    if (!loading) {
      setClicked(true);
      setSwitchAccount(getMe.agency_id, id);
    }
  };

  useEffect(() => {
    if (loading) {
      setDisabledAll(true);
    }
  }, [loading]);

  useEffect(() => {
    if (data) {
      if (highlight) {
        fetchAccountDataLog({
          agencyId: getMe.agency_id,
          accountId: data.id,
        });
      }

      const redirectPath = accepted ? `/r/${data.slug}/dashboard` : `/r/${data.slug}${REACT_APP_TERMS_URL}#terms`;
      history.push(redirectPath);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast('error', error);
    }
  }, [error]);

  return (
    <section
      className={styleModuleClasses(styles, 'section', type, {
        active: !!(accountIdFromCookie && Number(accountIdFromCookie) === Number(id)) && !disabledAll,
        'no-cursor': disabledAll,
      })}
      onClick={setAccount}
    >
      {disabledAll && <div className={styles['overlay']}>{clicked && <SpinnerBox />}</div>}

      <header {...(getMe.superAdmin && { title: id.toString() })}>
        <Heading size={5}>{name}</Heading>
        {favorite && (
          <IconButton
            classname={styles[favorite ? 'active' : '']}
            fill={favorite}
            onClick={() => {
              console.log('clicked', id);
            }}
          >
            Favorite
          </IconButton>
        )}
      </header>
      {allowance && (
        <footer>
          <div>
            <aside className={styles[allowance.planType]}>
              <PlanIcon plan={allowance.planType} />
            </aside>
            <main>
              <Paragraph size={3} weight={FontWeight.extraBold}>
                {allowance.planType === 'noplan' ? 'No Plan' : allowance.planType}
              </Paragraph>
              <Paragraph size={3} weight={FontWeight.semiBold}>
                {allowance.planType === 'noplan' ? (
                  'This account does not have a set plan.'
                ) : (
                  <>
                    {`${FormatNumberComma(
                      allowance.entryAllowance - allowance.entryAllowanceUsed,
                    )} / ${FormatNumberComma(allowance.entryAllowance)} Entries remaining`}
                  </>
                )}
              </Paragraph>
            </main>
          </div>
          <PlanBar plan={allowance.planType} resumeEntries={allowance.entriesRemaining} height=".5rem" />
        </footer>
      )}
    </section>
  );
}
