import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Select from '../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import EmailContent from './EmailContent';
import useSendEmail from './SendEmailProvider';
import SendEmailTest from './SendEmailTest';
import { EmailListByCode, PreviewEmailProps } from './interface';
import styles from './styles.module.scss';

export default function PreviewEmail({ handleSection, emailOptions }: PreviewEmailProps): JSX.Element {
  const [userSelected, setUserSelected] = useState<EmailListByCode>();

  const { senderEmail } = useSendEmail();

  const { getValues } = useFormContext();

  const handleEmail = (value: string) => {
    if (emailOptions) {
      const user = emailOptions.find((email) => email.value === value);
      setUserSelected(user);
    }
  };

  useEffect(() => {
    if (emailOptions) {
      setUserSelected(emailOptions[0]);
    }
  }, []);

  return (
    <>
      <div className={styles['column']}>
        <div className={styles['configure-paragraphs']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Email step preview
          </Paragraph>

          <Paragraph size={2}>Preview your email by testing combinations of custom variables</Paragraph>
        </div>
        <Select
          label="Select data"
          placeholder="Select an InviteLink"
          onChange={(id) => handleEmail(id.toString())}
          options={emailOptions}
          activeSearch
          defaultValue={userSelected?.value?.toString()}
          size="sm"
        />
      </div>
      <div className={styles['column']}>
        <div className={styles['column-container']}>
          <div className={styles['preview-title']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              Email Preview
            </Paragraph>
            <Button
              type="button"
              firstIcon={{ children: 'edit' }}
              variant="secondary"
              size="sm"
              onClick={() => handleSection('configuration')}
            />
          </div>

          <div className={styles['preview-header']}>
            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                From Address
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {senderEmail.sender}
              </Paragraph>
            </div>

            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                From Name
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {senderEmail.senderName}
              </Paragraph>
            </div>

            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                Subject
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {getValues('subject')}
              </Paragraph>
            </div>
          </div>

          <EmailContent userName={userSelected?.label} inviteLink={userSelected?.url} />

          <SendEmailTest code={userSelected?.code} />
        </div>
      </div>
    </>
  );
}
