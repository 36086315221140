import React from 'react';
import { IconAnimatedProps } from './interface';
import Lottie from 'lottie-react';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import styles from './styles.module.scss';

export function IconAnimated({ wrapper, icon, size, lottieAnimation }: IconAnimatedProps): JSX.Element {
  return (
    <div
      className={styleModuleClasses(styles, 'icon-wrapper', wrapper.color, {
        lg: Boolean(icon) && (!size || size === 'lg'),
        md: Boolean(icon) && size === 'md',
        sm: Boolean(icon) && size === 'sm',
      })}
    >
      {icon ? (
        <span className={styleModuleClasses(styles, 'material-symbols-rounded', icon.color)}>{icon.name}</span>
      ) : (
        <Lottie
          loop={true}
          autoPlay={true}
          animationData={lottieAnimation}
          style={{ height: '100px', width: '100px' }}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice',
          }}
        />
      )}
    </div>
  );
}
