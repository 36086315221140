import { ApolloError, gql, useLazyQuery, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { createContext, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { REACT_APP_SENTRY_DSN } from '../config/Env';
import { LIST_FIELDS } from '../pages/Admin/Campaign/Content/graphql';
import { AccountData, AccountPermission, AccountRewardPlugin, AlertMessagesData, FieldData, SummaryData } from '../ts';
import { ResumeEntriesData } from '../ts/allowance';
import { getAccountCookie, removeAccountCookie, setAccountCookie } from '../utils/accountCookie';
import replaceMonolithUrl from '../utils/replaceMonolithUrl';
import { Me } from './useAuth';

interface UserAccount {
  agencyId: number;
  accountId: number;
  accountName: string;
  accountSlug: string;
  summary?: SummaryData;
  accountTimezone: string;
  accountDateFormat: string;
  accountCreatedAt: string;
  accountPermission: AccountPermission;
  resumeEntries?: ResumeEntriesData;
  rewardPlugin?: AccountRewardPlugin;
}

export interface AccountVariables {
  agency_id: number;
  account_id: number;
}

export interface AccountBySlugValues {
  agency_id: number;
  account_slug: string;
}

export interface AccountBySlugVariables {
  variables: AccountBySlugValues;
}

export interface AccountIdValues {
  accountId: number;
}

export interface AccountIdVariables {
  variables: AccountIdValues;
}

interface AccountContext {
  loadingAccount: boolean;
  getAccount: UserAccount;
  refetchAccount: (variables: AccountVariables) => void;
  refetchAccountBySlug: (variables: AccountBySlugVariables) => void;
  alertMessages: (variables: AccountIdVariables) => void;
  getMessages: AlertMessagesData[];
  getDataFields: () => void;
  listFields: FieldData[];
  loadingFields: boolean;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  getMe: Me;
}

const GET_ACCOUNT = gql`
  query account($agency_id: ID!, $account_id: ID!) {
    account(agency_id: $agency_id, account_id: $account_id) {
      id
      name
      slug
      image
      agency_id
      config_timezone
      config_date_format
      created_at
      resumeEntries {
        entryAllowance
        entryAllowanceUsed
        planEndDate
        entryAllowanceEndDate
        planType
        planPeriod
        isActive
        subscriptionStatus
        isMigrate
      }
      rewardPlugin {
        url
        appUuid
        dataUid
        dataName
        dataHmac
        promotionCount
      }
    }
  }
`;

export const GET_ACCOUNT_BY_SLUG = gql`
  query accountFindBySlug($agency_id: ID!, $account_slug: String!) {
    accountFindBySlug(agency_id: $agency_id, account_slug: $account_slug) {
      id
      agency_id
    }
  }
`;

export const GET_ALERT_MESSAGES = gql`
  query alertMessages($accountId: ID!) {
    alertMessages(accountId: $accountId) {
      icon_class
      text
      alertMessageType
      key
    }
  }
`;

const AccountContext = createContext({});

export function AccountProvider({ children, getMe }: Props): JSX.Element {
  const history = useHistory();

  const {
    loading: loadingAccount,
    data: dataAccount,
    refetch: refetchAccount,
  } = useQuery<{ account: AccountData }>(GET_ACCOUNT, {
    variables: {
      account_id: getAccountCookie() ?? Object.keys(getMe.account_permissions)[0],
      agency_id: getMe.agency_id,
    },
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      console.log({
        gql: 'GET_ACCOUNT',
        variables: {
          account_id: getAccountCookie() ?? Object.keys(getMe.account_permissions)[0],
          agency_id: getMe.agency_id,
        },
        apolloError: error,
      });
    },
  });

  const getAccount = useMemo(() => {
    if (!dataAccount?.account) {
      return;
    }

    setAccountCookie(dataAccount.account.id.toString());

    if (REACT_APP_SENTRY_DSN) {
      Sentry.setContext('account', {
        accountId: dataAccount.account.id,
        accountName: dataAccount.account.name,
      });
    }

    return {
      agencyId: dataAccount.account.agency_id,
      accountId: dataAccount.account.id,
      accountName: dataAccount.account.name,
      accountSlug: dataAccount.account.slug,
      accountPermission: getMe.account_permissions[dataAccount.account.id] ?? AccountPermission.account_admin,
      accountTimezone: dataAccount.account.config_timezone,
      accountDateFormat: dataAccount.account.config_date_format,
      accountCreatedAt: dataAccount.account.created_at,
      resumeEntries: dataAccount.account.resumeEntries,
      rewardPlugin: dataAccount.account.rewardPlugin,
    };
  }, [dataAccount?.account]);

  const [refetchAccountBySlug] = useLazyQuery<{ accountFindBySlug: AccountData }>(GET_ACCOUNT_BY_SLUG, {
    onCompleted: (response) => {
      if (
        response.accountFindBySlug?.agency_id &&
        (getMe.superAdmin || getMe?.accounts?.indexOf(response.accountFindBySlug.id.toString()) > -1)
      ) {
        refetchAccount({
          agency_id: response.accountFindBySlug.agency_id,
          account_id: response.accountFindBySlug.id,
        });
      } else {
        history.push('/forbidden');
      }
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'GET_ACCOUNT_BY_SLUG',
        apolloError: error,
      });
      removeAccountCookie();
      history.push('/forbidden');
    },
  });

  const { data: dataMessages } = useQuery<{ alertMessages: AlertMessagesData[] }>(GET_ALERT_MESSAGES, {
    variables: {
      accountId: getAccount?.accountId,
    },
    skip: !getAccount?.accountId,
    onError: (error: ApolloError) => {
      console.log({
        gql: 'GET_ALERT_MESSAGES',
        apolloError: error,
      });
    },
  });

  const getMessages = useMemo(() => {
    if (!dataMessages?.alertMessages) {
      return [];
    }

    return dataMessages.alertMessages.map((item) => ({
      ...item,
      text: replaceMonolithUrl(item.text, getAccount?.accountSlug),
    }));
  }, [dataMessages]);

  const [getDataFields, { data: dataFields, loading: loadingFields }] = useLazyQuery<{
    entriesFields: { data: FieldData[] };
  }>(LIST_FIELDS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      accountId: getAccount?.accountId,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'LIST_FIELDS',
        variables: {
          account_id: getAccount?.accountId,
          agency_id: getAccount?.agencyId,
        },
        apolloError: error,
      });
    },
  });

  const listFields = useMemo(() => {
    return dataFields?.entriesFields.data
      ? dataFields?.entriesFields.data.filter((item) => item.base_field !== 'newsletter' && item.base_field !== 'terms')
      : [];
  }, [dataFields]);

  return (
    <AccountContext.Provider
      value={{
        loadingAccount,
        getAccount,
        refetchAccount,
        refetchAccountBySlug,
        getMessages,
        getDataFields,
        listFields,
        loadingFields,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export default function useAccountContext(): AccountContext {
  const context = useContext(AccountContext) as AccountContext;
  return context;
}
