import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const initialSchema = yup.object({
  id: yup.number(),
  name: yup.string().required('The Name is required.'),
  promo_id: yup.number().required('The Point to Promotion is required.'),
  slug: yup.string().required('The Slug is required.'),
});

const ValidationResolver = yupResolver(initialSchema);

export default ValidationResolver;
