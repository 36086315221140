import { graphql } from 'msw';

const zapierClientId = graphql.query('zapierClientId', (req, res, ctx) => {
  return res(
    ctx.data({
      zapierClientId: {
        client_id: null,
      },
    }),
  );
});

const credentialFindByService = graphql.mutation('credentialFindByService', (req, res, ctx) => {
  const data = { credentialFindByService: null };

  return res(
    ctx.delay(2000),
    ctx.data({
      ...data,
    }),
  );
});

const createCredential = graphql.mutation('createCredential', (req, res, ctx) => {
  return res(ctx.delay(2000), ctx.data({}));
});

const zapierZaps = graphql.query('zapierZaps', (req, res, ctx) => {
  const data = {
    zapierZaps: {
      data: [
        {
          id: '1480556881',
          title: 'Super test',
          state: 'draft',
          steps: [
            {
              app: {
                id: '26893',
                title: 'BeeLiked',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
            {
              app: {
                id: '6',
                title: 'Mailchimp',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
          ],
        },
        {
          id: '14805568812',
          title: 'Super test to beeliked with large size',
          state: 'on',
          steps: [
            {
              app: {
                id: '26893',
                title: 'BeeLiked',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
            {
              app: {
                id: '6',
                title: 'Mailchimp',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
          ],
        },
        {
          id: '147966989',
          title: 'Beeliked Sheets',
          state: 'off',
          steps: [
            {
              app: {
                id: '1498',
                title: 'Google Sheets',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/services/8913a06feb7556d01285c052e4ad59d0.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
            {
              type_of: 'write',
              app: {
                id: '26893',
                title: 'BeeLiked',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
            {
              type_of: 'write',
              app: {
                id: '1498',
                title: 'Google Sheets',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/services/8913a06feb7556d01285c052e4ad59d0.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
          ],
        },
        {
          id: '148055688',
          title: 'Mail',
          state: 'on',
          steps: [
            {
              type_of: 'read',
              app: {
                id: '26893',
                title: 'BeeLiked',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
            {
              type_of: 'write',
              app: {
                id: '6',
                title: 'Mailchimp',
                images: {
                  url_64x64:
                    'https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
                },
              },
            },
          ],
        },
      ],
    },
  };

  return res(ctx.delay(2000), ctx.data(data));
});

const zapierPopularApps = graphql.query('zapierPopularApps', (req, res, ctx) => {
  const data = {
    zapierPopularApps: [
      {
        slug: 'google-sheets',
        name: 'Google Sheets',
        image:
          'https://zapier-images.imgix.net/storage/services/8913a06feb7556d01285c052e4ad59d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/8913a06feb7556d01285c052e4ad59d0.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'mailchimp',
        name: 'Mailchimp',
        image:
          'https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'typeform',
        name: 'Typeform',
        image:
          'https://zapier-images.imgix.net/storage/services/065860c1e1210fdf4040105024099b0a.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/065860c1e1210fdf4040105024099b0a.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'bookingsync',
        name: 'BookingSync',
        image:
          'https://zapier-images.imgix.net/storage/developer_cli/5e8bb0d8a175e6a90aa472644ccac22e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/developer_cli/5e8bb0d8a175e6a90aa472644ccac22e.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'stripe',
        name: 'Stripe',
        image:
          'https://zapier-images.imgix.net/storage/services/27f5433f521806493e2c38a304e1786e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/27f5433f521806493e2c38a304e1786e.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'salesforce',
        name: 'Salesforce',
        image:
          'https://zapier-images.imgix.net/storage/services/1a4435539b4764e6ded3d368cabab387.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/1a4435539b4764e6ded3d368cabab387.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'constant-contact',
        name: 'Constant Contact',
        image:
          'https://zapier-images.imgix.net/storage/services/f4c75458ed4010289894f7a2c291547e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/f4c75458ed4010289894f7a2c291547e.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'shopify',
        name: 'Shopify',
        image:
          'https://zapier-images.imgix.net/storage/services/4da9d3e3f93cd522f85e1b0695341f89.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/4da9d3e3f93cd522f85e1b0695341f89.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'twilio',
        name: 'Twilio',
        image:
          'https://zapier-images.imgix.net/storage/services/304e0410159880592f94af8c8772642d.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/304e0410159880592f94af8c8772642d.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'simpletexting',
        name: 'SimpleTexting',
        image:
          'https://zapier-images.imgix.net/storage/developer_cli/a9aa21d4a46f5d1563065a21982a2672_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/developer_cli/a9aa21d4a46f5d1563065a21982a2672_2.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'lemlist',
        name: 'lemlist',
        image:
          'https://zapier-images.imgix.net/storage/services/680bf6826b78e813c1484e43e99e8e2f.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/680bf6826b78e813c1484e43e99e8e2f.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
      {
        slug: 'slack',
        name: 'Slack',
        image:
          'https://zapier-images.imgix.net/storage/services/6cf3f5a461feadfba7abc93c4c395b33_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
        images: {
          url_32x32:
            'https://zapier-images.imgix.net/storage/services/6cf3f5a461feadfba7abc93c4c395b33_2.png?auto=format%2Ccompress&fit=crop&h=32&ixlib=python-3.0.0&q=50&w=32',
        },
      },
    ],
  };

  return res(ctx.delay(2000), ctx.data(data));
});

const zapierZapTemplates = graphql.query('zapierZapTemplates', (req, res, ctx) => {
  const data = {
    zapierZapTemplates: [
      {
        id: '544797',
        steps: [
          {
            id: '1',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Marketing Opt-In',
          },
          {
            id: '2',
            title: 'Mailchimp',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/221770b81f376004aee4a712ef4353be.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Add/Update Subscriber',
          },
        ],
        title: 'Subscribe new BeeLiked opt-ins to Mailchimp',
        description_raw:
          "After someone fills in the entry form in your promotion and ticks the opt in to your newsletter, you'll want to subscribe them automatically to your Mailchimp newsletter list. This Zapier integration does that automatically, so you don't have to manually enter subscribers.",
      },
      {
        id: '544821',
        steps: [
          {
            id: '1',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Promotion Entry',
          },
          {
            id: '2',
            title: 'Salesforce',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/1a4435539b4764e6ded3d368cabab387.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Add Lead to Campaign',
          },
        ],
        title: 'Add new new BeeLiked entries as leads in Salesforce',
        status: 'published',
        description_raw:
          'When someone enters your BeeLiked-powered promotion, this Zap will automatically push the data as a lead to a campaign in Salesforce. Stay responsive to prospects without any added clicks or keystrokes.',
      },
      {
        id: '544856',
        steps: [
          {
            id: '1',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Promotion Entry',
          },
          {
            id: '2',
            title: 'Google Sheets',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/8913a06feb7556d01285c052e4ad59d0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Create Spreadsheet Row',
          },
        ],
        title: 'Add Google Sheets spreadsheet rows for new BeeLiked entries',
        status: 'published',
        description_raw:
          'Need an easy way to see who is entering and what outcomes they received in your promotion? Now with the help of this Zap, every promotion entry can be automatically added as a new row in a Google Sheets spreadsheet, enabling you to have all the information available at a glance, which you can then share easily with others.',
      },
      {
        id: '544876',
        steps: [
          {
            id: '1',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Marketing Opt-In',
          },
          {
            id: '2',
            title: 'Sendinblue',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/adbc91a16e5fdbf038f9532334e50bc6_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Add or Update Contact',
          },
        ],
        title: 'Add new opt-ins in BeeLiked to Sendinblue',
        status: 'published',
        description_raw:
          "After someone fills out the entry form in your promotion and ticks the opt-in to your newsletter or other marketing lists, you'll want to add them automatically to a Sendinblue list. This Zapier automation does just that, adding the email address as a subscriber to your Sendinblue list without you needing to do anything.",
      },
      {
        id: '544892',
        steps: [
          {
            id: '1',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Marketing Opt-In',
          },
          {
            id: '2',
            title: 'Constant Contact',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/f4c75458ed4010289894f7a2c291547e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Create Contact',
          },
        ],
        title: 'Subscribe new opt-ins in BeeLiked to Constant Contact lists',
        status: 'published',
        description_raw:
          'After someone completes the entry form in your BeeLiked promotion and ticks the checkbox to opt-in to your newsletter or other marketing lists, this integration lets you add them automatically to a Constant Contact list. Keep your Constant Contact list updated without the need for tedious data entry.',
      },
      {
        id: '561892',
        steps: [
          {
            id: '1',
            title: 'Shopify',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/4da9d3e3f93cd522f85e1b0695341f89.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Paid Order',
          },
          {
            id: '2',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Create Personalized EntryLink',
          },
          {
            id: '3',
            title: 'SendGrid',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer/a37a5b5ab495b5531dd406d40baa8a5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Send Email',
          },
        ],
        title: 'Send Shopify customers BeeLiked entrylinks via SendGrid emails for each completed order',
        status: 'published',
        description_raw:
          "After someone makes a purchase in your Shopify site, you'll want to send them a follow-up email which encourages them to visit your site again. This Zapier automation enables you to automate the sending of an email via Sendgrid to each customer as a follow up to their purchase and insert a unique BeeLiked EntryLink for them to click and play a promotion created on the BeeLiked platform. In this way, you can turn new customers into loyal customers and keep them coming back for more.",
      },
      {
        id: '561901',
        steps: [
          {
            id: '1',
            title: 'WooCommerce',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer/114e2646f703010f9740fed6672a62cb.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Order',
          },
          {
            id: '2',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Create Personalized EntryLink',
          },
          {
            id: '3',
            title: 'Email by Zapier',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/01c965c4215e4003b6dd573484563457.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Send Outbound Email',
          },
        ],
        title: 'Send customers BeeLiked entrylinks via email for new WooCommerce orders',
        status: 'published',
        description_raw:
          "As soon as someone makes a purchase in your WooCommerce store, you'll want to send a follow-up email to thank them for their purchase and incentivize them to visit your site again. This Zapier automation enables you to automate the sending of an email via Zapier Email to each customer and insert a unique BeeLiked EntryLink for them to click and play a promotion created on the BeeLiked platform. Use this automation to turn new customers into loyal customers.",
      },
      {
        id: '561904',
        steps: [
          {
            id: '1',
            title: 'Stripe',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/27f5433f521806493e2c38a304e1786e.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Charge',
          },
          {
            id: '2',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Create Personalized EntryLink',
          },
          {
            id: '3',
            title: 'SendGrid',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer/a37a5b5ab495b5531dd406d40baa8a5c.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Send Email',
          },
        ],
        title: 'Send customers BeeLiked entrylinks via SendGrid emails for new Stripe charges',
        status: 'published',
        description_raw:
          'This automation enables you to send an email via SendGrid to each Stripe customer as a follow up to their purchase and insert a unique BeeLiked EntryLink for them to click and play a promotion created on the BeeLiked platform.  Use this Zapier automation to bring each customer back to your store for more.',
      },
      {
        id: '561914',
        steps: [
          {
            id: '1',

            title: 'Pipedrive',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer/a70589f8db6a514b6820267b506409f0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Updated Deal Stage',
          },
          {
            id: '2',
            title: 'BeeLiked',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Create Personalized EntryLink',
          },
          {
            id: '3',
            title: 'Gmail',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/54f0bd6f9c31b757ab20d4c7058dc7c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Send Email',
          },
        ],
        title: 'Send updated Pipedrive leads Gmail emails containing a BeeLiked EntryLink',
        status: 'published',
        description_raw:
          "Let's say you've had a really good call with a prospect you'll want to follow up to thank them for their time and provide them further information. By moving their deal stage in Pipedrive you can automate this process and send them an email with Gmail with a personalized BeeLiked EntryLink for them to click and play an interactive promotion that you have created within the BeeLiked platform. In this way you will not need to send the same message again to prospects and can create a powerful incentive for them to engage once more with your business and so helping you close that sale.",
      },
      {
        id: '562090',
        steps: [
          {
            id: '1',
            title: 'Bonusly',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/252505df0c3feb9948625334a9ce14f2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'New Achievement',
          },
          {
            id: '2',

            title: 'BeeLiked',

            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/developer_cli/8dcd746747c6c768fcd17c431623d374_2.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Create Personalized EntryLink',
          },
          {
            id: '3',
            title: 'Gmail',
            images: {
              url_64x64:
                'https://zapier-images.imgix.net/storage/services/54f0bd6f9c31b757ab20d4c7058dc7c0.png?auto=format%2Ccompress&ixlib=python-3.0.0&q=50',
            },
            label: 'Send Email',
          },
        ],
        title: 'Send BeeLiked entrylinks via Gmail emails for new Bonusly achievements',
        status: 'published',
        description_raw:
          'Keep your employees happy by rewarding each achievement they receive in Bonusly with a Gmail email inviting them to click on the BeeLiked EntryLink to play a promotion created with the BeeLiked Platform. By automating this process you will give your teams extra incentives to earn Bonusly achievements whilst keeping control of your costs and management time.',
      },
    ],
  };

  return res(ctx.delay(2000), ctx.data(data));
});

export default [
  zapierClientId,
  zapierZaps,
  credentialFindByService,
  createCredential,
  zapierPopularApps,
  zapierZapTemplates,
];
