import React, { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { paginatorInfo, ZapierZap, ZapierZapError } from '../../../../ts';
import { LIST_ZAPS_GQL } from '../graphql';
import List from './List';
import useZapierContext from '../ZapierContextProvider';
import ErrorZaps from './ErrorZaps';
import Pagination from '../../../../components/Pagination';
import useQueryString from '../../../../hooks/useQueryString';
import style from './styles.module.scss';
import SectionCollapsibleFull from '../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import useAccountContext from '../../../../hooks/useAccount';
import ButtonNavigation from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { Paragraph } from '../../../../componentsV2/DesignSystem/Typography';

export default function MyZaps(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'my-zaps' });
  const { accessToken } = useZapierContext();
  const { pathname } = useLocation();
  const { PATH_ZAPIER } = useZapierContext();
  const query = useQueryString();
  const currentPage = query.get('page') ? Number(query.get('page')) : undefined;
  const { deleteCredential } = useZapierContext();
  const { getAccount } = useAccountContext();

  const {
    data: dataZaps,
    loading: loadingZaps,
    error: errorZaps,
    refetch: refetchZaps,
  } = useQuery<{
    zapierZaps: { data: ZapierZap[]; paginatorInfo: paginatorInfo };
  }>(LIST_ZAPS_GQL, {
    fetchPolicy: 'no-cache',
    variables: {
      accessToken,
      limit: 100,
      page: currentPage,
    },
    onError: (error: ApolloError) => {
      if (error.message !== 'VALIDATION_ERROR') {
        console.log('[LIST_ZAPIER_APPS_GQL]:', error);
      }
    },
  });

  const zaps = useMemo(() => {
    return dataZaps?.zapierZaps?.data ?? [];
  }, [dataZaps?.zapierZaps?.data]);

  const { total, per_page } = useMemo(
    () => dataZaps?.zapierZaps?.paginatorInfo ?? { total: 0, per_page: 100 },
    [dataZaps?.zapierZaps?.data],
  );

  const errorRequestZap: ZapierZapError | undefined = useMemo(() => {
    if (errorZaps) {
      let message = t('Sorry an error has occurred Please try again');
      let status = 400;
      if (errorZaps.message === 'VALIDATION_ERROR') {
        message = t('There was a problem with the authentication on Zapier');
        status = 403;
      }
      return { message, status };
    }
  }, [errorZaps]);

  const enablePagination = zaps && total > zaps.length;

  const logoutZapier = () => {
    deleteCredential({
      variables: {
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
      },
    });
  };

  return (
    <SectionCollapsibleFull
      expanded={true}
      header={{ title: 'My Zaps' }}
      badge={{ status: StatusBadge.icon, icon: { name: 'groups', color: 'enabled' } }}
      disableCollapse
      showButton={{
        children: 'Log out of Zapier',
        firstIcon: { children: 'logout' },
        variant: 'secondary',
        onClick: logoutZapier,
      }}
    >
      <div className={style['wrapper-my-zap']}>
        {errorZaps ? (
          <ErrorZaps refetch={refetchZaps} errorData={errorRequestZap} />
        ) : (
          <section>
            <div className={style['wrapper-inputs']}>
              {/* 
              @TODO
              <Input
                fullwidth
                placeholder="Search"
                value={filterValue}
                {...(filterValue !== '' && { icon: { name: 'close' } })}
              /> */}
              <Paragraph size={2}>Below are the existing Zaps created with your Zapier linked account.</Paragraph>
              <ButtonNavigation
                fullWidth
                firstIcon={{ children: 'lan' }}
                variant="secondary"
                to={`${PATH_ZAPIER}/build`}
              >
                Create a new Zap
              </ButtonNavigation>
            </div>
            <List zaps={zaps} loadingZaps={loadingZaps} />
          </section>
        )}

        {!!enablePagination && (
          <Pagination total={total} currentPage={currentPage ?? 1} totalPerPage={Number(per_page)} url={pathname} />
        )}
      </div>
    </SectionCollapsibleFull>
  );
}
