import { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AuthenticationData } from '../../../../ts';

export interface StepPosition {
  initial: string;
  verifyCode: string;
  stepProgress: string;
  setPassword: string;
  terms: string;
  accountCreated: string;
}

export enum Steps {
  verifyCode,
  setPassword,
  terms,
  accountCreated,
}

interface SignupContextProps {
  animations: StepPosition;
  enableSlide: StepPosition;
  changeStep: (step: Steps, flow: 'forward' | 'back') => void;
  userData: UserProps;
  updateUser: (user: UserProps) => void;
  inviteData: InviteProps;
  updateInvite: (invite: InviteProps) => void;
  authorization: AuthenticationData;
  setAuthorization: (authorization: AuthenticationData) => void;
}

interface UserProps {
  firstName: string;
  lastName: string;
  email: string;
  accountName: string;
  password: string;
  passwordConfirmation: string;
}

interface InviteProps {
  accountName: string;
  inviteCode?: string;
}

interface Props {
  children: JSX.Element | JSX.Element[];
}

const SignupContext = createContext({});

export function SignupContextProvider({ children }: Props): JSX.Element {
  const { verifyCode, inviteCode } = useParams<{ verifyCode: string; inviteCode: string }>();
  const [userData, setUserData] = useState<UserProps>({} as UserProps);
  const [inviteData, setInviteData] = useState<InviteProps>({} as InviteProps);
  const [enableSlide, setEnableSlide] = useState<boolean>(false);
  const [authorization, setAuthorization] = useState<AuthenticationData>();
  const [animations, setAnimations] = useState<StepPosition>(
    verifyCode
      ? {
          initial: 'left',
          setPassword: 'left',
          verifyCode: 'center',
          stepProgress: 'center',
          terms: 'right',
          accountCreated: 'right',
        }
      : {
          stepProgress: 'right',
          initial: 'center',
          verifyCode: 'right',
          setPassword: 'right',
          terms: 'right',
          accountCreated: 'right',
        },
  );

  const changeStep = (step: Steps, flow: 'forward' | 'back') => {
    switch (step) {
      case 0:
        if (flow == 'forward') {
          setAnimations({ ...animations, initial: 'left', verifyCode: 'center', stepProgress: 'center' });
          setEnableSlide(true);
        } else {
          setAnimations({ ...animations, initial: 'center', verifyCode: 'right', stepProgress: 'right' });
          setEnableSlide(false);
        }
        break;
      case 1:
        if (flow == 'forward') {
          setAnimations({ ...animations, setPassword: 'center', verifyCode: 'left' });
        } else {
          setAnimations({ ...animations, verifyCode: 'center', setPassword: 'right' });
        }
        break;
      case 2:
        if (inviteCode) {
          if (flow == 'forward') {
            setAnimations({ ...animations, terms: 'center', initial: 'left' });
          } else {
            setAnimations({ ...animations, terms: 'right', initial: 'center' });
          }
        } else {
          if (flow == 'forward') {
            setAnimations({ ...animations, terms: 'center', setPassword: 'left' });
          } else {
            setAnimations({ ...animations, terms: 'right', setPassword: 'center' });
          }
        }
        break;
      case 3:
        if (flow == 'forward') {
          setAnimations({ ...animations, stepProgress: 'left', terms: 'left', accountCreated: 'center' });
        }
        break;
      default:
    }
  };

  const updateUser = (userUpdated: UserProps) => {
    setUserData({ ...userUpdated });
  };
  const updateInvite = (inviteUpdated: InviteProps) => {
    setInviteData({ ...inviteUpdated });
  };

  useEffect(() => {
    if (verifyCode) {
      setAnimations({ ...animations, setPassword: 'left', initial: 'left' });
    }
  }, []);

  return (
    <SignupContext.Provider
      value={{
        animations,
        enableSlide,
        changeStep,
        userData,
        updateUser,
        inviteData,
        updateInvite,
        authorization,
        setAuthorization,
      }}
    >
      {children}
    </SignupContext.Provider>
  );
}

export default function useSignupContext(): SignupContextProps {
  const context = useContext(SignupContext) as SignupContextProps;
  return context;
}
