import { InputHTMLAttributes } from 'react';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import styles from './styles.module.scss';

/* eslint-disable @typescript-eslint/no-empty-interface */
interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  switchSize?: 'sm' | 'md' | 'lg';
  variant?: 'secondary';
}

export default function Switch({ switchSize = 'md', variant, ...rest }: SwitchProps): JSX.Element {
  return (
    <div className={styleModuleClasses(styles, 'form-check', variant, switchSize)}>
      <input className={styles['form-check-input']} type="checkbox" id={rest.id} {...rest} />
    </div>
  );
}
