import React, { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import useAccount from '../../hooks/useAccount';
import { FormatNumberComma } from '../DesignSystem/Utils/Format';
import Icon from '../DesignSystem/Icon';
import styles from './styles.module.scss';
import PlanIcon from '../DesignSystem/PlanIcon';
import PlanBar from '../DesignSystem/PlanBar';

export default function NavigationBarPlan(): JSX.Element {
  const [mouseOver, setMouseOver] = useState(false);
  const history = useHistory();
  const { getAccount } = useAccount();

  const resumeEntries = useMemo(() => {
    if (getAccount?.resumeEntries?.planType) {
      return getAccount?.resumeEntries;
    }
    return undefined;
  }, [getAccount?.resumeEntries]);

  const resumeRemaining = useMemo(() => {
    return resumeEntries ? resumeEntries.entryAllowance - resumeEntries.entryAllowanceUsed : 0;
  }, [resumeEntries]);

  const resumeEntriesProgressBar = useMemo(() => {
    return resumeEntries ? (resumeRemaining * 100) / resumeEntries.entryAllowance : 0;
  }, [resumeEntries, resumeRemaining]);

  return (
    <>
      <div
        className={[styles['plan-type-wrapper'], styles[resumeEntries?.planType ?? '']].join(' ')}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        onClick={() => history.push({ pathname: '/self-service' })}
      >
        <aside
          {...(mouseOver && {
            className: [styles['hover'], resumeEntries?.planType == 'freebee' ? styles['freebee'] : ''].join(' '),
          })}
        >
          <PlanIcon plan={resumeEntries?.planType ?? ''} />
          {resumeEntries?.planType &&
            (resumeEntries?.planType == 'freebee' ? <Icon>arrow_upward</Icon> : <Icon>settings</Icon>)}
        </aside>
        {resumeEntries && (
          <main>
            <div className={[styles['plan-hover-content'], styles[mouseOver ? 'hover' : '']].join(' ')}>
              {resumeEntries.planType == 'freebee' ? (
                <div>
                  <h2>Upgrade my plan</h2>
                  <span>To get more features</span>
                </div>
              ) : (
                <>
                  <div>
                    <h2>Manage plan</h2>
                    <span>Edit my plans settings</span>
                  </div>
                  <Icon>chevron_right</Icon>
                </>
              )}
            </div>
            <div className={[styles['plan-content'], styles[mouseOver ? 'hover' : '']].join(' ')}>
              <h2>{resumeEntries.planType}</h2>
              <span>
                {resumeEntries
                  ? `${FormatNumberComma(resumeRemaining)} / ${FormatNumberComma(
                      resumeEntries.entryAllowance,
                    )} entries remaining`
                  : 'No Interactions'}
              </span>
            </div>
          </main>
        )}
      </div>
      {resumeEntries && (
        <Link to="/self-service">
          <PlanBar plan={resumeEntries.planType ?? ''} resumeEntries={resumeEntriesProgressBar} />
        </Link>
      )}
    </>
  );
}
