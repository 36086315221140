import { useEffect, useState } from 'react';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import useShareLinks from '../../ShareLinksProvider';
import styles from './styles.module.scss';

export default function ShareLink(): JSX.Element {
  const { promotionSelected } = useShareLinks();
  const [micrositeUrl, setMicrositeUrl] = useState(promotionSelected.micrositeUrl);

  useEffect(() => {
    setMicrositeUrl(promotionSelected.micrositeUrl);
  }, [promotionSelected.micrositeUrl]);

  return (
    <SectionCollapsibleFull
      badge={{
        status: StatusBadge.icon,
        icon: { name: 'link', color: 'enabled' },
        number: 2,
      }}
      expanded={true}
      header={{ title: 'Share Link' }}
      containerClassname={styles['container-section']}
    >
      <Input
        label="Your live promotion link is below. Share it wherever you wish."
        readOnly={true}
        value={micrositeUrl}
        icon={{
          name: 'copy_all',
          onClick: () => navigator.clipboard.writeText(micrositeUrl),
        }}
      />
    </SectionCollapsibleFull>
  );
}
