import { useState } from 'react';
import { Button } from '../../DesignSystem/Inputs/Buttons/ButtonAction';
import { TableHeaderElement } from '../interface';
import useQueryParams from '../../../hooks/useQueryParams';
import useQueryString from '../../../hooks/useQueryString';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import styles from './styles.module.scss';

export function HeaderCell({ name, align = 'left', minWidth, width, sortDisabled }: TableHeaderElement): JSX.Element {
  //@todo implement when we encrypt the data const { getAccount } = useAccount();
  //@todo implement when we encrypt the data const [showPopover, setShowPopover] = useState(false);
  const addQueryParam = useQueryParams();
  const query = useQueryString();
  const [sortColumn, setSortColumn] = useState(query.get('sortColumn') ?? 'fold');

  const queryValues = {
    orderColumn: query.get('orderColumn') || 'created_at',
    sortColumn: query.get('sortColumn') || 'desc',
    currentPage: Number(query.get('page')) || 1,
    tableColumns: query.get('columns') || undefined,
  };

  const renderSortButton = (sortType: string) => {
    if (!!queryValues.sortColumn && queryValues?.orderColumn?.toLocaleLowerCase() != name?.toLocaleLowerCase()) {
      return 'unfold_more';
    }
    switch (sortType) {
      case 'asc':
        return 'keyboard_arrow_up';
      case 'desc':
        return 'keyboard_arrow_down';
      default:
        return 'unfold_more';
    }
  };
  function handleSortButton() {
    let columnIcon;
    if (queryValues?.orderColumn?.toLocaleLowerCase() != name?.toLocaleLowerCase()) {
      columnIcon = 'desc';
      addQueryParam({ ...queryValues, orderColumn: name, sortColumn: 'desc' });
    } else {
      switch (sortColumn) {
        case 'asc':
          columnIcon = 'fold';
          addQueryParam({ ...queryValues, orderColumn: name, sortColumn: '' });
          break;
        case 'desc':
          columnIcon = 'asc';
          addQueryParam({ ...queryValues, orderColumn: name, sortColumn: 'asc' });
          break;
        default:
          columnIcon = 'desc';
          addQueryParam({ ...queryValues, orderColumn: name, sortColumn: 'desc' });
          break;
      }
    }

    setSortColumn(columnIcon);
  }

  //@todo implement when we encrypt the data const renderEyeButton = (isHide: boolean) => {
  //   if (isHide) {
  //     return 'visibility_off';
  //   } else {
  //     return 'visibility';
  //   }
  // };
  return (
    <div
      className={styleModuleClasses(styles, 'each-header', align)}
      {...(minWidth ? { style: { minWidth: minWidth } } : undefined)}
      {...(width ? { style: { width: width } } : undefined)}
    >
      <Button
        variant="tertiary"
        text={{ color: 'dark' }}
        //@todo implement when we encrypt the data {...(!!eachColumn.is_uid_field && {
        //   firstIcon: {
        //     children: renderEyeButton(!!eachColumn.is_uid_field),
        //     onMouseEnter: () => setShowPopover(true),
        //     onMouseLeave: () => setShowPopover(false),
        //     ...(getAccount.accountPermission != 'account_admin' && {
        //       style: { cursor: 'default' },
        //       color: 'gray-40',
        //     }),
        //     popover: {
        //       showPopover: showPopover,
        //       color: 'dark',
        //       placement: 'top',
        //       hiddenOverlay: true,
        //       children: 'Sorry, you need to be an admin.',
        //       propRef: null,
        //     },
        //   },
        // })}
        {...(!sortDisabled && {
          lastIcon: { children: renderSortButton(sortColumn), onClick: handleSortButton },
        })}
      >
        {name}
      </Button>
    </div>
  );
}
