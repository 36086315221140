import React, { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import useAccount from '../../../../../hooks/useAccount';
import Card from '../../../../../componentsV2/DesignSystem/Card';
import { CardAnimations } from '../../../../../componentsV2/DesignSystem/Card/interface';
import Carousel from '../../../../../componentsV2/DesignSystem/CarouselImage';
import { CarouselImage } from '../../../../../componentsV2/DesignSystem/CarouselImage/interface';
import { LIST_DASHBOARD_READYMADES } from './graphql';
import { DashboardReadyMadeData } from './interface';
import styles from './styles.module.scss';
import ErrorRefetch from '../../../../../componentsV2/ErrorRefetch';

export default function CustomerStories(): JSX.Element {
  const { getAccount } = useAccount();

  const { data, loading, error, refetch } = useQuery<{
    dashboardReadyMades: { data: DashboardReadyMadeData[]; paginatorMeta: { total: number } };
  }>(LIST_DASHBOARD_READYMADES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId: getAccount.accountId,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'LIST_DASHBOARD_READYMADES',
        variables: {
          accountId: getAccount.accountId,
        },
        apolloError: error,
      });
    },
  });

  const customerStories = useMemo<CarouselImage[]>(() => {
    const dashboardReadyMades = data?.dashboardReadyMades?.data;

    return dashboardReadyMades
      ? dashboardReadyMades.map((customerStory) => ({
          id: customerStory.id,
          title: customerStory.title,
          description: customerStory.description,
          media: {
            url: customerStory.url,
            image_url: customerStory.media.image,
            image_alt: customerStory.media.image_alt,
          },
        }))
      : [];
  }, [data?.dashboardReadyMades?.data]);

  const refetchQuery = () => {
    refetch();
  };

  return (
    <Card
      identify="customer-stories"
      size="md"
      animation={{ type: CardAnimations.top, duration: '1.25s' }}
      header={{ title: 'Knowledge Base' }}
      footer={{
        buttons: [],
      }}
    >
      <div className={styles['customer-stories-wrapper']}>
        {error && !loading ? (
          <ErrorRefetch callback={refetchQuery} />
        ) : (
          <Carousel styles={{ height: '14.5rem' }} isLoading={loading} medias={customerStories} />
        )}
      </div>
    </Card>
  );
}
