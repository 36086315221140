import { FilterList } from './interface';

const FILTER_LIST: FilterList[] = [
  {
    id: 'promo_id',
    name: 'Promotion',
    options: [],
    enable: true,
    type: 'multi',
    slug: 'promo_id',
  },
  {
    id: 'created_at',
    name: 'Create At',
    slug: 'created_at',
    type: 'date',
    options: [],
    enable: false,
  },
];

export default FILTER_LIST;
