import { ApolloError, gql, useQuery } from '@apollo/client';

const EXISTS_INVITELINKS = gql`
  query existInviteLinks($accountId: ID!) {
    existInviteLinks(account_id: $accountId) {
      exists
    }
  }
`;

export interface ExistsInviteLinks {
  existInviteLinks: { exists: boolean };
}

export default function useFetchExistsInviteLinks(accountId: number): {
  loading: boolean;
  data: ExistsInviteLinks;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<ExistsInviteLinks>(EXISTS_INVITELINKS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'EXISTS_INVITELINKS',
        apolloError: error,
      });
    },
  });

  if (loading) {
    return {
      loading: true,
      data: { existInviteLinks: { exists: false } },
    };
  }

  if (error) {
    return {
      loading: false,
      data: { existInviteLinks: { exists: false } },
      error,
    };
  }
  return {
    loading: false,
    data: { existInviteLinks: { exists: data?.existInviteLinks?.exists || false } },
  };
}
