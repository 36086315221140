import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { DUPLICATE_PROMOTION } from './graphql';
import { ModalDuplicateInput } from './interface';
import Modal from '../DesignSystem/Modal';
import useAccount from '../../hooks/useAccount';
import Input from '../DesignSystem/Inputs/Input';

interface Props {
  promoId: number;
  origin?: string;
  callback: () => void;
}

export default function ModalDuplicate({ promoId, callback, origin }: Props): JSX.Element {
  const { t } = useTranslation('promotionsDuplicate');
  const { getAccount } = useAccount();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ModalDuplicateInput>({
    defaultValues: {
      title: '',
    },
  });

  const [duplicatePromotion, { loading: loadingDuplicate }] = useMutation(DUPLICATE_PROMOTION, {
    onError: (error: ApolloError) => {
      if (error.graphQLErrors[0]?.message === 'VALIDATION_ERROR') {
        setError('title', { message: error.graphQLErrors[0]?.extensions?.message as string, type: 'manual' });
      } else {
        console.log('[Error]: UPDATE_CUSTOM_SLUG', error);
      }
    },
    ...(origin === 'list' && { refetchQueries: ['promotions', 'promotionCounter'] }),
    onCompleted: () => callback(),
  });

  const onSubmit = handleSubmit((data) => {
    duplicatePromotion({
      variables: {
        title: data.title,
        accountId: getAccount.accountId,
        promoId,
      },
    });
  });

  return (
    <form onSubmit={onSubmit} noValidate>
      <Modal
        leftButton={{ children: t('Close'), variant: 'secondary', onClick: () => callback() }}
        rightButton={{ children: t('Save'), variant: 'primary', type: 'submit', loading: loadingDuplicate }}
        title={t('Duplicate Promotion')}
        callback={callback}
      >
        <Input
          label={t('New title')}
          id="title"
          type="text"
          {...register('title', { required: true })}
          {...(!!errors.title && { color: 'danger' })}
          helperText={errors.title?.type === 'required' ? t('Please provide a valid value.') : errors.title?.message}
        />
      </Modal>
    </form>
  );
}
