import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { popoverUpgradeSettings } from '../../../../../componentsV2/ButtonPopoverUpgradePlan/popoverUpgradeSettings';
import { PopoverExtendedProps } from '../../../../../componentsV2/DesignSystem/Popover/interface';
import SectionCollapsibleFull from '../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../componentsV2/DesignSystem/Sections/interface';
import SpinnerBox from '../../../../../componentsV2/DesignSystem/SpinnerBox';
import useAccount from '../../../../../hooks/useAccount';
import useQueryString from '../../../../../hooks/useQueryString';
import { usePlanPermissions } from '../../../../../providers/PlanPermissionsProvider';
import Content from './Content';
import Empty from './Empty';
import styles from './styles.module.scss';
import { useFetchWidgets } from './useFetchWidgets';

export default function WidgetList(): JSX.Element {
  const { getAccount } = useAccount();
  const LIMIT = 10;
  const query = useQueryString();
  const currentPage = query.get('page') ? Number(query.get('page')) : undefined;
  const { hasPermission } = usePlanPermissions();
  const history = useHistory();

  const { data, loading, refetch, pagination } = useFetchWidgets(getAccount.accountId, currentPage, LIMIT);

  const planPermissionDisplayToPlay = useMemo(() => {
    const permission = hasPermission('display_to_play');
    return {
      hasPermission: permission?.hasPermission,
      allowedPlans: permission?.allowedPlans,
    };
  }, []);

  const showButtonPopover: PopoverExtendedProps | undefined = useMemo(() => {
    if (!planPermissionDisplayToPlay.allowedPlans) {
      return undefined;
    }

    return popoverUpgradeSettings({
      planNameAllowed: planPermissionDisplayToPlay.allowedPlans,
      onUpgradeClick: () => history.push('/self-service'),
    });
  }, [planPermissionDisplayToPlay]);

  const isEmpty = !(data && data.length);

  return (
    <SectionCollapsibleFull
      badge={{ icon: { name: isEmpty ? 'play_arrow' : 'widgets', color: 'enabled' }, status: StatusBadge.icon }}
      expanded={true}
      header={{
        title: loading ? 'Loading...' : isEmpty ? 'Create a Display to Play Widgets' : 'Your Widgets',
      }}
      containerClassname={styles.container}
      wrapperClassname={styles.wrapper}
      showButton={
        !planPermissionDisplayToPlay.hasPermission
          ? {
              children: 'Upgrade Required',
              firstIcon: { children: 'lock' },
              borderStyle: 'rainbow',
              size: 'sm',
            }
          : undefined
      }
      showButtonPopover={showButtonPopover}
    >
      {loading ? (
        <div className={styles['spinner-container']}>
          <SpinnerBox />
        </div>
      ) : (
        <>
          {!data || data.length === 0 || !planPermissionDisplayToPlay.hasPermission ? (
            <Empty
              hasPermission={planPermissionDisplayToPlay.hasPermission || false}
              allowedPlans={planPermissionDisplayToPlay.allowedPlans}
            />
          ) : (
            <Content widgets={data} refetch={refetch} pagination={pagination} currentPage={currentPage} />
          )}
        </>
      )}
    </SectionCollapsibleFull>
  );
}
