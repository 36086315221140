import { InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        coupons: {
          keyArgs: ['agency_id', 'account_id', 'collectionId'],
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            return {
              ...incoming,
              data: [...existing.data, ...incoming.data],
            };
          },
        },
        couponSearch: {
          keyArgs: ['filters'],
          merge(existing, incoming) {
            if (!incoming) return existing;
            if (!existing) return incoming;

            return {
              ...incoming,
              data: [...existing.data, ...incoming.data],
            };
          },
        },
      },
    },
  },
});

export default cache;
