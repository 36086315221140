import { useHistory } from 'react-router-dom';

const redirectTo = (url: string): void => {
  const history = useHistory();
  if (url.indexOf('://', 0) > 0) {
    window.location.href = url;
  }
  history.push(url);
};

export default redirectTo;
