import React, { useMemo, useState } from 'react';
import Skeleton from '../Skeleton';
import { CarouselImageProps } from './interface';
import styles from './styles.module.scss';
import Icon from '../Icon';

export default function Carousel(carousel: CarouselImageProps): JSX.Element {
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  function handleCarouselIndex(type: '-' | '+') {
    if (type === '-') {
      setCarouselIndex((prev) => prev - 1);
    } else {
      setCarouselIndex((prev) => prev + 1);
    }
  }
  const newArray = useMemo(() => {
    return carousel.medias.map((media, index) => ({
      ...media,
      index,
    }));
  }, [carousel.medias]);

  return (
    <div className={styles['carousel-wrapper']}>
      <div className={styles['media']} style={carousel.styles}>
        {carousel.isLoading ? (
          <Skeleton />
        ) : (
          newArray.map(({ media, index }) => (
            <div
              key={`image-carousel-${index}`}
              className={[styles['child'], carouselIndex === index ? styles['active'] : ''].join(' ')}
            >
              <a href={media.url} target="_blank" rel="noreferrer">
                <img src={media.image_url} alt={media?.image_alt} />
              </a>
            </div>
          ))
        )}
      </div>

      <footer>
        <div className={styles['description-wrapper']}>
          {carousel.isLoading ? (
            <Skeleton width={100} height={1.25} />
          ) : (
            <>
              {newArray?.[carouselIndex]?.title && (
                <a href={newArray?.[carouselIndex]?.media?.url} target="_blank" rel="noreferrer">
                  <h3>{newArray[carouselIndex]?.title}</h3>
                </a>
              )}
              {newArray?.[carouselIndex]?.description && (
                <span>
                  <a href={newArray[carouselIndex]?.media?.url} target="_blank" rel="noreferrer">
                    {newArray[carouselIndex]?.description}
                  </a>
                </span>
              )}
            </>
          )}
        </div>
        <div className={styles['arrows-wrapper']}>
          <button onClick={() => handleCarouselIndex('-')} disabled={!newArray || carouselIndex === 0}>
            <Icon>chevron_left</Icon>
          </button>
          <button
            onClick={() => handleCarouselIndex('+')}
            disabled={!newArray || carouselIndex === newArray.length - 1}
          >
            <Icon>chevron_right</Icon>
          </button>
        </div>
      </footer>
    </div>
  );
}
