import React from 'react';
import { uid } from 'uid';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { CheckboxProps } from './interface';
import styles from './styles.module.scss';

export default React.forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { label, animation, checkboxSize, ...props }: CheckboxProps,
  ref,
): JSX.Element {
  const customId = props.id || `checkbox-label-${uid()}`;
  const size = checkboxSize ? checkboxSize : 'md';
  return (
    <div className={styleModuleClasses(styles, 'wrapper-checkbox', animation, size)}>
      <input className="material-symbols-rounded" type="checkbox" ref={ref} {...props} id={customId} />
      {!!label && <label htmlFor={customId}>{label}</label>}
    </div>
  );
});
