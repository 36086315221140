import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import SpinnerBox from '../../../../componentsV2/DesignSystem/SpinnerBox';
import useAccount from '../../../../hooks/useAccount';
import useToast from '../../../../hooks/useToast';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import ConfigureEmail from './ConfigureEmail';
import EmailSuccessfullySent from './EmailSuccessfullySent';
import PreviewEmail from './PreviewEmail';
import PreviewSend from './PreviewSend';
import useSendEmail, { Section } from './SendEmailProvider';
import SetupEmailMasking from './SetupEmailMasking';
import ValidationResolver from './ValidationResolver';
import { SendEmailProps, SendEmailRef, SendEmailVariables } from './interface';
import styles from './styles.module.scss';
import { useFetchSenderListEmailByAccount } from './useFetchSenderListEmailByAccount';
import { useLazyFetchInviteLinksByAccount } from './useLazyFetchInviteLinksByAccount';
import { useSendEmailByCodeMutation } from './useSendEmailByCodeMutation';
import { useSendEmailByGenerateIdMutation } from './useSendEmailByGenerateIdMutation';

export default React.forwardRef<SendEmailRef, SendEmailProps>(function SendEmail(
  { micrositeUrl, generateId, code, firstSection, invitelinkUrl, firstName, emailListByCode }: SendEmailProps,
  ref,
) {
  const [section, setSection] = useState<Section>(firstSection || 'configuration');

  const {
    setStepSectionEmail,
    setLoadingSectionEmail,
    setEnableSendEmail,
    stepSectionEmail,
    setSenderEmail,
    senderEmail,
  } = useSendEmail();

  const { getAccount } = useAccount();
  const { toast } = useToast();

  const { data: dataInviteLink, loading: loadingInviteLink, fetchInviteLinks } = useLazyFetchInviteLinksByAccount();
  const { data: dataSenderListEmail, loading: loadingSenderListEmail } = useFetchSenderListEmailByAccount(
    getAccount.accountId,
  );

  useEffect(() => {
    if (dataSenderListEmail && dataSenderListEmail[0]) {
      setSenderEmail(dataSenderListEmail[0]);
    }
  }, [dataSenderListEmail]);

  useEffect(() => {
    if (generateId) {
      fetchInviteLinks({
        accountId: getAccount.accountId,
        page: 1,
        filters: {
          generator_id: generateId.toString(),
        },
      });
    }
  }, [generateId]);

  useEffect(() => {
    if (!firstSection) {
      setSection(stepSectionEmail);
    }
  }, [stepSectionEmail, firstSection]);

  const emailOptions = useMemo(() => {
    if (dataInviteLink) {
      const response = dataInviteLink
        .filter((invite) => invite.data && Object.keys(invite.data).length > 0 && !!invite.data['email'])
        .map((invite) => ({
          label: invite.data['first-name'] || invite.data['email'],
          value: invite.data['email'],
          url: `${invite['url']}?inv=${invite['code']}`,
          code: invite['code'],
        }));
      return response;
    }
    return [];
  }, [dataInviteLink]);

  useEffect(() => {
    if (dataInviteLink.length > 0) {
      const checkNoEmail =
        dataInviteLink.findIndex(
          (invite) => invite.data && Object.keys(invite.data).length > 0 && !!invite.data['email'],
        ) > -1;

      setEnableSendEmail(checkNoEmail);
    }
  }, [dataInviteLink]);

  const {
    sendEmailByGenerateId,
    loading: loadingSendEmailByGenerateId,
    data: dataSendEmailByGenerateId,
  } = useSendEmailByGenerateIdMutation();

  const {
    sendEmailByCode,
    loading: loadingSendEmailByCode,
    data: dataSendEmailByCode,
    error,
  } = useSendEmailByCodeMutation();

  const methods = useForm<SendEmailVariables>({
    resolver: ValidationResolver,
    defaultValues: {
      subject: '',
      greeting: '',
      greeting_font_color: '#000000',
      greeting_font_size: '16px',
      content: '',
      content_font_color: '#000000',
      content_font_size: '16px',
      enter_button_text: '',
      enter_button_color: '#FFFFFF',
      enter_button_size: '16px',
      enter_button_background_color: '#1a3a51',
      signature: '',
      signature_font_color: '#000000',
      signature_font_size: '16px',
      footer_text: '',
      footer_text_font_color: '#000000',
      footer_text_font_size: '12px',
      invitelink_font_color: '#2396fb',
      invitelink_font_size: '16px',
      background: '#FFFFFF',
    },
  });

  const { handleSubmit, getValues, setValue, setError, clearErrors } = methods;

  useEffect(() => {
    setValue('subject', 'Enter Now for Your Chance to Win the Grand Prize!');
    setValue('greeting', 'Hi');
    setValue(
      'content',
      "You've been invited into the draw to win our grand prize! Don't miss your chance to be the lucky winner. Click the link below to play now and see if you can claim the prize!",
    );
    setValue('enter_button_text', 'Play Now');
    setValue('signature', 'Thank you\nThe Beeliked Team.');
    setValue('footer_text', 'The Promoter of this promotion is BeeLiked.');
  }, []);

  useEffect(() => {
    if (error) {
      toast('error', 'An error occurred when trying to send the email');
    }
  }, [error]);

  useEffect(() => {
    if (dataSendEmailByGenerateId || dataSendEmailByCode) {
      setSection('success');
      setStepSectionEmail('success');
    }
  }, [dataSendEmailByGenerateId, dataSendEmailByCode]);

  useEffect(
    () => setLoadingSectionEmail(loadingSendEmailByGenerateId || loadingSendEmailByCode),
    [loadingSendEmailByGenerateId, loadingSendEmailByCode],
  );

  const submit = handleSubmit((variables) => {
    if (!senderEmail) {
      toast('error', 'Email Setup Required');
      return;
    }

    const formattedContent = variables.content?.replace(/\n/g, '<br />') || '';
    const formattedSignature = variables.signature?.replace(/\n/g, '<br />') || '';
    const formattedFooterText = variables.footer_text?.replace(/\n/g, '<br />') || '';

    const data = {
      ...variables,
      greeting: variables.greeting || '',
      content: formattedContent,
      enter_button_text: variables.enter_button_text || '',
      signature: formattedSignature,
      footer_text: formattedFooterText,
      testEmailTo: undefined,
    };

    if (code) {
      sendEmailByCode?.({
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
        code,
        data,
        senderId: senderEmail.senderId,
        sender: senderEmail.sender,
        senderName: senderEmail.senderName,
      });
    } else if (generateId) {
      sendEmailByGenerateId({
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
        generateId,
        data,
        senderId: senderEmail.senderId,
        sender: senderEmail.sender,
        senderName: senderEmail.senderName,
      });
    }
  });

  const handleSection = (option: Section) => {
    setSection(option);
  };

  const nextSection = () => {
    clearErrors('subject');
    const subject = getValues('subject');
    if (section === 'configuration') {
      if (subject !== '') {
        setStepSectionEmail('preview');
        setSection('preview');
      } else {
        setError('subject', { type: 'required' });
      }
    } else if (section === 'preview-send') {
      if (subject !== '') {
        submit();
      } else {
        setError('subject', { type: 'required' });
      }
    } else {
      submit();
    }
  };

  const backSection = () => {
    setSection('configuration');
    setStepSectionEmail('configuration');

    if (section === 'configuration') {
      setValue('content', '');
      setValue('subject', '');
      setValue('greeting', '');
      setValue('enter_button_text', '');
      setValue('signature', '');
      setValue('testEmailTo', '');
    }
  };

  useImperativeHandle(ref, () => ({
    nextSection: nextSection,
    backSection: backSection,
  }));

  if (loadingInviteLink || loadingSenderListEmail) {
    return (
      <div className={styles['loading']}>
        <SpinnerBox />
      </div>
    );
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={submit} className={styles['sender-form']}>
          {!senderEmail && <SetupEmailMasking />}
          <div
            className={styleModuleClasses(styles, 'wrapper', {
              'one-column': section === 'success',
            })}
          >
            {section === 'preview-send' && (
              <PreviewSend invitelinkUrl={invitelinkUrl} firstName={firstName} code={code} />
            )}
            {section === 'configuration' && <ConfigureEmail />}
            {section === 'preview' && (
              <PreviewEmail
                handleSection={handleSection}
                micrositeUrl={micrositeUrl}
                emailOptions={emailListByCode || emailOptions}
              />
            )}
            {section === 'success' && <EmailSuccessfullySent />}
          </div>
        </form>
      </FormProvider>
    </>
  );
});
