import { useEffect, useRef, useState } from 'react';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import { Button } from '../Inputs/Buttons/ButtonAction';
import ButtonNavigation from '../Inputs/Buttons/ButtonNavigation';
import Popover from '../Popover';
import { CardAnimations, CardProps } from './interface';
import styles from './styles.module.scss';

export default function Card({
  size,
  animation,
  identify,
  header,
  footer,
  children,
  border,
  showButton,
  showButtonPopover,
}: CardProps): JSX.Element {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handlePopover = (popoverAction: boolean) => setShowPopover(popoverAction);

  useEffect(() => {
    if (showButtonPopover?.forceClose) {
      setShowPopover(false);
    }
  }, [showButtonPopover?.forceClose]);

  return (
    <div
      className={styleModuleClasses(styles, 'card', {
        [size as string]: !!size,
        [animation?.type as CardAnimations]: !!animation && !!animation.type,
        border: border,
      })}
      style={{
        gridArea: identify,
        animationDuration: animation?.duration ?? '0',
      }}
    >
      <header className={header.breakLineOnMobile ? styles['break-line'] : ''}>
        <h5 className={styleModuleClasses(styles, { 'flex-1': !showButton && !header.removeFillTitle })}>
          {header.title}
        </h5>
        {header.headerContent && <div>{header.headerContent}</div>}
        {!!showButton && (
          <div className={styles['right-button']}>
            <Button
              ref={buttonRef}
              size="sm"
              variant="secondary"
              {...showButton}
              {...(showButtonPopover && { onMouseEnter: () => handlePopover(true) })}
              {...(showButtonPopover?.hiddenOverlay &&
                showButtonPopover && { onMouseLeave: () => handlePopover(false) })}
            />
            {showButtonPopover && (
              <Popover
                placement="auto"
                propRef={buttonRef}
                showPopover={showPopover}
                callback={() => handlePopover(false)}
                size={showButtonPopover?.size ?? 'sm'}
                header={{
                  title: showButtonPopover?.header?.title,
                  button: {
                    firstIcon: { children: 'close' },
                    text: { color: 'gray-48' },
                    size: 'md',
                    onClick: () => handlePopover(false),
                  },
                }}
                showArrow
                color={showButtonPopover?.color}
                hiddenOverlay={showButtonPopover?.hiddenOverlay}
                {...(showButtonPopover.buttonsFooter && {
                  buttonsFooter: showButtonPopover.buttonsFooter.map((button) => ({
                    ...button,
                    onClick: (event) => {
                      if (button.onClick) {
                        button.onClick(event);
                      }
                      handlePopover(false);
                    },
                  })),
                })}
              >
                <div>
                  <span>{showButtonPopover?.children}</span>
                </div>
              </Popover>
            )}
          </div>
        )}
      </header>
      <main className={!footer?.buttons?.length ? styles['rounded'] : ''}>{children}</main>
      {!!footer && (
        <footer className={footer.align ? styles[footer.align] : ''}>
          {footer.buttons?.map((button, index) => (
            <ButtonNavigation key={`button-${button.children}-${index}`} {...button} />
          ))}
          {footer.leftButton && <Button {...footer.leftButton}>{footer.leftButton.children}</Button>}
          {footer.rightButton && <Button {...footer.rightButton}>{footer.rightButton.children}</Button>}
        </footer>
      )}
    </div>
  );
}
