import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { Heading } from '../../../../../../componentsV2/DesignSystem/Typography';
import { FontWeight } from '../../../../../../componentsV2/DesignSystem/Typography/interface';
import useAuth from '../../../../../../hooks/useAuth';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import useSignupContext from '../../SignupContextProvider';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

export default function StepAccountCreated(): JSX.Element {
  const { setAuthorization } = useAuth();
  const { animations, authorization } = useSignupContext();
  const history = useHistory();

  const goDashboard = () => {
    setAuthorization(authorization);
    setTimeout(() => history.push('/account-manager'), 1000);
  };

  useMemo(() => {
    if (animations.accountCreated === 'center') {
      setTimeout(() => goDashboard(), 1500);
    }
  }, [animations.accountCreated]);

  return (
    <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.accountCreated])}>
      <IconAnimated size="md" icon={{ name: 'check' }} wrapper={{ color: 'green' }} />
      <Heading size={3}>Your account has been created</Heading>
      <Heading size={6} weight={FontWeight.semiBold}>
        You will be redirected to Dashboard
      </Heading>
      <Button onClick={goDashboard} fullWidth>
        Go to Dashboard
      </Button>
    </div>
  );
}
