import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../../../../componentsV2/DesignSystem/Inputs/Input';
import SpinnerBox from '../../../../../../../componentsV2/DesignSystem/SpinnerBox';
import { FontWeight, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../../../../../hooks/useAccount';
import EditFeildsItem from './EditFeildsItem';
import { EditFieldsProps } from './interface';
import styles from './styles.module.scss';

export default function EditFields({ fileHeaders, name }: EditFieldsProps): JSX.Element {
  const { loadingFields, listFields, getDataFields } = useAccount();
  const [showUidField, setShowUidField] = useState<boolean>(false);

  const {
    register,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();

  const disabledField: boolean[] = watch('disabledField');

  useEffect(() => getDataFields(), []);

  useEffect(() => {
    if (name) {
      setValue('name', name);
    }
  }, [name]);

  const handleSwitch = (key: number) => {
    const disabled = disabledField.map((value, index) => (key === index ? !value : value));
    setValue('disabledField', disabled);
  };

  return (
    <>
      <Paragraph size={1} weight={FontWeight.extraBold}>
        Create in Bulk
      </Paragraph>
      <div className={styles['paragraph-content']}>
        <Paragraph size={2}>Match the header row of your CSV file to your existing Audience fields</Paragraph>
        <Paragraph size={2}>
          Please map the columns of your csv file to the data columns that exist in your Audience data. If a field does
          not exist yet, try editing the entry form on a promotion to add a new custom field.
        </Paragraph>
      </div>
      <div className={styles['edit-fields-container']}>
        <div className={styles['content-file-name']}>
          <Input
            {...register('name', {
              required: 'Please provide a valid value.',
            })}
            label="File Name"
            {...(Boolean(errors.name) && {
              helperText: errors.name.message,
              color: 'danger',
            })}
            inputSize="sm"
          />
        </div>
        <div className={styles['content-table']}>
          {loadingFields ? (
            <div className={styles['spinner']}>
              <SpinnerBox />
            </div>
          ) : (
            <>
              <table className={styles['table']}>
                <thead>
                  <tr>
                    {fileHeaders.map((_, key: number) => (
                      <th scope="col" className="left" key={`column-${key}`}>
                        Column {key + 1}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {fileHeaders.map((header: string, key: number) => (
                      <td className="middle" key={`header-${key}`}>
                        <div
                          className={`d-flex justify-content-between align-items-center ${styles['form-group-label']}`}
                        >
                          {header}&thinsp;
                          <div className="form-check form-switch d-flex">
                            <input
                              className="form-check-input form-switch form-switch-md mt-0 mb-0"
                              type="checkbox"
                              onChange={() => handleSwitch(key)}
                              id={`id-disable-column-${key}`}
                              defaultChecked={!disabledField[key]}
                            />
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>

                  <tr>
                    {fileHeaders.map((_, key: number) => (
                      <td key={`value-${key}`}>
                        {!!listFields && (
                          <EditFeildsItem
                            fields={listFields}
                            index={key}
                            showUidField={showUidField}
                            setShowUidField={setShowUidField}
                          />
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </>
  );
}
