import classnames from 'classnames';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import RadioBox from '../../../../../../../componentsV2/DesignSystem/Inputs/RadioBox';
import { FieldData } from '../../../../../../../ts';
import useShareLinks from '../../../ShareLinksProvider';
import { EditFeildsItemProps } from './interface';
import styles from './styles.module.scss';

export default function EditFeildsItem({
  fields,
  index,
  showUidField,
  setShowUidField,
}: EditFeildsItemProps): JSX.Element {
  const {
    register,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { setUidField, uidField, getUidFromlistFields, getFormFieldsWithUidFieldList, setDefaultUidField } =
    useShareLinks();

  const [disabled, slug] = watch(['disabledField', `data.${index}.slug`]);

  const isUidField = slug && fields.find((field) => field.slug === slug)?.is_uid_field;

  const checkDisabled = disabled[index];

  const uidFields = useMemo(() => getUidFromlistFields(fields), [fields]);

  useEffect(() => {
    if (uidFields) {
      const fieldsData = getFormFieldsWithUidFieldList(getValues('data'), uidFields);
      setShowUidField(fieldsData.length > 1);
      if (fieldsData?.[0]?.slug) {
        setDefaultUidField(fieldsData?.[0]?.slug);
      } else {
        setUidField(undefined);
      }
    }
  }, [slug]);

  useEffect(() => {
    setValue(`data.${index}.slug`, null);
    clearErrors(`data.${index}.slug`);
  }, [checkDisabled]);

  const handleUidField = (value: string) => {
    setUidField(value);
  };

  return (
    <div className={styles['form-group']} data-uid={slug === uidField ? uidField : undefined}>
      <select
        className={classnames('form-select', {
          'is-invalid': Boolean(errors.data?.[index]?.slug),
        })}
        {...register(`data.${index}.slug`, {
          required: !checkDisabled,
          disabled: checkDisabled,
        })}
        defaultValue=""
        disabled={checkDisabled}
      >
        <option value="" disabled>
          Select
        </option>
        {fields.map((item: FieldData) => (
          <option key={item.id} value={item.slug}>
            {item.name}
          </option>
        ))}
      </select>
      {Boolean(errors.data?.[index]?.slug) && <div className="invalid-feedback">Please select a column.</div>}
      {isUidField && showUidField && (
        <div className={styles['uid-field']}>
          <RadioBox
            name="uid-fiel-name"
            label="UID Field"
            onChange={() => handleUidField(slug)}
            radioSize="md"
            checked={slug === uidField}
          />
        </div>
      )}
    </div>
  );
}
