import React from 'react';
import Skeleton from '../../../../../../componentsV2/DesignSystem/Skeleton';

export default function SkeletonLoading(): JSX.Element {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton key={`skeleton-header-${index}`} height={1} />
      ))}
    </>
  );
}
