import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/_atoms/Button';
import { LoadMoreButtonProps } from './interface';

export default function LoadMoreButton({ total, limit, disableLoadMore, loadMore }: LoadMoreButtonProps): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'build' });

  return (
    <>
      {total >= limit && !disableLoadMore && (
        <div className="d-flex justify-content-center mt-3">
          <Button type="button" color="primary" onClick={loadMore}>
            {t('Load more')}
          </Button>
        </div>
      )}
    </>
  );
}
