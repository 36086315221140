import { useRef } from 'react';
import Modal from '../../../../componentsV2/DesignSystem/Modal';
import SendEmail from '../SendEmail';
import useSendEmail from '../SendEmail/SendEmailProvider';
import { SendEmailRef } from '../SendEmail/interface';
import styles from './styles.module.scss';

interface SendEmailModalProps {
  setOpenModal: (openModal: boolean) => void;
  code: string;
  invitelinkUrl?: string;
  firstName?: string;
}

export default function SendEmailModal({
  setOpenModal,
  code,
  invitelinkUrl,
  firstName,
}: SendEmailModalProps): JSX.Element {
  const sendEmailRef = useRef<SendEmailRef>(null);
  const { loadingSectionEmail, stepSectionEmail } = useSendEmail();

  const nextSection = () => {
    if (sendEmailRef.current) {
      sendEmailRef.current.nextSection();
    }
  };

  return (
    <Modal
      title="Send Email"
      callback={() => setOpenModal(false)}
      size="xxl"
      {...(stepSectionEmail !== 'success' && {
        leftButton: {
          children: 'Close',
          variant: 'secondary',
          size: 'sm',
          onClick: () => setOpenModal(false),
          disabled: loadingSectionEmail,
        },
        rightButton: {
          children: 'Everything OK, send email now',
          size: 'sm',
          onClick: nextSection,
          disabled: loadingSectionEmail,
          loading: loadingSectionEmail,
        },
      })}
      customClass={styles['modal-container']}
    >
      <SendEmail
        ref={sendEmailRef}
        invitelinkUrl={invitelinkUrl}
        code={code}
        firstName={firstName}
        firstSection="preview-send"
      />
    </Modal>
  );
}
