/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentNode, OperationVariables, QueryHookOptions } from '@apollo/client';
import { useEffect, useState } from 'react';

interface DataType<T> {
  data?: T;
  loading?: boolean;
  refetch: (variables?: OperationVariables) => void;
  error: string | undefined;
}

interface QueryFakeHookOptions<T> extends QueryHookOptions<T, OperationVariables> {
  defaultValue: T;
}

export default function useFakeQuery<T>(
  gql: DocumentNode,
  options: QueryFakeHookOptions<T>,
  enableError = false,
): DataType<T> {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError(() => (enableError && Math.random() > 0.8 ? 'Simulated error occurred' : undefined));
      setLoading(false);
    }, 1000);
  }, []);

  const refetch = (variables?: OperationVariables) => {
    console.log('variables', variables);
    setLoading(true);
    setTimeout(() => {
      setError(() => (enableError && Math.random() > 0.8 ? 'Simulated error occurred during refetch' : undefined));
      setLoading(false);
    }, 700);
  };

  if (error) {
    return {
      data: undefined,
      loading: false,
      error,
      refetch,
    };
  }

  return {
    data: options?.defaultValue,
    loading,
    error,
    refetch,
  };
}

/*
How to use

1. set typescript
export interface AccountData {
  id: number;
  name: string;
  slug: string;
}

2. set gql
const FAVORITE_ACCOUNTS_GQL = gql`
  query accounts($agency_id: ID!, $user_id: ID) {
    accounts(agency_id: $agency_id, user_id: $user_id) {
      data {
        id
        name
        plan
        entries {
          current
          total
          percentual
        }
        favorite
      }
    }
  }
`;

3. set default values
const DEFAULT_VALUES = {
  accounts: {
    data: [
      {
        id: 2,
        name: 'Hello BeeLiked',
        allowance: { planType: 'premium', entryAllowanceUsed: 500, entryAllowance: 1000, entriesRemaining: 35 },
      },
      {
        id: 4,
        name: 'Hello BeeLiked',
        allowance: { planType: 'essentials', entryAllowanceUsed: 500, entryAllowance: 1000, entriesRemaining: 50 },
      },
      {
        id: 5,
        name: 'Hello BeeLiked',
        allowance: { planType: 'essentials', entryAllowanceUsed: 500, entryAllowance: 1000, entriesRemaining: 5 },
      },
    ] as AccountData[],
};


4. call useFakeQuery
const { data, loading, refetch } = useFakeQuery<{ accounts: { data: AccountData[]; } }>(
  FAVORITE_ACCOUNTS_GQL,
  {
    defaultValue: DEFAULT_VALUES,
    variables: {
      agency_id: 1,
      user_id: 2121,
    },
  },
);

example:
Application/src/pages/Admin/Account/ChooseAnAccount/FavoriteAccounts/index.tsx

*/
