import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import SpinnerBox from '../../../components/_molecules/SpinnerBox';
import useAccount from '../../../hooks/useAccount';
import AppKeys from './AppKeys';
import ChooseAnAccount from './ChooseAnAccount';
import SwitchAccount from './SwitchAccount';

export default function Account(): JSX.Element {
  const { path } = useRouteMatch();
  const { slug } = useParams<{ slug: string }>();
  const { t } = useTranslation('translation');
  const { getAccount, refetchAccountBySlug } = useAccount();

  useEffect(() => {
    if (!Object.keys(getAccount).length) {
      return;
    }

    if (slug !== getAccount.accountSlug && path !== '/account') {
      refetchAccountBySlug({
        variables: {
          agency_id: getAccount.agencyId,
          account_slug: slug,
        },
      });
    }
  }, [getAccount, slug]);

  if (slug !== getAccount?.accountSlug && path !== '/account') {
    return <SpinnerBox className="mt-5" text={t('Checking account')} />;
  }

  return (
    <Switch>
      <Route exact path={`${path}/app-keys`} component={AppKeys} />
      <Route exact path={`${path}/switch-account`} component={SwitchAccount} />
      <Route path={`${path}/choose-account`} component={ChooseAnAccount} />
    </Switch>
  );
}
