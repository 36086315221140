import ButtonNavigation from '../Inputs/Buttons/ButtonNavigation';
import { BreadcrumbProps } from './interface';
import styles from './styles.module.scss';

export default function Breadcrumb({ backToUrl, items }: BreadcrumbProps): JSX.Element {
  return (
    <div className={styles.breadcrumb}>
      <div>
        <ButtonNavigation firstIcon={{ children: 'arrow_back_ios' }} variant="secondary" to={backToUrl} size="sm" />
        <ul>
          {items.map((item, index) => (
            <li key={`breadcrumb-item-${index}`}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
