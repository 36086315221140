import { useFormContext } from 'react-hook-form';
import QRCode from '../../../../../../assets/imgs/widget-qrcode.svg';
import { PromotionVisibility } from '../../../../../../ts';
import { DisplayProps } from './interface';
import styles from './styles.module.scss';

export default function Display({ imageFile, promotionVisibility }: DisplayProps): JSX.Element {
  const { watch } = useFormContext();

  const [
    header_message,
    header_message_font_color,
    message1,
    message1_font_color,
    message2,
    message2_font_color,
    expire_time,
    page_background,
    widget_background,
  ] = watch([
    'header_message',
    'header_message_font_color',
    'message1',
    'message1_font_color',
    'message2',
    'message2_font_color',
    'expire_time',
    'page_background',
    'widget_background',
  ]);

  return (
    <div className={styles['background']} style={{ backgroundColor: page_background }}>
      <div className={styles['wrapper']}>
        <div className={styles['container']} style={{ backgroundColor: widget_background }}>
          {imageFile && <img src={imageFile} />}

          <h3
            style={{
              color: header_message_font_color,
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {header_message}
          </h3>

          <p
            style={{
              color: message1_font_color,
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {message1}
          </p>

          <img src={QRCode} className={styles['qr-code']} />

          <div className={styles['expire-time-container']}>
            <div className={styles['expire-time']}>
              <div className={styles['message2']}>
                <p
                  style={{
                    color: message2_font_color,
                    fontFamily: 'Plus Jakarta Sans',
                  }}
                >
                  {message2}
                </p>
              </div>
              {promotionVisibility === PromotionVisibility.PRIVATE && (
                <div
                  className={styles['timer']}
                  style={{
                    color: message2_font_color,
                    fontFamily: 'Plus Jakarta Sans',
                  }}
                >
                  {expire_time}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
