import React from 'react';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import styles from './styles.module.scss';

export default function Empty(): JSX.Element {
  return (
    <div className={styles['no-promotion-wrapper']}>
      <IconAnimated wrapper={{ color: 'primary' }} icon={{ name: 'videogame_asset_off' }} />
      <h4>{`There are no promotions to show here.`}</h4>
    </div>
  );
}
