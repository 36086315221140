import React from 'react';
import SkeletonLoading from './SkeletonLoading';
import { FormatNumberTwoNumeric } from '../../../../../../componentsV2/DesignSystem/Utils/Format';
import styles from '../styles.module.scss';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import { PromotionCounterNavProps } from './interface';

export default function PromotionCounterNav({
  promotionsCounterList,
  loading,
  selected,
}: PromotionCounterNavProps): JSX.Element {
  return (
    <div className={styles['promotion-header']}>
      {loading ? (
        <SkeletonLoading />
      ) : (
        promotionsCounterList?.map((status, index) => (
          <button
            key={`promotion-header-${index}`}
            className={styleModuleClasses(styles, status.title.toLowerCase(), {
              [styles['selected']]: (status.selected && selected === undefined) || status.status === selected,
            })}
            onClick={status.onClick}
          >
            {status.title} <span>{status.quantity === 0 ? 0 : FormatNumberTwoNumeric(status.quantity)}</span>
          </button>
        ))
      )}
    </div>
  );
}
