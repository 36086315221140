import styleModuleClasses from '../../../utils/styleModuleClasses';
import { IconProps } from './interface';
import styles from './styles.module.scss';

export default function Icon({
  customFont,
  fill,
  size,
  children,
  color,
  style,
  customClass,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: IconProps): JSX.Element {
  if (customFont) {
    return <span className={styleModuleClasses(styles, 'icon', 'custom', customClass, children)}></span>;
  }

  return (
    <span
      className={styleModuleClasses(styles, 'icon', 'material-symbols-rounded', size, customClass, color, {
        fill: !!fill,
      })}
      data-text-color={color}
      style={style}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </span>
  );
}
