import Icon from '../Icon';
import ButtonNavigation from '../Inputs/Buttons/ButtonNavigation';
import { PlanIconProps } from './interface';

export default function PlanIcon({ plan }: PlanIconProps): JSX.Element {
  switch (plan.toLocaleLowerCase()) {
    case 'enterprise':
      return <Icon>location_city</Icon>;
    case 'premium':
      return <Icon customFont>premium</Icon>;
    case 'standard':
      return <Icon>token</Icon>;
    case 'essentials':
      return <Icon> hub</Icon>;
    case 'freebee':
      return <Icon>device_hub</Icon>;
    case 'noplan':
      return <Icon>arrow_upward</Icon>;
    default:
      return (
        <ButtonNavigation to="/self-service" firstIcon={{ children: 'arrow_upward' }} fullWidth>
          Upgrade Plan
        </ButtonNavigation>
      );
  }
}
