import { ApolloError, gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { paginatorInfoFragment } from '../../../../../components/Pagination/paginator.gql';
import { SimplePaginationInfo, paginatorInfo } from '../../../../../ts';

export interface AccountData {
  id: number;
  name: string;
  slug: string;
  allowance: AccountDataAllowance;
}

interface AccountDataAllowance {
  entryAllowance: number;
  entryAllowanceUsed: number;
  planType: string;
  entriesRemaining: number;
}

interface FetchAccountWithPlanByUserResult {
  loading: boolean;
  data: AccountData[] | null;
  pagination: SimplePaginationInfo;
  error?: string;
  refetch?: (variables: {
    agencyId: number;
    userId: number;
    currentPage?: number;
    search?: string;
    sortOrder?: string;
    sortBy?: string;
  }) => void;
}

const ACCOUNT_LIST_WITH_PLAN_BY_USER = gql`
  query accountListWithPlanByUser(
    $agencyId: ID!
    $userId: ID!
    $page: Int
    $search: String
    $filter: String
    $limit: Int
  ) {
    accountListWithPlanByUser(
      agencyId: $agencyId
      userId: $userId
      page: $page
      search: $search
      filter: $filter
      limit: $limit
    ) {
      data {
        id
        name
        slug
        allowance {
          entryAllowance
          entryAllowanceUsed
          planType
          entriesRemaining
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export function useFetchAccountWithPlanByUser(
  agencyId: number,
  userId: number,
  currentPage?: number,
  search?: string,
  filter?: string,
  limit?: number,
): FetchAccountWithPlanByUserResult {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: limit || 12,
  });

  const { data, loading, error, refetch } = useQuery<{
    accountListWithPlanByUser: { data: AccountData[]; paginatorInfo: paginatorInfo };
  }>(ACCOUNT_LIST_WITH_PLAN_BY_USER, {
    fetchPolicy: 'no-cache',
    variables: {
      agencyId,
      userId,
      page: currentPage,
      search,
      filter,
      limit,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'ACCOUNT_LIST_WITH_PLAN_BY_USER.',
        apolloError: error,
      });
    },
  });

  useEffect(() => {
    if (data?.accountListWithPlanByUser.paginatorInfo) {
      setPagination({
        total: data.accountListWithPlanByUser.paginatorInfo.total,
        currentPage: data.accountListWithPlanByUser.paginatorInfo.current_page,
        totalPerPage: Number(data.accountListWithPlanByUser.paginatorInfo.per_page),
      });
    }
  }, [data?.accountListWithPlanByUser.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: null,
      pagination,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error: error.message,
      pagination,
    };
  }

  return {
    loading: false,
    data: data?.accountListWithPlanByUser.data || null,
    pagination,
    refetch,
  };
}
