import React from 'react';
import { FontWeight } from '..';
import { headersProps } from '../interface';
import styles from './styles.module.scss';
import styleModuleClasses from '../../../../utils/styleModuleClasses';

export function Heading({ children, color, size, weight }: headersProps): JSX.Element {
  const sizeMappings = {
    1: 'h1',
    2: 'h2',
    3: 'h3',
    4: 'h4',
    5: 'h5',
    6: 'h6',
  };

  return (
    <h1 className={styleModuleClasses(styles, color, sizeMappings[size], weight ?? FontWeight.extraBold)}>
      {children}
    </h1>
  );
}
