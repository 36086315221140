import { Heading, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { FontWeight } from '../../../../../../../componentsV2/DesignSystem/Typography/interface';
import { TestimonialProps } from './interface';
import style from './styles.module.scss';

export default function Testimonial({
  message,
  author,
  role,
  company,
  background,
  animation,
}: TestimonialProps): JSX.Element {
  return (
    <div className={[style['testimonial-wrapper'], animation].join(' ')}>
      <img {...background} />
      <aside>
        <Paragraph size={1} weight={FontWeight.semiBold}>
          {message}
        </Paragraph>
        <div>
          <Heading size={6} weight={FontWeight.extraBold}>
            {author}
          </Heading>
          <Paragraph size={3}>{`${role}|${company}`}</Paragraph>
        </div>
      </aside>
    </div>
  );
}
