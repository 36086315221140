import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ButtonShowHide from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonShowHide';
import Input from '../../../../../componentsV2/DesignSystem/Inputs/Input';
import Select from '../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import Switch from '../../../../../componentsV2/DesignSystem/Inputs/Switch';
import { FontWeight, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../../../hooks/useAccount';
import { PluginFormProps } from '../interfaces';
import IconPreview from './Display/IconPreview';
import PluginPreview from './Display/PluginPreview';
import UploadPluginImage from './UploadPluginImage';

import useFetchTypographies from '../../../../../graphql/queries/useFetchTypographies';
import styles from './styles.module.scss';

export default function PluginForm({
  file,
  setFile,
  imageUrl,
  isHeroSectionChecked,
  setIsHeroSectionChecked,
  showGreetingMessage,
  setShowGreetingMessage,
  showHeading,
  setShowHeading,
  showDescription,
  setShowDescription,
  isCountdownChecked,
  setIsCountdownChecked,
  showExpiresIn,
  setShowExpiresIn,
  showExpiresDays,
  setShowExpiresDays,
  showExpiresHours,
  setShowExpiresHours,
  showExpiresMinutes,
  setShowExpiresMinutes,
}: PluginFormProps): JSX.Element {
  const { getAccount } = useAccount();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);

  const { register, control, formState, watch, setValue } = useFormContext();

  const [powered_by_blkd, disable_floating_action_button] = watch([
    'powered_by_blkd',
    'disable_floating_action_button',
  ]);

  const { data, loading } = useFetchTypographies();

  const typographyOptions = useMemo(() => {
    if (data) {
      return data.map((item) => {
        const link = document.createElement('link');
        link.href = `https://fonts.googleapis.com/css?family=${item.family.replace(/ /g, '+')}`;
        link.rel = 'stylesheet';

        document.head.appendChild(link);

        return {
          value: item.family,
          label: item.family,
          typography: item.family,
        };
      });
    }
  }, [data]);

  const alignIconOptions = [
    { value: 'Top/Righ', label: 'Top/Right' },
    { value: 'Top/Left', label: 'Top/Left' },
    { value: 'Bottom/Right', label: 'Bottom/Right' },
    { value: 'Bottom/Left', label: 'Bottom/Left' },
  ];

  const setDisableFloatingActionGutton = () => {
    setValue('disable_floating_action_button', !disable_floating_action_button);
  };

  return (
    <>
      <div className={styles['name-container']}>
        <Input
          fullwidth
          label="Name"
          {...register('name')}
          {...(formState.errors.name && { helperText: formState.errors.name.message, color: 'danger' })}
        />
      </div>
      <div className={styles['grid-container']}>
        <div className={styles['form-column']}>
          <Paragraph size={1} weight={FontWeight.extraBold}>
            Plugin
          </Paragraph>

          <div className={styles['input_group_container']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              Styles
            </Paragraph>
            <div className={styles['input_group_wrapper']}>
              <div className={styles['description-field']}>
                <Paragraph size={2} weight={FontWeight.semiBold}>
                  Brand Color
                </Paragraph>
                <div className={styles['input-color-wrapper']}>
                  <Input {...register('brand_color')} type="color" />
                </div>
              </div>
              <div className={styles['description-field']}>
                <Paragraph size={2} weight={FontWeight.semiBold}>
                  Primary Color
                </Paragraph>
                <div className={styles['input-color-wrapper']}>
                  <Input {...register('primary_color')} type="color" />
                </div>
              </div>
              <div className={styles['description-field']}>
                <Paragraph size={2} weight={FontWeight.semiBold}>
                  Background Color
                </Paragraph>
                <div className={styles['input-color-wrapper']}>
                  <Input {...register('background_color')} type="color" />
                </div>
              </div>
              <hr className={styles['divider-input']} />
              <div className={styles['input-section']}>
                <Paragraph size={2} weight={FontWeight.semiBold}>
                  Typography
                </Paragraph>
                <Controller
                  control={control}
                  name="typography"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      activeSearch
                      placeholder={loading ? 'Loading...' : 'Select your font'}
                      onChange={(value) => onChange(value)}
                      options={typographyOptions}
                      defaultValue={value}
                      limit={30}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className={styles['input_group_container']}>
            <div className={styles['input-group-title-wrapper']}>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                Alert
              </Paragraph>
              <Switch checked={!disable_floating_action_button} onChange={() => setDisableFloatingActionGutton()} />
            </div>
            <div className={styles['input_group_wrapper']}>
              <div className={styles['input-section']}>
                <Paragraph size={2} weight={FontWeight.semiBold}>
                  Image
                </Paragraph>
                <div className={styles['drag-drop-field']}>
                  <UploadPluginImage
                    imagePreviewUrl={imagePreviewUrl}
                    setImagePreviewUrl={setImagePreviewUrl}
                    setFile={setFile}
                    filename={file.name}
                    extesions={['png', 'jpg', 'svg']}
                  />
                </div>
              </div>
              <hr className={styles['divider-input']} />
              <div className={styles['input-section']}>
                <Paragraph size={2} weight={FontWeight.semiBold}>
                  Align
                </Paragraph>
                <Controller
                  control={control}
                  name="image_align"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      activeSearch
                      placeholder={'Top/Right'}
                      onChange={(value) => onChange(value)}
                      options={alignIconOptions}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className={styles['input_group_container']}>
            <div className={styles['input-group-title-wrapper']}>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                Hero Section
              </Paragraph>
              <Switch checked={isHeroSectionChecked} onChange={() => setIsHeroSectionChecked(!isHeroSectionChecked)} />
            </div>
            <div className={styles['input_group_wrapper']}>
              <div className={styles['input-section']}>
                <div className={styles['plugin-description-field']}>
                  <Input
                    className={styles['input-rewards-plugin']}
                    label="Greeting / First Name"
                    {...register('greeting_message')}
                    placeholder="Hey"
                    disabled={!showGreetingMessage || !isHeroSectionChecked}
                  />
                  <ButtonShowHide
                    onClick={() => {
                      if (isHeroSectionChecked) setShowGreetingMessage(!showGreetingMessage);
                    }}
                    show={isHeroSectionChecked && showGreetingMessage}
                  />
                </div>
                <hr className={styles['divider-input']} />
                <div className={styles['plugin-description-field']}>
                  <Input
                    label="Heading"
                    {...register('heading_message')}
                    placeholder=""
                    className={styles['input-rewards-plugin']}
                    disabled={!showHeading || !isHeroSectionChecked}
                  />
                  <ButtonShowHide
                    onClick={() => {
                      if (isHeroSectionChecked) setShowHeading(!showHeading);
                    }}
                    show={isHeroSectionChecked && showHeading}
                  />
                </div>
                <div className={styles['plugin-description-field']}>
                  <Input
                    className={styles['input-rewards-plugin']}
                    label="Description"
                    {...register('description_message')}
                    placeholder="Here you can play some games that we have selected for you as a thank you for your effort, you can also access your vouchers"
                    disabled={!showDescription || !isHeroSectionChecked}
                  />
                  <ButtonShowHide
                    onClick={() => {
                      if (isHeroSectionChecked) setShowDescription(!showDescription);
                    }}
                    show={isHeroSectionChecked && showDescription}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles['input_group_container']}>
            <div className={styles['input-group-title-wrapper']}>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                Countdown
              </Paragraph>
              <Switch checked={isCountdownChecked} onChange={() => setIsCountdownChecked(!isCountdownChecked)} />
            </div>
            <div className={styles['input_group_wrapper']}>
              <div className={styles['input-section']}>
                <div className={styles['plugin-description-field']}>
                  <Input
                    className={styles['input-rewards-plugin']}
                    label="Text"
                    {...register('countdown_text_message')}
                    placeholder="Expires in:"
                    disabled={!showExpiresIn || !isCountdownChecked}
                  />
                  <ButtonShowHide
                    onClick={() => {
                      if (isCountdownChecked) setShowExpiresIn(!showExpiresIn);
                    }}
                    show={isCountdownChecked && showExpiresIn}
                  />
                </div>
                <hr className={styles['divider-input']} />
                <div className={styles['input-group-row']}>
                  <div className={styles['plugin-description-field']}>
                    <Input
                      className={styles['input-rewards-plugin']}
                      label="Days"
                      {...register('days')}
                      placeholder="DAYS"
                      disabled={!showExpiresDays || !isCountdownChecked}
                    />
                    <ButtonShowHide
                      onClick={() => {
                        if (isCountdownChecked) setShowExpiresDays(!showExpiresDays);
                      }}
                      show={isCountdownChecked && showExpiresDays}
                    />
                  </div>
                  <div className={styles['plugin-description-field']}>
                    <Input
                      className={styles['input-rewards-plugin']}
                      label="Hours"
                      {...register('hours')}
                      placeholder="HRS"
                      disabled={!showExpiresHours || !isCountdownChecked}
                    />
                    <ButtonShowHide
                      onClick={() => {
                        if (isCountdownChecked) setShowExpiresHours(!showExpiresHours);
                      }}
                      show={isCountdownChecked && showExpiresHours}
                    />
                  </div>
                  <div className={styles['plugin-description-field']}>
                    <Input
                      className={styles['input-rewards-plugin']}
                      label="Minutes"
                      {...register('minutes')}
                      placeholder="MIN"
                      disabled={!showExpiresMinutes || !isCountdownChecked}
                    />
                    <ButtonShowHide
                      onClick={() => {
                        if (isCountdownChecked) setShowExpiresMinutes(!showExpiresMinutes);
                      }}
                      show={isCountdownChecked && showExpiresMinutes}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              CTA Button
            </Paragraph>
          </div>
          <div className={styles['input-cta-wrapper']}>
            <div className={styles['description-field']}>
              <Input label="Text" {...register('cta_text_message')} placeholder="Play now" />
            </div>
          </div>

          <div className={styles['input-group-title-wrapper']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              Powered by Beeliked
            </Paragraph>
            <Switch checked={powered_by_blkd} onChange={() => setValue('powered_by_blkd', !powered_by_blkd)} />
          </div>
        </div>

        <div className={styles['preview-column']}>
          <div className={styles['section-icon']}>
            <IconPreview
              accountSlug={getAccount.accountSlug}
              imageUrl={!!imagePreviewUrl ? imagePreviewUrl : imageUrl}
            />
          </div>

          <div className={styles['section-rewards-plugin']}>
            <PluginPreview
              accountSlug={getAccount.accountSlug}
              isHeroSectionChecked={isHeroSectionChecked}
              showHeading={showHeading}
              showGreetingMessage={showGreetingMessage}
              showDescription={showDescription}
              isCountdownChecked={isCountdownChecked}
              showExpiresIn={showExpiresIn}
              showExpiresDays={showExpiresDays}
              showExpiresHours={showExpiresHours}
              showExpiresMinutes={showExpiresMinutes}
            />
          </div>
        </div>
      </div>
    </>
  );
}
