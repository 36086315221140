import { ApolloError, gql, useMutation } from '@apollo/client';

interface CreateAnonymousDownload {
  quantity: number;
  url: string;
}

const CREATE_ANONYMOUS_DOWNLOAD = gql`
  mutation createAnonymousDownload($accountId: ID!, $promoId: ID!, $url: String!, $quantity: Int!, $expiresAt: String) {
    createAnonymousDownload(
      accountId: $accountId
      promoId: $promoId
      url: $url
      quantity: $quantity
      expiresAt: $expiresAt
    ) {
      quantity
      url
    }
  }
`;

export function useCreateAnonymousDownloadMutation(): {
  loading: boolean;
  createAnonymousDownload: (variables: {
    accountId: number;
    promoId: number;
    url: string;
    quantity: number;
    expiresAt?: string;
  }) => void;
  data: CreateAnonymousDownload | null;
} {
  const [createAnonymousDownloadMutation, { loading, data }] = useMutation<{
    createAnonymousDownload: CreateAnonymousDownload;
  }>(CREATE_ANONYMOUS_DOWNLOAD, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'CREATE_ANONYMOUS_DOWNLOAD',
        apolloError: error,
      });
    },
  });

  const createAnonymousDownload = (variables: {
    accountId: number;
    promoId: number;
    url: string;
    quantity: number;
    expiresAt?: string;
  }) => {
    createAnonymousDownloadMutation({ variables });
  };

  return {
    loading,
    createAnonymousDownload,
    data: data?.createAnonymousDownload || null,
  };
}
