import { useEffect, useRef, useState } from 'react';
import styleModuleClasses from '../../utils/styleModuleClasses';
import Dropdown from '../DesignSystem/Dropdown';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import { FontWeight, Paragraph } from '../DesignSystem/Typography';
import { TableCell } from './Cell';
import Empty from './Empty';
import SkeletonTable from './SkeletonTable';
import { TableColumnsControlProps, TableDataCellSerialized, TableHeaderElement } from './interface';
import styles from './styles.module.scss';
import CheckBox from '../DesignSystem/Inputs/CheckBox';
import Modal from '../DesignSystem/Modal';
import Input from '../DesignSystem/Inputs/Input';
import Alert from '../DesignSystem/Alert';
// import { CSVLink } from 'react-csv';
import { HeaderCell } from './HeaderCell';

export default function TableColumnsControl({
  column,
  toggleColumns,
  loading,
  actionButtons,
  selectLine,
  lineSize,
  firstColumnFixed,
  lastColumnFixed,
  filtersFixed,
  bulkActions,
  // exportSelectedCsv,
  viewMore,
}: TableColumnsControlProps): JSX.Element {
  const [lineSelected, setLineSelected] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [shadowFirstColumn, setShadowFirstColumn] = useState<boolean>(false);
  const [shadowLastColumn, setShadowLastColumn] = useState<boolean>(false);
  const [bulkDeleteData, setBulkDeleteData] = useState('');
  //@todo in v3 const [controlConfidentialColumns, setControlConfidentialColumns] = useState({});

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = contentRef.current;
      const right = scrollLeft + clientWidth;

      if (scrollLeft === 0) {
        setShadowLastColumn(true);
        setShadowFirstColumn(false);
      }
      if (scrollLeft > 0 && right < scrollWidth) {
        setShadowFirstColumn(true);
        setShadowLastColumn(true);
      }

      if (right === scrollWidth) {
        if (scrollLeft > 0) {
          setShadowFirstColumn(true);
        }
        setShadowLastColumn(false);
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      handleScroll();
    }
  }, [loading]);
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', handleScroll);
      return () => {
        if (contentRef.current) {
          contentRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  const handleColumn = (id: string) => {
    const columnUpdated = column?.map((item) => {
      if (item.base_field === id) {
        return { ...item, enabled: !item.enabled };
      }
      return item;
    });
    const columnIdList = columnUpdated?.filter((item) => item.enabled === true).map((item) => item.base_field);

    toggleColumns?.(columnUpdated, columnIdList?.join(','));
  };

  const checkBoxColumnIndex =
    column?.findIndex((item) => {
      return item.base_field === selectLine?.columnId;
    }) || 0;

  const handleSelected = (id: string) => {
    const index = lineSelected.indexOf(id);
    const updateLines = index >= 0 ? lineSelected.filter((lineSelected) => lineSelected !== id) : [...lineSelected, id];
    setLineSelected(updateLines);
    selectLine?.handleCheckboxSelected?.(updateLines);

    if (updateLines.length === column?.[checkBoxColumnIndex].data?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const selectAll = () => {
    if (!allChecked) {
      const values = column?.[checkBoxColumnIndex].data?.map((value) => value.id);
      setLineSelected(values || []);
      selectLine?.handleCheckboxSelected?.(values || []);
    } else {
      setLineSelected([]);
      selectLine?.handleCheckboxSelected?.([]);
    }
    setAllChecked(!allChecked);
  };

  /*@todo in v3 useEffect(() => {
    if (loading || column === undefined) {
      setLineSelected([]);
      setAllChecked(false);
    } else {
      const hidePopoverStatus = column
        ?.filter((eachColumn) => !!eachColumn.is_uid_field)
        .map((eachIsConfidential) => {
          return eachIsConfidential.base_field;
        })
        .reduce((ac, a) => ({ ...ac, [a]: false }), {});
      if (hidePopoverStatus) setControlConfidentialColumns(hidePopoverStatus);
    }
  }, [loading, column]); */

  return (
    <div
      className={styleModuleClasses(styles, 'table', lineSize, {
        'shadow-first-column': !loading && firstColumnFixed && shadowFirstColumn,
        'shadow-last-column': !loading && lastColumnFixed && shadowLastColumn,
        'skeleton-table': loading || column === undefined,
      })}
      ref={contentRef}
    >
      {loading || !(column && column[0] && column[0].data) ? (
        <SkeletonTable />
      ) : (
        <>
          {column && column[0] && column[0].data && column[0].data.length > 0 ? (
            <>
              {!!lineSelected.length && !!bulkActions && (
                <div className={styles['bulk-wrapper']}>
                  <Paragraph size={2} weight={FontWeight.extraBold}>
                    Bulk Options
                  </Paragraph>
                  {/* @TODO V1 {!!exportSelectedCsv && (
                    <Button variant="secondary" firstIcon={{ children: 'file_download' }} size="sm">
                      <CSVLink
                        data={exportSelectedCsv}
                        headers={DEFAULT_COLUMNS.map((column) => column.name)}
                        filename={`beeliked_${exportSelectedCsv.length}_record${
                          exportSelectedCsv.length > 1 ? 's' : ''
                        }.csv`}
                        style={{ textDecoration: 'none', fontSize: 'inherit', color: 'inherit', fontWeight: 'inherit' }}
                      >
                        Export Selected
                      </CSVLink>
                    </Button>
                  )} */}
                  <Button
                    variant="secondary"
                    firstIcon={{ children: 'delete', color: 'red-block' }}
                    size="sm"
                    onClick={() => bulkActions?.setShowDeleteModal(true)}
                  >
                    Delete Selected
                  </Button>
                </div>
              )}
              <header
                className={styleModuleClasses(styles, {
                  'first-column-fixed': firstColumnFixed,
                  'last-column-fixed': lastColumnFixed,
                  'filters-fixed': filtersFixed,
                })}
              >
                {!!selectLine && (
                  <div className={styles['column-checkbox']}>
                    <CheckBox onChange={selectAll} checked={allChecked} />
                  </div>
                )}
                {column
                  .sort((a, b) => Number(a.order) - Number(b.order))
                  .sort((a, b) => Number(b.enabled) - Number(a.enabled))
                  .map(
                    (eachColumn, index) => eachColumn.enabled && <HeaderCell {...eachColumn} key={`header-${index}`} />,
                  )}
                {!!actionButtons?.length && <div className={styles['alignment-column']}></div>}
                <div style={{ width: '7rem' }}>
                  <div>
                    {!!toggleColumns && (
                      <>
                        <Dropdown
                          activeButton={{
                            button: {
                              size: 'sm',
                              variant: 'secondary',
                              firstIcon: { children: 'view_column' },
                              lastIcon: { children: 'keyboard_arrow_down', color: 'gray-56' },
                            },
                          }}
                          placement="bottom-end"
                        >
                          <div className={styles['wrapper-manage-filters']}>
                            {column
                              .sort((a, b) => Number(a.order) - Number(b.order))
                              .sort((a, b) => Number(b.enabled) - Number(a.enabled))
                              .map((eachColumn) => (
                                <Button
                                  fullWidth
                                  variant="tertiary"
                                  text={{ color: 'dark', align: 'between' }}
                                  lastIcon={{
                                    children: eachColumn.enabled ? 'visibility' : 'visibility_off',
                                    ...(eachColumn.enabled && { color: 'primary' }),
                                  }}
                                  key={eachColumn.name}
                                  {...((column.filter((eachColumn) => !!eachColumn.enabled).length > 1 ||
                                    !eachColumn.enabled) && {
                                    onClick: () => {
                                      handleColumn(eachColumn.base_field);
                                    },
                                  })}
                                >
                                  <Paragraph size={2} weight={FontWeight.semiBold}>
                                    {eachColumn.name}
                                  </Paragraph>
                                </Button>
                              ))}
                          </div>
                        </Dropdown>
                      </>
                    )}
                  </div>
                </div>
              </header>
              <main
                className={styleModuleClasses(styles, {
                  'first-column-fixed': firstColumnFixed,
                  'last-column-fixed': lastColumnFixed,
                })}
              >
                {!!selectLine && checkBoxColumnIndex && (
                  <div className={styleModuleClasses(styles, 'column', 'column-checkbox')}>
                    {column[checkBoxColumnIndex]?.data?.map((value, index) => {
                      return (
                        <div key={`checkbox-${index}`}>
                          <CheckBox
                            checked={lineSelected.indexOf(value.id) >= 0}
                            onChange={() => {
                              handleSelected(value.id);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
                {column.map(
                  ({ enabled, width, minWidth, align = 'left', data, type }, index) =>
                    enabled && (
                      <div
                        className={styleModuleClasses(styles, 'column', align)}
                        key={`column-${index}`}
                        {...(minWidth ? { style: { minWidth: minWidth } } : undefined)}
                        {...(width ? { style: { width: width } } : undefined)}
                      >
                        {data?.map((eachData, i) => {
                          return (
                            <TableCell
                              key={`cell-${i}`}
                              type={eachData.type || type}
                              hide={false}
                              value={eachData}
                              callback={() => viewMore?.callback(Number(eachData.id))}
                            />
                          );
                        })}
                      </div>
                    ),
                )}
                {!!actionButtons?.length && (
                  <>
                    <div className={`${styles['column']} ${styles['alignment-column']}`}>
                      {column[0].data?.map((_, index) => (
                        <div key={`alignment-column-${index}`} className={styles['alignment-column']}>
                          &nbsp;
                        </div>
                      ))}
                    </div>

                    <div className={styles['column']} style={{ width: '7rem' }}>
                      {column[0].data?.map((_, index) => (
                        <div className={styles['wrapper-cta']} key={`cta-${index}`}>
                          {Array.isArray(actionButtons) && actionButtons[index]}
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {filtersFixed && (
                  <>
                    <div className={`${styles['column']} ${styles['fill-column']}`}>
                      {column[0].data?.map((_, index) => (
                        <div key={`alignment-column-${index}`} className={styles['fill-column']}></div>
                      ))}
                    </div>

                    {/* <div className={styles['column']} style={{ width: '7rem' }}>
                      {column[0].data?.map((_, index) => (
                        <div className={styles['wrapper-cta']} key={`cta-${index}`}>
                          aaaa
                        </div>
                      ))}
                    </div> */}
                  </>
                )}
              </main>
            </>
          ) : (
            <Empty />
          )}
        </>
      )}
      {viewMore?.component}
      {bulkActions?.showDeleteModal && viewMore?.data && (
        <Modal
          title={`Delete ${lineSelected.length} record${lineSelected.length > 1 ? 's' : ''}`}
          size="small"
          callback={() => {
            bulkActions?.setShowDeleteModal(false);
            setBulkDeleteData('');
          }}
          leftButton={{
            children: 'Cancel',
            variant: 'secondary',
            onClick: () => {
              bulkActions?.setShowDeleteModal(false);
              setBulkDeleteData('');
            },
          }}
          rightButton={{
            children: 'Delete',
            variant: 'red-block',
            disabled: !(String(lineSelected.length) == bulkDeleteData) || bulkActions.deleteLoading,
            onClick: () => {
              bulkActions?.delete(lineSelected.join(','));
              setBulkDeleteData('');
              setLineSelected([]);
            },
          }}
        >
          <div className={styles['wrapper-modal']}>
            Are you sure you want to delete?
            <Alert color="danger-light">This entry will become anonymous</Alert>
            <Input
              label={`Type ${lineSelected.length} to confirm`}
              placeholder={String(lineSelected.length)}
              value={bulkDeleteData}
              onChange={(e) => setBulkDeleteData(e.target.value.toLocaleUpperCase())}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export function buildTableResult(
  header: TableHeaderElement[],
  contentTable: TableDataCellSerialized[],
): TableHeaderElement[] {
  return header.map((column) => {
    const data = contentTable.map((item) => {
      if (!!item[column.base_field as keyof TableDataCellSerialized]) {
        if (column.type == 'pill') {
          const parse = JSON.parse(item[column.base_field as keyof TableDataCellSerialized]);
          return {
            id: item.id,
            base_field: column.base_field,
            message: '',
            ...parse,
          };
        } else if (column.type == 'tag') {
          const parse = JSON.parse(item[column.base_field as keyof TableDataCellSerialized]);
          return {
            id: item.id,
            base_field: column.base_field,
            message: '',
            ...parse,
          };
        } else {
          return {
            id: item.id,
            base_field: column.base_field,
            message: item[column.base_field as keyof TableDataCellSerialized].toString(),
            ...(item?.avatar && { src: item.avatar }),
          };
        }
      } else {
        return {
          id: item.id,
          base_field: column.base_field,
          message: ' - ',
        };
      }
    });
    return { ...column, data };
  });
}

export function filterTableColumns(header: TableHeaderElement[], tableColumns?: string): TableHeaderElement[] {
  if (!!tableColumns) {
    const tableColumnsArray = tableColumns?.split(',');
    return header.map((column) => ({
      ...column,
      enabled: tableColumnsArray.includes(column.base_field),
    }));
  }
  return header;
}
