import { TableHeaderElement } from '../../../../../../componentsV2/TableColumnsControl/interface';

export const DEFAULT_COLUMNS: TableHeaderElement[] = [
  {
    id: '9990',
    name: 'Name',
    base_field: 'name',
    type: 'name',
    enabled: true,
    sortDisabled: true,
    order: 1,
    is_uid_field: false,
    width: '12rem',
    align: 'left',
  },
  {
    id: '9991',
    name: 'Promotion',
    base_field: 'promotion_name',
    type: 'tag',
    enabled: true,
    sortDisabled: true,
    order: 2,
    is_uid_field: false,
    width: '12rem',
    align: 'center',
  },
  {
    id: '9992',
    name: 'Game Type',
    base_field: 'game_type',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 3,
    is_uid_field: false,
    width: '12rem',
    align: 'left',
  },
  {
    id: '9993',
    name: 'Entry Method',
    base_field: 'visibility',
    type: 'pill',
    enabled: true,
    sortDisabled: true,
    order: 4,
    is_uid_field: false,
    width: '12rem',
    align: 'center',
  },
  {
    id: '9994',
    name: 'Launch Status',
    base_field: 'status_promo',
    type: 'pill',
    enabled: true,
    sortDisabled: true,
    order: 5,
    is_uid_field: false,
    width: '12rem',
    align: 'center',
  },
  {
    id: '9995',
    name: 'Reward Label',
    base_field: 'reward_label',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 6,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
  },
  {
    id: '9996',
    name: 'Result Label',
    base_field: 'result_label',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
  },
  {
    id: '9997',
    base_field: 'email',
    type: 'text',
    enabled: true,
    name: 'Email',
    sortDisabled: true,
    order: 8,
    width: '12rem',
    align: 'left',
  },
  {
    id: '9998',
    name: 'Opened Date',
    base_field: 'created_at',
    type: 'date',
    enabled: true,
    sortDisabled: true,
    order: 9,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
  },
  {
    id: '9999',
    name: 'Used Date',
    base_field: 'used_at',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 10,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '10000',
    name: 'Invite Code',
    base_field: 'invite_code',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 4,
    is_uid_field: false,
    width: '15rem',
    align: 'left',
  },
  {
    id: '10001',
    name: 'Terms and Conditions',
    base_field: 'terms',
    type: 'pill',
    enabled: true,
    sortDisabled: true,
    order: 6,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '10002',
    name: 'Newsletter',
    base_field: 'newsletter',
    type: 'pill',
    enabled: true,
    sortDisabled: true,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '10003',
    name: 'Expire Date',
    base_field: 'expire_date',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 8,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '10004',
    name: 'First Name',
    base_field: 'first_name',
    type: 'text',
    enabled: false,
    sortDisabled: true,
    order: 9,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '10005',
    name: 'Last Name',
    base_field: 'last_name',
    type: 'text',
    enabled: false,
    sortDisabled: true,
    order: 10,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
];
