import QRCode from 'react-qr-code';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import { Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import useShareLinks from '../../ShareLinksProvider';
import styles from './styles.module.scss';

export default function PrintQRCode(): JSX.Element {
  const { promotionSelected } = useShareLinks();

  const downloadSvg = () => {
    const svg = document.querySelector('svg');
    if (!svg) {
      return;
    }
    const svgData = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qr_code.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <SectionCollapsibleFull
      badge={{
        status: StatusBadge.icon,
        icon: { name: 'qr_code', color: 'enabled' },
        number: 2,
      }}
      expanded={true}
      header={{ title: 'Print QR Code' }}
      containerClassname={styles['container-section']}
    >
      <>
        <Paragraph size={2}>{'On scanning this QR people will be redirected to your promotion’s page'}</Paragraph>
        <div>
          <QRCode value={promotionSelected.micrositeUrl} size={164} />
        </div>
        <div>
          <Button firstIcon={{ children: 'qr_code' }} onClick={downloadSvg} variant="secondary" size="sm">
            Download QR Code
          </Button>
        </div>
      </>
    </SectionCollapsibleFull>
  );
}
