import React from 'react';
import ProgressbarPercentage from '../../../../../componentsV2/DesignSystem/ProgressbarPercentage';
import Empty from './Empty';
import { CollectionListProps } from './interface';
import { FormatNumberComma } from '../../../../../componentsV2/DesignSystem/Utils/Format';

export default function CollectionList({ collections }: CollectionListProps): JSX.Element {
  if (!collections.length) {
    return <Empty />;
  }

  return (
    <>
      {collections.map((coupon) => (
        <ProgressbarPercentage
          key={`coupon-${coupon.id}`}
          title={coupon.name}
          percentageHighlightingDanger={20}
          currentAmount={coupon.coupon_used}
          totalAmount={coupon.total_coupons}
          customDescriptionOfTotals={`${FormatNumberComma(coupon.coupon_left)} remaining`}
          showTotal={false}
          revertProgressbar={true}
        />
      ))}
    </>
  );
}
