import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import TabNavigation from '../../../componentsV2/DesignSystem/TabNavigation';
import { Heading } from '../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../hooks/useAccount';
import useAuth from '../../../hooks/useAuth';
import Forbidden from '../../Forbidden';
import Entries from './Entries';
import styles from './styles.module.scss';
import { Contacts } from './Contacts';
import ManageInviteLinks from './ManageInviteLinks';
import useFetchExistsInviteLinks from './useFetchExistInviteLinks';
import Pills from '../../../componentsV2/DesignSystem/Pills';
import { PillsColors } from '../../../componentsV2/DesignSystem/Pills/interface';

export default function Audience(): JSX.Element {
  const { path, url } = useRouteMatch();
  const { getAccount } = useAccount();
  const { denyEditorAccess } = useAuth();
  const { data } = useFetchExistsInviteLinks(getAccount.accountId);
  const tabItems = [
    {
      link: `${url}/entries`,
      icon: 'person',
      name: 'Entries',
    },
    {
      link: `${url}/contacts`,
      icon: 'contact_mail',
      name: 'Contacts',
    },
    {
      link: `${url}/insights`,
      icon: 'bar_chart',
      name: 'Insights',
      disabled: true,
    },
    {
      link: `${url}/sweepstakes`,
      icon: 'emoji_events',
      name: 'Sweepstakes',
      disabled: true,
    },
  ];

  if (!!data.existInviteLinks.exists) {
    tabItems.push({
      link: `${url}/manage-invitelinks`,
      icon: 'emoji_events',
      name: 'Manage Invitelinks',
      disabled: false,
    });
  }

  if (denyEditorAccess(getAccount.accountId)) {
    return <Forbidden title="Audience" />;
  }

  return (
    <div className={styles['wrapper']} id="audience">
      <div className={styles['heading-wrapper']}>
        <Heading size={2}>Data Center </Heading>
        <Pills color={PillsColors.green} size="lg">
          Beta
        </Pills>
      </div>
      <TabNavigation tabItems={tabItems.sort((a, b) => Number(a.disabled) - Number(b.disabled))} />

      <section>
        <Switch>
          {/*@todo on v2 <Route exact path={`${path}/insights`} component={Insights} /> */}
          <Route exact path={`${path}/entries`} component={Entries} />
          <Route exact path={`${path}/contacts`} component={Contacts} />
          {/*@todo on v2 <Route
            exact
            path={`${path}/sweepstakes`}
            component={() => (
              <SweepstakesProvider>
                <Sweepstakes />
              </SweepstakesProvider>
            )}
          /> */}
          <Route exact path={`${path}/manage-invitelinks`} component={ManageInviteLinks} />
          <Redirect path={url} to={`${url}/entries`} />
        </Switch>
      </section>
    </div>
  );
}
