import React from 'react';
import { PromotionContentHeaderProps } from './interface';
import styles from './styles.module.scss';

export default function PromotionContentHeader({ show }: PromotionContentHeaderProps): JSX.Element {
  if (!show) {
    return <></>;
  }
  const header = ['Name', 'Type', 'Live On', 'Entries', 'Actions'];
  return (
    <div className={styles['promotions-header']}>
      {header.map((name) => (
        <span key={name}>{name}</span>
      ))}
    </div>
  );
}
