import { ApolloClient, ApolloLink } from '@apollo/client';
import authLink from './AuthLink';
import cache from './Cache';
import errorLink from './ErrorLink';
import httpLink from './HttpLink';

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache,
});

export default apolloClient;
