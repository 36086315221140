import { useRef, useState } from 'react';
import capitalize from '../../utils/capitalize';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import PopoverUpgrade from './PopoverUpgrade';
import { ButtonPopoverUpgradePlanProps } from './interface';

export default function ButtonPopoverUpgradePlan({
  planNameAllowed,
  activated,
  hideButtonDescription,
  popover,
  buttonSize,
  buttonProps,
  children,
}: ButtonPopoverUpgradePlanProps): JSX.Element {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handlePopover = () => {
    setShowPopover((showPopover) => !showPopover);
  };

  return (
    <>
      {!activated && (
        <>
          <Button
            firstIcon={{ children: 'lock' }}
            text={{ color: 'dark' }}
            variant="secondary"
            ref={buttonRef}
            borderStyle="rainbow"
            onClick={handlePopover}
            onMouseEnter={() => setShowPopover(true)}
            size={buttonSize ?? 'sm'}
            {...buttonProps}
          >
            {!hideButtonDescription && `${capitalize(planNameAllowed[0])} Feature`}
            {children}
          </Button>
          <PopoverUpgrade
            popover={popover}
            planNameAllowed={planNameAllowed}
            handlePopover={handlePopover}
            showPopover={showPopover}
            setShowPopover={setShowPopover}
            propRef={buttonRef}
          />
        </>
      )}
    </>
  );
}
