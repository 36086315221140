export const NODE_ENV = process.env.NODE_ENV || 'development';
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development';
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8100/local/graphql';
export const REACT_APP_API_MOCKING = process.env.REACT_APP_API_MOCKING === 'true';
export const REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || '.dev.beeliked.local';
export const REACT_APP_COOKIE_PREFIX =
  process.env.REACT_APP_COOKIE_PREFIX || (REACT_APP_ENVIRONMENT === 'production' ? '' : 'dev_');
export const REACT_APP_MONOLITH_V2 = process.env.REACT_APP_MONOLITH_V2 || 'https://app.beeliked.local';
export const REACT_APP_X_API_KEY_GRAPHQL =
  process.env.REACT_APP_X_API_KEY_GRAPHQL || 'd41d8cd98f00b204e9800998ecf8427e';
export const REACT_APP_INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || 'n6lmejd5';
export const REACT_APP_INTERCOM_USER_HASH =
  process.env.REACT_APP_INTERCOM_USER_HASH || 'HqymXXNuNW-BfjhaGS5nXk0zX9kLgRPlVa4GZGQq';
export const REACT_APP_ADMIN_HOMEPAGE = process.env.REACT_APP_ADMIN_HOMEPAGE || '/dashboard';
export const REACT_APP_PH_ENABLE = process.env.REACT_APP_PH_ENABLE === 'true';
export const REACT_APP_PH_API_KEY = process.env.REACT_APP_PH_API_KEY || '';
export const REACT_APP_PH_API_HOST = process.env.REACT_APP_PH_API_HOST || '';
export const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const REACT_APP_SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development';
export const REACT_APP_ZAPIER_AUTHORIZE =
  process.env.REACT_APP_ZAPIER_AUTHORIZE || 'https://zapier.com/oauth/authorize';
export const REACT_APP_ZAPIER_REDIRECT_URI =
  process.env.REACT_APP_ZAPIER_REDIRECT_URI || 'https://v2.beeliked.com/credentials/zapier';
export const REACT_APP_ZAPIER_EMBED_CREATE =
  process.env.REACT_APP_ZAPIER_EMBED_CREATE || 'https://api.zapier.com/v1/embed/beeliked/create/';
export const REACT_APP_ZAPIER_EMBED_EDITOR =
  process.env.REACT_APP_ZAPIER_EMBED_EDITOR || 'https://zapier.com/app/editor/';
export const REACT_APP_HOST_LOCAL = process.env.REACT_APP_HOST_LOCAL || 'https://v2.beeliked.local:3005';
export const REACT_APP_HOST_DEVELOPMENT = process.env.REACT_APP_HOST_DEVELOPMENT || 'https://v2.dev.beeliked.com';
export const REACT_APP_ZAPIER_EMBED_CREATE_ZAP =
  process.env.REACT_APP_ZAPIER_EMBED_CREATE_ZAP || 'https://zapier.com/webintent/create-zap';
export const REACT_APP_TRANSLATION_LOCAL = process.env.REACT_APP_TRANSLATION_LOCAL === 'true';
export const REACT_APP_HUBSPOT_APP_ID = process.env.REACT_APP_HUBSPOT_APP_ID || '26970341';
export const REACT_APP_STRIPE = process.env.REACT_APP_STRIPE || 'pk_test_9vTeGEhrN1G1Nzo7X2U352gZ';
export const REACT_APP_ASSETS_IMAGES =
  process.env.REACT_APP_ASSETS_IMAGES || 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/';
export const REACT_APP_RECAPTCHA_V3_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || '6Lf2pUUpAAAAAEY7LOArBHQaKfQRoNVQyK-R11ff';
export const REACT_APP_TERMS_URL = '/settings/your-details';
export const REACT_APP_HIVE_URL = process.env.REACT_APP_HIVE_URL || 'https://hive.beeliked.local';
export const REACT_APP_HUBSPOT = process.env.REACT_APP_HUBSPOT || undefined;
export const ENABLE_BATCH_HTTP_LINK = process.env.ENABLE_BATCH_HTTP_LINK === 'true';
export const REACT_APP_WIDGET_URL = process.env.REACT_APP_WIDGET_URL || '';
