import { FilterList } from './interface';

export const CONTACTS_FILTER_LIST: FilterList[] = [
  {
    id: 'number_entries',
    name: 'Number of Entries',
    slug: 'number_entries',
    type: 'multi',
    options: [
      { label: 'All', value: '' },
      { label: '01 > 05', value: 'minimum' },
      { label: '06 > 10', value: 'medium' },
      { label: '10+', value: 'maximum' },
    ],
    enable: false,
  },
];

export const ENTRIES_FILTER_LIST: FilterList[] = [
  {
    id: 'status',
    name: 'Status',
    slug: 'status',
    type: 'mono',
    options: [
      { label: 'Live', value: 'live' },
      { label: 'Draft', value: 'draft' },
      { label: 'Ended', value: 'ended' },
    ],
    enable: true,
  },
  {
    id: 'visibility',
    name: 'Visibility',
    slug: 'visibility',
    type: 'mono',
    options: [
      { label: 'Public', value: 'public' },
      { label: 'Private', value: 'private' },
    ],
    enable: true,
  },
  {
    id: 'game_type',
    name: 'Game Type',
    slug: 'game_type',
    type: 'mono',
    options: [],
    enable: true,
  },
];
