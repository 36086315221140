import { ApolloError, gql, useMutation } from '@apollo/client';

interface SendEmailTest {
  status: string;
  message: string;
}

interface InviteLinkSendEmailData {
  subject: string;
  greeting?: string;
  greeting_font_color?: string;
  greeting_font_size?: string;
  content?: string;
  content_font_color?: string;
  content_font_size?: string;
  enter_button_text?: string;
  enter_button_color?: string;
  enter_button_size?: string;
  enter_button_background_color?: string;
  signature?: string;
  signature_font_color?: string;
  signature_font_size?: string;
  footer_text?: string;
  footer_text_font_color?: string;
  footer_text_font_size?: string;
  invitelink_font_color?: string;
  invitelink_font_size?: string;
  background?: string;
}

const SEND_EMAIL_TEST = gql`
  mutation sendEmailTest(
    $agencyId: ID!
    $accountId: ID!
    $testEmailTo: String!
    $data: InviteLinkSendEmailData
    $senderId: ID
    $sender: String
    $senderName: String
    $code: String
  ) {
    sendEmailTest(
      agencyId: $agencyId
      accountId: $accountId
      testEmailTo: $testEmailTo
      data: $data
      senderId: $senderId
      sender: $sender
      senderName: $senderName
      code: $code
    ) {
      status
      message
    }
  }
`;

export function useSendEmailTestMutation(): {
  loading: boolean;
  sendEmail: (variables: {
    agencyId: number;
    accountId: number;
    testEmailTo: string;
    data: InviteLinkSendEmailData;
    senderId: number;
    sender: string;
    senderName: string;
    code?: string;
  }) => void;
  data: SendEmailTest | null;
} {
  const [sendEmailTestMutation, { loading, data }] = useMutation<{
    sendEmailTest: SendEmailTest;
  }>(SEND_EMAIL_TEST, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'SEND_EMAIL_TEST',
        apolloError: error,
      });
    },
  });

  const sendEmail = (variables: {
    agencyId: number;
    accountId: number;
    testEmailTo: string;
    data: InviteLinkSendEmailData;
    senderId: number;
    sender: string;
    senderName: string;
    code?: string;
  }) => {
    sendEmailTestMutation({ variables });
  };

  return {
    loading,
    sendEmail,
    data: data?.sendEmailTest || null,
  };
}
