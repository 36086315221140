import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import posthog from 'posthog-js';
import { createContext, useContext, useEffect, useState } from 'react';
import { REACT_APP_PH_API_HOST, REACT_APP_PH_API_KEY, REACT_APP_PH_ENABLE } from '../config/Env';

interface Props {
  children: JSX.Element | JSX.Element[];
}

interface PostHogAccountVariables {
  agency_id: number;
  account_id: number;
  account_name: string;
  account_slug: string;
  plan_description: string;
  latest_payment_date: string;
  next_payment_date: string;
  user_count: number;
  live_promotions: number;
  status: string;
  joined_date: string;
  user_joined_date: string;
  churned_date: string;
  most_recent_promotion_launch_date: string;
  most_recent_promotion: string;
  live_promotions_list: string;
  admin_user: string;
  is_admin: boolean;
}

interface FetchAccountDataLogVariables {
  accountId: number;
  agencyId: number;
}

interface PosthogAccountData {
  accountId: number;
  agencyId: number;
  accountSlug: string;
  accountName: string;
  planType: string;
}

interface PosthogUserData {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
}

interface PostHogContext {
  fetchAccountDataLog: (variables: FetchAccountDataLogVariables) => void;
  captureEvent: (description: string, data: Record<string, string | number>) => void;
  resetPostHog: () => void;
  captureDowngradeClickEvent: () => void;
  updateAccountName: (newAccountName: string) => void;
  updateUser: (firstName: string, lastName: string, email: string) => void;
  capturePendingDowngradeEvent: (active?: boolean, planDescription?: string, churnedDate?: string) => void;
  initializePostHog: (agencyId: number, accountId: number, userData: PosthogUserData) => void;
}

export const GET_ACCOUNT_LOG = gql`
  query accountDataLog($agencyId: ID!, $accountId: ID!) {
    accountDataLog(agencyId: $agencyId, accountId: $accountId) {
      agency_id
      account_id
      account_name
      account_slug
      plan_description
      latest_payment_date
      next_payment_date
      user_count
      live_promotions
      status
      joined_date
      churned_date
      most_recent_promotion_launch_date
      most_recent_promotion
      live_promotions_list
      admin_user
      user_joined_date
      is_admin
    }
  }
`;

const PostHogContext = createContext({});

export function PostHogProvider({ children }: Props): JSX.Element {
  const [account, setAccount] = useState<PosthogAccountData>();
  const [user, setUser] = useState<PosthogUserData>();

  const [fetchAccountDataLog] = useLazyQuery<{ accountDataLog: PostHogAccountVariables }>(GET_ACCOUNT_LOG, {
    onCompleted: (response) => {
      populateProvider(response.accountDataLog);
      populatePosthog(response.accountDataLog);
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'GET_ACCOUNT_LOG',
        apolloError: error,
      });
    },
  });

  useEffect(() => {
    if (REACT_APP_PH_ENABLE) {
      posthog.init(REACT_APP_PH_API_KEY, {
        api_host: REACT_APP_PH_API_HOST,
        debug: false,
      });
    }
  }, []);

  const initializePostHog = (agencyId: number, accountId: number, userData: PosthogUserData) => {
    if (REACT_APP_PH_ENABLE) {
      setUser(userData);

      fetchAccountDataLog({
        variables: {
          accountId,
          agencyId,
        },
      });
    }
  };

  const populateProvider = (postHogAccountVariables: PostHogAccountVariables) => {
    setAccount({
      agencyId: postHogAccountVariables.agency_id,
      accountId: postHogAccountVariables.account_id,
      accountSlug: postHogAccountVariables.account_slug,
      accountName: postHogAccountVariables.account_name,
      planType: postHogAccountVariables.plan_description,
    });
  };

  const populatePosthog = (postHogAccountVariables: PostHogAccountVariables) => {
    if (!user) {
      return;
    }

    posthog.identify(user.userId.toString());

    const userData = {
      account_name: postHogAccountVariables.account_name,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      joined_date: postHogAccountVariables.user_joined_date,
      live_promotions: postHogAccountVariables.live_promotions,
      most_recent_promotion_launch_date: postHogAccountVariables.most_recent_promotion_launch_date,
      most_recent_promotion: postHogAccountVariables.most_recent_promotion,
      live_promotions_list: postHogAccountVariables.live_promotions_list
        ? JSON.parse(postHogAccountVariables.live_promotions_list)
        : null,
      status: postHogAccountVariables.status,
      accountID: postHogAccountVariables.account_id,
      accountSlug: postHogAccountVariables.account_slug,
      admin_user: postHogAccountVariables.admin_user ? JSON.parse(postHogAccountVariables.admin_user) : undefined,
      plan_description: postHogAccountVariables.plan_description,
      isAdmin: postHogAccountVariables.is_admin,
    };

    posthog.capture('$identify', {
      distinct_id: user.userId.toString(),
      $set: userData,
      timestamp: new Date().toISOString(),
    });

    posthog.group('account', 'id:' + postHogAccountVariables.account_id, {
      account_name: postHogAccountVariables.account_name,
      user_count: postHogAccountVariables.user_count,
      joined_date: postHogAccountVariables.joined_date,
      plan_description: postHogAccountVariables.plan_description,
      live_promotions: postHogAccountVariables.live_promotions,
      most_recent_promotion_launch_date: postHogAccountVariables.most_recent_promotion_launch_date,
      most_recent_promotion: postHogAccountVariables.most_recent_promotion,
      live_promotions_list: postHogAccountVariables.live_promotions_list
        ? JSON.parse(postHogAccountVariables.live_promotions_list)
        : null,
      status: postHogAccountVariables.status,
      admin_user: postHogAccountVariables.admin_user ? JSON.parse(postHogAccountVariables.admin_user) : undefined,
    });
  };

  const captureEvent = (description: string, properties: Record<string, string | number>) => {
    if (REACT_APP_PH_ENABLE && account) {
      posthog.capture(description, properties);

      fetchAccountDataLog({
        variables: {
          accountId: account.accountId,
          agencyId: account.agencyId,
        },
      });
    }
  };

  const captureDowngradeClickEvent = () => {
    if (REACT_APP_PH_ENABLE && account) {
      posthog.capture('Downgrade Plan Clicked', {
        at_risk_at: new Date().toISOString(),
        email: user?.email,
        account_name: account.accountName,
        accountID: account.accountId,
        $set: {
          at_risk_at: new Date().toISOString(),
        },
      });
    }
  };

  const capturePendingDowngradeEvent = (active = true, planDescription = '', churnedDate = '') => {
    if (REACT_APP_PH_ENABLE && account && user) {
      posthog?.identify(user.userId.toString(), {
        status: active ? 'pending_downgrade' : 'paying',
      });

      posthog.group('account', 'id:' + account.accountId, {
        status: active ? 'pending_downgrade' : 'paying',
      });

      if (active) {
        posthog.capture('Account Downgraded', {
          email: user.email,
          plan_description: planDescription,
          previous_plan_description: account.planType,
          account_name: account.accountName,
          accountID: account.accountId,
          churned_date: churnedDate,
        });
      } else {
        posthog.capture('Account Downgrade Cancelled', {
          email: user.email,
          plan_description: account.planType,
          cancelled_plan_description: planDescription,
          account_name: account.accountName,
          accountID: account.accountId,
        });
      }
    }
  };

  const updateAccountName = (newAccountName: string) => {
    if (REACT_APP_PH_ENABLE && account) {
      posthog.group('account', 'id:' + account.accountId, {
        account_name: newAccountName,
      });
    }
  };

  const updateUser = (firstName: string, lastName: string, email: string) => {
    if (REACT_APP_PH_ENABLE && user) {
      posthog?.identify(user.userId.toString(), {
        first_name: firstName,
        last_name: lastName,
        email,
      });
    }
  };

  const resetPostHog = () => {
    if (REACT_APP_PH_ENABLE) {
      posthog?.reset(true);
    }
  };

  return (
    <PostHogContext.Provider
      value={{
        fetchAccountDataLog,
        captureEvent,
        resetPostHog,
        captureDowngradeClickEvent,
        updateAccountName,
        updateUser,
        capturePendingDowngradeEvent,
        initializePostHog,
      }}
    >
      {children}
    </PostHogContext.Provider>
  );
}

export default function usePostHogContext(): PostHogContext {
  const context = useContext(PostHogContext) as PostHogContext;
  return context;
}
