import { useEffect, useMemo, useState } from 'react';
import Select from '../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import Modal from '../../../../../componentsV2/DesignSystem/Modal';
import { PillsColors } from '../../../../../componentsV2/DesignSystem/Pills/interface';
import SectionCollapsibleHalf from '../../../../../componentsV2/DesignSystem/Sections/CollapsibleHalf';
import { SectionCollapsibleFullProps, StatusBadge } from '../../../../../componentsV2/DesignSystem/Sections/interface';
import SpinnerBox from '../../../../../componentsV2/DesignSystem/SpinnerBox';
import { Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import VideoPlayerResponsive from '../../../../../componentsV2/VideoPlayerResponsive';
import useFetchPromotionsSimpleList from '../../../../../graphql/queries/useFetchPromotionsSimpleList';
import useAccount from '../../../../../hooks/useAccount';
import useQueryString from '../../../../../hooks/useQueryString';
import { PromotionVisibility } from '../../../../../ts';
import capitalize from '../../../../../utils/capitalize';
import Empty from '../Empty';
import useShareLinks from '../ShareLinksProvider';
import styles from './styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SectionCollapsibleProps extends Omit<SectionCollapsibleFullProps, 'children' | 'handleCollapse'> {}

export default function SelectPromotion(): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const query = useQueryString();

  const promoId = query.get('promo_id') || undefined;

  const { getAccount } = useAccount();

  const { promotionSelected, setPromotionSelected } = useShareLinks();

  const { data, loading } = useFetchPromotionsSimpleList(getAccount.accountId, { status: 'draft,scheduled,live' });

  const dataPromotions = useMemo(() => {
    return data?.map((promotion) => ({
      label: promotion.name,
      value: promotion.id,
      pill: {
        children: promotion.visibility,
        color: promotion.visibility === PromotionVisibility.PUBLIC ? PillsColors.green : PillsColors.primary,
      },
    }));
  }, [data]);

  const handlePromotion = (id: number) => {
    const promotionSelected = data?.find((item) => Number(item.id) === Number(id));
    if (promotionSelected) {
      setPromotionSelected(promotionSelected);
    }
  };

  useEffect(() => {
    if (promoId) {
      handlePromotion(Number(promoId));
    } else if (data) {
      setPromotionSelected(data[0]);
    }
  }, [data]);

  const sectionCollapsibleHalfProps: SectionCollapsibleProps = loading
    ? {
        badge: { icon: { name: 'share', color: 'enabled' }, status: StatusBadge.icon },
        expanded: true,
        header: {
          title: 'Loading',
        },
      }
    : data === null || data?.length === 0
    ? {
        badge: { icon: { name: 'share', color: 'enabled' }, status: StatusBadge.icon },
        expanded: true,
        header: {
          title: 'Share your promotions',
        },
      }
    : {
        badge: {
          status: promotionSelected?.id ? StatusBadge.icon : StatusBadge.enabled,
          icon: { name: 'done', color: 'green' },
          number: 1,
        },
        expanded: true,
        header: { title: 'Select Promotion' },
        containerClassname: styles['container-section'],
        showButton: { firstIcon: { children: 'question_mark' }, size: 'sm' },
        showButtonPopover: {
          header: {
            title: 'Take a moment to learn all the ways you can share your promotion.',
          },
          color: 'dark',
          size: 'sm',
          buttonsFooter: [
            {
              children: 'Learn More',
              size: 'sm',
              onClick: () => setShowModal(true),
              fullWidth: true,
            },
          ],
        },
      };

  return (
    <SectionCollapsibleHalf {...sectionCollapsibleHalfProps}>
      <>
        {showModal && (
          <Modal title="Share Center Overview" callback={() => setShowModal(false)}>
            <VideoPlayerResponsive urlVideo="https://vimeo.com/1005055380" />
          </Modal>
        )}
        {loading ? (
          <div className={styles['loading']}>
            <SpinnerBox />
          </div>
        ) : data === null || data?.length === 0 ? (
          <Empty />
        ) : (
          <>
            <Select
              label="Select a promotion you want to share"
              placeholder={loading ? 'Loading promotions' : 'Select a promotion'}
              onChange={(id) => handlePromotion(Number(id))}
              options={dataPromotions}
              pill={{
                children: promotionSelected?.visibility ? capitalize(promotionSelected.visibility) : '',
                color: PillsColors.green,
              }}
              activeSearch
              disabled={loading}
              defaultValue={promotionSelected ? promotionSelected.id?.toString() : ''}
            />
            {!!promotionSelected && (
              <Paragraph size={2}>
                {promotionSelected.visibility === PromotionVisibility.PUBLIC
                  ? 'This promotion is public and so anyone with the link can enter.'
                  : 'Your promotion is private. Each person will need their own InviteLink to enter.'}
              </Paragraph>
            )}
          </>
        )}
      </>
    </SectionCollapsibleHalf>
  );
}
