import { forwardRef, useRef } from 'react';
import styleModuleClasses from '../../../../../utils/styleModuleClasses';
import Icon from '../../../Icon';
import Popover from '../../../Popover';
import styles from '../styles.module.scss';
import { ActionProps } from './interface';

const Button = forwardRef<HTMLButtonElement, ActionProps>(
  (
    {
      children,
      firstIcon,
      lastIcon,
      variant,
      loading,
      text,
      fullWidth,
      customClass,
      align,
      size,
      borderStyle,
      disabled,
      active,
      popover,
      ...rest
    }: ActionProps,
    ref,
  ) => {
    const firstIconRef = useRef(null);
    const lastIconRef = useRef(null);
    const buttonRef = useRef(null);
    return (
      <>
        <button
          ref={ref}
          className={styleModuleClasses(styles, 'button', customClass, {
            [`text-${text?.color}`]: !!text?.color,
            [size ?? 'md']: true,
            [variant ?? 'primary']: true,
            active: !!active,
            disabled: !!disabled,
            align: !!align,
            fullWidth: !!fullWidth,
            ['gray-8']: !!borderStyle,
          })}
          type="button"
          {...rest}
          {...(disabled && { onClick: undefined })}
        >
          <div
            className={`${styles['content']} ${text?.align ? styles[text.align] : styles['center']}`}
            ref={buttonRef}
          >
            {firstIcon?.children &&
              (!!loading ? (
                <Icon customClass={styles['loading-icon']}>progress_activity</Icon>
              ) : (
                <div ref={firstIconRef}>
                  <Icon customClass={styles['icon']} {...firstIcon}>
                    {firstIcon.children}
                  </Icon>
                </div>
              ))}
            {children && (
              <main className={!firstIcon?.children && !lastIcon?.children && loading ? styles['loading'] : ''}>
                <span>{children}</span>
                {!!loading && <Icon customClass={styles['loading-icon']}>progress_activity</Icon>}
              </main>
            )}
            {lastIcon?.children &&
              (!!loading ? (
                <Icon customClass={styles['loading-icon']}>progress_activity</Icon>
              ) : (
                <div ref={lastIconRef}>
                  <Icon customClass={styles['icon']} {...lastIcon}>
                    {lastIcon.children}
                  </Icon>
                </div>
              ))}
          </div>
        </button>
        {firstIcon?.popover && <Popover {...firstIcon?.popover} propRef={firstIconRef} />}
        {popover && <Popover {...popover} propRef={buttonRef} />}
        {lastIcon?.popover && <Popover {...lastIcon?.popover} propRef={lastIconRef} />}
      </>
    );
  },
);

Button.displayName = 'Button';

export { Button };
