import { Fragment, useEffect, useState } from 'react';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Select from '../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import SelectMulti from '../../../../componentsV2/DesignSystem/Inputs/Selects/SelectMulti';
import styles from './styles.module.scss';
import { DropdownSelectFilters } from './DropdownSelectFilters';
import { FilterList, FiltersPramsProps, FiltersProps, FilterValueProps, SavedFilterList } from './interface';
import { DropdownSelectFiltersSaved } from './DropdownSelectFiltersSaved';
import SAVED_FILTER_LIST from './SavedFilterList';
import { OptionsProps } from '../../../../componentsV2/DesignSystem/Inputs/Selects/interface';
import { InputDate } from '../../../../componentsV2/DesignSystem/Inputs/InputDate';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import useQueryParams from '../../../../hooks/useQueryParams';

export default function Filters({
  search,
  clearUrlFilters,
  data: promotionData,
  loadingData: promotionLoading,
  gameTypeData,
  gameTypeLoading,
  params,
  filterList,
  filterListLoading,
}: FiltersProps): JSX.Element {
  const [filters, setFilters] = useState<FilterList[]>(filterList);
  const [filtersSaved] = useState<SavedFilterList[]>(SAVED_FILTER_LIST);
  const [filterValue, setFilterValue] = useState<FilterValueProps | null>();
  const [enableFilter, setEnableFilter] = useState<boolean>(false);
  const isFilterEnabled = !!filters.find((filter) => !!filter.enable);
  const [showCreateFilterPopover, setShowCreateFilterPopover] = useState(false);
  const addQueryParam = useQueryParams();

  const loadParametersFilter = (parameters: FiltersPramsProps) => {
    const loadFilter: FilterValueProps = {};
    if (parameters.promo_id) {
      loadFilter['promo_id'] = parameters.promo_id.split(',');
      toggleFilters('promo_id');
    }
    if (parameters.number_entries) {
      loadFilter['number_entries'] = parameters.number_entries;
      toggleFilters('number_entries');
    }
    if (parameters.created_at) {
      loadFilter['created_at'] = parameters.created_at;
      toggleFilters('created_at');
    }
    if (parameters.date) {
      loadFilter['date'] = parameters.date;
      toggleFilters('date');
    }
    if (parameters.status) {
      loadFilter['status'] = parameters.status;
      toggleFilters('status');
    }
    if (parameters.visibility) {
      loadFilter['visibility'] = parameters.visibility;
      toggleFilters('visibility');
    }
    if (parameters.game_type) {
      loadFilter['game_type'] = parameters.game_type;
      toggleFilters('game_type');
    }
    setFilterValue(loadFilter);
    addQueryParam(loadFilter);
    filters.findIndex((filter) => filter.enable) > -1;
  };

  function handleDate(id: string, date: Date | null | (Date | null)[]) {
    const dateArray = [...date];
    const dateFormated = dateArray.map((each) => {
      return each
        ? `${each.getFullYear()}-${each.getMonth() + 1}-${each.getDate() < 10 ? '0' + each.getDate() : each.getDate()}`
        : null;
    });
    if (dateFormated.join('_').length > 1) search(id, dateFormated.join('_').replaceAll('/', '-'));
  }

  const toggleFilters = (id: string) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => (filter.id == id ? { ...filter, enable: !filter.enable } : filter)),
    );
  };
  const toggleSavedFilters = (idList: string[]) => {
    idList.forEach((id) =>
      setFilters((prevFilters) =>
        prevFilters.map((filter) => (filter.id === id ? { ...filter, enable: !filter.enable } : filter)),
      ),
    );
    setEnableFilter(isFilterEnabled ? false : true);
  };

  const handleFilterValue = (id: string, value: string | string[]) => {
    const newFilterValue = { ...filterValue };

    if (value === '' || (Array.isArray(value) && value.length === 0)) {
      delete newFilterValue[id];
    } else {
      newFilterValue[id] = value;
    }

    setFilterValue(newFilterValue);
    search(id, value.toString());
  };

  const handleClearFilter = () => {
    const newFilters = [...filters];
    setFilters(newFilters.map((each) => ({ ...each, enable: false })));
    setEnableFilter(false);
    clearUrlFilters();
  };
  const renderInputs = (filter: FilterList) => {
    switch (filter.type) {
      case 'multi':
        return (
          <SelectMulti
            label={filter.name}
            placeholder={promotionLoading ? 'Loading...' : 'Select'}
            onChange={(value) => handleFilterValue(filter.slug, value)}
            selectValue={
              filterValue && filterValue[filter.slug] ? ([] as string[]).concat(filterValue[filter.slug]) : []
            }
            options={filter.options || []}
            optionsAsPills
            fullWidth
          />
        );
      case 'date':
        const startDate = params.created_at?.split('_')[0]?.split('-');
        const endDate = params.created_at?.split('_')[1]?.split('-');
        return (
          <div className={styles['date-wrapper']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              {filter.name}
            </Paragraph>
            <InputDate
              startDate={
                !!Date.parse(String(params.created_at?.split('_')[0])) && startDate
                  ? new Date(`${startDate[1]}-${startDate[2]}-${startDate[0]}`)
                  : null
              }
              endDate={
                !!Date.parse(String(params.created_at?.split('_')[1])) && endDate
                  ? new Date(`${endDate[1]}-${endDate[2]}-${endDate[0]}`)
                  : null
              }
              locale="en"
              isRange
              format="full-date"
              slug={filter.slug}
              callback={handleDate}
            />
          </div>
        );

      default:
        return (
          <Select
            label={filter.name}
            placeholder="Select"
            onChange={(value) => handleFilterValue(filter.id, value.toString())}
            options={filter.options}
            defaultValue={filterValue && filterValue[filter.id] ? filterValue[filter.id].toString() : ''}
          />
        );
    }
  };

  useEffect(() => {
    if (!!filterValue && !!Object.keys(filterValue).filter((each) => !!each).length && !promotionLoading)
      setEnableFilter(true);
  }, [promotionLoading]);

  useEffect(() => {
    loadParametersFilter(params);
  }, []);

  useEffect(() => {
    if (promotionData && promotionData.length > 0 && !promotionLoading) {
      const promotionSerialized = promotionData.map((promotion) => ({
        label: promotion.name,
        value: promotion.id,
      })) as OptionsProps[];
      setFilters((prevFilters) => {
        return prevFilters.map((filter) =>
          filter.id === 'promo_id' ? { ...filter, options: promotionSerialized } : filter,
        );
      });
    }
  }, [promotionData, promotionLoading, filterListLoading]);

  useEffect(() => {
    if (gameTypeData && gameTypeData.length > 0 && !gameTypeLoading) {
      const gameTypeSerialized = gameTypeData.map((game) => ({
        label: game.title,
        value: game.slug,
      })) as OptionsProps[];
      setFilters((prevFilters) => {
        return prevFilters.map((filter) =>
          filter.id === 'game_type' ? { ...filter, options: gameTypeSerialized } : filter,
        );
      });
    }
  }, [gameTypeData, gameTypeLoading, filterListLoading]);

  return (
    <>
      <div className={styles['filters-container']}>
        <div className={styles['filters-container-header']}>
          <DropdownSelectFiltersSaved filters={filtersSaved} toggleFilters={toggleSavedFilters} />
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setEnableFilter((prev) => !prev)}
            firstIcon={{ children: 'filter_list' }}
            lastIcon={{ children: enableFilter ? 'expand_less' : 'expand_more', color: 'gray-56' }}
            disabled={filterListLoading && promotionLoading}
          >
            Filters
          </Button>
        </div>

        {enableFilter && (
          <div className={styles['filters-container-fields']}>
            {isFilterEnabled && (
              <div>
                {filters
                  .filter((filter) => filter.enable === true)
                  .map((filter) => {
                    return <Fragment key={`select-multi-${filter.id}`}>{renderInputs(filter)}</Fragment>;
                  })}
              </div>
            )}
            <footer>
              <div>
                <DropdownSelectFilters filters={filters} toggleFilters={toggleFilters} />
                <Button
                  variant="secondary"
                  size="sm"
                  {...(!!isFilterEnabled && { onClick: handleClearFilter })}
                  disabled={!isFilterEnabled}
                >
                  Clear Filter
                </Button>
              </div>
              <div className={styles['wrapper-save-filter']}>
                {/* @todo feature to save filters 
                <Input
                  placeholder="Type Filter Name"
                  inputSize="sm"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  disabled={!filterName}
                /> */}
                <Button
                  variant="secondary"
                  onClick={() => undefined}
                  size="sm"
                  disabled={true}
                  //todo feature to save filters disabled={!filterName}
                  onMouseEnter={() => setShowCreateFilterPopover(true)}
                  onMouseLeave={() => setShowCreateFilterPopover(false)}
                  popover={{
                    color: 'dark',
                    placement: 'top-end',
                    showPopover: showCreateFilterPopover,
                    callback: () => undefined,
                    hiddenOverlay: true,
                    children: 'This feature is coming soon.',
                    propRef: null,
                  }}
                >
                  Save Filter
                </Button>
              </div>
            </footer>
          </div>
        )}
      </div>
    </>
  );
}
