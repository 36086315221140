import { gql } from '@apollo/client';

export const LIST_DASHBOARD_READYMADES = gql`
  query dashboardReadyMades($accountId: ID!) {
    dashboardReadyMades(accountId: $accountId, page: 1, pageSize: 4) {
      data {
        id
        title
        description
        url
        media {
          image_alt
          image
        }
      }
      paginatorMeta {
        total
      }
    }
  }
`;
