import { ApolloError, useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import useAccount from '../../../../../../hooks/useAccount';
import { InvitateCreated, InviteInbulkGenerator } from '../../../../../../ts';
import getUTCDateAfterAddingDays from '../../../../../../utils/getUTCDateAfterAddingDays';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import { CREATE_INBULK_FILE_MAPPER, CREATE_INDIVIDUALLY_INVITE } from '../../../../Campaign/Content/graphql';
import useShareLinks, { FileInbulk } from '../../ShareLinksProvider';
import Individually from './ Individually';
import { IndividuallyRef } from './ Individually/interface';
import InBulk from './InBulk';
import { FileDataMapper, InBulkRef, InbulkStep } from './InBulk/interface';
import styles from './styles.module.scss';
import { useCreateFileDownloadMutation } from './useCreateFileDownloadMutation';

export default function CreatePersonalized(): JSX.Element {
  const inbulkSubmitRef = useRef<InBulkRef>(null);
  const individuallySubmitRef = useRef<IndividuallyRef>(null);

  const [validation, setValidation] = useState<boolean>(true);
  const [fileInbulk, setFileInbulk] = useState<FileInbulk>({
    name: '',
    file: null,
  });
  const [inbulkStep, setInbulkStep] = useState<InbulkStep>('upload');

  const { getAccount } = useAccount();

  const { createFileDownload, data: dataFileDownload } = useCreateFileDownloadMutation();

  const {
    setInviteTypeListMethod,
    promotionSelected,
    seDownloadLink,
    executeSingleForm,
    setExecuteSingleForm,
    setLoadingPersonalized,
    inviteMethodPersonalized,
    setInviteMethodPersonalized,
    generatorId,
    quantityInBulk,
    executeInBulkForm,
    setExecuteInBulkForm,
    clearFields,
    inviteLinkCreated,
    expireDays,
    uidField,
    setUidField,
  } = useShareLinks();

  useEffect(() => {
    if (executeSingleForm) {
      handleSubmitSingForm();
    }
  }, [executeSingleForm]);

  useEffect(() => {
    if (executeInBulkForm) {
      handleSubmitInbulkForm();
    }
  }, [executeInBulkForm]);

  useEffect(() => {
    if (dataFileDownload) {
      seDownloadLink({
        url: dataFileDownload.url,
        quantity: dataFileDownload.quantity,
        name: dataFileDownload.name,
      });
      inbulkInviteLinksCreated();
    }
  }, [dataFileDownload]);

  useEffect(() => {
    if (clearFields) {
      clearFileInbulk();
    }
  }, [clearFields]);

  const [createIndividuallyInvite, { loading: loadingInvite, data: dataInvite }] = useMutation<{
    createIndividuallyInvite: InvitateCreated;
  }>(CREATE_INDIVIDUALLY_INVITE, {
    onError: () => {
      console.log('[Error]: CREATE_INDIVIDUALLY_INVITE');
    },
  });

  const [createInbulkFileMapper] = useMutation<{
    createInbulkFileMapper: InviteInbulkGenerator;
  }>(CREATE_INBULK_FILE_MAPPER, {
    onCompleted: () => inbulkInviteLinksCreated(),
    onError: (error: ApolloError) => {
      console.log('[Error]: CREATE_INBULK_FILE_MAPPER', error.message);
    },
  });

  useEffect(() => {
    if (loadingInvite) {
      setLoadingPersonalized(true);
    }
  }, [loadingInvite]);

  const requestInbulkFileMapper = (data: FileDataMapper[], name?: string) => {
    if (!generatorId) {
      return;
    }
    setLoadingPersonalized(true);

    const expires_at = getUTCDateAfterAddingDays(expireDays);

    if (quantityInBulk > 1000) {
      createInbulkFileMapper({
        variables: {
          agency_id: getAccount.agencyId,
          account_id: getAccount.accountId,
          data: JSON.stringify(data),
          generator_id: generatorId,
          expires_at,
          name,
          uid_field_slug: uidField,
        },
      });
    } else {
      createFileDownload({
        accountId: getAccount.accountId,
        data: JSON.stringify(data),
        generatorId: generatorId,
        name,
        expiresAt: expires_at,
        uidFieldSlug: uidField,
      });
    }
  };

  const requestIndividuallyInvite = (fields?: Record<string, string>) => {
    const uid = {
      uid_field_slug: uidField ? uidField : undefined,
      uid: uidField ? fields?.[uidField] : undefined,
    };

    createIndividuallyInvite({
      variables: {
        agency_id: getAccount.agencyId,
        account_id: getAccount.accountId,
        promo_id: promotionSelected.id,
        ...(fields && { data: JSON.stringify(fields) }),
        expires_at: getUTCDateAfterAddingDays(expireDays),
        ...(uid && { ...uid }),
      },
    });
  };

  const inbulkInviteLinksCreated = () => {
    setLoadingPersonalized(false);
    clearFileInbulk();
    setInviteTypeListMethod('inbulk');
    inviteLinkCreated();
    setUidField(undefined);
  };

  const clearFileInbulk = () => {
    setFileInbulk({
      name: '',
      file: null,
    });
    setInviteMethodPersonalized(null);
    setInbulkStep('upload');
  };

  const handleSubmitInbulkForm = () => {
    if (inbulkSubmitRef.current) {
      inbulkSubmitRef.current.submitForm();
      setExecuteInBulkForm(false);
    }
  };

  const handleSubmitSingForm = () => {
    if (individuallySubmitRef.current) {
      individuallySubmitRef.current.submitForm();
      setExecuteSingleForm(false);
    }
  };

  return (
    <div
      className={styleModuleClasses(styles, 'container-section', {
        'one-column': inviteMethodPersonalized !== null,
      })}
    >
      <div className={inviteMethodPersonalized === 'inbulk' ? styles['hidden'] : ''}>
        <Individually
          ref={individuallySubmitRef}
          requestIndividuallyInvite={requestIndividuallyInvite}
          dataInvite={dataInvite?.createIndividuallyInvite}
        />
      </div>

      <div className={inviteMethodPersonalized === 'individually' ? styles['hidden'] : ''}>
        <InBulk
          validation={validation}
          setValidation={setValidation}
          fileInbulk={fileInbulk}
          setFileInbulk={setFileInbulk}
          ref={inbulkSubmitRef}
          requestInbulkFileMapper={requestInbulkFileMapper}
          inbulkStep={inbulkStep}
          setInbulkStep={setInbulkStep}
          clearFileInbulk={clearFileInbulk}
        />
      </div>
    </div>
  );
}
