import React from 'react';
import Empty from './Empty';
import { PromotionContentList } from './interface';
import { PromoteStatus } from '../../../../../../ts';
import NoDraftPromotions from './NoDraftPromotions';
import Item from './Item';

export default function PromotionContent({ promotions, status }: PromotionContentList): JSX.Element {
  if (!promotions.length) {
    return status === PromoteStatus.DRAFT ? <NoDraftPromotions /> : <Empty />;
  }
  return (
    <>
      {promotions.map((promotion) => (
        <Item key={promotion.id} {...promotion} />
      ))}
    </>
  );
}
