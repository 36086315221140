import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AppKeys(): JSX.Element {
  const { t } = useTranslation('account');

  return (
    <main className="p-6">
      <h1>{t('APP Keys')}</h1>
    </main>
  );
}
