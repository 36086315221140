import Icon from '../../Icon';
import DatePicker from 'react-datepicker';
import styles from './styles.module.scss';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { InputDateProps } from './interface';

export function InputDate({
  date,
  callback,
  isRange,
  format,
  locale,
  slug,
  startDate,
  endDate,
}: InputDateProps): JSX.Element {
  function chooseFormat(dateFormat: string) {
    switch (dateFormat) {
      case 'year':
        return { showYearPicker: true };
        break;
      case 'month-year':
        return { showMonthYearPicker: true };
      default:
        return { showFullMonthYearPicker: true };
    }
  }
  return (
    <div className={styles['input-date']}>
      <DatePicker
        placeholderText="Select date"
        {...(isRange
          ? {
              selected: startDate,
              startDate: startDate,
              endDate: endDate,
              selectsRange: true,
              onChange: (eachDate) => callback(slug, eachDate),
            }
          : { selectsRange: false, selected: date, onChange: (eachDate) => callback(slug, eachDate) })}
        {...(!!format && chooseFormat(format))}
        showIcon
        icon={<Icon>calendar_month</Icon>}
        calendarClassName={styles['calendar-wrapper']}
        locale={locale}
        monthClassName={(eachDay) => {
          const isSelectedDay =
            eachDay.getDate() == startDate?.getDate() &&
            eachDay.getMonth() == startDate?.getMonth() &&
            eachDay.getFullYear() == startDate?.getFullYear();

          return styleModuleClasses(styles, 'day', {
            selected: !!isSelectedDay,
          });
        }}
        dayClassName={(eachDay) => {
          const isToday =
            eachDay.getDate() == new Date().getDate() &&
            eachDay.getMonth() == new Date().getMonth() &&
            eachDay.getFullYear() == new Date().getFullYear();
          const isSelectedDay =
            eachDay.getDate() == startDate?.getDate() &&
            eachDay.getMonth() == startDate?.getMonth() &&
            eachDay.getFullYear() == startDate?.getFullYear();
          const isOnRange = !!startDate && !!endDate && eachDay >= startDate && eachDay <= endDate ? true : false;

          return styleModuleClasses(styles, 'day', {
            ['is-today']: isToday && !isSelectedDay && !isOnRange,
            ['is-on-range']: isOnRange,
            selected: !!isSelectedDay && !isOnRange,
          });
        }}
      />
    </div>
  );
}
