import React from 'react';
import styles from './styles.module.scss';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import { PlanBarProps } from './interface';

export default function PlanBar({ resumeEntries, plan, height }: PlanBarProps): JSX.Element {
  return (
    <div className={styles['interaction-bar-wrapper']} style={{ height: height }}>
      <div
        className={styleModuleClasses(styles, 'interaction-bar', plan, { 'low-level': resumeEntries < 20 })}
        style={{ width: resumeEntries + '%' }}
      />
    </div>
  );
}
