import React, { useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import useAccount from '../../../../hooks/useAccount';
import useQueryString from '../../../../hooks/useQueryString';
import { CredentialServicesName, ZapierWindowMessageType } from '../../../../ts';
import { CREATE_CREDENTIAL_GQL } from '../graphql';
import SpinnerBox from '../../../../componentsV2/DesignSystem/SpinnerBox';

export default function Authorizing(): JSX.Element {
  const { getAccount } = useAccount();

  const query = useQueryString();
  const accessToken = query.get('access_token') ?? '';

  const [createCredential] = useMutation(CREATE_CREDENTIAL_GQL, {
    onError: (error: ApolloError) => {
      console.log('[Error]: CREATE_CREDENTIAL_GQL', error);
    },
    onCompleted: () => {
      window.opener.postMessage(
        {
          type: ZapierWindowMessageType.ACCESS_TOKEN,
          accessToken,
        },
        '*',
      );

      self.close();
    },
  });

  useEffect(() => {
    if (accessToken !== '') {
      createCredential({
        variables: {
          agencyId: getAccount.agencyId,
          accountId: getAccount.accountId,
          isPrivate: true,
          serviceName: CredentialServicesName.ZAPIER,
          serviceId: `agency_${getAccount.agencyId}_zapier`,
          serviceClientId: accessToken,
          serviceSecret: accessToken,
        },
      });
    }
  }, [accessToken]);

  return (
    <div className="content-box">
      <SpinnerBox />
    </div>
  );
}
