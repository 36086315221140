import { useState } from 'react';
import Pagination from '../../../../../componentsV2/Pagination';
import { SimplePaginationInfo } from '../../../../../ts';
import styles from '../styles.module.scss';
import TableContent from './TableContent';
import Header from '../../Header';
import { ContactsProps } from '../useFetchContacts';
import { PillsColors } from '../../../../../componentsV2/DesignSystem/Pills/interface';
import { FiltersPramsProps } from '../../Filters/interface';
import { useExportContacts } from './useExportContacts';

interface ContentProps {
  data: ContactsProps[];
  dataLoading: boolean;
  pagination: SimplePaginationInfo;
  currentPage: number;
  handlePagination: (page: number) => void;
  search: (field: string, value: string) => void;
  params: FiltersPramsProps;
}

export default function Content({
  data,
  dataLoading,
  pagination,
  currentPage,
  handlePagination,
  search,
  params,
}: ContentProps): JSX.Element {
  const [exportFields, setExportFields] = useState<string>('');
  const { fetchExport, loading } = useExportContacts();

  return (
    <div className={styles['content-container']}>
      <Header
        title="All Contacts"
        pills={[{ children: pagination.total.toString(), color: PillsColors.primary }]}
        exportFields={exportFields}
        search={search}
        exportCallback={fetchExport}
        params={params}
        exportLoading={loading}
      />
      <TableContent data={data} dataLoading={dataLoading} setExportFields={setExportFields} />
      <footer style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Pagination
          total={pagination.total}
          currentPage={currentPage}
          totalPerPage={pagination.totalPerPage}
          setPage={handlePagination}
        />
      </footer>
    </div>
  );
}
