import { useFormContext } from 'react-hook-form';
import styles from './styles.module.scss';

interface EmailContentProps {
  userName?: string;
  inviteLink?: string;
}

export default function EmailContent({ userName, inviteLink }: EmailContentProps): JSX.Element {
  const { watch } = useFormContext();

  const [
    greeting,
    greeting_font_color,
    greeting_font_size,
    content,
    content_font_color,
    content_font_size,
    enter_button_text,
    enter_button_color,
    enter_button_size,
    enter_button_background_color,
    signature,
    signature_font_color,
    signature_font_size,
    footer_text,
    footer_text_font_color,
    footer_text_font_size,
    invitelink_font_color,
    invitelink_font_size,
    background,
  ] = watch([
    'greeting',
    'greeting_font_color',
    'greeting_font_size',
    'content',
    'content_font_color',
    'content_font_size',
    'enter_button_text',
    'enter_button_color',
    'enter_button_size',
    'enter_button_background_color',
    'signature',
    'signature_font_color',
    'signature_font_size',
    'footer_text',
    'footer_text_font_color',
    'footer_text_font_size',
    'invitelink_font_color',
    'invitelink_font_size',
    'background',
  ]);

  const formattedContent = content.replace(/\n/g, '<br />');
  const formattedSignature = signature.replace(/\n/g, '<br />');
  const formattedFooterText = footer_text.replace(/\n/g, '<br />');

  return (
    <div className={styles['content']}>
      <div className={styles['centralize']}>
        <div className={styles['blank']} style={{ backgroundColor: `${background}` }}>
          <div className={styles['greeting']}>
            <div style={{ fontSize: `${greeting_font_size}`, color: `${greeting_font_color}` }}>
              {`${greeting} `}
              <span>{userName?.trim() || `{First Name}`}</span>,
            </div>
          </div>
          <div
            style={{ fontSize: `${content_font_size}`, color: `${content_font_color}` }}
            dangerouslySetInnerHTML={{ __html: formattedContent }}
          />
          <div className={styles['enter-button-container']}>
            <div>
              <div
                className={styles['enter-button']}
                style={{
                  fontSize: `${enter_button_size}`,
                  color: `${enter_button_color}`,
                  backgroundColor: enter_button_background_color,
                }}
              >
                {enter_button_text || '\u00A0'}
              </div>
            </div>

            <div
              className={styles['invite-link']}
              style={{ fontSize: `${invitelink_font_size}`, color: `${invitelink_font_color}` }}
            >
              {inviteLink || `{Invite_link}`}
            </div>
          </div>
          <div
            style={{ fontSize: `${signature_font_size}`, color: `${signature_font_color}` }}
            dangerouslySetInnerHTML={{ __html: formattedSignature }}
          />
        </div>
      </div>
      <div
        className={styles['footer']}
        style={{ fontSize: `${footer_text_font_size}`, color: `${footer_text_font_color}` }}
        dangerouslySetInnerHTML={{ __html: formattedFooterText }}
      />
    </div>
  );
}
