import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import Content from './Content';
import { EmbedYourPluginProps } from './interface';

import styles from './styles.module.scss';

export default function EmbedYourPlugin({
  sectionsStatus,
  nextStep,
  previousStep,
  loading,
}: EmbedYourPluginProps): JSX.Element {
  return (
    <div className={styles['margin-top']}>
      <SectionCollapsibleFull
        badge={sectionsStatus.badge}
        expanded={sectionsStatus.opened}
        header={{ title: 'Embed your Plugin' }}
        wrapperClassname={styles.wrapper}
        {...(!loading && {
          footer: {
            leftButton: {
              children: 'Back',
              variant: 'secondary',
              size: 'sm',
              onClick: previousStep,
            },
            rightButton: {
              children: 'Next',
              variant: 'primary',
              size: 'sm',
              type: 'submit',
              onClick: nextStep,
            },
          },
        })}
      >
        <Content />
      </SectionCollapsibleFull>
    </div>
  );
}
