import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ButtonPopoverConfirm from '../../../../../../componentsV2/ButtonPopoverConfirm';
import ButtonNavigation from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { ItemProps } from './interface';
import styles from './styles.module.scss';
import { useMutationDeletePlugin } from './useMutationDeletePlugin';
import useAccount from '../../../../../../hooks/useAccount';

export default function Item({ plugin, refetch }: ItemProps): JSX.Element {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { getAccount } = useAccount();
  const { url } = useRouteMatch();

  const { requestDeleteRewardsPlugin, data, loading } = useMutationDeletePlugin();

  const handleDelete = (id: number) => {
    requestDeleteRewardsPlugin?.(getAccount.accountId, id);
    setDisabled(true);
  };

  useEffect(() => {
    if (data?.id) {
      refetch?.({ accountId: getAccount.accountId });
    }
  }, [data]);

  return (
    <tr>
      <td>
        <div className={styles['widget-name']}>
          {!!plugin?.image_file && <img src={plugin?.image_file} alt={plugin?.name} className={styles['thumbnail']} />}
          <Paragraph size={2} weight={FontWeight.semiBold}>
            {plugin?.name}
          </Paragraph>
        </div>
      </td>

      <td className={styles['align-center']}>
        <div className={styles['align-buttons']}>
          <div>
            <ButtonNavigation
              firstIcon={{ children: 'edit' }}
              variant="secondary"
              to={`${url}/create-plugin/${plugin.id}`}
              size="sm"
              disabled={disabled}
            />
          </div>
          <div>
            <ButtonPopoverConfirm
              onClick={() => handleDelete(plugin.id)}
              buttonProps={{ firstIcon: { children: 'delete', color: 'red-block' }, loading, disabled }}
              popoverProps={{
                header: {
                  title: 'Are you sure you wish to delete this?',
                },
              }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
