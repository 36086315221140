import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Skeleton from '../Skeleton';
import { CarouselVideoProps } from './interface';
import { Paragraph, FontWeight } from '../Typography';
import styles from './styles.module.scss';

export default function CarouselVideo(carousel: CarouselVideoProps): JSX.Element {
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const [videoStates, setVideoStates] = useState<boolean[]>([]);
  const playerRefs = useRef<Array<ReactPlayer | null>>([]);

  const handleCarouselIndex = (type: '-' | '+') => {
    const newIndex = type === '-' ? carouselIndex - 1 : carouselIndex + 1;
    setCarouselIndex(newIndex);

    const player = playerRefs.current[carouselIndex];
    player?.getInternalPlayer()?.pause();
  };

  const playVideo = (index: number) => {
    const player = playerRefs.current[index];
    player?.getInternalPlayer()?.play();

    setVideoStates((prevStates) =>
      prevStates.map((state, i) => {
        return i === index ? true : state;
      }),
    );
  };

  const medias = useMemo(() => carousel.medias, [carousel.medias]);

  useEffect(() => {
    const values = medias.map(() => false);
    if (videoStates.length === 0) {
      setVideoStates(values);
    }
  }, [medias]);

  return (
    <div className={styles['carousel-wrapper']}>
      <div className={styles['media']}>
        {carousel.isLoading ? (
          <Skeleton />
        ) : (
          medias.map(({ media }, index) => (
            <div
              key={`video-carousel-${index}`}
              className={`${styles['child']} ${index === carouselIndex ? styles['active'] : ''}`}
            >
              <div className={`${styles['thumbnail']} ${videoStates[index] ? styles['desactive'] : ''}`}>
                <button onClick={() => playVideo(index)}>
                  <span className="material-symbols-rounded">play_circle</span>
                </button>
                <img src={media?.thumbnail} alt={media?.alt} />
              </div>
              <ReactPlayer
                url={media?.src}
                width="100%"
                height="100%"
                controls
                ref={(ref) => (playerRefs.current[index] = ref)}
              />
            </div>
          ))
        )}
      </div>
      <footer>
        <div className={styles['description-wrapper']}>
          {carousel.isLoading ? (
            <Skeleton width={100} height={1.25} />
          ) : (
            <>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {medias[carouselIndex]?.title}
              </Paragraph>
              <span>{medias[carouselIndex]?.description}</span>
            </>
          )}
        </div>
        <div className={styles['arrows-wrapper']}>
          <button onClick={() => handleCarouselIndex('-')} disabled={!medias || carouselIndex === 0}>
            <span className="material-symbols-rounded">chevron_left</span>
          </button>
          <button onClick={() => handleCarouselIndex('+')} disabled={!medias || carouselIndex === medias.length - 1}>
            <span className="material-symbols-rounded">chevron_right</span>
          </button>
        </div>
      </footer>
    </div>
  );
}
