import { ChangeEvent, useMemo } from 'react';
import { Button } from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../componentsV2/DesignSystem/Inputs/Input';
import Select from '../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import useDebounce from '../../../../../hooks/useDebounce';
import capitalize from '../../../../../utils/capitalize';
import { useFetchSelfServicePlans } from '../../../SelfService/SectionChoosePlan/useFetchSelfServicePlans';
import styles from '../styles.module.scss';
import { FiltersProps } from './interface';

export default function Filters({
  handleTypeOfList,
  typeOfList,
  handleSearch,
  handleFilterByPlan,
  search,
}: FiltersProps): JSX.Element {
  const debounce = useDebounce({ ms: 700 });

  const { data } = useFetchSelfServicePlans();

  const dataPlans = useMemo(() => {
    if (data) {
      const dataPlan = data.map((plan) => ({
        label: capitalize(plan.type),
        value: plan.type,
      }));
      dataPlan.unshift({
        label: 'All Plans',
        value: '-',
      });
      return dataPlan;
    }
    return [];
  }, [data]);

  const handleFilter = (value: string) => {
    handleFilterByPlan?.(value === '-' ? '' : value);
  };

  const handleInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debounce(() => handleSearch?.(value));
  };

  const searchDefaultValue = useMemo(() => search, []);

  return (
    <header>
      <Input
        placeholder="Search for an account"
        onChange={(event) => handleInputSearch(event)}
        defaultValue={searchDefaultValue}
        fullwidth={true}
      />
      <div>
        <Select placeholder="Filter by plan" onChange={handleFilter} options={dataPlans} />
        <Button
          variant="secondary"
          size="md"
          {...(typeOfList == 'grid' && { customClass: styles['active'] })}
          firstIcon={{ children: 'grid_view' }}
          onClick={() => handleTypeOfList('grid')}
        />
        <Button
          variant="secondary"
          size="md"
          {...(typeOfList == 'list' && { customClass: styles['active'] })}
          firstIcon={{ children: 'view_list' }}
          onClick={() => handleTypeOfList('list')}
        />
      </div>
    </header>
  );
}
