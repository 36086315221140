import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../../../../componentsV2/Pagination';
import useQueryString from '../../../../../../hooks/useQueryString';
import scrollToElement from '../../../../../../utils/scrollToElement';
import Item from './Item';
import { ListProps } from './interface';
import styles from './styles.module.scss';

export default function List({ widgets, pagination, refetch, currentPage }: ListProps): JSX.Element {
  const history = useHistory();
  const query = useQueryString();

  const showExtraComponents = useMemo(() => widgets, [widgets]);

  const addQueryParam = (params: Record<string, string | number | undefined>) => {
    const newQuery = new URLSearchParams(query.toString());

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        newQuery.set(key, String(value));
      } else {
        newQuery.delete(key);
      }
    }

    history.replace(`?${newQuery.toString()}`);
  };

  const handlePagination = (page: number) => {
    scrollToElement();
    addQueryParam({ page });
  };

  return (
    <>
      <table className={styles['widget-table']}>
        <thead>
          <th>Widget Name</th>
          <th className={styles['th-promotion']}>Promotion</th>
          <th className={styles['th-team-users']}>Team Users</th>
          <th className={styles['th-button-actions']}>&nbsp;</th>
        </thead>
        <tbody>
          {widgets.map((widget) => (
            <Item key={`widget-${widget.id}`} widget={widget} refetch={refetch} />
          ))}
        </tbody>
      </table>

      {showExtraComponents && (
        <div className={styles['pagination']}>
          <Pagination
            total={pagination.total}
            currentPage={currentPage ?? 1}
            totalPerPage={pagination.totalPerPage}
            setPage={handlePagination}
          />
        </div>
      )}
    </>
  );
}
