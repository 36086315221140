import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ZapierPopularAppData } from '../../../../../ts';
import { ZAPIER_POPULAR_APPS_GQL } from '../../graphql';
import useDebounce from '../../../../../hooks/useDebounce';
import ListApps from './ListApps';
import useZapierContext from '../../ZapierContextProvider';
import styles from './styles.module.scss';
import ButtonNavigation from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import Input from '../../../../../componentsV2/DesignSystem/Inputs/Input';
import { FontWeight, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import Skeleton from '../../../../../componentsV2/DesignSystem/Skeleton';

export default function Apps(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'build' });
  const debounce = useDebounce({ ms: 700 });
  const filterTitle = useRef<HTMLInputElement>(null);
  const [filterValue, setFilterValue] = useState<string>('');
  const { setAppSlug, PATH_ZAPIER } = useZapierContext();

  const {
    data: dataZapierApps,
    loading: loadingZapierApps,
    refetch: refetchZapierApp,
  } = useQuery<{
    zapierPopularApps: ZapierPopularAppData[];
  }>(ZAPIER_POPULAR_APPS_GQL, {
    notifyOnNetworkStatusChange: true,
    onError: (error: ApolloError) => {
      console.log('[ZAPIER_POPULAR_APPS_GQL]:', error);
    },
  });

  const apps = useMemo(() => {
    return dataZapierApps?.zapierPopularApps ?? [];
  }, [dataZapierApps?.zapierPopularApps]);

  const onTitleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFilterValue(value);
    debounce(() =>
      refetchZapierApp({
        titleSearch: value,
      }),
    );
  }, []);

  useEffect(() => setAppSlug(), []);

  const clearFilter = () => {
    setFilterValue('');
    refetchZapierApp({
      titleSearch: '',
    });
    if (filterTitle?.current) {
      filterTitle.current.value = '';
      filterTitle.current.focus();
    }
  };

  return (
    <div className={styles['wrapper-apps']}>
      <header>
        <Paragraph size={1} weight={FontWeight.extraBold}>
          Choose an App
        </Paragraph>
        <div>
          <Input
            fullwidth
            placeholder="Search"
            value={filterValue}
            onChange={onTitleSearch}
            {...(filterValue !== '' && { icon: { name: 'close', onClick: clearFilter } })}
          />
          <ButtonNavigation
            fullWidth
            firstIcon={{ children: 'lan' }}
            variant="secondary"
            to={`${PATH_ZAPIER}/create-zap-template`}
          >
            {t('Create Zap Template')}
          </ButtonNavigation>
        </div>
      </header>
      {loadingZapierApps ? (
        <footer>
          <div>
            <Skeleton width={'10rem'} height={1} />
          </div>
          <div>
            {Array.from({ length: 6 }, () => '').map((_, index) => (
              <Skeleton key={index} width={'4.625rem'} height={4.625} />
            ))}
          </div>
        </footer>
      ) : (
        <ListApps apps={apps} filterValue={filterValue} />
      )}
    </div>
  );
}
