import { ApolloError, gql, useMutation } from '@apollo/client';

const CREATE_WIDGET = gql`
  mutation createWidget($accountId: ID!, $data: WidgetInputData) {
    createWidget(accountId: $accountId, data: $data) {
      id
      account_id
      promo_id
      name
      image_file
      created_at
      updated_at
    }
  }
`;

interface CreateWidgetResult {
  id: number;
  name: string;
  promo_id: number;
}

export interface CreateWidgetDataVariables {
  name: string;
  promo_id?: number;
  slug?: string;
  header_message?: string;
  header_message_font_style?: string;
  header_message_font_color?: string;
  message1?: string;
  message1_font_style?: string;
  message1_font_color?: string;
  message2?: string;
  message2_font_style?: string;
  message2_font_color?: string;
  expire_time?: number;
  widget_background?: string;
  page_background?: string;
  image_file?: string;
}

export function useMutationCreateWidget(): {
  loading: boolean;
  createWidget: (variables: { accountId: number; data: CreateWidgetDataVariables }) => void;
  data: CreateWidgetResult | null;
} {
  const [createWidgetMutation, { loading, data }] = useMutation<{ createWidget: CreateWidgetResult }>(CREATE_WIDGET, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'CREATE_WIDGET',
        apolloError: error,
      });
    },
  });

  const createWidget = (variables: { accountId: number; data: CreateWidgetDataVariables }) => {
    createWidgetMutation({ variables });
  };

  return {
    loading,
    createWidget,
    data: data?.createWidget || null,
  };
}
