import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../components/Pagination/paginator.gql';

export const LIST_ZAPIER_APPS_GQL = gql`
  query zapierApps($filters: ZapierAppFilterInput) {
    zapierApps(filters: $filters) {
      data {
        uuid
        title
        slug
        description
        image
        url
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const LIST_ZAP_TEMPLATES_GQL = gql`
  query zapierZapTemplates($apps: String, $limit: Int, $offset: Int) {
    zapierZapTemplates(apps: $apps, limit: $limit, offset: $offset) {
      id
      steps {
        id
        title
        images {
          url_64x64
        }
      }
      title
      status
      description_raw
    }
  }
`;

export const LIST_ZAPS_GQL = gql`
  query zapierZaps($accessToken: String!, $limit: Int, $page: Int) {
    zapierZaps(accessToken: $accessToken, limit: $limit, page: $page) {
      data {
        id
        title
        state
        steps {
          app {
            id
            title
            images {
              url_64x64
              url_32x32
            }
          }
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const ZAPIER_CLIENT_ID_GQL = gql`
  query zapierClientId {
    zapierClientId {
      client_id
    }
  }
`;

export const CREATE_CREDENTIAL_GQL = gql`
  mutation createCredential(
    $agencyId: ID!
    $accountId: ID!
    $isPrivate: Boolean
    $serviceName: CredentialServicesName!
    $serviceId: String!
    $serviceClientId: String!
    $serviceSecret: String!
  ) {
    createCredential(
      agencyId: $agencyId
      accountId: $accountId
      isPrivate: $isPrivate
      serviceName: $serviceName
      serviceId: $serviceId
      serviceClientId: $serviceClientId
      serviceSecret: $serviceSecret
    ) {
      service_secret
    }
  }
`;

export const CREDENTIAL_FIND_BY_SERVICE_GQL = gql`
  mutation credentialFindByService(
    $agencyId: ID!
    $accountId: ID!
    $serviceName: CredentialServicesName!
    $serviceId: String!
  ) {
    credentialFindByService(
      agencyId: $agencyId
      accountId: $accountId
      serviceName: $serviceName
      serviceId: $serviceId
    ) {
      service_secret
    }
  }
`;

export const CREDENTIAL_DELETE_GQL = gql`
  mutation deleteCredential($agencyId: ID!, $accountId: ID!) {
    deleteCredential(agencyId: $agencyId, accountId: $accountId) {
      id
      message
    }
  }
`;

export const ZAPIER_POPULAR_APPS_GQL = gql`
  query zapierPopularApps($titleSearch: String) {
    zapierPopularApps(titleSearch: $titleSearch) {
      slug
      name
      images {
        url_32x32
      }
    }
  }
`;
