import { createContext, useContext, useState } from 'react';
import { SenderContact } from './useFetchSenderListEmailByAccount';

interface ShareLinksContextProps {
  stepSectionEmail: Section;
  setStepSectionEmail: (stepSectionEmail: Section) => void;
  loadingSectionEmail: boolean;
  setLoadingSectionEmail: (loadingSectionEmail: boolean) => void;
  resetStepSectionEmail: () => void;
  enableSendEmail: boolean;
  setEnableSendEmail: (disabledSendEmail: boolean) => void;
  senderEmail: SenderContact;
  setSenderEmail: (senderEmail: SenderContact) => void;
}

interface SendEmailProvider {
  children: JSX.Element | JSX.Element[];
}

export type Section = 'configuration' | 'preview' | 'success' | 'preview-send';

const SendEmailContext = createContext({});

export function SendEmailProvider({ children }: SendEmailProvider): JSX.Element {
  const [stepSectionEmail, setStepSectionEmail] = useState<Section>('configuration');
  const [loadingSectionEmail, setLoadingSectionEmail] = useState<boolean>(false);
  const [enableSendEmail, setEnableSendEmail] = useState<boolean>(true);
  const [senderEmail, setSenderEmail] = useState<SenderContact>();

  const resetStepSectionEmail = () => {
    setStepSectionEmail('configuration');
  };

  return (
    <SendEmailContext.Provider
      value={{
        stepSectionEmail,
        setStepSectionEmail,
        loadingSectionEmail,
        setLoadingSectionEmail,
        resetStepSectionEmail,
        enableSendEmail,
        setEnableSendEmail,
        senderEmail,
        setSenderEmail,
      }}
    >
      {children}
    </SendEmailContext.Provider>
  );
}

export default function useSendEmail(): ShareLinksContextProps {
  return useContext(SendEmailContext) as ShareLinksContextProps;
}
