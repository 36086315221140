import { gql } from '@apollo/client';

export const paginatorInfoFragment = gql`
  fragment paginatorInfo_Part on PaginatorInfo {
    current_page
    first_page_url
    from
    last_page
    last_page_url
    next_page_url
    path
    per_page
    prev_page_url
    to
    total
  }
`;
