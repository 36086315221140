import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { PluginListProps } from './interface';
import Item from './Item';

import styles from './styles.module.scss';

export default function Content({ plugins, refetch }: PluginListProps): JSX.Element {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['text-wrapper']}>
        <Paragraph size={2} weight={FontWeight.semiBold}>
          Your Plugins
        </Paragraph>
      </div>
      <table className={styles['widget-table']}>
        <thead>
          <th>Plugin</th>
          <th className={styles['th-button-actions']}>&nbsp;</th>
        </thead>
        <tbody>
          <Item key={`widget`} plugin={plugins} refetch={refetch} />
        </tbody>
      </table>
    </div>
  );
}
