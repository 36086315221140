import { setContext } from '@apollo/client/link/context';
import { getAuthCookie } from '../../utils/authCookie';
import { REACT_APP_X_API_KEY_GRAPHQL } from '../../config/Env';

const authLink = setContext((_, { headers }) => {
  const authenticationToken = getAuthCookie();
  return {
    headers: {
      ...headers,
      authorization: !!authenticationToken && `Bearer ${authenticationToken}`,
      'x-api-key': REACT_APP_X_API_KEY_GRAPHQL,
    },
  };
});

export default authLink;
