import { FontWeight, Paragraph } from '../../../componentsV2/DesignSystem/Typography';
import { PluginHeaderProps } from './interface';

import styles from './styles.module.scss';

export default function PluginHeader({
  heading,
  primary_color,
  brand_color,
  greeting_message,
  description,
}: PluginHeaderProps): JSX.Element {
  const showHeading = !!heading;
  const showGreetingMessage = !!greeting_message;
  const showDescription = !!description;

  return (
    <>
      <header className={styles['hero_section']}>
        {showHeading && (
          <div>
            <span className={styles['heading_text']} style={{ color: primary_color }}>
              {heading}
            </span>
          </div>
        )}

        <Paragraph size={2} weight={FontWeight.regular}>
          {showGreetingMessage && (
            <span className={styles['bold']} style={{ color: brand_color }}>
              {greeting_message} Bruce,{' '}
            </span>
          )}
          {showDescription && <span style={{ color: primary_color }}>{description}</span>}
        </Paragraph>
      </header>
      <div>
        <hr className={styles['divider-plugin-header']} />
      </div>
    </>
  );
}
