import React from 'react';
import Skeleton from '../../../../../../componentsV2/DesignSystem/Skeleton';
import styles from './styles.module.scss';

export default function SkeletonLoading(): JSX.Element {
  return (
    <div className={styles['promotions-header']}>
      {[30, 70, 80, 70].map((width, index) => (
        <Skeleton width={width} height={1} key={`skeleton-header-${index}`} />
      ))}
    </div>
  );
}
