import { IconAnimated } from '../../../../../../../componentsV2/DesignSystem/IconAnimated';
import ButtonNavigation from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import useShareLinks from '../../../ShareLinksProvider';
import styles from './styles.module.scss';

export default function Inbulk(): JSX.Element {
  const { downloadLink } = useShareLinks();

  return (
    <div className={styles['invitelinks-container']}>
      <div className={styles['container-icon-wrapper']}>
        <IconAnimated wrapper={{ color: 'green' }} icon={{ name: 'check' }} />

        {downloadLink?.quantity ? (
          <Paragraph size={2}>
            Your list with <span>{downloadLink.name || downloadLink.quantity + 'InviteLinks'}</span> is being created.
            You can:
          </Paragraph>
        ) : (
          <>
            <Paragraph size={2}>You will receive an email when the file has been successfully generated.</Paragraph>
            <Paragraph size={2}>
              You can also find the InviteLinks are available for download from the Manage Invite section of this Share
              Center.
            </Paragraph>
          </>
        )}

        {downloadLink?.url && (
          <ButtonNavigation
            to={downloadLink.url}
            target="_blank"
            size="sm"
            variant="secondary"
            firstIcon={{ children: 'file_download' }}
          >
            Export List
          </ButtonNavigation>
        )}
      </div>
    </div>
  );
}
