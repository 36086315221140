import React from 'react';
import { SkeletonProps } from './interface';
import styles from './styles.module.scss';

export default function Skeleton({ width, height, rounded }: SkeletonProps): JSX.Element {
  return (
    <div
      className={styles['skeleton']}
      style={{
        width: typeof width !== 'number' ? width : width + '%',
        height: typeof height !== 'number' ? height : height + 'rem',
        borderRadius: typeof rounded !== 'number' ? rounded : rounded + 'rem',
      }}
    ></div>
  );
}
