import { gql } from '@apollo/client';

export const DASHBOARD_TUTORIALS = gql`
  query dashboardTutorials($accountId: ID!) {
    dashboardTutorials(accountId: $accountId) {
      data {
        id
        title
        video_url
        media {
          image_alt
          image
        }
      }
    }
  }
`;
