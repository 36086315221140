import React, { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import Card from '../../../../../componentsV2/DesignSystem/Card';
import { CardAnimations } from '../../../../../componentsV2/DesignSystem/Card/interface';
import { DASHBOARD_NEWS } from './graphql';
import SkeletonLoading from './SkeletonLoading';
import { DashboardNewsData } from './interface';
import NewsList from './NewsList';
import styles from './styles.module.scss';

export default function News(): JSX.Element {
  const {
    data: dataNews,
    loading: loadingNews,
    error: errorNews,
    refetch: refetchNews,
  } = useQuery<{
    dashboardNews: { data: DashboardNewsData[] };
  }>(DASHBOARD_NEWS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const news = useMemo(() => {
    return dataNews?.dashboardNews?.data ?? [];
  }, [dataNews?.dashboardNews?.data]);

  const refetchQuery = () => {
    refetchNews();
  };

  return (
    <Card
      identify="news"
      size="md"
      animation={{ type: CardAnimations.top, duration: '1.75s' }}
      header={{ title: 'News' }}
      footer={{
        buttons: [
          {
            children: 'View All',
            variant: 'tertiary',
            lastIcon: { children: 'chevron_right' },
            to: 'https://www.beeliked.com/blog',
            target: '_blank',
          },
        ],
      }}
    >
      <div className={styles['news-wrapper']}>
        {loadingNews ? <SkeletonLoading /> : <NewsList news={news} error={errorNews} refetchNews={refetchQuery} />}
      </div>
    </Card>
  );
}
