import QRCode from '../../../../../../../assets/imgs/widget-qrcode.svg';
import { ThumbProps } from '../interface';
import styles from './styles.module.scss';

export default function Thumb({
  header_message,
  header_message_font_color,
  message1,
  message1_font_color,
  message2,
  message2_font_color,
  expire_time,
  page_background,
  widget_background,
  imageFile,
}: ThumbProps): JSX.Element {
  return (
    <div className={styles['background']} style={{ backgroundColor: page_background }}>
      <div className={styles['wrapper']}>
        <div className={styles['container']} style={{ backgroundColor: widget_background }}>
          {imageFile && <img src={imageFile} />}

          <h3
            style={{
              color: header_message_font_color,
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {header_message}
          </h3>

          <p
            style={{
              color: message1_font_color,
              fontFamily: 'Plus Jakarta Sans',
            }}
          >
            {message1}
          </p>

          <img src={QRCode} className={styles['qr-code']} />

          <div className={styles['expire-time-container']}>
            <div className={styles['expire-time']}>
              <div className={styles['message2']}>
                <p
                  style={{
                    color: message2_font_color,
                    fontFamily: 'Plus Jakarta Sans',
                  }}
                >
                  {message2}
                </p>
              </div>
              <div className={styles['timer']}>{expire_time}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
