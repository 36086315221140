import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const initialSchema = yup.object({
  firstName: yup.string().required('The first name field is required'),
  lastName: yup.string().required('The last name field is required'),
  email: yup.string().email('This email is not valid').required('The email field is required'),
  account: yup.string().required('The account name  field is required'),
});

const ValidationResolver = yupResolver(initialSchema);

export default ValidationResolver;
