import { useFormContext } from 'react-hook-form';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import pluginPopup from '../../../../../../assets/imgs/plugiinPopup.svg';
import pluginSlide from '../../../../../../assets/imgs/pluginSlide.svg';
import pluginStandard from '../../../../../../assets/imgs/pluginStandard.svg';

import styles from './styles.module.scss';

export default function Content(): JSX.Element {
  const { watch, setValue } = useFormContext();

  const [embed_type] = watch(['embed_type']);

  const embedOptions = [
    { img: pluginStandard, value: 'Standard' },
    { img: pluginSlide, value: 'Slide' },
    { img: pluginPopup, value: 'Popup' },
  ];

  return (
    <div className={styles['setup_plugin_wrapper']}>
      <div className={styles['heading']}>
        <Paragraph size={1} weight={FontWeight.regular}>
          Choose Embed options to add your plugin to your website
        </Paragraph>
      </div>
      <div className={styles['setup_plugin_body']}>
        {embedOptions.map((option, index) => (
          <div key={index} className={embed_type === option.value ? styles['selected'] : ''}>
            <img
              onClick={() => setValue('embed_type', option.value)}
              src={option.img}
              alt={`embed option ${option.value}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
