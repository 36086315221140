import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { Heading } from '../../../../../../componentsV2/DesignSystem/Typography';
import useToast from '../../../../../../hooks/useToast';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import useSignupContext, { Steps } from '../../SignupContextProvider';
import { CREATE_USER_GQL, GET_TERMS_GQL } from '../../graphql';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

declare global {
  interface Window {
    fpr?: (action: string, data: { email: string }) => void;
  }
}

export default function StepTerms(): JSX.Element {
  const { animations, changeStep, userData, setAuthorization } = useSignupContext();
  const [agreeButton, setAgreeButton] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(true);
  const termsText = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { inviteCode, inviteId } = useParams<{ inviteCode: string; inviteId: string }>();
  const { toast } = useToast();

  const nextStep = (e: React.FormEvent) => {
    e.preventDefault();
    if (!agreeButton) {
      return;
    }
    createUser({
      variables: {
        ...userData,
        confirmCode: inviteCode,
        inviteId: inviteId,
        agreementId: data.agreement.id,
      },
    });
  };

  const previousStep = () => changeStep(Steps.terms, 'back');

  const { data } = useQuery(GET_TERMS_GQL, {
    onError: (error: ApolloError) => {
      toast('error', error.message);
    },
  });

  const [createUser, { loading }] = useMutation(CREATE_USER_GQL, {
    onCompleted: (response) => {
      if (window.fpr) {
        window.fpr('referral', { email: userData.email });
      } else {
        console.error('The function fpr is not defined in the global window object.');
      }
      setAuthorization(response.createUser.authorization);

      changeStep(Steps.accountCreated, 'forward');
    },
    onError: (error: ApolloError) => {
      toast('error', error.message);
    },
  });

  const handleReadFull = () => {
    if (
      termsText.current &&
      termsText.current?.scrollHeight - termsText.current?.clientHeight == termsText.current?.scrollTop
    ) {
      setShowPopover(false);
      setAgreeButton(true);
    }

    ['mouseenter', 'touchmove'].forEach((event) => {
      termsText.current?.lastChild?.addEventListener(event, () => {
        setShowPopover(false);
        setAgreeButton(true);
      });
    });
  };

  return (
    <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.terms])}>
      <IconAnimated size="md" icon={{ name: 'gavel' }} wrapper={{ color: 'primary' }} />
      <Heading size={3}>{data?.agreement?.title}</Heading>
      <form onSubmit={(e) => nextStep(e)}>
        <div
          className={styles['terms']}
          ref={termsText}
          onScroll={handleReadFull}
          dangerouslySetInnerHTML={{ __html: data?.agreement?.content }}
        />
        <div className={styles['gradient']} />
        <div className={styles['wrapper-buttons']}>
          {showPopover && (
            <div className={styles['scroll-message']}>
              <p>You need to scroll and read all the terms and conditions and accept it</p>
            </div>
          )}

          <Button fullWidth type="submit" loading={loading} disabled={!agreeButton} ref={buttonRef}>
            Yes, I agree.
          </Button>

          <Button fullWidth variant="tertiary" firstIcon={{ children: 'keyboard_backspace' }} onClick={previousStep}>
            {`I don't agree`}
          </Button>
        </div>
      </form>
    </div>
  );
}
