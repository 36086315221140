import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import defaultPluginIcon from '../../../../../assets/imgs/plugin-default-icon.svg';
import { FileUploadProps } from '../../../../../components/DragDropUpload';
import SectionCollapsibleFull from '../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../componentsV2/DesignSystem/Sections/interface';
import SpinnerBox from '../../../../../componentsV2/DesignSystem/SpinnerBox';
import useAccount from '../../../../../hooks/useAccount';
import useToast from '../../../../../hooks/useToast';
import { PluginInput, PluginSectionsStateProps } from '../interfaces';
import EmbedYourPlugin from './EmbedYourPlugin';
import PluginForm from './PluginForm';
import PluginModal from './PluginModal';
import PluginValidationResolver from './PluginValidationResolver';
import { useFetchPlugin } from './useFetchPlugin';
import { useMutationCreatePlugin } from './useMutationCreatePlugin';
import { useMutationUpdatePlugin } from './useMutationUpdatePlugin';

import styles from '../../styles.module.scss';

export default function CreateRewardsPlugin(): JSX.Element {
  const [file, setFile] = useState<FileUploadProps>({ name: '', file: null });
  const [imageUrl, setImageUrl] = useState<string>();
  const [saved, setSaved] = useState<boolean>(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [isHeroSectionChecked, setIsHeroSectionChecked] = useState<boolean>(true);
  const [isCountdownChecked, setIsCountdownChecked] = useState<boolean>(true);
  const [showGreetingMessage, setShowGreetingMessage] = useState<boolean>(true);
  const [showHeading, setShowHeading] = useState<boolean>(true);
  const [showDescription, setShowDescription] = useState<boolean>(true);
  const [showExpiresIn, setShowExpiresIn] = useState<boolean>(true);
  const [showExpiresDays, setShowExpiresDays] = useState<boolean>(true);
  const [showExpiresHours, setShowExpiresHours] = useState<boolean>(true);
  const [showExpiresMinutes, setShowExpiresMinutes] = useState<boolean>(true);

  const [sectionsStatus, setSectionsStatus] = useState<PluginSectionsStateProps>({
    plugin: {
      badge: { status: StatusBadge.enabled, icon: { name: 'done', color: 'green' }, number: 1 },
      opened: true,
      disableCollapse: false,
    },
    userPermission: {
      badge: { status: StatusBadge.disabled, icon: { name: 'done', color: 'green' }, number: 2 },
      opened: false,
      disableCollapse: true,
    },
  });

  const history = useHistory();
  const { url } = useRouteMatch();
  const { getAccount } = useAccount();
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();

  const { saveRewardsPlugin, data: dataPluginCreated, loading: loadingPluginCreated } = useMutationCreatePlugin();
  const { updateRewardsPlugin, data: dataPluginUpdated, loading: loadingPluginUpdated } = useMutationUpdatePlugin();
  const { data, loading } = useFetchPlugin(getAccount.accountId, Number(id));

  const methods = useForm<PluginInput>({
    resolver: PluginValidationResolver,
    defaultValues: {
      id: undefined,
      name: '',
      brand_color: '',
      primary_color: '',
      background_color: '',
      typography: '',
      image_file: '',
      image_align: '',
      greeting_message: '',
      heading_message: '',
      description_message: '',
      countdown_text_message: '',
      days: '',
      hours: '',
      minutes: '',
      cta_text_message: '',
      powered_by_blkd: true,
      embed_type: '',
      disable_floating_action_button: false,
    },
  });

  const { handleSubmit, setValue, getValues, setError } = methods;

  useEffect(() => {
    if (dataPluginCreated?.id) {
      toast('success', 'Saved Successfully');
      setValue('id', dataPluginCreated?.id);
      setSaved(true);
      updateSection();

      history.push(`${url}/${dataPluginCreated.id}`);
    }
  }, [dataPluginCreated]);

  useEffect(() => {
    if (dataPluginUpdated?.id) {
      if (dataPluginUpdated.image_file) {
        setImageUrl(dataPluginUpdated.image_file);
      }
      toast('success', 'Updated Successfully');
      setSaved(true);
      updateSection();
    }
  }, [dataPluginUpdated]);

  useEffect(() => {
    if (!id) {
      setDefaultValues(
        '',
        '#F4550D', //brand color
        '#151515', // primary color
        '#FFFFFF', // background color
        'Inter',
        defaultPluginIcon,
        'Top/Right',
        'Hey',
        'Arcade',
        'Here you can play some games that we have selected for you as a thank you for your effort, you can also access your vouchers',
        'Expires in:',
        'DAYS',
        'HRS',
        'MIN',
        'Play Now',
        true,
        'Popup',
        false,
      );
    }
  }, [id]);

  // Load data
  useEffect(() => {
    if (data) {
      setValue('id', data?.id);
      setValue('name', data.name);

      if (data.id) {
        if (!data?.heading_message && !data?.description_message && !data?.greeting_message) {
          setIsHeroSectionChecked(false);
        } else if (!data?.heading_message) {
          setShowHeading(false);
        } else if (!data?.description_message) {
          setShowDescription(false);
        } else if (!data?.greeting_message) {
          setShowGreetingMessage(false);
        }

        if (!data?.countdown_text_message && !data?.days && !data?.hours && !data?.minutes) {
          setIsCountdownChecked(false);
        } else if (!data?.countdown_text_message) {
          setShowExpiresIn(false);
        } else if (!data?.days) {
          setShowExpiresDays(false);
        } else if (!data?.hours) {
          setShowExpiresHours(false);
        }

        setDefaultValues(
          data?.name,
          data?.brand_color,
          data?.primary_color,
          data?.background_color,
          data?.typography,
          data?.image_file,
          data?.image_align,
          data?.greeting_message,
          data?.heading_message,
          data?.description_message,
          data?.countdown_text_message,
          data?.days,
          data?.hours,
          data?.minutes,
          data?.cta_text_message,
          data?.powered_by_blkd,
          data?.embed_type,
          data?.disable_floating_action_button,
        );
        updateSection();
      } else {
        setDefaultValues(
          '',
          '#F4550D', //brand color
          '#151515', // primary color
          '#FFFFFF', // background color
          'Inter',
          defaultPluginIcon,
          'Top/Right',
          'Hey',
          'Arcade',
          'Here you can play some games that we have selected for you as a thank you for your effort, you can also access your vouchers',
          'Expires in:',
          'DAYS',
          'HRS',
          'MIN',
          'Play Now',
          true,
          'Popup',
          false,
        );
      }
    }
  }, [data]);

  /* @TODO: commented to check navigation
   *const pluginName = useMemo(() => {
   *  return data?.name ? generateSlug(data?.name) : 'New Plugin';
   *}, [data]);
   */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setDefaultValues = (
    name: string,
    brand_color?: string,
    primary_color?: string,
    background_color?: string,
    typography?: string,
    image_file?: string,
    image_align?: string,
    greeting_message?: string,
    heading_message?: string,
    description_message?: string,
    countdown_text_message?: string,
    days?: string,
    hours?: string,
    minutes?: string,
    cta_text_message?: string,
    powered_by_blkd?: boolean,
    embed_type?: string,
    disable_floating_action_button?: boolean,
  ) => {
    setValue('name', name);
    setValue('brand_color', brand_color);
    setValue('primary_color', primary_color);
    setValue('background_color', background_color);
    setValue('typography', typography);
    setImageUrl(image_file || defaultPluginIcon);
    setValue('image_align', image_align);
    setValue('greeting_message', greeting_message);
    setValue('heading_message', heading_message);
    setValue('description_message', description_message);
    setValue('countdown_text_message', countdown_text_message);
    setValue('days', days);
    setValue('hours', hours);
    setValue('minutes', minutes);
    setValue('cta_text_message', cta_text_message);
    setValue('powered_by_blkd', powered_by_blkd);
    setValue('embed_type', embed_type);
    setValue('disable_floating_action_button', disable_floating_action_button);
  };

  const updateSectionOpened = (
    key: keyof PluginSectionsStateProps,
    opened: boolean,
    status: StatusBadge,
    disableCollapse?: boolean,
  ) => {
    setSectionsStatus((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        badge: {
          ...prevState[key].badge,
          status,
        },
        opened,
        ...(disableCollapse !== undefined && { disableCollapse }),
      },
    }));
  };

  const updateSection = () => {
    updateSectionOpened('plugin', true, StatusBadge.icon, false);
    updateSectionOpened('userPermission', false, StatusBadge.enabled, false);
  };

  const updateSectionNext = () => {
    if (!getValues('name').length) {
      setError('name', { type: 'required', message: 'Name is required' });
    } else {
      updateSectionOpened('plugin', false, StatusBadge.icon, false);
      updateSectionOpened('userPermission', true, StatusBadge.enabled, false);
    }
    window.scrollTo(0, 0);
  };

  const updateSectionBack = () => {
    updateSectionOpened('plugin', true, StatusBadge.enabled, false);
    updateSectionOpened('userPermission', false, StatusBadge.disabled, true);
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('The FileReader result is not a string.'));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const onSubmit = handleSubmit(async (variables) => {
    const dataPlugin = {
      ...variables,
      greeting_message: isHeroSectionChecked && showGreetingMessage ? variables.greeting_message : '',
      heading_message: isHeroSectionChecked && showHeading ? variables.heading_message : '',
      description_message: isHeroSectionChecked && showDescription ? variables.description_message : '',
      countdown_text_message: isCountdownChecked && showExpiresIn ? variables.countdown_text_message : '',
      days: isCountdownChecked && showExpiresDays ? variables.days : '',
      hours: isCountdownChecked && showExpiresHours ? variables.hours : '',
      minutes: isCountdownChecked && showExpiresMinutes ? variables.minutes : '',
    };

    if (variables.id) {
      delete dataPlugin.id;
      if (file.file) {
        const imageBase64 = await getBase64(file.file);
        dataPlugin.image_file = imageBase64;
      }
      updateRewardsPlugin({
        accountId: getAccount.accountId,
        data: dataPlugin,
      });
    } else {
      if (file.file) {
        const imageBase64 = await getBase64(file.file);
        dataPlugin.image_file = imageBase64;
      }
      saveRewardsPlugin({
        accountId: getAccount.accountId,
        data: dataPlugin,
      });
    }
  });

  const cancel = () => {
    history.push(`/share-center/rewards-plugin`);
  };

  const onNext = () => {
    updateSectionNext();
  };

  const onPrevious = () => {
    updateSectionBack();
  };

  const openModal = () => {
    setPreviewModal(true);
  };

  return (
    <>
      {previewModal && <PluginModal setPreviewModal={setPreviewModal} datAapUid={data?.app_uid} />}
      {/* @TODO: commented to check navigation
        <Breadcrumb backToUrl="/share-center/rewards-plugin" items={['Plugins', pluginName]} />
      */}
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} noValidate>
          <SectionCollapsibleFull
            badge={sectionsStatus.plugin.badge}
            expanded={sectionsStatus.plugin.opened}
            header={{ title: 'Set up your Plugin' }}
            containerClassname={styles.container}
            {...(!loading && {
              footer: {
                leftButton: {
                  children: 'Back',
                  variant: 'secondary',
                  size: 'sm',
                  onClick: cancel,
                  disabled: loadingPluginCreated || loadingPluginUpdated,
                },
                rightButton: {
                  children: 'Next',
                  variant: 'primary',
                  size: 'sm',
                  onClick: onNext,
                  loading: loadingPluginCreated || loadingPluginUpdated,
                  disabled: loadingPluginCreated || loadingPluginUpdated,
                },
              },
            })}
          >
            <>
              {loading && !saved ? (
                <div className={styles['spinner-container']}>
                  <SpinnerBox />
                </div>
              ) : (
                <PluginForm
                  file={file}
                  setFile={setFile}
                  imageUrl={imageUrl}
                  isHeroSectionChecked={isHeroSectionChecked}
                  setIsHeroSectionChecked={setIsHeroSectionChecked}
                  isCountdownChecked={isCountdownChecked}
                  setIsCountdownChecked={setIsCountdownChecked}
                  setShowGreetingMessage={setShowGreetingMessage}
                  showGreetingMessage={showGreetingMessage}
                  setShowHeading={setShowHeading}
                  showHeading={showHeading}
                  setShowDescription={setShowDescription}
                  showDescription={showDescription}
                  setShowExpiresIn={setShowExpiresIn}
                  showExpiresIn={showExpiresIn}
                  showExpiresDays={showExpiresDays}
                  setShowExpiresDays={setShowExpiresDays}
                  showExpiresHours={showExpiresHours}
                  setShowExpiresHours={setShowExpiresHours}
                  showExpiresMinutes={showExpiresMinutes}
                  setShowExpiresMinutes={setShowExpiresMinutes}
                />
              )}
            </>
          </SectionCollapsibleFull>
          <EmbedYourPlugin
            sectionsStatus={sectionsStatus.userPermission}
            nextStep={openModal}
            previousStep={onPrevious}
            loading={loadingPluginCreated || loadingPluginUpdated}
          />
        </form>
      </FormProvider>
    </>
  );
}
