import Icon from '../../../../../../componentsV2/DesignSystem/Icon';
import Modal from '../../../../../../componentsV2/DesignSystem/Modal';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import { sectionStatus } from '../../../../../../componentsV2/DesignSystem/Sections/sectionsUtils';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { REACT_APP_WIDGET_URL } from '../../../../../../config/Env';
import useAccount from '../../../../../../hooks/useAccount';
import { useGetPluginSecretKey } from '../useGetPluginSecretKey';
import { PluginModalProps } from './interfaces';

import styles from './styles.module.scss';

export default function PluginModal({ setPreviewModal, datAapUid }: PluginModalProps): JSX.Element {
  const { getAccount } = useAccount();
  const { data, loading } = useGetPluginSecretKey(getAccount?.accountId);

  const badge = { status: StatusBadge.disabled, number: 1 };
  const badge1 = { status: StatusBadge.enabled, number: 2 };
  const badge2 = { status: StatusBadge.enabled, number: 3 };

  const closeModal = () => {
    setPreviewModal(false);
  };

  const scriptText = `
            <script
              src='${REACT_APP_WIDGET_URL}/rewards-plugin.js'
              data-app-uuid='${datAapUid}'
              data-uid='add your data-uid here'
              data-name='add name here'
              data-hmac='add your secret key here'
            ></script>
            `;

  return (
    <Modal
      title="Standard Embed"
      callback={closeModal}
      leftButton={{ children: 'Close', variant: 'secondary', onClick: closeModal }}
      size="small"
    >
      <div className={styles['embed_preview_body']}>
        <div className={styles['body_section_header']}>
          <div className={styles['badge_number']}>{badge && sectionStatus(badge)}</div>
          <Paragraph size={1} weight={FontWeight.extraBold}>
            Copy the codes
          </Paragraph>
        </div>

        <Paragraph size={1} weight={FontWeight.semiBold} color="#333947">
          Plugin code
        </Paragraph>

        <div className={styles['plugin_code_text']}>
          <Paragraph size={2} weight={FontWeight.regular}>
            {scriptText}
          </Paragraph>
        </div>
        <div className={styles['plugin_copy_text']}>
          <Paragraph size={2} weight={FontWeight.regular}>
            {`Copy this code to the <body> section of your web page`}
          </Paragraph>
        </div>

        <div className={styles['copy_button_section']}>
          <button
            className={styles['copy_button']}
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(scriptText);
            }}
          >
            <div>
              <Icon customClass={styles['copy_icon']}>copy_all</Icon>
            </div>
            <div>Copy Code</div>
          </button>
        </div>

        <hr />

        <div className={styles['body_section_header']}>
          <div className={styles['badge_number']}>{badge1 && sectionStatus(badge1)}</div>
          <Paragraph size={1} weight={FontWeight.extraBold}>
            Add the code in your site
          </Paragraph>
        </div>

        <Paragraph size={2} weight={FontWeight.regular}>
          {`Log into your site builder. Open the page or element where you want to put your promotion. There may be a tool to embed an element. Open the code view, usually marked with this icon </> or called something like “HTML editor.” Paste the promotion embed code where you want the promotion to appear and Save and/or Publish your page.`}
        </Paragraph>

        <hr />
        <div className={styles['body_section_header']}>
          <div className={styles['badge_number']}>{badge2 && sectionStatus(badge2)}</div>
          <Paragraph size={1} weight={FontWeight.extraBold}>
            Copy the secret key
          </Paragraph>
        </div>

        <div className={styles['copy_button_section']}>
          {loading || !data ? (
            <Paragraph size={2} weight={FontWeight.regular}>
              {`Loading...`}
            </Paragraph>
          ) : (
            <button
              className={styles['copy_hmac_key_button']}
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(data as string);
              }}
            >
              <div>
                <Icon customClass={styles['copy_icon']}>copy_all</Icon>
              </div>
              <div>*************{data?.slice(-5)}</div>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}
