import { gql, useApolloClient, useMutation } from '@apollo/client';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useAccount from '../../../../../../hooks/useAccount';
import { InvitateCreated, ShareInviteAnonymous } from '../../../../../../ts';
import getUTCDateAfterAddingDays from '../../../../../../utils/getUTCDateAfterAddingDays';
import { CREATE_INBULK_QUANTITY_INVITE } from '../../../../Campaign/Content/graphql';
import useShareLinks from '../../ShareLinksProvider';
import AnnonymousLinks from './AnnonymousLinks';
import { CreateAnonymousProps, CreateAnonymousRef } from './interface';
import { useCreateAnonymousDownloadMutation } from './useCreateAnonymousDownloadMutation';

export default forwardRef<CreateAnonymousRef, CreateAnonymousProps>(function CreateAnonymous(
  { loadingAnonymous, setLoadingAnonymous }: CreateAnonymousProps,
  ref,
) {
  const {
    promotionSelected,
    pushNewInviteLink,
    setInviteTypeListMethod,
    setGeneratorId,
    seDownloadLink,
    inviteLinkCreated,
    expireDays,
  } = useShareLinks();
  const { getAccount } = useAccount();

  const client = useApolloClient();

  const methods = useForm<ShareInviteAnonymous>({
    defaultValues: {
      agency_id: getAccount.agencyId,
      promo_id: promotionSelected.id.toString(),
      invites_number: 1,
    },
  });
  const { handleSubmit } = methods;

  const { createAnonymousDownload, data: dataAnonymousDownload } = useCreateAnonymousDownloadMutation();

  const [createInbulkQuantityInvite] = useMutation(CREATE_INBULK_QUANTITY_INVITE, {
    onCompleted: () => [
      setInviteTypeListMethod('inbulk'),
      inviteLinkCreated(),
      setGeneratorId(null),
      seDownloadLink(undefined),
      setLoadingAnonymous(false),
    ],
    onError: () => console.log('[Error]: CREATE_INBULK_QUANTITY_INVITE'),
  });

  useEffect(() => {
    if (dataAnonymousDownload) {
      setInviteTypeListMethod('inbulk');
      seDownloadLink({
        url: dataAnonymousDownload.url,
        quantity: dataAnonymousDownload.quantity,
      });
      setGeneratorId(null);
      inviteLinkCreated();
      setLoadingAnonymous(false);
    }
  }, [dataAnonymousDownload]);

  const onSubmit = handleSubmit(async (data) => {
    if (!data.invites_number) {
      return;
    }

    const expires_at = getUTCDateAfterAddingDays(expireDays);
    if (data.invites_number > 1000) {
      createInbulkQuantityInvite({
        variables: {
          agency_id: getAccount.agencyId,
          account_id: getAccount.accountId,
          promo_id: promotionSelected.id,
          url: promotionSelected.micrositeUrl,
          quantity: data.invites_number,
          expires_at,
        },
      });
    } else if (data.invites_number > 10 && data.invites_number < 1001) {
      createAnonymousDownload({
        accountId: getAccount.accountId,
        promoId: promotionSelected.id,
        url: promotionSelected.micrositeUrl,
        quantity: data.invites_number,
        expiresAt: expires_at,
      });
    } else {
      const CREATE_INVITE_LINK_GQL = gql`
        mutation createIndividuallyInvite($agency_id: ID!, $account_id: ID!, $promo_id: ID!, $expires_at: String) {
            ${Array.from(Array(data.invites_number).keys()).map(
              (index) =>
                `
                createInviteLink_${index}:  createIndividuallyInvite(agency_id: $agency_id, account_id: $account_id, promo_id: $promo_id, expires_at: $expires_at) {
                  id
                  url
                  code
                  data
                  created_at
                  uid
                  expires_at
                  expire_date
                }
              `,
            )}
        }
      `;
      await client
        .mutate({
          mutation: CREATE_INVITE_LINK_GQL,
          variables: {
            agency_id: getAccount.agencyId,
            account_id: getAccount.accountId,
            promo_id: promotionSelected.id,
            expires_at,
          },
        })
        .then((result) => {
          const inviteLinks = result.data;
          Object.keys(inviteLinks)
            .map((invite) => ({
              url: inviteLinks[invite].url,
              created_at: inviteLinks[invite].created_at,
              uid: inviteLinks[invite].uid,
              code: inviteLinks[invite].code,
              expire_date: inviteLinks[invite].expire_date,
            }))
            .map((invite) => pushNewInviteLink(invite as InvitateCreated));

          inviteLinkCreated();
          setInviteTypeListMethod('individually');
          setLoadingAnonymous(false);
        })
        .catch(() => {
          console.log('[Error]: CREATE_INVITE_LINK_GQL]');
        });
    }
  });

  useImperativeHandle(ref, () => ({
    submitForm: onSubmit,
  }));

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} noValidate>
        <AnnonymousLinks loading={loadingAnonymous} />
      </form>
    </FormProvider>
  );
});
