import { useMemo } from 'react';
import Skeleton from '../../../../../componentsV2/DesignSystem/Skeleton';
import styleModuleClasses from '../../../../../utils/styleModuleClasses';
import styles from '../AccountItem/styles.module.scss';

interface Props {
  type?: 'grid' | 'list';
}

export default function SkeletonLoading({ type }: Props): JSX.Element {
  if (type == 'list') {
    return (
      <section className={styleModuleClasses(styles, 'section', type)}>
        <header>
          <Skeleton height={1.25} width={'6rem'} rounded={1} />
          {/* @TODO: favorite icon it will be used in the future
            <Skeleton height={2} width={'2rem'} rounded={1} />
          */}
        </header>
        <footer>
          <div>
            <aside>
              <Skeleton width={'2rem'} height={2} rounded={2} />
            </aside>
            <main>
              <Skeleton width={'2rem'} height={0.75} />
              <Skeleton width={'4rem'} height={0.85} />
            </main>
          </div>
          <Skeleton height={0.5} rounded={1} />
        </footer>
      </section>
    );
  }

  const randomWidth = useMemo(() => Math.floor(Math.random() * 71) + 25, []);
  return (
    <section className={styleModuleClasses(styles, 'section', type)}>
      <header>
        <Skeleton height={1.25} width={randomWidth} rounded={1} />
        {/* @TODO: favorite icon it will be used in the future
          <Skeleton height={2} width={'2rem'} rounded={1} />
        */}
      </header>
      <footer>
        <div>
          <aside>
            <Skeleton width={'2rem'} height={2} rounded={2} />
          </aside>
          <main>
            <Skeleton width={35} height={0.75} />
            <Skeleton width={80} height={0.85} />
          </main>
        </div>
        <Skeleton height={0.5} rounded={1} />
      </footer>
    </section>
  );
}
