import { useFormContext } from 'react-hook-form';
import HtmlHeader from '../../../../../../../components/_molecules/HtmlHeader';
import PluginFilters from '../../../../../../../components/_molecules/PluginFilters';
import PluginFooter from '../../../../../../../components/_molecules/PluginFooter';
import PluginHeader from '../../../../../../../components/_molecules/PluginHeader';
import Icon from '../../../../../../../componentsV2/DesignSystem/Icon';
import RewardsPluginBg from '../../HtmlBackgrounds/RewardsPluginBg';
import spinTheWheelBackground from '../../../../../../../assets/imgs/spin-the-wheel-bg.svg';
import grabPromotionBackground from '../../../../../../../assets/imgs/grab-promotion-bg.svg';
import PluginCarouselCard from '../../../../../../../components/_molecules/PluginCarouselCard';
import { PluginPreviewProps } from './interfaces';

import styles from './styles.module.scss';

export default function PluginPreview({
  accountSlug,
  isHeroSectionChecked,
  showHeading,
  showGreetingMessage,
  showDescription,
  isCountdownChecked,
  showExpiresIn,
  showExpiresDays,
  showExpiresHours,
  showExpiresMinutes,
}: PluginPreviewProps): JSX.Element {
  const carouselCards = [
    {
      promotion_background: spinTheWheelBackground,
    },
    {
      promotion_background: grabPromotionBackground,
    },
  ];
  const { watch } = useFormContext();

  const [
    brand_color,
    background_color,
    primary_color,
    typography,
    greeting_message,
    heading_message,
    description_message,
    countdown_text_message,
    days,
    hours,
    minutes,
    cta_text_message,
    powered_by_blkd,
  ] = watch([
    'brand_color',
    'background_color',
    'primary_color',
    'typography',
    'greeting_message',
    'heading_message',
    'description_message',
    'countdown_text_message',
    'days',
    'hours',
    'minutes',
    'cta_text_message',
    'powered_by_blkd',
  ]);

  return (
    <>
      <HtmlHeader urlText={`www.${accountSlug}.com`} />
      <div className={styles['html_page']}>
        <RewardsPluginBg />
        <div className={styles['fullscreen-overlay']}>
          <div
            className={styles['plugin_container']}
            style={{
              backgroundColor: background_color,
              fontFamily: `${typography}, Inter`,
            }}
          >
            {(showHeading || showGreetingMessage || showDescription) && isHeroSectionChecked ? (
              <PluginHeader
                heading={isHeroSectionChecked && showHeading ? heading_message : null}
                greeting_message={isHeroSectionChecked && showGreetingMessage ? greeting_message : null}
                description={isHeroSectionChecked && showDescription ? description_message : null}
                primary_color={primary_color}
                brand_color={brand_color}
              />
            ) : null}

            <section className={styles['filters_carousel_wrapper']}>
              <PluginFilters brand_color={brand_color} />

              <div className={styles['carousel_section']}>
                <div
                  className={styles['carousel_arrow_left']}
                  style={{
                    backgroundColor: brand_color,
                    boxShadow: `0 0 0 1px ${brand_color}`,
                  }}
                >
                  <Icon>chevron_left</Icon>
                </div>
                <div
                  className={styles['carousel_arrow_right']}
                  style={{
                    backgroundColor: brand_color,
                    boxShadow: `0 0 0 1px ${brand_color}`,
                  }}
                >
                  <Icon>chevron_right</Icon>
                </div>
                {carouselCards.map((card, index) => {
                  return (
                    <PluginCarouselCard
                      key={index}
                      primary_color={primary_color}
                      brand_color={brand_color}
                      expires_in={isCountdownChecked && showExpiresIn ? countdown_text_message : ''}
                      expires_days={isCountdownChecked && showExpiresDays ? days : ''}
                      expires_hours={isCountdownChecked && showExpiresHours ? hours : ''}
                      expires_minutes={isCountdownChecked && showExpiresMinutes ? minutes : ''}
                      promotion_background={card.promotion_background}
                      button_text={cta_text_message}
                    />
                  );
                })}
              </div>
            </section>
            {powered_by_blkd && (
              <>
                <div>
                  <hr className={styles['divider-footer']} />
                </div>
                <PluginFooter background_color={background_color} primary_color={primary_color} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
