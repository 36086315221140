import React from 'react';
import classnames from 'classnames';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  className?: string;
}

export default React.forwardRef<HTMLInputElement, Props>(function Input({ error, className, ...props }: Props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      className={classnames('form-control', className, {
        'is-invalid': error,
      })}
    />
  );
});
