export interface ZapierAppData {
  uuid: string;
  title: string;
  slug: string;
  description: string;
  image: string;
  url: string;
  images: ZapierAppImages;
}

export interface ZapierZapTemplatesData {
  id: number;
  steps: ZapierZapTemplateSteps[];
  title: string;
  slug: string;
  status: string;
  description_plain: string;
  description_raw: string;
  url: string;
  description: string;
  create_url: string;
  type: string;
}

export interface ZapierZapTemplateSteps {
  id: number;
  uuid: string;
  title: string;
  slug: string;
  description: string;
  image: string;
  hex_color: string;
  images: ZapierAppImages;
  api: string;
  url: string;
  label: string;
}

export interface ZapierAppImages {
  url_16x16: string;
  url_32x32: string;
  url_64x64: string;
  url_128x128: string;
}

export interface ZapierZap {
  id: number;
  title: string;
  state: 'on' | 'draft' | 'off';
  description: string;
  steps: ZapierZapStep[];
}

export interface ZapierZapStep {
  type_of: string;
  app: ZapierZapStepApp;
}

export interface ZapierZapStepApp {
  id: number;
  title: string;
  images: ZapierAppImages;
}

export enum CredentialServicesName {
  ZAPIER = 'ZAPIER',
}

export interface ZapierZapError {
  status: number;
  message: string;
}

export interface ZapierPopularAppData {
  slug: string;
  name: string;
  image: string;
  images: ZapierAppImages;
  primary_color: string;
}

export enum ZapierWindowMessageType {
  ACCESS_TOKEN,
}
