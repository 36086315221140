import { useRef, useState } from 'react';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { Button } from '../../Inputs/Buttons/ButtonAction';
import Pills from '../../Pills';
import Popover from '../../Popover';
import { Heading } from '../../Typography';
import { SectionCollapsibleHalfProps, StatusBadge } from '../interface';
import { sectionStatus } from '../sectionsUtils';
import styles from '../styles.module.scss';

export default function SectionCollapsibleHalf({
  header,
  badge,
  footer,
  expanded,
  handleCollapse,
  wrapperClassname,
  showButton,
  showButtonPopover,
  children,
  evenClosed,
  containerClassname,
}: SectionCollapsibleHalfProps): JSX.Element {
  const validateToHandleCollapse = badge?.status === StatusBadge.enabled || badge?.status === StatusBadge.icon;

  const [showPopover, setShowPopover] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <section className={styleModuleClasses(styles, 'section', containerClassname)}>
      <header
        className={[validateToHandleCollapse ? styles['enabled'] : ''].join(' ')}
        onClick={validateToHandleCollapse ? handleCollapse : undefined}
      >
        {badge && sectionStatus(badge)}
        <Heading size={4}>{header.title || ''}</Heading>
        {!!header.pill && <Pills {...header.pill} />}
        {showButton && (
          <>
            <Button
              ref={buttonRef}
              size="sm"
              variant="secondary"
              onClick={handleCollapse}
              {...(typeof showButton === 'boolean' || !showButton.firstIcon
                ? { lastIcon: { children: !expanded ? 'edit' : 'close' } }
                : { ...showButton })}
              {...(showButtonPopover && { onMouseEnter: () => setShowPopover(true) })}
              {...(showButtonPopover?.hiddenOverlay &&
                showButtonPopover && { onMouseLeave: () => setShowPopover(false) })}
            />
            {showButtonPopover && (
              <Popover
                propRef={buttonRef}
                showPopover={showPopover}
                callback={() => setShowPopover(false)}
                placement="left"
                size={showButtonPopover?.size ?? 'sm'}
                header={showButtonPopover?.header}
                showArrow
                color={showButtonPopover?.color}
                hiddenOverlay={showButtonPopover?.hiddenOverlay}
                {...(showButtonPopover.buttonsFooter && {
                  buttonsFooter: showButtonPopover.buttonsFooter.map((button) => ({
                    ...button,
                    onClick: button.onClick ? button.onClick : () => setShowPopover(false),
                  })),
                })}
              >
                <div>
                  <span>{showButtonPopover?.children}</span>
                </div>
              </Popover>
            )}
          </>
        )}
      </header>
      {!!evenClosed?.hiddenWhenOpened && !!expanded ? undefined : (
        <main className={[expanded ? styles['expanded'] : ''].join(' ')}>{evenClosed?.content}</main>
      )}
      <div className={[expanded ? styles['expanded'] : '', wrapperClassname].join(' ')}>{children}</div>
      {footer && (
        <footer className={[expanded ? styles['expanded'] : ''].join(' ')}>
          {footer.leftButton && (
            <Button {...footer.leftButton} onClick={footer.leftButton.onClick || handleCollapse}>
              {footer.leftButton.children}
            </Button>
          )}
          {footer.rightButton && (
            <Button {...footer.rightButton} onClick={footer.rightButton.onClick}>
              {footer.rightButton.children}
            </Button>
          )}
        </footer>
      )}
    </section>
  );
}
