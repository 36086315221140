import { ApolloError, gql, useMutation } from '@apollo/client';

interface ExpiryInviteLink {
  status: string;
  message: string;
  random: number;
}

const EXPIRE_INVITELINK_BULK = gql`
  mutation expireInviteLinkBulk($agencyId: ID!, $accountId: ID!, $codes: String) {
    expireInviteLinkBulk(agencyId: $agencyId, accountId: $accountId, codes: $codes) {
      status
      message
      random
    }
  }
`;

export function useExpiryInviteLinksMutation(): {
  loading: boolean;
  expiryInviteLinks?: (agencyId: number, accountId: number, codes: string) => void;
  data: ExpiryInviteLink | null;
  error?: ApolloError;
} {
  const [expiryInviteLinksMutation, { loading, data, error }] = useMutation<{ expireInviteLinkBulk: ExpiryInviteLink }>(
    EXPIRE_INVITELINK_BULK,
    {
      onError: (error: ApolloError) => {
        console.log({
          gql: 'EXPIRE_INVITELINK_BULK',
          apolloError: error,
        });
      },
    },
  );

  const expiryInviteLinks = (agencyId: number, accountId: number, codes: string) => {
    expiryInviteLinksMutation({ variables: { agencyId, accountId, codes } });
  };

  if (error) {
    return {
      error,
      loading: false,
      data: null,
    };
  }

  return {
    loading,
    expiryInviteLinks,
    data: data?.expireInviteLinkBulk || null,
  };
}
