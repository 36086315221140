import React from 'react';
import getPercentage from '../../../utils/getPercent';
import { FormatNumberComma } from '../Utils/Format';
import { ProgressbarPercentageProps } from './interface';
import styles from './styles.module.scss';

export default function ProgressbarPercentage({
  title,
  showTotal,
  percentageHighlightingDanger,
  currentAmount,
  totalAmount,
  revertProgressbar = false,
  customDescriptionOfTotals,
}: ProgressbarPercentageProps): JSX.Element {
  const percentage = getPercentage(totalAmount, currentAmount, revertProgressbar);

  return (
    <div className={styles['coupon-wrapper']}>
      <div>
        <span>{title}</span>
        <span>
          {customDescriptionOfTotals !== undefined ? (
            customDescriptionOfTotals
          ) : (
            <>
              {currentAmount}{' '}
              {showTotal &&
                totalAmount >= 0 &&
                percentage >= 0 &&
                ` of ${FormatNumberComma(totalAmount)} (${FormatNumberComma(percentage)}
          %)`}
            </>
          )}
        </span>
      </div>
      <div>
        <div
          style={{ width: percentage + '%' }}
          className={`${styles['progress-bar']} ${
            styles[percentageHighlightingDanger && percentage <= percentageHighlightingDanger ? 'low-quantity' : '']
          }`}
        ></div>
      </div>
    </div>
  );
}
