const arrayToSentence = (arr?: string[]): string => {
  if (!arr) {
    return '';
  } else if (arr.length === 1) {
    return arr[0];
  } else {
    const lastItem = arr.pop();
    return arr.join(', ') + ' and ' + lastItem;
  }
};

export default arrayToSentence;
