import { ApolloError, gql, useMutation } from '@apollo/client';

interface CreateFileDownload {
  quantity: number;
  url: string;
  name: string;
}

const CREATE_FILE_DOWNLOAD = gql`
  mutation createFileDownload(
    $accountId: ID!
    $generatorId: ID!
    $data: String!
    $name: String
    $expiresAt: String
    $uidFieldSlug: String
  ) {
    createFileDownload(
      accountId: $accountId
      generatorId: $generatorId
      data: $data
      name: $name
      expiresAt: $expiresAt
      uidFieldSlug: $uidFieldSlug
    ) {
      quantity
      url
      name
    }
  }
`;

export function useCreateFileDownloadMutation(): {
  loading: boolean;
  createFileDownload: (variables: {
    accountId: number;
    generatorId: number;
    data: string;
    name?: string;
    expiresAt?: string;
    uidFieldSlug?: string;
  }) => void;
  data: CreateFileDownload | null;
} {
  const [createFileDownloadMutation, { loading, data }] = useMutation<{
    createFileDownload: CreateFileDownload;
  }>(CREATE_FILE_DOWNLOAD, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'CREATE_FILE_DOWNLOAD',
        apolloError: error,
      });
    },
  });

  const createFileDownload = (variables: {
    accountId: number;
    generatorId: number;
    data: string;
    name?: string;
    expiresAt?: string;
    uidFieldSlug?: string;
  }) => {
    createFileDownloadMutation({ variables });
  };

  return {
    loading,
    createFileDownload,
    data: data?.createFileDownload || null,
  };
}
