import { useRouteMatch } from 'react-router-dom';
import ButtonNavigation from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import List from './List';
import { WidgetListProps } from './interface';
import styles from './styles.module.scss';

export default function Content({ widgets, refetch, pagination, currentPage }: WidgetListProps): JSX.Element {
  const { url } = useRouteMatch();
  return (
    <div className={styles['wrapper']}>
      <Paragraph size={2} weight={FontWeight.semiBold}>
        Create your widget
      </Paragraph>

      <Paragraph size={2}>
        On loading the link they will be logged in to the Display to Play widget. Clicking the logo will generate a new
        QR allowing a new entry.
      </Paragraph>

      <ButtonNavigation firstIcon={{ children: 'widgets' }} variant="primary" to={`${url}/create-widget`} size="sm">
        Create new widget
      </ButtonNavigation>

      <List widgets={widgets} refetch={refetch} pagination={pagination} currentPage={currentPage} />
    </div>
  );
}
