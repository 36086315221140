import { createContext, useContext, useState } from 'react';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import { PromotionSimpleData } from '../../../../graphql/queries/useFetchPromotionsSimpleList';
import {
  FieldData,
  InvitateCreated,
  InviteCreatedFormated,
  InviteCreationMethod,
  ShareInviteIndividuallyField,
} from '../../../../ts';

interface ShareLinksContextProps {
  promotionSelected: PromotionSimpleData;
  setPromotionSelected: (promotion: PromotionSimpleData) => void;
  inviteMethod: InviteCreationMethod;
  setInviteMethod: (method?: InviteCreationMethod) => void;
  inviteMethodPersonalized: InviteMethodPersonalized | undefined;
  setInviteMethodPersonalized: (inviteMethodPersonalized: InviteMethodPersonalized | null) => void;
  inviteTypeListMethod: InviteMethodPersonalized;
  setInviteTypeListMethod: (inviteTypeListMethod?: InviteMethodPersonalized) => void;
  inviteListDataPersonalized: InviteListDataPersonalized[];
  downloadLink: DownloadLink;
  seDownloadLink: (downloadLink?: DownloadLink) => void;
  executeSingleForm: boolean;
  setExecuteSingleForm: (executeSingleForm: boolean) => void;
  loadingPersonalized: boolean;
  setLoadingPersonalized: (loadingPersonalized: boolean) => void;
  quantityInBulk: number;
  setQuantityInBulk: (quantityInBulk: number) => void;
  generatorId: number | null;
  setGeneratorId: (generatorId: number | null) => void;
  executeInBulkForm: boolean;
  setExecuteInBulkForm: (executeInBulkForm: boolean) => void;
  clearFields: number;
  setClearFields: (clearFields: number) => void;
  setSectionExpanded: (key: keyof SectionsStateProps, value: boolean, status: StatusBadge) => void;
  getSectionValue: (key: keyof SectionsStateProps) => SectionStateProps;
  pushNewInviteLink: (inviteData: InvitateCreated, listFields?: FieldData[]) => void;
  inviteLinkCreated: () => void;
  expireDays: number;
  setExpireDays: (expireDays: number) => void;
  uidField: string;
  setUidField: (uidField?: string) => void;
  getUidFromlistFields: (fields?: FieldData[]) => FieldData[];
  getFormFieldsWithUidFieldList: (
    dataFields: ShareInviteIndividuallyField[],
    uidFieldsData: FieldData[],
  ) => ShareInviteIndividuallyField[];
  setDefaultUidField: (slug: string) => void;
}

interface ShareLinksProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface DownloadLink {
  url?: string;
  name?: string;
  quantity?: number;
}

export interface FileInbulk {
  name: string;
  file: File | null;
}

export type InviteMethodPersonalized = 'individually' | 'inbulk';

interface SectionsStateProps {
  kindOfInviteLinks: SectionStateProps;
  inviteLinks: SectionStateProps;
  sendEmailSection: SectionStateProps;
}

interface SectionStateProps {
  badge: { status: StatusBadge; icon?: { name: string; color: string }; number: number };
  expanded: boolean;
}

export interface InviteListDataPersonalized extends InviteCreatedFormated {
  email?: string;
  first_name?: string;
}

const ShareLinksContext = createContext({});

export function ShareLinksProvider({ children }: ShareLinksProviderProps): JSX.Element {
  // Select promotion
  const [promotionSelected, setPromotionSelected] = useState<PromotionSimpleData>();
  // choose a method = anonymous or personalized
  const [inviteMethod, setInviteMethod] = useState<InviteCreationMethod>();
  // when method selected was personalized = individually or inbulk
  const [inviteMethodPersonalized, setInviteMethodPersonalized] = useState<InviteMethodPersonalized | null>(null);
  // set type of list = single or grouped
  // it is individually when [inviteMethod=anonymous] or [inviteListMode=personalized && inviteMethodPersonalized=individually]
  // it is inbulk when [inviteListMode=personalized && inviteMethodPersonalized=inbulk]
  const [inviteTypeListMethod, setInviteTypeListMethod] = useState<InviteMethodPersonalized>();
  // list of invitelinks created individually
  const [inviteListDataPersonalized, setInviteListDataPersonalized] = useState<InviteListDataPersonalized[]>([]);
  const [downloadLink, seDownloadLink] = useState<DownloadLink>();
  const [executeSingleForm, setExecuteSingleForm] = useState<boolean>(false);
  const [loadingPersonalized, setLoadingPersonalized] = useState<boolean>(false);
  const [generatorId, setGeneratorId] = useState<number | null>(null);
  const [quantityInBulk, setQuantityInBulk] = useState<number>(0);
  const [executeInBulkForm, setExecuteInBulkForm] = useState<boolean>(false);
  const [clearFields, setClearFields] = useState<boolean>(false);
  const [expireDays, setExpireDays] = useState<number>(0);
  const [uidField, setUidField] = useState<string>();

  const [sectionsPrivateStatus, setSectionsPrivateStatus] = useState<SectionsStateProps>({
    kindOfInviteLinks: {
      badge: { status: StatusBadge.enabled, icon: { name: 'done', color: 'green' }, number: 2 },
      expanded: true,
    },
    inviteLinks: {
      badge: { status: StatusBadge.disabled, icon: { name: 'done', color: 'green' }, number: 3 },
      expanded: false,
    },
    sendEmailSection: {
      badge: { status: StatusBadge.disabled, icon: { name: 'done', color: 'green' }, number: 4 },
      expanded: false,
    },
  });

  const setSectionExpanded = (key: keyof SectionsStateProps, expanded: boolean, status: StatusBadge) => {
    setSectionsPrivateStatus((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        badge: {
          ...prevState[key].badge,
          status,
        },
        expanded,
      },
    }));
  };

  const getSectionValue = (key: keyof SectionsStateProps) => {
    return {
      badge: sectionsPrivateStatus[key].badge,
      expanded: sectionsPrivateStatus[key].expanded,
    };
  };

  const inviteLinkCreated = () => {
    setSectionExpanded('kindOfInviteLinks', true, StatusBadge.icon);
    setSectionExpanded('inviteLinks', true, StatusBadge.icon);
    if (inviteMethod === InviteCreationMethod.PERSONALIZED) {
      setSectionExpanded('sendEmailSection', true, StatusBadge.enabled);
    }
  };

  const pushNewInviteLink = (inviteData: InvitateCreated, listFields?: FieldData[]) => {
    let dataFieldsFormated;
    let first_name;
    let email;
    if (inviteData.data) {
      const dataParsed = JSON.parse(inviteData.data);

      dataFieldsFormated = Object.keys(dataParsed).map((item) => {
        const name = listFields?.find((fieldData: FieldData) => fieldData.slug === item);
        return { name: name?.name, value: dataParsed[item] };
      });

      email = dataFieldsFormated.find((field) => field.name?.toLocaleUpperCase() === 'EMAIL');
      first_name = dataFieldsFormated.find((field) => field.name?.toLocaleUpperCase() === 'FIRST NAME');
    }

    const newInviteData = {
      url: `${inviteData.url}?inv=${inviteData.code}`,
      data: dataFieldsFormated,
      created_at: inviteData.created_at,
      uid: inviteData.uid,
      code: inviteData.code,
      email: email?.value || '',
      first_name: first_name?.value || '',
      expire_date: inviteData.expire_date || '',
    } as InviteListDataPersonalized;
    setInviteListDataPersonalized((prev) => [newInviteData, ...prev]);
  };

  const getUidFromlistFields = (fields: FieldData[]) => (fields ? fields.filter((field) => field.is_uid_field) : []);

  const getFormFieldsWithUidFieldList = (dataFields: ShareInviteIndividuallyField[], uidFieldsData: FieldData[]) =>
    dataFields.filter((field) => uidFieldsData.some((data) => data.slug === field.slug));

  const setDefaultUidField = (slug: string) => {
    if (slug) {
      if (slug !== uidField) {
        setUidField(slug);
      }
    }
  };

  return (
    <ShareLinksContext.Provider
      value={{
        promotionSelected,
        setPromotionSelected,
        inviteMethod,
        setInviteMethod,
        inviteMethodPersonalized,
        setInviteMethodPersonalized,
        inviteTypeListMethod,
        setInviteTypeListMethod,
        inviteListDataPersonalized,
        downloadLink,
        seDownloadLink,
        executeSingleForm,
        setExecuteSingleForm,
        loadingPersonalized,
        setLoadingPersonalized,
        quantityInBulk,
        setQuantityInBulk,
        generatorId,
        setGeneratorId,
        executeInBulkForm,
        setExecuteInBulkForm,
        clearFields,
        setClearFields,
        setSectionExpanded,
        getSectionValue,
        pushNewInviteLink,
        inviteLinkCreated,
        expireDays,
        setExpireDays,
        uidField,
        setUidField,
        getUidFromlistFields,
        getFormFieldsWithUidFieldList,
        setDefaultUidField,
      }}
    >
      {children}
    </ShareLinksContext.Provider>
  );
}

export default function useShareLinks(): ShareLinksContextProps {
  const context = useContext(ShareLinksContext) as ShareLinksContextProps;
  return context;
}
