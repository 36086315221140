import { FontWeight, Paragraph } from '../../../componentsV2/DesignSystem/Typography';
import Dot from '../../_atoms/Dot';
import { HtmlHeaderProps } from './interface';

import styles from './styles.module.scss';
import Icon from '../../../componentsV2/DesignSystem/Icon';

export default function HtmlHeader({ urlText }: HtmlHeaderProps): JSX.Element {
  const dotColors = ['#F43454', '#FFAE0B', '#00C267'];

  return (
    <header className={styles['html_header_container']}>
      <div className={styles['padding-top-15']}>
        <Paragraph size={2} weight={FontWeight.semiBold}>
          Preview
        </Paragraph>
      </div>
      <div className={styles['html_url_wrapper']}>
        <div className={styles['icon-wrapper']}>
          <Icon customClass={styles['icon']}>lock</Icon>
        </div>
        <div className={styles['padding-top-2']}>
          <Paragraph size={2} weight={FontWeight.regular} color="#949CB2">
            {urlText}
          </Paragraph>
        </div>
        <div className={styles['icon-wrapper']}>
          <Icon customClass={styles['icon']}>link</Icon>
        </div>
      </div>
      <div className={styles['dots-wrapper']}>
        {dotColors.map((color, index) => (
          <Dot key={index} bgColor={color} />
        ))}
      </div>
    </header>
  );
}
