import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';

interface Props {
  page: string | number;
  currentPage: number;
  handlePage: (page: number) => void;
}

export default function PaginationItem({ page, currentPage, handlePage }: Props): JSX.Element {
  return (
    <>
      {page === '...' ? (
        <Button
          disabled
          variant={'tertiary'}
          text={{ color: 'gray-48' }}
          firstIcon={{ children: 'more_horiz', style: { transform: 'translate(0px, 25%)' } }}
        />
      ) : (
        <Button variant={page === currentPage ? 'secondary' : 'tertiary'} onClick={() => handlePage(Number(page))}>
          {page}
        </Button>
      )}
    </>
  );
}
