import Subscription from '../../pages/Admin/Subscription';
import Builder from '../../pages/Admin/Campaign/Builder';
import DevSupport from '../../pages/Admin/DevSupport';
import MaskingDomain from '../../pages/Admin/MaskingDomain';

export const ROUTES_DEV = [
  {
    component: Subscription,
    path: '/subscription',
  },
  {
    component: Builder,
    path: '/campaign/:promo_id/builder',
    exact: true,
  },
  {
    component: DevSupport,
    path: '/dev-support',
  },
  {
    component: MaskingDomain,
    path: '/masking-your-domain',
  },
];
