import React from 'react';
import classnames from 'classnames';
import Input from '../../_atoms/Input';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  errorMessage?: string;
  classContainer?: string;
  id?: string;
}

export default React.forwardRef<HTMLInputElement, Props>(function InputFormGroup(
  { label, errorMessage, classContainer, ...props }: Props,
  ref,
): JSX.Element {
  return (
    <div className={classnames('form-group', classContainer)}>
      {!!label && (
        <label htmlFor={props.id} className="form-label">
          {label}
        </label>
      )}
      <Input {...props} ref={ref} />
      <div className="invalid-feedback">{errorMessage}</div>
    </div>
  );
});
