import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import VideoPlayerProps from './interface';
import styles from './styles.module.scss';

export default function VideoPlayer({
  urlVideo,
  urlThumbnail,
  altThumbnail,
  size,
  hidePlayButton,
}: VideoPlayerProps): JSX.Element {
  const [startVideo, setStartVideo] = useState<boolean>(false);
  const [videoSize, setVideoSize] = useState<[number, number]>([0, 0]);
  const playerRef = useRef<ReactPlayer | null>(null);

  useEffect(() => {
    if (size) {
      const sortedSizes = [...size].sort((a, b) => b.breakpoint - a.breakpoint);

      const handleResize = () => {
        const screenWidth = document.documentElement.clientWidth;
        for (let i = 0; i < sortedSizes.length; i++) {
          const { breakpoint, width } = sortedSizes[i];
          if (screenWidth >= breakpoint) {
            const height = width * (9 / 16);
            setVideoSize([width, height]);
            break;
          }
        }
      };

      handleResize();

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [size]);

  const playVideo = () => {
    const player = playerRef.current;
    player?.getInternalPlayer()?.play();
    setStartVideo(true);
  };

  return (
    <div className={styles.media} style={size && { width: `${videoSize[0]}px`, height: `${videoSize[1]}px` }}>
      {!startVideo && (
        <div className={styles.thumbnail} onClick={playVideo}>
          {!hidePlayButton && (
            <button onClick={playVideo}>
              <span className="material-symbols-rounded">play_circle</span>
            </button>
          )}
          {urlThumbnail && <img src={urlThumbnail} alt={altThumbnail} />}
        </div>
      )}
      <ReactPlayer url={urlVideo} width="100%" height="100%" controls ref={playerRef} /> *
    </div>
  );
}
