import { Fragment, useEffect, useState } from 'react';
import { Button } from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Select from '../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import SelectMulti from '../../../../../componentsV2/DesignSystem/Inputs/Selects/SelectMulti';
import { OptionsProps } from '../../../../../componentsV2/DesignSystem/Inputs/Selects/interface';
import { useFetchInvitesGenerator } from '../../../../../graphql/queries/useFetchInvitesGenerator';
import useFetchPromotionsSimpleList from '../../../../../graphql/queries/useFetchPromotionsSimpleList';
import useAccount from '../../../../../hooks/useAccount';
import { PromotionVisibility } from '../../../../../ts';
import { UrlFilters } from '../interface';
import styles from '../styles.module.scss';
import useFetchInviteLinksUsers from '../useFetchInviteLinksUsers';
import { DropdownSelectFilters } from './DropdownSelectFilters';
import FILTER_LIST from './FilterList';
import SimpleFilter from './SimpleFilter';
import { FilterList, FilterValue, FiltersProps } from './interface';

export default function Filters({ search, clearUrlFilters, params }: FiltersProps): JSX.Element {
  const [filters, setFilters] = useState<FilterList[]>(FILTER_LIST);
  const [filterValue, setFilterValue] = useState<FilterValue | null>();
  const [enableFilter, setEnableFilter] = useState<boolean>(false);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);

  const { getAccount } = useAccount();

  const { data: promotionData, loading: promotionLoading } = useFetchPromotionsSimpleList(getAccount.accountId, {
    visibility: PromotionVisibility.PRIVATE,
  });

  const { data: usersData, loading: usersLoading } = useFetchInviteLinksUsers(getAccount.accountId);

  const { data: invitesGeneratorData, loading: invitesGeneratorLoading } = useFetchInvitesGenerator(
    getAccount.agencyId,
    getAccount.accountId,
    undefined,
    1,
    {
      order: 'created_at',
      sort: 'desc',
    },
    1000,
  );

  useEffect(() => {
    if (promotionData && promotionData.length > 0 && !promotionLoading) {
      const promotionSerialized = promotionData.map((promotion) => ({
        label: promotion.name,
        value: promotion.id,
      })) as OptionsProps[];
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.id === 'promotion_id' ? { ...filter, options: promotionSerialized } : filter,
        ),
      );
    }
  }, [promotionData, promotionLoading]);

  useEffect(() => {
    if (invitesGeneratorData.length > 0 && !invitesGeneratorLoading) {
      const generatorsSerialized = invitesGeneratorData.map((generator) => ({
        label: generator.name || generator.id,
        value: generator.id,
      })) as OptionsProps[];
      setFilters((prevFilters) =>
        prevFilters.map((filter) =>
          filter.id === 'generator_id' ? { ...filter, options: generatorsSerialized } : filter,
        ),
      );
    }
  }, [invitesGeneratorData, invitesGeneratorLoading]);

  useEffect(() => {
    if (usersData && usersData.length > 0 && !usersLoading) {
      const usersSerialized = usersData.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      })) as OptionsProps[];
      setFilters((prevFilters) =>
        prevFilters.map((filter) => (filter.id === 'user_id' ? { ...filter, options: usersSerialized } : filter)),
      );
    }
  }, [usersData, usersLoading]);

  const toggleFilters = (id: string) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => (filter.id === id ? { ...filter, enable: !filter.enable } : filter)),
    );
  };

  const loadParametersFilter = (parameters: UrlFilters) => {
    const loadFilter: FilterValue = {};
    if (parameters.created_at) {
      loadFilter['created_at'] = parameters.created_at;
      toggleFilters('created_at');
    }
    if (parameters.generator_id) {
      loadFilter['generator_id'] = parameters.generator_id.split(',');
      toggleFilters('generator_id');
    }
    if (parameters.invite_type) {
      loadFilter['invite_type'] = parameters.invite_type;
      toggleFilters('invite_type');
    }
    if (parameters.invite_used) {
      loadFilter['invite_used'] = parameters.invite_used;
      toggleFilters('invite_used');
    }
    if (parameters.promotion_id) {
      loadFilter['promotion_id'] = parameters.promotion_id.split(',');
      toggleFilters('promotion_id');
    }
    if (parameters.user_id) {
      loadFilter['user_id'] = parameters.user_id.split(',');
      toggleFilters('user_id');
    }
    setFilterValue(loadFilter);
    filters.findIndex((filter) => filter.enable) > -1;
  };

  useEffect(() => {
    loadParametersFilter(params);
  }, []);

  useEffect(() => {
    setIsFilterEnabled(!!filters.find((each) => !!each.enable));
  }, [filters]);

  const handleFilterValue = (id: string, value: string | string[]) => {
    const newFilterValue = { ...filterValue };

    if (value === '' || (Array.isArray(value) && value.length === 0)) {
      delete newFilterValue[id];
    } else {
      newFilterValue[id] = value;
    }

    setFilterValue(newFilterValue);
    search(id, value.toString());
  };

  const handleClearFilter = () => {
    setFilterValue(null);
    setFilters(
      filters.map((each) => {
        return { ...each, enable: false };
      }),
    );
    clearUrlFilters();
    setEnableFilter(true);
  };

  return (
    <section className={styles['wrapper-filter']}>
      <header>
        <SimpleFilter search={search} />
        <Button
          variant="secondary"
          size="sm"
          onClick={() => setEnableFilter((prev) => !prev)}
          firstIcon={{ children: 'filter_list' }}
          lastIcon={{ children: enableFilter ? 'expand_less' : 'expand_more', color: 'gray-56' }}
        >
          Filters
        </Button>
      </header>
      {enableFilter && (
        <main>
          {isFilterEnabled && (
            <header>
              {filters
                .filter((filter) => filter.enable === true)
                .map((filter) => {
                  return (
                    <Fragment key={`select-multi-${filter.id}`}>
                      {filter.multi ? (
                        <SelectMulti
                          label={filter.name}
                          placeholder={
                            promotionLoading || invitesGeneratorLoading || usersLoading ? 'Loading...' : 'Select'
                          }
                          onChange={(value) => handleFilterValue(filter.id, value)}
                          selectValue={
                            filterValue && filterValue[filter.id] ? ([] as string[]).concat(filterValue[filter.id]) : []
                          }
                          options={filter.options || []}
                          optionsAsPills
                          fullWidth
                        />
                      ) : (
                        <Select
                          label={filter.name}
                          placeholder="Select"
                          onChange={(value) => handleFilterValue(filter.id, value.toString())}
                          options={filter.options}
                          defaultValue={filterValue && filterValue[filter.id] ? filterValue[filter.id].toString() : ''}
                        />
                      )}
                    </Fragment>
                  );
                })}
            </header>
          )}
          <footer>
            <DropdownSelectFilters filters={filters} toggleFilters={toggleFilters} />
            <Button variant="secondary" size="sm" onClick={handleClearFilter} disabled={!isFilterEnabled}>
              Clear Filter
            </Button>
          </footer>
        </main>
      )}
    </section>
  );
}
