import { Route, Switch } from 'react-router-dom';
import SpinnerBox from '../../../componentsV2/DesignSystem/SpinnerBox';
import useAccount from '../../../hooks/useAccount';
import useAuth from '../../../hooks/useAuth';
import Forbidden from '../../Forbidden';
import Authentication from './Authentication';
import Authorizing from './Authorizing';
import Build from './Build';
import CreateZap from './CreateZap';
import CreateZapTemplate from './CreateZapTemplate';
import EditZap from './EditZap';
import MyZaps from './MyZaps';
import useZapierContext, { ZapierProvider } from './ZapierContextProvider';
import { ZapierProps } from './interface';
import styles from './styles.module.scss';

function Main(): JSX.Element {
  const { accessToken, loadingCredentialFindByService, PATH_ZAPIER } = useZapierContext();

  if (loadingCredentialFindByService) {
    return <SpinnerBox />;
  }

  return (
    <main className={styles['zapier']}>
      {accessToken ? (
        <Switch>
          <Route path={PATH_ZAPIER} component={MyZaps} exact />
          <Route path={`${PATH_ZAPIER}/build`} component={Build} exact />
          <Route path={`${PATH_ZAPIER}/build/:id`} component={CreateZap} exact />
          <Route path={`${PATH_ZAPIER}/edit/:id`} component={EditZap} exact />
          <Route path={`${PATH_ZAPIER}/create-zap-template`} component={CreateZapTemplate} exact />
        </Switch>
      ) : (
        <Switch>
          <Route path={`${PATH_ZAPIER}/authorizing`} component={Authorizing} />
          <Route path={`${PATH_ZAPIER}`} component={Authentication} />
        </Switch>
      )}
    </main>
  );
}

export default function Zapier({ promo_id }: ZapierProps): JSX.Element {
  const { denyEditorAccess } = useAuth();
  const { getAccount } = useAccount();

  if (denyEditorAccess(getAccount.accountId)) {
    return <Forbidden />;
  }

  return (
    <ZapierProvider promo_id={promo_id}>
      <Main />
    </ZapierProvider>
  );
}
