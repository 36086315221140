import { gql } from '@apollo/client';

export const LIST_SIGNUP_TESTIMONIALS = gql`
  query testimonials {
    testimonials(page: 1, pageSize: 10) {
      data {
        id
        message
        author
        role
        company
        background {
          src
          alt
        }
      }
    }
  }
`;
