import { ApolloError, gql, useQuery } from '@apollo/client';

const INVITELINKS_USERS = gql`
  query inviteLinksUsers($accountId: ID!) {
    inviteLinksUsers(accountId: $accountId) {
      id
      email
      first_name
      last_name
    }
  }
`;

export interface InviteLinksUsers {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}

export default function useFetchInviteLinksUsers(accountId: number): {
  loading: boolean;
  data: InviteLinksUsers[] | null;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<{ inviteLinksUsers: InviteLinksUsers[] }>(INVITELINKS_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'INVITELINKS_USERS',
        apolloError: error,
      });
    },
  });

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
    };
  }

  return {
    loading: false,
    data: data?.inviteLinksUsers || null,
  };
}
