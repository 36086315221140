import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const initialSchema = yup.object({
  widgetId: yup.number().required('The widget id field is required.'),
  username: yup.string().required('The user name field is required.'),
  password: yup.string().required('The pin field is required.'),
});

const ValidationResolver = yupResolver(initialSchema);

export default ValidationResolver;
