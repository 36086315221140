import { addDays } from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';

const getUTCDateAfterAddingDays = (daysToAdd: number): string | undefined => {
  if (!daysToAdd) {
    return undefined;
  }

  const timeZone = 'UTC';
  const utcDate = addDays(new Date(), daysToAdd);
  const utcZonedDate = utcToZonedTime(utcDate, timeZone);
  return formatTz(utcZonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });
};

export default getUTCDateAfterAddingDays;
