import { ApolloError, useMutation } from '@apollo/client';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../../../../../componentsV2/DesignSystem/Icon';
import Popover from '../../../../../../componentsV2/DesignSystem/Popover';
import ModalDuplicate from '../../../../../../componentsV2/ModalDuplicate';
import { REACT_APP_MONOLITH_V2 } from '../../../../../../config/Env';
import useAccount from '../../../../../../hooks/useAccount';
import useToast from '../../../../../../hooks/useToast';
import { PromoteListData, PromoteStatus, PromotionVisibility } from '../../../../../../ts';
import { DELETE_PROMOTION, LIST_PROMOTION_GQL, PROMOTION_COUNTER } from '../../../../Campaign/List/graphql';
import PromotionDropDown from './PromotionDropDown';
import styles from './styles.module.scss';

export default function Item(promotion: PromoteListData): JSX.Element {
  const [showModalDuplicate, setShowModalDuplicate] = useState<boolean>(false);
  const [showViewPopover, setShowViewPopover] = useState<boolean>(false);
  const [showInsightsPopover, setShowInsightsPopover] = useState<boolean>(false);
  const [showEntriesPopover, setShowEntriesPopover] = useState<boolean>(false);
  const [showIntegrationsPopover, setShowIntegrationsPopover] = useState<boolean>(false);
  const [showSharePopover, setShowSharePopover] = useState<boolean>(false);
  const [showLaunchControlPopover, setShowLaunchControlPopover] = useState<boolean>(false);
  const [showBuilderPopover, setShowBuilderPopover] = useState<boolean>(false);
  const [showDuplicatePopover, setShowDuplicatePopover] = useState<boolean>(false);
  const [showDeletePopover, setShowDeletePopover] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const viewRef = useRef(null);
  const insightsRef = useRef(null);
  const entriesRef = useRef(null);
  const integrationsRef = useRef(null);
  const shareRef = useRef(null);
  const launchControlRef = useRef(null);
  const builderRef = useRef(null);
  const duplicateRef = useRef(null);
  const deleteRef = useRef(null);

  const { getAccount } = useAccount();
  const { toast } = useToast();
  const { t } = useTranslation('promotionsList', { keyPrefix: 'dropdown_actions_menu' });

  const [deletePromotion, { loading: loadingDeletePromotion }] = useMutation<{
    deletePromotion: { status: string };
  }>(DELETE_PROMOTION, {
    refetchQueries: [LIST_PROMOTION_GQL, PROMOTION_COUNTER],
    onError: (error: ApolloError) => {
      toast('error', error.graphQLErrors[0]?.extensions?.message as string);
    },
  });

  const toggleConfirmDelete = (promoId: number) => {
    deletePromotion({
      variables: {
        accountId: getAccount.accountId,
        promoId: promoId,
      },
    });
  };

  const toggleModal = () => setShowModalDuplicate((showModalDuplicate) => !showModalDuplicate);

  useMemo(() => !!confirmDelete && setTimeout(() => setConfirmDelete(false), 2000), [confirmDelete]);

  const micrositeUrlKey = useMemo(
    () =>
      `${promotion.microsite_url}${
        promotion.status === (PromoteStatus.DRAFT || PromoteStatus.SCHEDULED) ? `?key=${promotion.passcode}` : ``
      }`,
    [promotion.microsite_url, promotion.status],
  );

  return (
    <>
      {showModalDuplicate && <ModalDuplicate promoId={promotion.id} callback={toggleModal} origin="list" />}
      <li key={`promotion-${promotion.id}`} className={styles['promotion-wrapper']}>
        <div className={styles['promotion-name-wrapper']}>
          <h2>{promotion.name}</h2>
          <span>{promotion.campaign_name}</span>
        </div>
        <div className={styles['promotion-type-wrapper']}>
          {promotion.visibility === PromotionVisibility.PRIVATE ? 'Private' : 'Public'}
        </div>
        <div className={styles['promotion-liveon-wrapper']}>
          {promotion.start_date ? new Date(promotion.start_date).toDateString() : '-'}
        </div>
        <div className={styles['promotion-entries-wrapper']}>{promotion.entry_count}</div>
        <div className={styles['promotion-actions-wrapper']}>
          <PromotionDropDown
            promoId={promotion.id}
            promoStatus={promotion.status}
            passcode={promotion.passcode}
            micrositeUrl={promotion.microsite_url}
            toggleConfirmDelete={toggleConfirmDelete}
            loadingDeletePromotion={loadingDeletePromotion}
          />
        </div>
        <div className={styles['buttons-options']}>
          {promotion.status !== PromoteStatus.DRAFT && promotion.status !== PromoteStatus.SCHEDULED && (
            <>
              <a
                href={promotion.microsite_url}
                ref={viewRef}
                onMouseEnter={() => setShowViewPopover(true)}
                onMouseLeave={() => setShowViewPopover(false)}
                target="_blank"
                rel="noreferrer"
              >
                <Icon>visibility</Icon>
                <Popover
                  mainStyle={{ padding: '.5rem' }}
                  color="gray"
                  hiddenOverlay
                  placement="bottom"
                  showPopover={showViewPopover}
                  callback={() => setShowViewPopover((prev) => !prev)}
                  propRef={viewRef}
                >
                  {t('link_view')}
                </Popover>
              </a>
              <a
                href={`${REACT_APP_MONOLITH_V2}/campaign/${promotion.id}/insights`}
                ref={insightsRef}
                onMouseEnter={() => setShowInsightsPopover(true)}
                onMouseLeave={() => setShowInsightsPopover(false)}
              >
                <Icon>bar_chart</Icon>
                <Popover
                  mainStyle={{ padding: '.5rem' }}
                  color="gray"
                  hiddenOverlay
                  placement="bottom"
                  showPopover={showInsightsPopover}
                  callback={() => setShowInsightsPopover((prev) => !prev)}
                  propRef={insightsRef}
                >
                  {t('link_insights')}
                </Popover>
              </a>
            </>
          )}
          <a
            href={`${REACT_APP_MONOLITH_V2}/campaign/${promotion.id}/entries`}
            ref={entriesRef}
            onMouseEnter={() => setShowEntriesPopover(true)}
            onMouseLeave={() => setShowEntriesPopover(false)}
          >
            <Icon>person</Icon>
            <Popover
              mainStyle={{ padding: '.5rem' }}
              color="gray"
              hiddenOverlay
              placement="bottom"
              showPopover={showEntriesPopover}
              callback={() => setShowEntriesPopover((prev) => !prev)}
              propRef={entriesRef}
            >
              {t('link_entries')}
            </Popover>
          </a>
          <Link
            to="/settings/integrations"
            ref={integrationsRef}
            onMouseEnter={() => setShowIntegrationsPopover(true)}
            onMouseLeave={() => setShowIntegrationsPopover(false)}
          >
            <Icon>terminal</Icon>
            <Popover
              mainStyle={{ padding: '.5rem' }}
              color="gray"
              hiddenOverlay
              placement="bottom"
              showPopover={showIntegrationsPopover}
              callback={() => setShowIntegrationsPopover((prev) => !prev)}
              propRef={integrationsRef}
            >
              {t('link_integrations')}
            </Popover>
          </Link>
          <Link
            to={`/share-center/share-links?promo_id=${promotion.id}`}
            ref={shareRef}
            onMouseEnter={() => setShowSharePopover(true)}
            onMouseLeave={() => setShowSharePopover(false)}
          >
            <Icon>share</Icon>
            <Popover
              mainStyle={{ padding: '.5rem' }}
              color="gray"
              hiddenOverlay
              placement="bottom"
              showPopover={showSharePopover}
              callback={() => setShowSharePopover((prev) => !prev)}
              propRef={shareRef}
            >
              {t('link_share')}
            </Popover>
          </Link>

          {(promotion.status === PromoteStatus.DRAFT || promotion.status === PromoteStatus.SCHEDULED) && (
            <a
              href={micrositeUrlKey}
              ref={viewRef}
              onMouseEnter={() => setShowViewPopover(true)}
              onMouseLeave={() => setShowViewPopover(false)}
              target="_blank"
              rel="noreferrer"
            >
              <Icon>visibility</Icon>
              <Popover
                mainStyle={{ padding: '.5rem' }}
                color="gray"
                hiddenOverlay
                placement="bottom"
                showPopover={showViewPopover}
                callback={() => setShowViewPopover((prev) => !prev)}
                propRef={viewRef}
              >
                {t('link_preview')}
              </Popover>
            </a>
          )}
          <Link
            to={`/${getAccount.accountSlug}/promotions/${promotion.id}/launch-control`}
            ref={launchControlRef}
            onMouseEnter={() => setShowLaunchControlPopover(true)}
            onMouseLeave={() => setShowLaunchControlPopover(false)}
          >
            <Icon>rocket</Icon>
            <Popover
              mainStyle={{ padding: '.5rem' }}
              color="gray"
              hiddenOverlay
              placement="bottom"
              showPopover={showLaunchControlPopover}
              callback={() => setShowLaunchControlPopover((prev) => !prev)}
              propRef={launchControlRef}
            >
              {t('link_launch_control')}
            </Popover>
          </Link>
          <a
            href={`${REACT_APP_MONOLITH_V2}/campaign/${promotion.id}/builder`}
            ref={builderRef}
            onMouseEnter={() => setShowBuilderPopover(true)}
            onMouseLeave={() => setShowBuilderPopover(false)}
          >
            <Icon>web</Icon>
            <Popover
              mainStyle={{ padding: '.5rem' }}
              color="gray"
              hiddenOverlay
              placement="bottom"
              showPopover={showBuilderPopover}
              callback={() => setShowBuilderPopover((prev) => !prev)}
              propRef={builderRef}
            >
              {t('link_builder')}
            </Popover>
          </a>
          <button
            onClick={toggleModal}
            onMouseEnter={() => setShowDuplicatePopover(true)}
            onMouseLeave={() => setShowDuplicatePopover(false)}
            ref={duplicateRef}
          >
            <Icon>file_copy</Icon>
            <Popover
              mainStyle={{ padding: '.5rem' }}
              color="gray"
              placement="bottom"
              hiddenOverlay
              showPopover={showDuplicatePopover}
              callback={() => setShowDuplicatePopover((prev) => !prev)}
              propRef={duplicateRef}
            >
              {t('link_duplicate')}
            </Popover>
          </button>
          {(promotion.status === PromoteStatus.DRAFT || promotion.status === PromoteStatus.SCHEDULED) && (
            <button
              onClick={() => setConfirmDelete((prev) => !prev)}
              onMouseEnter={() => setShowDeletePopover(true)}
              onMouseLeave={() => setShowDeletePopover(false)}
              ref={deleteRef}
              className={styles[loadingDeletePromotion ? 'loading' : '']}
            >
              {loadingDeletePromotion ? <Icon>rotate_right</Icon> : <Icon>delete</Icon>}
              <Popover
                mainStyle={{ padding: '.5rem' }}
                color="gray"
                placement="bottom"
                hiddenOverlay
                showPopover={showDeletePopover && !confirmDelete}
                callback={() => setShowDeletePopover((prev) => !prev)}
                propRef={deleteRef}
              >
                {t('link_delete')}
              </Popover>
              <Popover
                mainStyle={{ padding: '.5rem' }}
                color="gray"
                placement="left"
                hiddenOverlay
                showPopover={confirmDelete}
                callback={() => setConfirmDelete((prev) => !prev)}
                propRef={deleteRef}
                customClassArrow={styles['delete-arrow']}
                buttonsFooter={[
                  { children: 'cancel', variant: 'secondary' },
                  {
                    children: t('link_delete'),
                    variant: 'primary',
                    style: { background: '#F43454' },
                    onClick: () => toggleConfirmDelete(promotion.id),
                  },
                ]}
              >
                <>Are you sure you wish to delete this?</>
              </Popover>
            </button>
          )}
        </div>
      </li>
    </>
  );
}
