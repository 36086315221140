import React from 'react';
import { useTranslation } from 'react-i18next';
import useZapierContext from '../../ZapierContextProvider';
import styles from './styles.module.scss';
import { ItemZapTemplateProps } from './interface';
import ButtonNavigation from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';

export default function ItemZapTemplate({ template }: ItemZapTemplateProps): JSX.Element {
  const { PATH_ZAPIER } = useZapierContext();
  const { t } = useTranslation('zapier', { keyPrefix: 'build' });
  return (
    <div className={styles['wrapper-template']}>
      <div className={styles['align-logos-integrations']}>
        {template.steps.map((step, index) => (
          <React.Fragment key={`step-${index}`}>
            <div className="logos-integrations">
              <img height="25" src={step.images.url_64x64} />
            </div>
          </React.Fragment>
        ))}
      </div>
      <footer>
        <div className={styles['content-hover']}>
          <ButtonNavigation size="md" variant="secondary" to={`${PATH_ZAPIER}/build/${template.id}`}>
            {t('Create')}
          </ButtonNavigation>
        </div>
        <div className={styles['description']}>
          <div className="crop-text-line-1">
            <strong>{template.title !== '' ? template.title : 'No title'}</strong>
          </div>
          <div className="crop-text-line-2">{template.description_raw || 'No description'}</div>
        </div>
      </footer>
    </div>
  );
}
