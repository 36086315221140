import { ApolloLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createUploadLink } from 'apollo-upload-client';
import { ENABLE_BATCH_HTTP_LINK, REACT_APP_API_MOCKING, REACT_APP_API_URL } from '../../config/Env';

const uploadLink = createUploadLink({
  uri: REACT_APP_API_URL,
  credentials: 'same-origin',
  ...(REACT_APP_API_MOCKING && { fetch: (...args) => fetch(...args) }),
  fetchOptions: {
    method: 'POST',
  },
}) as unknown as ApolloLink;

let httpLink: ApolloLink;

if (ENABLE_BATCH_HTTP_LINK) {
  const batchHttpLink = new BatchHttpLink({
    uri: REACT_APP_API_URL,
    batchMax: 5,
    batchInterval: 20,
  });

  httpLink = ApolloLink.split(
    (operation) => {
      const { variables } = operation;
      return Object.values(variables).some(
        (value) => value instanceof File || (Array.isArray(value) && value.some((file) => file instanceof File)),
      );
    },
    uploadLink,
    batchHttpLink,
  );
} else {
  httpLink = uploadLink;
}

export default httpLink;
