import { REACT_APP_MONOLITH_V2 } from '../Env';
import { NavigationSidebarLink } from './interface';

const NavigationSidebarLinks: NavigationSidebarLink[] = [
  {
    icon: 'dashboard',
    title: 'Home',
    url: '/dashboard',
  },
  {
    icon: 'videogame_asset',
    title: 'Promotions',
    url: '/promotions',
  },
  {
    icon: 'share',
    title: 'Share Center',
    url: '/share-center',
  },
  {
    icon: 'groups',
    title: 'Audience',
    url: `${REACT_APP_MONOLITH_V2}/audience`,
  },
  {
    icon: 'bar_chart',
    title: 'Data Center',
    url: `/data-center`,
    isNew: true,
    superAdmin: true,
  },
  {
    icon: 'local_activity',
    title: ' Reward Center',
    url: '/rewards/coupons',
  },
  {
    icon: 'terminal',
    title: 'Developer',
    url: '/developer',
  },
];

export default NavigationSidebarLinks;
