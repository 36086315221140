import { ChangeEvent, useState } from 'react';
import Dropdown from '../../../../../componentsV2/DesignSystem/Dropdown';
import { Button } from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../componentsV2/DesignSystem/Inputs/Input';
import { FontWeight, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import useDebounce from '../../../../../hooks/useDebounce';
import styles from '../styles.module.scss';
import { FilterOptions, SimpleFilterProps } from './interface';

export default function SimpleFilter({ search }: SimpleFilterProps): JSX.Element {
  const debounce = useDebounce({ ms: 500 });

  const filterOptions: FilterOptions[] = [
    { field: 'entry_search', name: 'Personal Information' },
    { field: 'code', name: 'Code' },
  ];

  const [selectFilter, setSelectFilter] = useState<FilterOptions>(filterOptions[0]);

  const [searchValue, setSearchValue] = useState<string>('');

  const setFilter = (filterOption: FilterOptions) => {
    setSelectFilter(filterOption);
    search(filterOption.field, searchValue);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const field = selectFilter.field;
    const value = event.target.value;
    setSearchValue(value);

    if (value.length === 3) {
      search(field, value);
    } else if (value.length === 0 || value.length > 3) {
      debounce(() => search(field, value));
    }
  };

  return (
    <div className={styles['wrapper-search-input']}>
      <Dropdown
        activeButton={{
          button: {
            children: selectFilter.name,
            lastIcon: { children: 'expand_more' },
          },
        }}
        placement="bottom-start"
      >
        <div className={styles['wrapper-manage-filters']}>
          {filterOptions.map((filterOption, index) => (
            <Button
              key={`filter-names-${index}`}
              fullWidth
              variant="tertiary"
              text={{ color: 'dark', align: 'between' }}
              data-close="true"
              onClick={() => setFilter(filterOption)}
            >
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {filterOption.name}
              </Paragraph>
            </Button>
          ))}
        </div>
      </Dropdown>
      <Input placeholder="Search" value={searchValue} onChange={(e) => handleSearch(e)} />
    </div>
  );
}
