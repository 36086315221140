import { gql, useLazyQuery } from '@apollo/client';
import useToast from '../../../../../hooks/useToast';
import { FiltersPramsProps } from '../../Filters/interface';

const EXPORT_ENTRIES = gql`
  query exportEntries(
    $accountId: ID!
    $userId: ID!
    $fields: String
    $pagination: PaginationInput
    $filters: AudienceFilters
  ) {
    exportEntries(accountId: $accountId, userId: $userId, fields: $fields, pagination: $pagination, filters: $filters) {
      status
      message
    }
  }
`;

interface VariablesInterface {
  accountId: number;
  userId: string;
  fields: string;
  params: FiltersPramsProps;
}

interface ExportEntries {
  loading: boolean;
  fetchExport: (variables: VariablesInterface) => void;
}

export function useExportEntries(): ExportEntries {
  const { toast } = useToast();
  const [exportEntries, { loading: exportEntriesLoading }] = useLazyQuery<{ status: string; message: string }>(
    EXPORT_ENTRIES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        toast('success', 'Entries exported with sucess.');
      },
      onError: () => {
        toast('error', 'Error to export entries.');
      },
    },
  );

  const fetchExport = (variables: VariablesInterface) => {
    exportEntries({ variables });
  };

  if (exportEntriesLoading) {
    return {
      loading: true,
      fetchExport: fetchExport,
    };
  }

  return {
    fetchExport: fetchExport,
    loading: exportEntriesLoading,
  };
}
