import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import ButtonCopy from '../../../../componentsV2/ButtonCopy';
import ButtonPopoverConfirm from '../../../../componentsV2/ButtonPopoverConfirm';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Pills from '../../../../componentsV2/DesignSystem/Pills';
import { PillsColors } from '../../../../componentsV2/DesignSystem/Pills/interface';
import SectionCollapsibleHalf from '../../../../componentsV2/DesignSystem/Sections/CollapsibleHalf';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import { Heading } from '../../../../componentsV2/DesignSystem/Typography';
import Pagination from '../../../../componentsV2/Pagination';
import TableColumnsControl, { filterTableColumns } from '../../../../componentsV2/TableColumnsControl';
import {
  TableCellValue,
  TableDataCellSerialized,
  TableHeaderElement,
  TypeColumn,
} from '../../../../componentsV2/TableColumnsControl/interface';
import useAccount from '../../../../hooks/useAccount';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQueryString from '../../../../hooks/useQueryString';
import { FieldData, InviteData } from '../../../../ts';
import capitalize from '../../../../utils/capitalize';
import { SendEmailProvider } from '../SendEmail/SendEmailProvider';
import { DEFAULT_COLUMNS } from './DefaultColumns';
import Filters from './Filters';
import NoInviteLinks from './NoInviteLinks';
import PreviewEmailModal from './PreviewEmailModal';
import SendEmailModal from './SendEmailModal';
import TableHeaderButtons from './TableHeaderButtons';
import { UrlFilters } from './interface';
import styles from './styles.module.scss';
import { useExpireInviteLinkMutation } from './useExpireInviteLinkMutation';
import { useFetchInviteLinksByAccount } from './useFetchInviteLinksByAccount';

export default function ManageInviteLinks(): JSX.Element {
  const [serializedTable, setSerializedTable] = useState<TableHeaderElement[]>();
  const [deleteInviteLinkId, setDeleteInviteLinkId] = useState<string>();
  const [invitesSelected, setInvitesSelected] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [messageIdPreview, setMessageIdPreview] = useState<number | undefined>();
  const [modalData, setModalData] = useState<{ code: string; invitelinkUrl: string; firstName?: string }>();
  const [exportFields, setExportFields] = useState<string>();

  const addQueryParam = useQueryParams();

  const { getAccount, listFields, getDataFields } = useAccount();

  // Load Fields
  useEffect(() => getDataFields(), []);

  // Filters
  const query = useQueryString();
  const queryValues = {
    currentPage: Number(query.get('page')) || 1,
    orderColumn: query.get('order') || 'created_at',
    sortColumn: query.get('sort') || 'desc',
    tableColumns: query.get('columns') || undefined,
  };

  const filters = {
    code: query.get('code') || undefined,
    entry_search: query.get('entry_search') || undefined,
    user_id: query.get('user_id') || undefined,
    promotion_id: query.get('promotion_id') || undefined,
    created_at: query.get('created_at') || undefined,
    invite_type: query.get('invite_type') || undefined,
    invite_used: query.get('invite_used') || undefined,
    generator_id: query.get('generator_id') || undefined,
  };

  const params: UrlFilters = {
    order: queryValues.orderColumn,
    sort: queryValues.sortColumn,
    ...filters,
  };

  // Query Fetch InviteLinks
  const {
    data: dataInviteLink,
    loading: loadingInviteLink,
    pagination,
    fetchInviteLinks,
  } = useFetchInviteLinksByAccount(getAccount.accountId, queryValues.currentPage, params);

  // Mutation Expire InviteLink
  const { requestExpirationDate, data: dataExpirationDate } = useExpireInviteLinkMutation();

  // Serialize data to table
  const getSerealized = (dataInviteLink: InviteData[], fields: FieldData[]) => {
    return dataInviteLink.map((value) => {
      const serealized: TableDataCellSerialized = {
        created_at: format(new Date(value.created_at), 'dd MMM yyyy - HH:mm'),
        type: capitalize(value.type),
        uid: value.uid,
        code: value.code,
        generator_name: value.generator_name,
        promotion: value.promotion,
        used_at: value.used_at ? format(new Date(value.used_at), 'dd MMM yyyy - HH:mm') : 'Unused',
        expire_date: value.expire_date ? format(new Date(value.expire_date), 'dd MMM yyyy - HH:mm') : '',
        message_request_timestamp: value.message_request_timestamp
          ? format(new Date(value.message_request_timestamp), 'dd MMM yyyy - HH:mm')
          : '',
        user_id: value.created_by,
        message_request_status: value.message_request_status,
        url: value.url,
      };

      fields.forEach((field) => {
        serealized[field.slug] = value.data?.[field.slug] || '';
      });

      return serealized;
    });
  };

  // Merge columns
  const mergeColumns = (defaultColumns: TableHeaderElement[], fields: FieldData[]) => {
    const additionalColumns = fields.map((field, index) => {
      const enabled = ['first-name', 'last-name', 'email'].includes(field.slug);

      return {
        id: field.slug,
        base_field: field.slug,
        type: 'text' as TypeColumn,
        enabled,
        name: field.name,
        order: index + DEFAULT_COLUMNS.length + 1,
        sortDisabled: true,
      };
    });

    return [...defaultColumns, ...additionalColumns];
  };

  const validInviteLink = (dataInviteLink?: InviteData[], listFields?: FieldData[], loading?: boolean) => {
    return !dataInviteLink || !listFields || (listFields && listFields.length === 0) || loading;
  };

  // Build data table
  useEffect(() => {
    if (validInviteLink(dataInviteLink, listFields, loadingInviteLink)) {
      return;
    }

    const dataSerealized = getSerealized(dataInviteLink, listFields);
    const concatenateHeader = mergeColumns(DEFAULT_COLUMNS, listFields);

    let result: TableHeaderElement[] = buildTableResult(concatenateHeader, dataSerealized);
    result = filterTableColumns(result, queryValues.tableColumns);
    handlerFieldsToExport(result);
    setSerializedTable(result);
  }, [dataInviteLink, listFields, loadingInviteLink]);

  // Serialize export fields
  const handlerFieldsToExport = (data?: TableHeaderElement[]) => {
    const exportFieldsEnabled = data?.filter((item) => item.enabled).map((item) => item.id);
    const exportFieldsMapped = exportFieldsEnabled?.length ? `${exportFieldsEnabled.join(',')},url` : 'url';
    setExportFields(exportFieldsMapped);
  };

  // Handle columns (show/hide)
  const toggleColumns = (data?: TableHeaderElement[], columns?: string) => {
    handlerFieldsToExport(data);
    setSerializedTable(data);
    addQueryParam({ columns });
  };

  const handlePagination = (page: number) => {
    addQueryParam({ page });
  };

  const searchInviteLinks = (field: string, value: string) => {
    const searchFilter = value ? { [field]: value } : { [field]: '' };

    const filters = {
      order: params.order,
      sort: params.sort,
      ...searchFilter,
    };

    addQueryParam({ ...filters, page: 1 });
  };

  const refreshInviteLinks = () => {
    fetchInviteLinks({
      accountId: getAccount.accountId,
      page: queryValues.currentPage,
      filters: params,
    });
  };

  const tableActionButtons = useMemo(() => {
    if (dataInviteLink) {
      return dataInviteLink.map((invite) => {
        return (
          <>
            {!!invite.message_id && (
              <Button
                onClick={() => setMessageIdPreview(invite.message_id)}
                firstIcon={{ children: 'drafts' }}
                variant="secondary"
                size="sm"
              />
            )}
            <ButtonCopy
              text={`${invite.url}?inv=${invite.code}`}
              hoverMessage="Copy InviteLink URL"
              onClickMessage="Copied InviteLink URL"
            />
            <ButtonPopoverConfirm
              buttonProps={{
                variant: 'secondary',
                firstIcon: { children: 'expire', customFont: true, customClass: styles['custom-icon'] },
                size: 'sm',
                disabled: deleteInviteLinkId === invite.code || invite.expired,
                loading: deleteInviteLinkId === invite.code,
              }}
              popoverProps={{
                header: { title: 'Are you sure you want to expire this invitelink?' },
                placement: 'left-start',
                buttonsFooter: [
                  {
                    children: 'Yes, Expire',
                  },
                ],
              }}
              onClick={() => {
                deleteInviteLink(invite.code);
              }}
            />
          </>
        );
      });
    }
    return [];
  }, [dataInviteLink, deleteInviteLinkId]);

  useEffect(() => {
    if (dataInviteLink) {
      setDeleteInviteLinkId(undefined);
    }
  }, [dataInviteLink]);

  useEffect(() => {
    if (dataExpirationDate?.id) {
      fetchInviteLinks({
        accountId: getAccount.accountId,
        page: queryValues.currentPage,
      });
    }
  }, [dataExpirationDate?.id]);

  const deleteInviteLink = (code: string) => {
    setDeleteInviteLinkId(code);
    requestExpirationDate({
      agencyId: getAccount.agencyId,
      accountId: getAccount.accountId,
      code,
    });
  };

  const clearUrlFilters = () => {
    addQueryParam({
      user_id: undefined,
      promotion_id: undefined,
      created_at: undefined,
      invite_type: undefined,
      invite_used: undefined,
      generator_id: undefined,
      page: 1,
    });
  };

  const handleCheckboxSelected = (itensSelected: string[]) => {
    setInvitesSelected(itensSelected);
  };

  const buildTableResult = (
    header: TableHeaderElement[],
    contentTable: TableDataCellSerialized[],
  ): TableHeaderElement[] => {
    return header.map((column) => {
      const data = contentTable.map((item) => {
        return {
          message: (item[column.id as keyof TableDataCellSerialized] || '').toString(),
          id: item.code,
          ...(column.id === 'message_request_timestamp' &&
            item['message_request_status'] === 'failed' && {
              message: 'Failed',
              type: 'tooltip',
              buttonTooltipProps: {
                tooltip: 'Re-send InviteLink',
                size: 'sm',
                variant: 'red-block-light',
                firstIcon: { children: 'mark_email_unread' },
                onClick: () =>
                  openSendEmailModal(item['code'], `${item['url']}?inv=${item['code']}`, item['first-name']),
              },
            }),
          ...(column.id === 'message_request_timestamp' &&
            !item['message_request_timestamp'] &&
            item['message_request_status'] === 'pending' && {
              message: 'Processing...',
            }),
        } as TableCellValue;
      });
      return { ...column, data };
    });
  };

  const openSendEmailModal = (code: string, invitelinkUrl: string, firstName?: string) => {
    setModalData({ code, invitelinkUrl, firstName });
    setOpenModal(true);
  };

  return (
    <>
      <SectionCollapsibleHalf
        header={{ title: 'Manage InviteLinks' }}
        badge={{ status: StatusBadge.icon, icon: { name: 'search', color: 'enabled' } }}
        expanded
        evenClosed={{
          hiddenWhenOpened: false,
          content: <Filters search={searchInviteLinks} clearUrlFilters={clearUrlFilters} params={params} />,
        }}
      >
        <section className={styles['wrapper-result']}>
          <header>
            <div>
              <Heading size={4}>Results</Heading>
              <Pills color={PillsColors.primary} size="lg">
                {pagination.total.toString()}
              </Pills>
            </div>
            {dataInviteLink?.length > 0 && (
              <TableHeaderButtons
                invitesSelected={invitesSelected}
                refreshInviteLinks={refreshInviteLinks}
                filters={params}
                exportFields={exportFields}
              />
            )}
          </header>
          <main>
            {dataInviteLink?.length === 0 &&
            Object.values(filters).some((value) => value === undefined) &&
            !loadingInviteLink ? (
              <NoInviteLinks />
            ) : (
              <TableColumnsControl
                firstColumnFixed={true}
                lastColumnFixed={true}
                column={serializedTable}
                toggleColumns={toggleColumns}
                loading={loadingInviteLink}
                actionButtons={tableActionButtons}
                selectLine={{
                  handleCheckboxSelected,
                  columnId: 'code',
                }}
              />
            )}
          </main>
          <footer>
            <Pagination
              total={pagination.total}
              currentPage={queryValues.currentPage}
              totalPerPage={pagination.totalPerPage}
              setPage={handlePagination}
            />
          </footer>
        </section>
      </SectionCollapsibleHalf>
      {openModal && modalData?.code && (
        <SendEmailProvider>
          <SendEmailModal
            setOpenModal={setOpenModal}
            code={modalData.code}
            invitelinkUrl={modalData?.invitelinkUrl}
            firstName={modalData?.firstName}
          />
        </SendEmailProvider>
      )}

      {messageIdPreview && <PreviewEmailModal setMessageIdPreview={setMessageIdPreview} messageId={messageIdPreview} />}
    </>
  );
}
