import { ApolloError, gql, useMutation } from '@apollo/client';

interface SendEmailByGenerateId {
  status: string;
  message: string;
}

interface InviteLinkSendEmailData {
  subject: string;
  greeting?: string;
  greeting_font_color?: string;
  greeting_font_size?: string;
  content?: string;
  content_font_color?: string;
  content_font_size?: string;
  enter_button_text?: string;
  enter_button_color?: string;
  enter_button_size?: string;
  enter_button_background_color?: string;
  signature?: string;
  signature_font_color?: string;
  signature_font_size?: string;
  footer_text?: string;
  footer_text_font_color?: string;
  footer_text_font_size?: string;
  invitelink_font_color?: string;
  invitelink_font_size?: string;
  background?: string;
}

const SEND_EMAIL_GENERATEID = gql`
  mutation sendEmailByGenerateId(
    $agencyId: ID!
    $accountId: ID!
    $generateId: ID!
    $data: InviteLinkSendEmailData
    $senderId: ID
    $sender: String
    $senderName: String
  ) {
    sendEmailByGenerateId(
      agencyId: $agencyId
      accountId: $accountId
      generateId: $generateId
      data: $data
      senderId: $senderId
      sender: $sender
      senderName: $senderName
    ) {
      status
      message
    }
  }
`;

export function useSendEmailByGenerateIdMutation(): {
  loading: boolean;
  sendEmailByGenerateId: (variables: {
    agencyId: number;
    accountId: number;
    generateId: number;
    data: InviteLinkSendEmailData;
    senderId: number;
    sender: string;
    senderName: string;
  }) => void;
  data: SendEmailByGenerateId | null;
} {
  const [sendEmailByGenerateIdMutation, { loading, data }] = useMutation<{
    sendEmailByGenerateId: SendEmailByGenerateId;
  }>(SEND_EMAIL_GENERATEID, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'SEND_EMAIL_GENERATEID',
        apolloError: error,
      });
    },
  });

  const sendEmailByGenerateId = (variables: {
    agencyId: number;
    accountId: number;
    generateId: number;
    data: InviteLinkSendEmailData;
    senderId: number;
    sender: string;
    senderName: string;
  }) => {
    sendEmailByGenerateIdMutation({ variables });
  };

  return {
    loading,
    sendEmailByGenerateId,
    data: data?.sendEmailByGenerateId || null,
  };
}
