import React from 'react';
import useAccount from '../../hooks/useAccount';
import AlertMessage from '../AlertMessage';
import styles from './styles.module.scss';

export function BannerNotifications(): JSX.Element {
  const { getMessages } = useAccount();
  return (
    <>
      {getMessages && (
        <div className={styles['banner-notifications']}>
          {getMessages?.map((message, index) => (
            <AlertMessage key={`message-list-${index}`} type={message.alertMessageType}>
              {message.text}
            </AlertMessage>
          ))}
        </div>
      )}
    </>
  );
}
