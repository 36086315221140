import { SavedFilterList } from './interface';

const SAVED_FILTER_LIST: SavedFilterList[] = [
  {
    id: 'marketing_view',
    name: 'Marketing View',
    options: [{ value: 'promo_id' }, { value: 'number_entries' }],
    enable: false,
    multi: true,
  },
  {
    id: 'marketing_view',
    name: 'Marketing View',
    options: [{ value: 'promo_id' }, { value: 'number_entries' }],
    enable: false,
    multi: true,
  },
];

export default SAVED_FILTER_LIST;
