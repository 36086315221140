export interface PromoteData {
  id: number;
  account_id: number;
  name: string;
  slug: string;
  start_date: string;
  end_date: string;
  visibility: PromotionVisibility;
  status: PromoteStatus;
  microsite_url: string;
  external_domain: string;
  is_locked: boolean;
  created_date: string;
  passcode: string;
  passcode_time: string;
  campaign_name: string;
  entry_count: number;
  current_time: string;
  domain_type: number;
  enable_custom_domain: boolean;
  custom_msg_flag: boolean;
  custom_msg_no_invite: string;
  custom_msg_invalid_invite: string;
  result_access_enable: boolean;
  result_access_limit: number;
  custom_msg_input_invite: string;
  custom_msg_reaccess: string;
  render: string;
  totalLive: number;
}

export enum PromoteStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  ENDED = 'ended',
  SCHEDULED = 'scheduled',
}

export enum PromotionVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export interface PromoteStartInput {
  account_id: number;
  promo_id: number;
  start_date?: string;
  visibility?: PromotionVisibility;
  captcha?: boolean;
  quantityReAccess?: number;
  inviteCodeMessage?: string;
  invalidMessage?: string;
  customMessageFlag?: boolean;
  customMsgNoInvite?: string;
  customMsgInvalidInvite?: string;
  resultAccessEnable?: boolean;
  resultAccessLimit?: number;
  method?: string;
  customMsgInputInvite?: string;
  customMsgReaccess?: string;
}

export interface PromoteEndInput {
  account_id: number;
  promo_id: number;
  end_date: string;
}

export interface PromotionCounter {
  draft: number;
  live: number;
  ended: number;
}

export interface DuplicatePromoteInput {
  accountId: number;
  promoId: number;
  title: string;
}

export enum NavbarLinkType {
  LEFT = 'left',
  RIGHT = 'right',
  MOBILE = 'mobile',
}

export interface NavbarLinks {
  title: string;
  link: string;
  icon: string;
  navbarLinkType: NavbarLinkType[];
  styleClasses?: NavbarLinksStyleClasses;
  target?: boolean;
}

interface NavbarLinksStyleClasses {
  left?: NavbarLinksStyleClassesSide;
  right?: NavbarLinksStyleClassesSide;
  mobile?: NavbarLinksStyleClassesSide;
}

interface NavbarLinksStyleClassesSide {
  li?: string;
  link?: string;
}

export interface RefreshPasscode {
  passcode: string;
  passcode_time: string;
  current_time: string;
}

export interface DomainTypesData {
  name: string;
  value: number;
  enable: boolean;
}

export interface CustomPromotionUrlVariables {
  accountId: number;
  promoId: number;
  customSlug: string;
  customDomain?: string;
  enableCustomDomain?: boolean;
  domainType?: number;
}

export interface MicrositeUrlValue {
  microsite_url: string;
  slug: string;
  external_domain?: string;
  domainType?: number;
  enable_custom_domain?: boolean;
}

export interface UpdateDomainMask {
  external_domain: string;
  id: number;
  microsite_url: string;
  slug: string;
  domain_type: number;
  enable_custom_domain: boolean;
}

export interface UpdateDomainMaskInput {
  accountId: number;
  promoId: number;
  customSlug: string;
  enableCustomDomain: boolean;
  customDomain: string;
}

export interface PromoteListData {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  visibility: PromotionVisibility;
  status: PromoteStatus;
  microsite_url: string;
  passcode: string;
  campaign_name: string;
  entry_count: number;
}
