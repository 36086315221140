/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import { ButtonProps } from './ButtonProps';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends ButtonProps {}

export default React.forwardRef<HTMLButtonElement, Props>(function Button(
  { children, size, color, className, spinner, disabled, ...props }: Props,
  ref,
) {
  const properties = {
    'btn-xs': size === 'xs',
    'btn-sm': size === 'sm',
    'btn-lg': size === 'lg',
  };

  const colors = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    success: 'btn-success',
    danger: 'btn-danger',
    warning: 'btn-warning',
    info: 'btn-info',
    light: 'btn-light',
    dark: 'btn-dark',
    link: 'btn-link',
  };

  return (
    <button
      {...props}
      ref={ref}
      className={classnames('btn', className, color ? colors[color] : undefined, properties)}
      disabled={disabled || spinner}
    >
      {spinner && <span className={classnames('spinner-border spinner-border-sm', { 'me-2': children })} />}
      {children}
    </button>
  );
});
