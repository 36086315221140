import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import InteractionUsage from './InteractionUsage';
import Plan from './Plan';
import StudioTimeUsage from './StudioTimeUsage';

export default function Account(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/interaction-usage`} component={InteractionUsage} />
      <Route exact path={`${path}/plan`} component={Plan} />
      <Route exact path={`${path}/studio-time-usage`} component={StudioTimeUsage} />
    </Switch>
  );
}
