import { ApolloError, gql, useMutation } from '@apollo/client';

const DELETE_WIDGET = gql`
  mutation deleteWidget($accountId: ID!, $widgetId: ID!) {
    deleteWidget(accountId: $accountId, widgetId: $widgetId) {
      id
      message
    }
  }
`;

interface DeleteWidget {
  id: number;
  message: string;
}

export function useMutationDeleteWidget(): {
  loading: boolean;
  requestDeleteWidget?: (accountId: number, widgetId: number) => void;
  data: DeleteWidget | null;
} {
  const [calculate, { loading, data, error }] = useMutation<{ deleteWidget: DeleteWidget }>(DELETE_WIDGET, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      console.log({
        gql: 'DELETE_WIDGET',
        apolloError: error,
      });
    },
  });

  const requestDeleteWidget = (accountId: number, widgetId: number) => {
    calculate({
      variables: {
        accountId,
        widgetId,
      },
    });
  };

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
    };
  }

  return {
    loading: false,
    data: data?.deleteWidget || null,
    requestDeleteWidget,
  };
}
