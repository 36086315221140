import { useHistory } from 'react-router-dom';
import { popoverUpgradeSettings } from '../../../ButtonPopoverUpgradePlan/popoverUpgradeSettings';
import { ActionProps } from '../../Inputs/Buttons/ButtonAction/interface';
import { PopoverExtendedProps } from '../../Popover/interface';

interface UseUpgradePlanPermissionSectionCollapsibleButton {
  showButton?: ActionProps;
  showButtonPopover?: PopoverExtendedProps;
}

export function useUpgradePlanPermissionSectionCollapsibleButton(
  permission: {
    hasPermission?: boolean;
    allowedPlans?: string[];
  },
  userRolePermission = true,
): UseUpgradePlanPermissionSectionCollapsibleButton {
  const history = useHistory();

  const userPermissionButton = (): ActionProps | undefined => {
    return userRolePermission ? undefined : { firstIcon: { children: 'lock_person' }, size: 'sm' };
  };

  const userPermissionPopover = (): PopoverExtendedProps | undefined =>
    userRolePermission
      ? undefined
      : {
          color: 'gray',
          children: "You don't have access to edit - Ask an account admin to change your permission",
          hiddenOverlay: true,
        };

  const showButton: ActionProps | undefined = permission.hasPermission
    ? userPermissionButton()
    : {
        children: permission.allowedPlans ? 'Upgrade Required' : undefined,
        firstIcon: { children: 'lock' },
        borderStyle: 'rainbow',
        size: 'sm',
      };

  const showButtonPopover: PopoverExtendedProps | undefined =
    !permission.allowedPlans || permission.hasPermission
      ? userPermissionPopover()
      : popoverUpgradeSettings({
          planNameAllowed: permission.allowedPlans,
          onUpgradeClick: () => history.push('/self-service'),
        });

  return { showButton, showButtonPopover };
}
