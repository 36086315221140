export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  require_2fa: boolean;
  last_password_updated_at: string;
}

export interface UpdateUserInput {
  firstName: string;
  lastName: string;
}

export interface ChangePasswordUserInput {
  password: string;
  passwordConfirmation: string;
}

export interface TwoFactorAutentication {
  status: string;
  secret_2fa: string;
  qrcode_2fa: string;
}

export enum UserRole {
  ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  ACCOUNT_EDITOR = 'ACCOUNT_EDITOR',
}
