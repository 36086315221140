import React, { useMemo, useState } from 'react';
import { OverlayProps, OverlayStyles } from './interface';
import styles from './styles.module.scss';

export default function Overlay({ spacing, color, position, backgroundBlur, callback }: OverlayProps): JSX.Element {
  const [style, setStyle] = useState<OverlayStyles>();
  const body = document.querySelector('body');
  const isThereOverlay = document.querySelectorAll('.selector-overlay');

  function handleClick() {
    if (callback) callback();
    if (body && isThereOverlay.length <= 1) body.style.overflowY = '';
  }

  useMemo(() => {
    if (window.innerWidth > 576 && body) {
      body.style.overflowY = 'hidden';
    }

    setStyle({
      inset: spacing,
      background: color,
      zIndex: position,
      backdropFilter: `blur(${backgroundBlur})`,
    });
  }, []);

  return <div className={styles['overlay'] + ' selector-overlay'} style={style} onClick={handleClick} />;
}
