import { graphql } from 'msw';

const inviteLinks = graphql.query('inviteLinks', (req, res, ctx) => {
  const { promo_id } = req.variables;

  const data =
    Number(promo_id) === 19701
      ? []
      : [
          {
            id: '2',
            agency_id: '1',
            account_id: '3227',
            code: 'I9TzmD6OQy',
            url: 'https://beeliked-ready-made-2-0.oooh.local/zoo-crocodile-spin-the-wheel2',
            promotion_id: 19345,
            created_at: new Date(),
          },
          {
            id: '1',
            agency_id: '1',
            account_id: '3227',
            code: '7AmXhHPF9T',
            url: 'https://beeliked-ready-made-2-0.oooh.local/zoo-crocodile-spin-the-wheel2',
            promotion_id: 19345,
            created_at: new Date(),
          },
        ];

  return res(
    ctx.delay(2000),
    ctx.data({
      inviteLinks: {
        data,
      },
    }),
  );
});

const createIndividuallyInvite = graphql.mutation('createIndividuallyInvite', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      createIndividuallyInvite: {
        id: '2',
        code: 'I9TzmD6OQy',
        url: 'https://beeliked-ready-made-2-0.oooh.local/zoo-crocodile-spin-the-wheel2',
        data: '{"first-name":"Bee", "last-name":"Liked"}',
      },
    }),
  );
});

const inviteLinksGenerator = graphql.query('inviteLinksGenerator', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      inviteLinksGenerator: {
        data: [
          {
            id: '6',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'quantity',
            url: 'https://teste.com',
            quantity: 3,
            generated_quantity: 0,
            expires_at: null,
            status: 3,
            created_at: '2021-09-14T11:14:19.000000Z',
          },
          {
            id: '7',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'quantity',
            url: 'https://teste.com',
            quantity: 3,
            generated_quantity: 0,
            expires_at: null,
            status: 0,
            created_at: '2021-09-14T12:49:52.000000Z',
          },
          {
            id: '8',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'quantity',
            url: 'https://teste.com',
            quantity: 3,
            generated_quantity: 0,
            expires_at: null,
            status: 0,
            created_at: '2021-09-14T13:03:48.000000Z',
          },
          {
            id: '9',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'quantity',
            url: 'https://teste.com',
            quantity: 3,
            generated_quantity: 0,
            expires_at: null,
            status: 0,
            created_at: '2021-09-14T13:27:10.000000Z',
          },
          {
            id: '12',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'file',
            url: 'https://test.com',
            quantity: 2,
            generated_quantity: 0,
            expires_at: null,
            status: 5,
            created_at: '2021-09-14T17:11:58.000000Z',
          },
          {
            id: '13',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'file',
            url: 'https://test.com',
            quantity: 2,
            generated_quantity: 0,
            expires_at: null,
            status: 5,
            created_at: '2021-09-14T17:12:28.000000Z',
          },
          {
            id: '14',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'file',
            url: 'https://test.com',
            quantity: 2,
            generated_quantity: 0,
            expires_at: null,
            status: 5,
            created_at: '2021-09-15T09:21:34.000000Z',
          },
          {
            id: '15',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'file',
            url: 'https://test.com',
            quantity: 2,
            generated_quantity: 0,
            expires_at: null,
            status: 5,
            created_at: '2021-09-15T09:29:20.000000Z',
          },
          {
            id: '16',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'file',
            url: 'https://test.com',
            quantity: 2,
            generated_quantity: 0,
            expires_at: null,
            status: 5,
            created_at: '2021-09-15T09:31:55.000000Z',
          },
          {
            id: '17',
            agency_id: '1',
            account_id: '3227',
            promotion_id: '19285',
            user_id: '2762',
            type: 'file',
            url: 'https://test.com',
            quantity: 2,
            generated_quantity: 0,
            expires_at: null,
            status: 5,
            created_at: '2021-09-15T09:33:58.000000Z',
          },
        ],
        paginatorInfo: {
          current_page: 1,
          first_page_url: '?promotion_id=19285&limit=10&page=1',
          from: 1,
          last_page: 3,
          last_page_url: '?promotion_id=19285&limit=10&page=3',
          next_page_url: '?promotion_id=19285&limit=10&page=2',
          path: '',
          per_page: '10',
          prev_page_url: null,
          to: 10,
          total: 24,
        },
      },
    }),
  );
});

const createInbulkQuantityInvite = graphql.mutation('createInbulkQuantityInvite', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      createInbulkQuantityInvite: {
        id: 100,
      },
    }),
  );
});

const createInbulkFileInvite = graphql.mutation('createInbulkFileInvite', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      createInbulkFileInvite: {
        id: 100,
        file_headers: ['First Name', 'Last Name', 'E-mail', 'Phone', 'Twitter', 'Facebook', 'Website', 'UID'],
      },
    }),
  );
});

const createInbulkFileMapper = graphql.mutation('createInbulkFileMapper', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      createInbulkFileMapper: {
        id: 100,
      },
    }),
  );
});

const inviteLinkDownloadFile = graphql.query('inviteLinkDownloadFile', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      inviteLinkDownloadFile: {
        temporary_url: 'https://github.com/wp-plugins/beeliked-microsite/archive/refs/heads/master.zip',
      },
    }),
  );
});

const inviteLinkGenerator = graphql.query('inviteLinkGenerator', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      inviteLinkGenerator: {
        id: '6',
        agency_id: '1',
        account_id: '3227',
        promotion_id: '19285',
        user_id: '2762',
        type: 'quantity',
        url: 'https://teste.com',
        quantity: 3,
        generated_quantity: 0,
        expires_at: null,
        status: 0,
        created_at: '2021-09-14T11:14:19.000000Z',
      },
    }),
  );
});

const deleteInviteLinkGenerator = graphql.mutation('deleteInviteLinkGenerator', (req, res, ctx) => {
  const { id } = req.variables;

  return res(
    ctx.delay(2000),
    ctx.data({
      deleteInviteLinkGenerator: {
        id,
        message: 'In-bulk invite successfully deleted.',
      },
    }),
  );
});

const expireInviteLink = graphql.mutation('expireInviteLink', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      expireInviteLink: {
        id: 1,
        expires_at: new Date(),
      },
    }),
  );
});

export default [
  inviteLinks,
  createIndividuallyInvite,
  inviteLinksGenerator,
  createInbulkQuantityInvite,
  createInbulkFileInvite,
  createInbulkFileMapper,
  inviteLinkDownloadFile,
  inviteLinkGenerator,
  deleteInviteLinkGenerator,
  expireInviteLink,
];
