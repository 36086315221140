import React from 'react';
import { useTranslation } from 'react-i18next';
import BlkdSpinner from '../../../assets/imgs/blkd-spinner.svg';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface Props {
  text?: string;
  noText?: boolean;
  className?: string;
}

export default function SpinnerBox({ text, noText, className }: Props): JSX.Element {
  const { t } = useTranslation('translation');

  return (
    <div className={classnames(styles['spinner-box'], className)}>
      <object type="image/svg+xml" data={BlkdSpinner} />
      {!noText && (text ?? t('Loading'))}
    </div>
  );
}
