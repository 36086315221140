import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SpinnerBox from '../../../components/_molecules/SpinnerBox';
import useAuth from '../../../hooks/useAuth';

export default function Logout(): JSX.Element {
  const { t } = useTranslation('forbidden');
  const { clearAuthorization } = useAuth();
  const history = useHistory();
  useEffect(() => {
    clearAuthorization();
    history.push('/signin');
  }, []);

  return <SpinnerBox className="mt-5" text={t('Logout')} />;
}
