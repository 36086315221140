import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../componentsV2/DesignSystem/Inputs/Input';
import useAccount from '../../../../hooks/useAccount';
import useToast from '../../../../hooks/useToast';
import { SendEmailTestProps } from './interface';
import useSendEmail from './SendEmailProvider';
import styles from './styles.module.scss';
import { useSendEmailTestMutation } from './useSendEmailTestMutation';

export default function SendEmailTest({ code }: SendEmailTestProps): JSX.Element {
  const { senderEmail } = useSendEmail();

  const { sendEmail, loading: loadingSendEmailTest, data: dataSendEmailTest } = useSendEmailTestMutation();

  const {
    register,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { getAccount } = useAccount();
  const { toast } = useToast();

  useEffect(() => {
    if (dataSendEmailTest) {
      toast('success', 'Test Email Sent Successfully!');
    }
  }, [dataSendEmailTest]);

  const [testEmailToValue, subjectValue] = watch(['testEmailTo', 'subject']);

  useEffect(() => {
    clearErrors(testEmailToValue);
  }, [testEmailToValue]);

  useEffect(() => {
    clearErrors(subjectValue);
  }, [subjectValue]);

  const sendEmailTest = async () => {
    if (!senderEmail) {
      toast('error', 'Provide the From Address.');
      return;
    }

    const testEmailTo = getValues('testEmailTo');
    const subject = getValues('subject');

    if (subject && testEmailTo) {
      const formattedContent = getValues('content') ? getValues('content').replace(/\n/g, '<br />') : '';
      const formattedSignature = getValues('signature') ? getValues('signature').replace(/\n/g, '<br />') : '';
      const formattedFooterText = getValues('footer_text') ? getValues('footer_text').replace(/\n/g, '<br />') : '';

      const greeting = getValues('greeting') || '';
      const greeting_font_color = getValues('greeting_font_color');
      const greeting_font_size = getValues('greeting_font_size');
      const content = formattedContent;
      const content_font_color = getValues('content_font_color');
      const content_font_size = getValues('content_font_size');
      const enter_button_text = getValues('enter_button_text') || '';
      const enter_button_color = getValues('enter_button_color');
      const enter_button_size = getValues('enter_button_size');
      const enter_button_background_color = getValues('enter_button_background_color');
      const signature = formattedSignature;
      const signature_font_color = getValues('signature_font_color');
      const signature_font_size = getValues('signature_font_size');
      const footer_text = formattedFooterText;
      const footer_text_font_color = getValues('footer_text_font_color');
      const footer_text_font_size = getValues('footer_text_font_size');
      const invitelink_font_color = getValues('invitelink_font_color');
      const invitelink_font_size = getValues('invitelink_font_size');
      const background = getValues('background');

      sendEmail({
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
        testEmailTo,
        code,
        data: {
          subject,
          greeting,
          greeting_font_color,
          greeting_font_size,
          content,
          content_font_color,
          content_font_size,
          enter_button_text,
          enter_button_color,
          enter_button_size,
          enter_button_background_color,
          signature,
          signature_font_color,
          signature_font_size,
          footer_text,
          footer_text_font_color,
          footer_text_font_size,
          invitelink_font_color,
          invitelink_font_size,
          background,
        },
        senderId: senderEmail.senderId,
        sender: senderEmail.sender,
        senderName: senderEmail.senderName,
      });
    } else {
      if (subject === '') {
        setError('subject', { type: 'required' });
      }

      if (testEmailTo === '') {
        setError('testEmailTo', { type: 'required' });
      } else if (!isValidEmail(testEmailTo)) {
        setError('testEmailTo', { type: 'validate', message: 'Invalid email address' });
      }
    }
  };

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className={styles['send-email-test']}>
      <div>
        <Input
          {...register('testEmailTo')}
          label="Send a test"
          placeholder="Enter email address"
          {...(errors.testEmailTo && {
            helperText: errors.testEmailTo.message,
            color: 'danger',
          })}
          inputSize="sm"
        />
      </div>
      <div>
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={sendEmailTest}
          loading={loadingSendEmailTest}
          disabled={loadingSendEmailTest || !testEmailToValue || testEmailToValue?.length === 0}
        >
          Send Email Test
        </Button>
      </div>
    </div>
  );
}
