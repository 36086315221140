import React from 'react';
import { toast as showToast, Toast, ToastOptions } from 'react-hot-toast';
import { Renderable, ValueOrFunction } from 'react-hot-toast/dist/core/types';
import classnames from 'classnames';

export type ToastType = 'success' | 'error' | 'warning';

interface UseToast {
  toast(
    method: ToastType,
    msg?: ValueOrFunction<Renderable, Toast>,
    options?: ToastOptions,
    icon?: string | null,
  ): void;
}

export default function useToast(): UseToast {
  const messages = {
    error(msg: ValueOrFunction<Renderable, Toast>) {
      return <div>{msg || 'Sorry, an error has occurred. Please try again.'}</div>;
    },
    success(msg: ValueOrFunction<Renderable, Toast>) {
      return <div>{msg || 'Record Successfully Saved.'}</div>;
    },
    warning(msg: ValueOrFunction<Renderable, Toast>) {
      return <div>{msg || 'Attention Please.'}</div>;
    },
  };

  const toast = (method: ToastType, msg?: ValueOrFunction<Renderable, Toast>, options?: ToastOptions) => {
    const methodFn = showToast['custom'];

    return methodFn(
      (t: Toast) => (
        <div
          className={classnames('toast show', method, {
            'animate-enter': t.visible,
            'animate-leave': !t.visible,
          })}
        >
          <div className="d-flex">
            <div className="toast-body d-flex">{messages[method](msg ?? null)}</div>
            <button type="button" className="btn-close" onClick={() => showToast.dismiss(t.id)} />
          </div>
        </div>
      ),
      {
        duration: 4000,
        ...options,
      },
    );
  };
  return { toast };
}
