import ImgNothing from '../../assets/imgs/nothing.svg';
import styles from './styles.module.scss';

export default function Empty(): JSX.Element {
  return (
    <div className={styles['empty-page']}>
      <div>
        <img src={ImgNothing} />
        <h2>No search results found.</h2>
      </div>
    </div>
  );
}
