import { createContext, useContext } from 'react';
import { PlanPermissions, PlanPermissionsPermission } from '../hooks/useFetchPlanPermissions';

interface PlanPermissionsContextType {
  permissions: PlanPermissions[];
  hasPermission: (parameter: string) => PlanPermissionsPermission | undefined;
  refetchPlanPermission?: (variables?: { accountId: number }) => void;
  planPermissionByParameter: (parameter: string) => {
    hasPermission?: boolean;
    allowedPlans?: string[];
  };
}

interface Props {
  children: JSX.Element | JSX.Element[];
  permissions: PlanPermissions[];
  refetchPlanPermission?: (variables?: { accountId: number }) => void;
}

const PlanPermissionsContext = createContext<PlanPermissionsContextType | null>(null);

export function PlanPermissionsProvider({ children, permissions, refetchPlanPermission }: Props): JSX.Element {
  const hasPermission = (parameter: string) => {
    const permission = permissions.find((permission) => permission.parameter === parameter);
    return permission?.permission;
  };

  const planPermissionByParameter = (parameter: string) => {
    const permission = hasPermission(parameter);
    return {
      hasPermission: permission?.hasPermission,
      allowedPlans: permission?.allowedPlans,
    };
  };

  return (
    <PlanPermissionsContext.Provider
      value={{ permissions, hasPermission, refetchPlanPermission, planPermissionByParameter }}
    >
      {children}
    </PlanPermissionsContext.Provider>
  );
}

export function usePlanPermissions(): PlanPermissionsContextType {
  const context = useContext(PlanPermissionsContext) as PlanPermissionsContextType;

  if (context === null) {
    throw new Error('usePlanPermissions must be used within PlanPermissionsProvider');
  }

  return context;
}
