import { createArray } from '../../../../../../../utils/createArray';

import styles from './styles.module.scss';

export default function IconPluginBg(): JSX.Element {
  return (
    <div className={styles['html_container']}>
      <div className={styles['flex-space-between']}>
        <div className={styles['flex-gap']}>
          <div className={styles['circle']} />
          <div className={styles['rectangle']} />
        </div>
        <div className={styles['flex-column-space-between']}>
          {createArray(2).map((elem) => {
            return <div key={elem} className={styles['long-rectangle']} />;
          })}
        </div>
      </div>
      <div className={styles['flex-center-gap-small']}>
        {createArray(3).map((elem) => {
          return <div key={elem} className={styles['large-rectangle']} />;
        })}
      </div>
    </div>
  );
}
