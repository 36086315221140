import React, { useState } from 'react';
import { Button } from '../ButtonAction';
import { ButtonConfirmProps } from './interface';
import styles from './styles.module.scss';

export default function ButtonConfirm({
  actionButton,
  leftButton,
  rightButton,
  advice,
}: ButtonConfirmProps): JSX.Element {
  const [showConfirmWrapper, setShowConfirmWrapper] = useState<boolean>(false);
  return (
    <>
      <Button {...actionButton} onClick={() => setShowConfirmWrapper((prev) => !prev)}>
        {actionButton.children}
      </Button>
      <div
        className={[
          styles['wrapper-advice-message'],
          styles[showConfirmWrapper ? 'expanded' : ''],
          styles[advice.color ?? ''],
          styles[actionButton.size ?? ''],
        ].join(' ')}
        style={{ borderRadius: advice.rounded }}
      >
        <span>{advice.text}</span>
        <footer>
          <Button {...leftButton} onClick={() => leftButton.onClick ?? setShowConfirmWrapper((prev) => !prev)}>
            {leftButton.children}
          </Button>
          <Button {...rightButton}>{rightButton.children}</Button>
        </footer>
      </div>
    </>
  );
}
