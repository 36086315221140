import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../../components/Pagination/paginator.gql';

export const LIST_COLLECTIONS = gql`
  query collections($agency_id: ID!, $account_id: ID!, $page: Int, $limit: Int) {
    collections(agencyId: $agency_id, accountId: $account_id, page: $page, limit: $limit) {
      data {
        id
        slug
        name
        description
        test_coupon_code
        total_coupons
        coupon_used
        coupon_left
        collectionImportedFiles {
          id
          file
          total_rows
          imported_rows
          failed_message
          status
        }
        average_used_per_day
        coupons_used_by
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const CREATE_COLLECTION = gql`
  mutation createCollection($agencyId: ID!, $accountId: ID!, $name: String!, $description: String) {
    createCollection(agencyId: $agencyId, accountId: $accountId, name: $name, description: $description) {
      id
    }
  }
`;

export const UPDATE_COLLECTION = gql`
  mutation updateCollection(
    $agencyId: ID!
    $accountId: ID!
    $collectionId: ID!
    $name: String
    $description: String
    $testCouponCode: String
  ) {
    updateCollection(
      agencyId: $agencyId
      accountId: $accountId
      collectionId: $collectionId
      name: $name
      description: $description
      testCouponCode: $testCouponCode
    ) {
      id
      description
      testCouponCode: test_coupon_code
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($agencyId: ID!, $accountId: ID!, $collectionId: ID!) {
    deleteCollection(agencyId: $agencyId, accountId: $accountId, collectionId: $collectionId) {
      id
      message
    }
  }
`;

export const CREATE_COUPON_BULK = gql`
  mutation createCouponBulk($agencyId: ID!, $accountId: ID!, $collectionId: ID!, $code: [String!]!) {
    createCouponBulk(agencyId: $agencyId, accountId: $accountId, collectionId: $collectionId, code: $code) {
      id
      code
    }
  }
`;

export const LIST_COUPONS = gql`
  query coupons(
    $agency_id: ID!
    $account_id: ID!
    $collectionId: ID!
    $page: Int
    $limit: Int
    $sortBy: String
    $orderBy: OrderBy
    $decrypt: Int
  ) {
    coupons(
      agencyId: $agency_id
      accountId: $account_id
      collectionId: $collectionId
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      decrypt: $decrypt
    ) {
      data {
        id
        code
        used_by_id
        winner_name
        winner_email
        collection_id
        coupon_used_at
        coupon_used
        invite_code
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($agencyId: ID!, $accountId: ID!, $collectionId: ID!, $couponId: ID!) {
    deleteCoupon(agencyId: $agencyId, accountId: $accountId, collectionId: $collectionId, couponId: $couponId) {
      id
      message
    }
  }
`;

export const COUPONS_IDS_ALL = gql`
  query couponsIdsAll($agency_id: ID!, $account_id: ID!, $collectionId: ID!, $filters: CouponsIdsAllFilter) {
    couponsIdsAll(agencyId: $agency_id, accountId: $account_id, collectionId: $collectionId, filters: $filters) {
      ids
    }
  }
`;

export const DELETE_COUPON_BULK = gql`
  mutation deleteCouponBulk($agencyId: ID!, $accountId: ID!, $collectionId: ID!, $couponIds: [Int!]!) {
    deleteCouponBulk(agencyId: $agencyId, accountId: $accountId, collectionId: $collectionId, couponIds: $couponIds) {
      message
    }
  }
`;

export const UPDATE_COUPON_ACTIVE_BULK = gql`
  mutation updateCouponActiveBulk($agencyId: ID!, $accountId: ID!, $collectionId: ID!, $couponIds: [Int!]!) {
    updateCouponActiveBulk(
      agencyId: $agencyId
      accountId: $accountId
      collectionId: $collectionId
      couponIds: $couponIds
    ) {
      code
    }
  }
`;

export const CREATE_COUPONS_BY_FILE = gql`
  mutation createCouponsByFile($agencyId: ID!, $accountId: ID!, $collectionId: ID!, $file: Upload!) {
    createCouponsByFile(agencyId: $agencyId, accountId: $accountId, collectionId: $collectionId, file: $file) {
      id
    }
  }
`;

export const COLLECTION_EXPORTS_COUPONS = gql`
  mutation collectionExportsCoupons(
    $agencyId: ID!
    $accountId: ID!
    $collectionId: ID!
    $usedCoupon: Boolean
    $couponIds: [ID!]
  ) {
    collectionExportsCoupons(
      agencyId: $agencyId
      accountId: $accountId
      collectionId: $collectionId
      usedCoupon: $usedCoupon
      couponIds: $couponIds
    ) {
      message
    }
  }
`;

export const COLLECTION_SET_READ = gql`
  mutation collectionSetRead($agencyId: ID!, $accountId: ID!, $collectionId: ID!, $fileImporterId: [ID!]!) {
    collectionSetRead(
      agencyId: $agencyId
      accountId: $accountId
      collectionId: $collectionId
      fileImporterId: $fileImporterId
    ) {
      id
    }
  }
`;

export const COLLECTION_LIST_IMPORT_DOWNLOAD = gql`
  query collectionListImportDownload($agencyId: ID!, $accountId: ID!, $collectionId: ID!, $fileImporterId: ID!) {
    collectionListImportDownload(
      agencyId: $agencyId
      accountId: $accountId
      collectionId: $collectionId
      fileImporterId: $fileImporterId
    ) {
      temporary_url
    }
  }
`;

export const LIST_COUPONS_CACHE = gql`
  query coupons($collectionId: ID!) {
    coupons(collectionId: $collectionId) {
      data {
        id
      }
    }
  }
`;

export const LIST_COUPON_SEARCH = gql`
  query couponSearch(
    $agency_id: ID!
    $account_id: ID!
    $sortBy: String
    $orderBy: OrderBy
    $filters: CouponSearchFilter
    $page: Int
    $limit: Int
  ) {
    couponSearch(
      agencyId: $agency_id
      accountId: $account_id
      sortBy: $sortBy
      orderBy: $orderBy
      filters: $filters
      page: $page
      limit: $limit
    ) {
      data {
        id
        code
        used_by_id
        winner_name
        winner_email
        collection_id
        coupon_used_at
        invite_code
        collection {
          name
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const LIST_COUPON_SEARCH_CACHE = gql`
  query couponSearch($filters: CouponSearchFilter) {
    couponSearch(filters: $filters) {
      data {
        id
      }
    }
  }
`;
