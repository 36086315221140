import { ApolloError, useQuery } from '@apollo/client';
import { PaginationInput } from '../../../../ts';
import { ENTRIES_FIELDS } from './entries.gql';
import { TableHeaderElement } from '../../../../componentsV2/TableColumnsControl/interface';

export interface EntriesFilters {
  order?: string;
  sort?: string;
  promo_id?: string;
  search?: string;
}

export interface EntriesVariables {
  accountId: number;
  pagination?: PaginationInput;
}

interface EntriesResult {
  loading: boolean;
  data: TableHeaderElement[];
  fetchFields: (variables: EntriesVariables) => void;
}

export function useFetchEntriesFields(accountId: number): EntriesResult {
  const { data, loading, refetch } = useQuery<{
    entriesFields: { data: TableHeaderElement[] };
  }>(ENTRIES_FIELDS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const fetchFields = (variables: EntriesVariables) => {
    refetch(variables);
  };

  if (loading) {
    return {
      loading: true,
      data: [],
      fetchFields,
    };
  }

  return {
    loading: false,
    data: data?.entriesFields?.data || [],
    fetchFields,
  };
}
