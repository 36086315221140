import { useMemo } from 'react';
import PaginationItem from './PaginationItem';
import { PaginationProps } from './interface';
import styles from './styles.module.scss';

export default function Pagination({ total, totalPerPage, currentPage, setPage }: PaginationProps): JSX.Element {
  const totalPages = Math.ceil(total / totalPerPage);

  const pages = useMemo(() => {
    if (totalPages < 7) {
      return [...new Array(totalPages)].map((_, i) => i + 1);
    } else if (currentPage < 4) {
      return [1, 2, 3, 4, 5, '...', totalPages];
    } else if (currentPage > totalPages - 4) {
      return [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    } else {
      return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    }
  }, [totalPages, currentPage]);

  const handlePage = (page: number) => {
    setPage(page);
  };

  return (
    <div className={styles['buttons-wrapper']}>
      {pages.map((page, index) => (
        <PaginationItem key={`page-${index}`} page={page} currentPage={currentPage} handlePage={handlePage} />
      ))}
    </div>
  );
}
