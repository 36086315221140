import { gql } from '@apollo/client';

export const GENERATE_CODE_GQL = gql`
  mutation generateCode($email: String!, $reCaptcha: String!) {
    generateCode(email: $email, reCaptcha: $reCaptcha) {
      status
      message
    }
  }
`;
export const VERIFY_CODE_GQL = gql`
  mutation verifyCode($email: String!, $code: String!) {
    verifyCode(email: $email, code: $code) {
      status
      message
    }
  }
`;

export const GET_TERMS_GQL = gql`
  query agreement {
    agreement {
      status
      id
      title
      content
    }
  }
`;
export const CREATE_USER_GQL = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $accountName: String
    $password: String!
    $passwordConfirmation: String!
    $agreementId: String!
    $inviteId: String
    $confirmCode: String
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      accountName: $accountName
      password: $password
      passwordConfirmation: $passwordConfirmation
      agreementId: $agreementId
      inviteId: $inviteId
      confirmCode: $confirmCode
    ) {
      status
      message
      authorization {
        access_token
        expires_in
        refresh_expires_in
        refresh_token
        scope
        session_state
        token_type
        status
      }
    }
  }
`;
export const VERIFY_INVITE_GQL = gql`
  mutation verifyInvite($code: String!, $inviteId: String!, $accountId: String!, $agencyId: String!) {
    verifyInvite(code: $code, inviteId: $inviteId, accountId: $accountId, agencyId: $agencyId) {
      email
      firstName
      lastName
      inviteCode
      accountName
    }
  }
`;
