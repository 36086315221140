import { ApolloError, gql, useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';

interface SetSwitchAccount {
  id: number;
  slug: string;
}

const SET_SWITCH_ACCOUNT = gql`
  mutation setSwitchAccount($agencyId: ID!, $accountId: ID!) {
    setSwitchAccount(agencyId: $agencyId, accountId: $accountId) {
      id
      slug
    }
  }
`;

export function useSetSwitchAccountMutation(): {
  loading: boolean;
  setSwitchAccount: (agencyId: number, accountId: number) => void;
  data: SetSwitchAccount | null;
  error?: string;
} {
  const [customError, setCustomError] = useState<string>();
  const [setSwitchAccountMutation, { loading, data, error }] = useMutation<{ setSwitchAccount: SetSwitchAccount }>(
    SET_SWITCH_ACCOUNT,
    {
      onError: (error: ApolloError) => {
        const error400 = error.networkError as unknown as Record<string, string>;

        if (parseInt(error400?.statusCode, 10) === 400) {
          setCustomError('You do not have permission to access this page.');
        }
        console.log({
          gql: 'SET_SWITCH_ACCOUNT',
          apolloError: error,
        });
      },
    },
  );

  const setSwitchAccount = (agencyId: number, accountId: number) => {
    setCustomError(undefined);
    setSwitchAccountMutation({ variables: { agencyId, accountId } });
  };

  const returnError = useMemo(() => {
    return customError ? customError : error?.message;
  }, [error, customError]);

  if (returnError) {
    return {
      error: returnError,
      loading: false,
      setSwitchAccount,
      data: null,
    };
  }

  return {
    loading,
    setSwitchAccount,
    data: data?.setSwitchAccount || null,
  };
}
