import Cookies, { CookieAttributes } from 'js-cookie';
import { REACT_APP_COOKIE_DOMAIN, REACT_APP_COOKIE_PREFIX } from '../config/Env';
import { AuthenticationData } from '../ts';

export const setAuthCookie = (data: AuthenticationData): void => {
  const cookieAttributes: CookieAttributes = {
    domain: REACT_APP_COOKIE_DOMAIN,
    secure: true,
    sameSite: 'None',
    expires: 1 / 24 / 60 / 60,
  };

  const currentTime = Date.now();

  const expiresIn = data.expires_in || 1800;
  const refreshExpiresIn = 3600;

  const accessTokenExpiration = new Date(currentTime + expiresIn * 1000);
  const refreshTokenExpiration = new Date(currentTime + refreshExpiresIn * 1000);

  Cookies.set(`${REACT_APP_COOKIE_PREFIX}usr_access_token`, data.access_token, {
    ...cookieAttributes,
    expires: accessTokenExpiration,
  });

  Cookies.set(`${REACT_APP_COOKIE_PREFIX}usr_refresh_token`, data.refresh_token, {
    ...cookieAttributes,
    expires: refreshTokenExpiration,
  });
};

export const removeAuthCookie = (): void => {
  const domain = REACT_APP_COOKIE_DOMAIN;

  Cookies.remove(`${REACT_APP_COOKIE_PREFIX}usr_access_token`, { domain });
  Cookies.remove(`${REACT_APP_COOKIE_PREFIX}usr_refresh_token`, { domain });
};

export const getAuthCookie = (): string | undefined => {
  return Cookies.get(`${REACT_APP_COOKIE_PREFIX}usr_access_token`);
};

export const getRefreshAuthCookie = (): string | undefined => {
  return Cookies.get(`${REACT_APP_COOKIE_PREFIX}usr_refresh_token`);
};
