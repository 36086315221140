import { ApolloError, gql, useMutation } from '@apollo/client';
import { InviteData } from '../../../../ts';

export const EXPIRATION_DATE_INVITE_LINK = gql`
  mutation expireInviteLink($agencyId: ID!, $accountId: ID!, $code: String!) {
    expireInviteLink(agencyId: $agencyId, accountId: $accountId, code: $code) {
      id
      expires_at
    }
  }
`;

export function useExpireInviteLinkMutation(): {
  loading: boolean;
  requestExpirationDate: (variables: { agencyId: number; accountId: number; code: string }) => void;
  data: InviteData | null;
} {
  const [setExpirationDate, { data, loading }] = useMutation<{
    expireInviteLink: InviteData;
  }>(EXPIRATION_DATE_INVITE_LINK, {
    onError: (error: ApolloError) => {
      console.log('[Error]: EXPIRATION_DATE_INVITE_LINK', error.message);
    },
  });

  const requestExpirationDate = (variables: { agencyId: number; accountId: number; code: string }) => {
    setExpirationDate({ variables });
  };

  return {
    loading,
    requestExpirationDate,
    data: data?.expireInviteLink || null,
  };
}
