export const REACT_APP_LINK_DEVELOPER_DOCUMENTATION = 'https://developer.beeliked.com/';
export const REACT_APP_LINK_PRICING = 'https://www.beeliked.com/pricing';
export const REACT_APP_LINK_PUBLIC_PRIVATE_PROMOTIONS =
  'https://support.beeliked.com/knowledge/public-vs-private-promotions';
export const REACT_APP_LINK_CONTACT_SALES = 'https://www.beeliked.com/contact-sales';
export const REACT_APP_LINK_CUSTOMIZE_YOUR_CAMPAIGN =
  'https://help.beeliked.com/en/articles/3311450-customize-your-campaign-s-data-entry-form';
export const REACT_APP_LINK_PRIVACY_POLICY = 'https://www.beeliked.com/privacy-policy';
export const REACT_APP_LINK_COUPON_COLLECTIONS =
  'https://support.beeliked.com/knowledge/add-coupons-to-a-coupon-collection';
export const REACT_APP_LINK_TWO_FACTORY_AUTHENTICATION =
  'https://support.beeliked.com/knowledge/two-factor-authentication';
export const TERMS_AND_POLICIES = 'https://www.beeliked.com/terms-and-conditions';
