import React, { createContext, useContext, useEffect, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { getZapierAccessToken, removeZapierAccessToken, setZapierAccessToken } from '../../../utils/zapierStorage';
import { CREDENTIAL_DELETE_GQL, CREDENTIAL_FIND_BY_SERVICE_GQL } from './graphql';
import useAccount from '../../../hooks/useAccount';
import { CredentialServicesName } from '../../../ts';
import { Breadcrumb } from '../../../components/TitlePages/interface';

interface DeleteCredential {
  agencyId: number;
  accountId: number;
}

interface DeleteCredentialVariables {
  variables: DeleteCredential;
}

interface AppSlugVariables {
  name: string;
  slug: string;
}

interface ZapierContext {
  accessToken: string;
  setZapierToken(accessToken: string): void;
  PATH_ZAPIER: string;
  PATH_COMES_FROM?: Breadcrumb;
  loadingCredentialFindByService: boolean;
  appSlug: AppSlugVariables;
  setAppSlug: (appSlug?: AppSlugVariables) => void;
  deleteCredential: (variables: DeleteCredentialVariables) => void;
  loadingDeleteCredential: boolean;
  promo_id?: string;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  promo_id?: string;
}

const ZapierContext = createContext({});

export function ZapierProvider({ children, promo_id }: Props): JSX.Element {
  const { getAccount } = useAccount();
  const [accessToken, setAccessToken] = useState<string | null>(getZapierAccessToken());
  const [appSlug, setAppSlug] = useState<AppSlugVariables | undefined>();
  const { url } = useRouteMatch();

  const PATH_ZAPIER = url;
  const PATH_COMES_FROM =
    url.indexOf('/developer') > -1
      ? {
          path: '/developer',
          title: 'Developer',
        }
      : undefined;

  const [credentialFindByService, { loading: loadingCredentialFindByService }] = useMutation(
    CREDENTIAL_FIND_BY_SERVICE_GQL,
    {
      onError: (error: ApolloError) => {
        console.log('[Error]: CREDENTIAL_FIND_BY_SERVICE_GQL', error);
      },
      onCompleted: (response) => {
        if (response.credentialFindByService) {
          setZapierAccessToken(response.credentialFindByService.service_secret);
          setAccessToken(response.credentialFindByService.service_secret);
        }
      },
    },
  );

  const [deleteCredential, { loading: loadingDeleteCredential }] = useMutation(CREDENTIAL_DELETE_GQL, {
    onError: (error: ApolloError) => {
      console.log('[Error]: CREDENTIAL_DELETE_GQL', error);
    },
    onCompleted: () => {
      removeZapierAccessToken();
      setAccessToken(null);
    },
  });

  const setZapierToken = (token: string) => {
    setZapierAccessToken(token);
    setAccessToken(token);
  };

  useEffect(() => {
    if (accessToken === null) {
      credentialFindByService({
        variables: {
          agencyId: getAccount.agencyId,
          accountId: getAccount.accountId,
          serviceName: CredentialServicesName.ZAPIER,
          serviceId: `agency_${getAccount.agencyId}_zapier`,
        },
      });
    }
  }, []);

  return (
    <ZapierContext.Provider
      value={{
        accessToken,
        setZapierToken,
        PATH_ZAPIER,
        PATH_COMES_FROM,
        loadingCredentialFindByService,
        appSlug,
        setAppSlug,
        deleteCredential,
        loadingDeleteCredential,
        promo_id,
      }}
    >
      {children}
    </ZapierContext.Provider>
  );
}

export default function useZapierContext(): ZapierContext {
  const context = useContext(ZapierContext) as ZapierContext;
  return context;
}
