import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import useShareLinks from '../../ShareLinksProvider';
import Inbulk from './Inbulk';
import ListSingleInviteLinks from './ListSingleInviteLinks';
import styles from './styles.module.scss';

export default function InviteLinks(): JSX.Element {
  const { getSectionValue, inviteTypeListMethod } = useShareLinks();

  const inviteLinks = getSectionValue('inviteLinks');

  return (
    <>
      {inviteLinks.badge.status !== StatusBadge.disabled && (
        <SectionCollapsibleFull
          badge={inviteLinks.badge}
          expanded={inviteLinks.expanded}
          header={{ title: 'InviteLinks' }}
          containerClassname={styles['container-section']}
        >
          {inviteTypeListMethod === 'inbulk' ? <Inbulk /> : <ListSingleInviteLinks />}
        </SectionCollapsibleFull>
      )}
    </>
  );
}
