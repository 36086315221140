import React from 'react';
import NewsItem from './NewsItem';
import { NewsProps } from './interface';
import ErrorRefetch from '../../../../../../componentsV2/ErrorRefetch';

export default function NewsList({ news, error, refetchNews }: NewsProps): JSX.Element {
  if (error) {
    return <ErrorRefetch callback={refetchNews} />;
  }

  return (
    <>
      {news.map((item) => (
        <NewsItem item={item} key={`card-${item.id}`} />
      ))}
    </>
  );
}
