import Input from '../DesignSystem/Inputs/Input';
import { TwoFAInputProps } from './interface';
import styles from './styles.module.scss';

export default function TwoFAInput({ register, trigger, setValue, setFocus, button }: TwoFAInputProps): JSX.Element {
  function handleFocusInput(element: string) {
    setTimeout(() => {
      switch (element) {
        case 'first':
          setValue('second', '');
          setFocus('second');
          break;
        case 'second':
          setValue('third', '');
          setFocus('third');
          break;
        case 'third':
          setValue('fourth', '');
          setFocus('fourth');
          break;
        case 'fourth':
          setValue('fifth', '');
          setFocus('fifth');
          break;
        case 'fifth':
          setValue('sixth', '');
          setFocus('sixth');
          break;
        case 'sixth':
          button?.focus();
          break;
      }
    }, 50);
  }

  const handlePaste = async () => {
    const code = await navigator.clipboard.readText();
    code?.split('').map((code: string, index: number) => {
      switch (index) {
        case 0:
          setValue('first', code);
          break;
        case 1:
          setValue('second', code);
          break;
        case 2:
          setValue('third', code);
          break;
        case 3:
          setValue('fourth', code);
          break;
        case 4:
          setValue('fifth', code);
          break;
        case 5:
          setValue('sixth', code);
          break;
      }
    });
    trigger?.(['first', 'second', 'third', 'fourth', 'fifth', 'sixth']);
    setTimeout(() => button?.click(), 150);
  };
  return (
    <div className={styles['wrapper-verify-code']}>
      <Input
        {...register('first')}
        type="text"
        placeholder="0"
        maxLength={1}
        onPaste={handlePaste}
        onKeyPress={() => handleFocusInput('first')}
      />
      <Input
        {...register('second')}
        type="text"
        placeholder="0"
        maxLength={1}
        onKeyPress={() => handleFocusInput('second')}
      />
      <Input
        {...register('third')}
        type="text"
        placeholder="0"
        maxLength={1}
        onKeyPress={() => handleFocusInput('third')}
      />
      <Input
        {...register('fourth')}
        type="text"
        placeholder="0"
        maxLength={1}
        onKeyPress={() => handleFocusInput('fourth')}
      />
      <Input
        {...register('fifth')}
        type="text"
        placeholder="0"
        maxLength={1}
        onKeyPress={() => handleFocusInput('fifth')}
      />
      <Input
        {...register('sixth')}
        type="text"
        placeholder="0"
        maxLength={1}
        onKeyPress={() => handleFocusInput('sixth')}
      />
    </div>
  );
}
