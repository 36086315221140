import React from 'react';

import { useTranslation } from 'react-i18next';
import useAccount from '../../../../hooks/useAccount';
import ImgError from '../../../../assets/imgs/img-erro1.svg';
import Button from '../../../../components/_atoms/Button';
import { ZapierZapError } from '../../../../ts';
import useZapierContext from '../ZapierContextProvider';

interface Props {
  refetch: () => void;
  errorData?: ZapierZapError;
}

export default function ErrorZaps({ refetch, errorData }: Props): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'my-zaps' });
  const { getAccount } = useAccount();
  const { deleteCredential, loadingDeleteCredential } = useZapierContext();

  const logoutZapier = () => {
    deleteCredential({
      variables: {
        agencyId: getAccount.agencyId,
        accountId: getAccount.accountId,
      },
    });
  };

  return (
    <div>
      <div>
        <img src={ImgError} />
        {!!errorData && (
          <>
            <p>{errorData?.message}</p>
            {errorData?.status === 403 ? (
              <Button color="secondary" onClick={logoutZapier} spinner={loadingDeleteCredential}>
                {t('Re-authenticate')}
              </Button>
            ) : (
              <Button color="secondary" onClick={() => refetch()}>
                {t('Try again')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
