import { OrderBy } from './global';

export interface CollectionData {
  id: number;
  slug: string;
  name: string;
  description: string;
  test_coupon_code: string;
  total_coupons: number;
  coupon_used: number;
  coupon_left: number;
  collectionImportedFiles?: CollectionFilesImported[];
  average_used_per_day: number;
  coupons_used_by: string;
}

export interface CollectionInput {
  agencyId: number;
  accountId: number;
  collectionId: number;
  name: string;
  description?: string;
  testCouponCode?: string;
}

export interface CouponInput {
  agencyId: number;
  accountId: number;
  collectionId: number;
  code: string[];
  codes?: CouponId[];
}

export interface CouponId {
  id: string;
}

interface CollectionName {
  name: string;
}

export interface CouponData {
  id: number;
  code: string;
  used_by_id: string | null;
  winner_name: string | null;
  winner_email: string | null;
  collection_id: number;
  coupon_used_at: string | null;
  coupon_used: string | null;
  invite_code: string | null;
  collection: CollectionName;
}

export enum CouponStatusUsed {
  ALL = 'All',
  USED = 'Used',
  UNUSED = 'Unused',
}

export interface CouponActiveInput {
  agencyId: number;
  accountId: number;
  collectionId: number;
  couponIds: number[];
}

export interface CouponFormError {
  id: number;
  name: string;
  codes: string[];
}

export enum FilesImportedStatus {
  STATUS_FAILED = -1,
  STATUS_CREATED = 0,
  STATUS_PROCESSING = 1,
  STATUS_FINISHED = 2,
  STATUS_EXPIRED = 3,
}

export interface CollectionFilesImported {
  id: number;
  imported_rows: number;
  total_rows: number;
  failed_message: string;
  status: FilesImportedStatus;
  file: string;
}

export interface CollectionListImportDownload {
  temporary_url: string;
}

export interface CouponListInput {
  agency_id: number;
  account_id: number;
  collectionId: number;
  page?: number;
  limit?: number;
  sortBy?: string;
  orderBy?: OrderBy;
  decrypt?: number;
}

export interface CouponSearchInput {
  agency_id: number;
  account_id: number;
  sortBy?: string;
  orderBy?: string;
  filters?: CouponSearchFilterInput;
}

export interface CouponSearchFilterInput {
  code?: string;
  winner_name?: string;
  winner_email?: string;
}
