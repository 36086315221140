import React from 'react';
import { useLocation } from 'react-router-dom';
import SpinnerBox from '../../../components/_molecules/SpinnerBox';
import { REACT_APP_HOST_DEVELOPMENT, REACT_APP_HOST_LOCAL } from '../../../config/Env';
import useQueryString from '../../../hooks/useQueryString';
import { AppEnvironment } from '../../../ts';
import redirectTo from '../../../utils/redirectTo';
import queryString from '../../../utils/queryString';

interface AuthorizationParameters {
  env?: AppEnvironment;
}

export default function Zapier(): JSX.Element {
  const { hash } = useLocation();

  const query = useQueryString();
  const p = query.get('p') ?? '';

  let data: AuthorizationParameters = {};

  if (p !== '') {
    const parameters = window.atob(p);
    data = JSON.parse(parameters);
  }

  const hashString = hash.slice(1);
  const hashParameters = queryString.parse(hashString);

  const path = `/zapier/authorizing?access_token=${hashParameters?.access_token}&p=${p}`;

  const url = {
    [AppEnvironment.LOCAL]: REACT_APP_HOST_LOCAL,
    [AppEnvironment.DEVELOPMENT]: REACT_APP_HOST_DEVELOPMENT,
    [AppEnvironment.PRODUCTION]: '',
  };

  redirectTo(`${url[data.env ?? AppEnvironment.PRODUCTION]}${path}`);

  return (
    <div className="content-box">
      <SpinnerBox text="Authorizing..." />
    </div>
  );
}
