import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../../../componentsV2/Pagination';
import useAuth from '../../../../../hooks/useAuth';
import useQueryString from '../../../../../hooks/useQueryString';
import scrollToElement from '../../../../../utils/scrollToElement';
import styleModuleClasses from '../../../../../utils/styleModuleClasses';
import AccountItem from '../AccountItem';
import AccountsEmpty from '../AccountsEmpty';
import Filters from '../Filters';
import SkeletonLoading from '../SkeletonLoading';
import useSwitchAccount, { TypeOfList } from '../SwitchAccountContextProvider';
import styles from '../styles.module.scss';
import { useFetchAccountWithPlanByUser } from './useFetchAccountWithPlanByUser';

export default function AllAccounts(): JSX.Element {
  const { getTypeAll, handleViewOption } = useSwitchAccount();
  const { getMe } = useAuth();
  const history = useHistory();

  const query = useQueryString();
  const currentPage = query.get('page') ? Number(query.get('page')) : undefined;
  const search = query.get('search') || undefined;
  const filter = query.get('filter') || undefined;

  const { data, loading, pagination } = useFetchAccountWithPlanByUser(
    getMe.agency_id,
    getMe.user_id,
    currentPage,
    search,
    filter,
    12,
  );

  const addQueryParam = (params: Record<string, string | number | undefined>) => {
    const newQuery = new URLSearchParams(query.toString());

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        newQuery.set(key, String(value));
      } else {
        newQuery.delete(key);
      }
    }

    history.replace(`?${newQuery.toString()}`);
  };

  const showExtraComponents = useMemo(
    () => (data && pagination.total > 12) || Boolean(search || filter || currentPage),
    [data, pagination.total, search, filter, currentPage],
  );

  const handleTypeOfList = (typeOfList: TypeOfList) => {
    handleViewOption('all', typeOfList);
  };

  const handlePagination = (page: number) => {
    scrollToElement();
    addQueryParam({ page });
  };

  const handleSearch = (value: string) => {
    addQueryParam({ search: value, page: 1 });
  };

  const handleFilterByPlan = (value: string) => {
    addQueryParam({
      filter: value,
      page: 1,
    });
  };

  return (
    <>
      {showExtraComponents && (
        <Filters
          typeOfList={getTypeAll}
          handleTypeOfList={handleTypeOfList}
          handleSearch={handleSearch}
          handleFilterByPlan={handleFilterByPlan}
          search={search}
        />
      )}
      <main
        className={styleModuleClasses(styles, getTypeAll, {
          'no-grid': Boolean(data && data.length === 0 && !loading),
        })}
      >
        {loading ? (
          <>
            {Array.from({ length: 12 }).map((_, index) => (
              <SkeletonLoading type={getTypeAll} key={`loading-${index}`} />
            ))}
          </>
        ) : (
          <>
            {!data || data.length === 0 ? (
              <AccountsEmpty search={Boolean(search || filter)} />
            ) : (
              data.map((account) => (
                <AccountItem
                  key={`card-${account.id}`}
                  id={account.id}
                  name={account.name}
                  allowance={account.allowance}
                  type={getTypeAll}
                />
              ))
            )}
          </>
        )}
      </main>
      {showExtraComponents && (
        <footer>
          <Pagination
            total={pagination.total}
            currentPage={currentPage ?? 1}
            totalPerPage={pagination.totalPerPage}
            setPage={handlePagination}
          />
        </footer>
      )}
    </>
  );
}
