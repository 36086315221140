import { TableHeaderElement } from '../../../../componentsV2/TableColumnsControl/interface';

export const DEFAULT_COLUMNS: TableHeaderElement[] = [
  {
    id: 'created_at',
    base_field: 'created_at',
    type: 'text',
    enabled: true,
    name: 'Created at',
    sortDisabled: true,
    order: 1,
    align: 'left',
  },
  {
    id: 'type',
    base_field: 'type',
    type: 'text',
    enabled: true,
    name: 'Type',
    sortDisabled: true,
    order: 2,
    align: 'left',
    width: '7rem',
  },
  {
    id: 'code',
    base_field: 'code',
    type: 'copy',
    enabled: true,
    name: 'Invite Code',
    sortDisabled: true,
    order: 3,
    align: 'left',
    width: '10rem',
  },
  {
    id: 'uid',
    base_field: 'uid',
    type: 'text',
    enabled: false,
    name: 'UID',
    sortDisabled: true,
    order: 4,
    align: 'left',
  },
  {
    id: 'generator_name',
    base_field: 'generator_name',
    type: 'text',
    enabled: false,
    name: 'List Name',
    sortDisabled: true,
    order: 5,
    align: 'left',
    width: '18rem',
  },
  {
    id: 'promotion',
    base_field: 'promotion',
    type: 'text',
    enabled: true,
    name: 'Promotion',
    sortDisabled: true,
    order: 6,
    align: 'left',
    width: '18rem',
  },
  {
    id: 'message_request_timestamp',
    base_field: 'message_request_timestamp',
    type: 'text',
    enabled: true,
    name: 'Invite Sent',
    sortDisabled: true,
    order: 7,
    align: 'left',
    width: '11rem',
  },
  {
    id: 'used_at',
    base_field: 'used_at',
    type: 'text',
    enabled: true,
    name: 'Used at',
    sortDisabled: true,
    order: 8,
    align: 'left',
    width: '11rem',
  },
  {
    id: 'expire_date',
    base_field: 'expire_date',
    type: 'text',
    enabled: false,
    name: 'Expire Date',
    sortDisabled: true,
    order: 9,
    align: 'left',
    width: '11.5rem',
  },
  {
    id: 'user_id',
    base_field: 'user_id',
    type: 'text',
    enabled: false,
    name: 'Created by',
    sortDisabled: true,
    order: 10,
    align: 'left',
    width: '11.5rem',
  },
];
