import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Popover from '../DesignSystem/Popover';
import { PopoverUpgradeProps } from './interface';
import { popoverUpgradeSettings } from './popoverUpgradeSettings';

export default function PopoverUpgrade({
  planNameAllowed,
  handlePopover,
  showPopover,
  setShowPopover,
  propRef,
}: PopoverUpgradeProps): JSX.Element {
  const history = useHistory();

  const settings = useMemo(
    () =>
      popoverUpgradeSettings({
        planNameAllowed,
        onCancelClick: handlePopover,
        onUpgradeClick: () => history.push('/self-service'),
      }),

    [planNameAllowed],
  );

  return (
    <Popover
      propRef={propRef}
      showPopover={showPopover}
      callback={() => setShowPopover(false)}
      placement="top"
      size={settings.size}
      showArrow
      color={settings.color}
      header={settings.header}
      buttonsFooter={settings.buttonsFooter}
    >
      <div>
        <span>{settings.children}</span>
      </div>
    </Popover>
  );
}
