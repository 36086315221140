import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import EmailContent from './EmailContent';
import FormEmail from './FormEmail';
import SendEmailTest from './SendEmailTest';
import styles from './styles.module.scss';

export default function ConfigureEmail(): JSX.Element {
  return (
    <>
      <div className={styles['column']}>
        <FormEmail />
      </div>
      <div className={styles['column']}>
        <div className={styles['column-container']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Email Content
          </Paragraph>
          <EmailContent />
          <SendEmailTest />
        </div>
      </div>
    </>
  );
}
