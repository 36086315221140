import { Redirect, Route, Switch } from 'react-router-dom';
import RedirectCustomRoute from './components/RedirectCustomRoute';
import { ROUTES_ADMIN, ROUTES_PUBLIC } from './config/Routes';
import useAuth from './hooks/useAuth';
import Admin from './pages/Admin';
import CheckAccount from './pages/Admin/Account/CheckAccount';
import Logout from './pages/Admin/Logout';
import Credentials from './pages/Credentials';
{
  /* @TODO: it will be will be made available in the future
  const Agency = lazy(() => import('./pages/Billing/Agency'));
*/
}

export default function Routes(): JSX.Element {
  const { logged } = useAuth();

  return (
    <div className="theme">
      <Switch>
        <Route path="/credentials" component={Credentials} />
      </Switch>
      {!logged ? (
        <div className="container">
          <Switch>
            {ROUTES_PUBLIC.map((route, index) => (
              <Route key={index} {...route} />
            ))}
            {/*
              @TODO: it will be will be made available in the future
              {ROUTES_AGENCY.map((route, index) => (
                <RedirectCustomRoute key={index} {...route} />
              ))}
            */}
            {ROUTES_ADMIN.map((route, index) => (
              <RedirectCustomRoute key={index} {...route} />
            ))}
            <Redirect to="/notfound" />
          </Switch>
        </div>
      ) : (
        <>
          <Switch>
            <Route path="/logout" component={Logout} />

            <Route path="/signin" render={() => <Redirect to="/" />} />
            <Route path="/signin/:step?/:email?/:code?" render={() => <Redirect to="/" />} />
            <Route path="/signup" render={() => <Redirect to="/" />} />
            <Route path="/signup/:agencyId?/:accountId/:inviteId/:inviteCode" render={() => <Redirect to="/" />} />
            <Route path="/signup/verify=:verifyCode?" render={() => <Redirect to="/" />} />

            <Route path="/r/:slug" component={CheckAccount} />
            <Route path="/" component={Admin} />
            {/*
            @TODO: it will be will be made available in the future
            <Route path="/agency" component={Agency} />
          */}
          </Switch>
        </>
      )}
    </div>
  );
}
