import SignIn from '../../pages/Auth/SignIn';
import SignUp from '../../pages/Auth/Signup';
import Home from '../../pages/Home';
import NotFound from '../../pages/NotFound';

export const ROUTES_PUBLIC = [
  {
    component: Home,
    path: '/',
    exact: true,
  },
  {
    component: SignIn,
    path: '/signin/:step?/:email?/:code?',
    exact: true,
  },
  {
    component: SignUp,
    path: '/signup',
    exact: true,
  },
  {
    component: SignUp,
    path: '/signup/:agencyId?/:accountId/:inviteId/:inviteCode',
    exact: true,
  },
  {
    component: SignUp,
    path: '/signup/verify=:verifyCode?',
    exact: true,
  },
  {
    component: NotFound,
    path: '/notfound',
    exact: true,
  },
];
