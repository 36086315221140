import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
  item: string | number;
  currentPage: number;
  url: string;
  params?: string;
  hash?: string;
}

export default function PaginationItem({ item, currentPage, url, params, hash }: Props): JSX.Element {
  return (
    <li className={classnames('page-item', { active: item === currentPage, disabled: item === '...' })}>
      <Link className="page-link" to={`${url}?page=${item}${params}${hash}`}>
        {item}
      </Link>
    </li>
  );
}
