import Pills from '../../../Pills';
import { SelectItemProps } from '../interface';

export default function SelectItem({ option, handleSelect }: SelectItemProps): JSX.Element {
  return (
    <li
      key={option.value}
      onClick={() => handleSelect(option)}
      {...(option.typography && { style: { fontFamily: option.typography } })}
    >
      {option.label}

      {!!option.pill?.children && <Pills {...option.pill} />}
    </li>
  );
}
