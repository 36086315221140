export function setFirstLastDate(): string {
  let allDays = 30;
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const soonDay = new Date().getDate();
  allDays = soonDay - allDays;
  const olderDay = new Date(year, month, allDays).getDate();
  const dateParsed = `${year}-${month + (soonDay < 31 ? 0 : 1)}-${olderDay}_${year}-${month + 1}-${soonDay}`;

  return dateParsed;
}
