import React from 'react';

import styles from './styles.module.scss';
import SpinnerBox from '../../../componentsV2/DesignSystem/SpinnerBox';
interface Props {
  text?: string;
}

export default function SpinnerAllScreen({}: Props): JSX.Element {
  return (
    <div className={styles['spinner-all-screen']}>
      <SpinnerBox />
    </div>
  );
}
