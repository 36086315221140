import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { REACT_APP_ZAPIER_EMBED_CREATE } from '../../../../config/Env';
import IframeContainer from '../../../../components/IframeContainer';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import SectionCollapsibleFull from '../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';

export default function CreateZap(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'create-zap' });
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  return (
    <SectionCollapsibleFull
      expanded={true}
      header={{ title: t('Create Zaps right in your account') }}
      badge={{ status: StatusBadge.icon, icon: { name: 'groups', color: 'enabled' } }}
      disableCollapse
      showButton={{
        children: 'Back to Create a Zap',
        firstIcon: { children: 'chevron_left' },
        variant: 'secondary',
        onClick: () => history.push('/settings/integrations/build'),
      }}
    >
      <IframeContainer src={`${REACT_APP_ZAPIER_EMBED_CREATE}${id}`} allow={true} />
    </SectionCollapsibleFull>
  );
}
