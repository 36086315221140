import useSignupContext from '../SignupContextProvider';
import Initial from './Initial';
import Testimonials from './Testimonials';

export default function Slide(): JSX.Element {
  const { enableSlide } = useSignupContext();

  return (
    <>
      <Initial style={{ opacity: !enableSlide ? 1 : 0 }} />
      <Testimonials style={{ opacity: enableSlide ? 1 : 0 }} />
    </>
  );
}
