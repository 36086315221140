import { graphql } from 'msw';

const PROMOTION_DATA = [
  {
    id: '19710',
    account_id: '3227',
    name: 'Hello Test',
    campaign_name: 'Peel to Reveal',
    slug: 'hello-test',
    start_date: '2021-08-30 18:46:49',
    end_date: '',
    visibility: 'private',
    status: 'live',
    microsite_url: 'https://peel-to-reveal.blkd-test.com/hello-test',
    passcode: 'ce95f820',
    current_time: '2021-09-30 09:46:49',
  },
  {
    id: '19711',
    account_id: '3227',
    name: 'Hi Test',
    campaign_name: 'Scratch Card',
    slug: 'hi-test',
    start_date: '2021-08-30 18:48:31',
    end_date: '2021-09-03 09:38:19',
    visibility: 'public',
    status: 'ended',
    microsite_url: 'https://scratch-card.blkd-test.com/hi-test',
    passcode: 'ce95f820',
    current_time: '2021-09-04 09:38:19',
  },
  {
    id: '19706',
    account_id: '3227',
    name: 'Peel me back',
    campaign_name: 'Spin the Wheel',
    slug: 'peel-me-back',
    start_date: '2021-08-12 12:14:21',
    end_date: '',
    visibility: 'public',
    status: 'live',
    microsite_url: 'https://peel-me-back.blkd-test.com/spin-the-wheel-promotion-slug',
    passcode: 'ce95f820',
    current_time: '2021-09-12 12:14:21',
  },
  {
    id: '19705',
    account_id: '3227',
    name: 'scratch me too',
    campaign_name: 'Advent Calendar',
    slug: 'scratch-me-too',
    start_date: '',
    end_date: '',
    visibility: 'public',
    status: 'draft',
    microsite_url: 'https://scratch-me-too.blkd-test.com/advent-calendar-promotion-slug',
    passcode: 'ce95f820',
    current_time: '2021-09-12 12:14:21',
  },
  {
    id: '19701',
    account_id: '3227',
    name: 'Spinner',
    campaign_name: 'Sweepstakes',
    slug: 'spinner',
    start_date: '2021-08-30 18:48:31',
    end_date: '2022-09-09 09:38:19',
    visibility: 'private',
    status: 'live',
    microsite_url: 'https://spinner.blkd-test.com/sweepstakes-promotion-slug',
    passcode: 'ce95f820',
    current_time: '2022-09-19 09:38:19',
  },
];

const promotion = graphql.query('promotion', (req, res, ctx) => {
  const { promo_id } = req.variables;

  const promotion = PROMOTION_DATA.filter((item) => item.id === promo_id)[0];

  return res(
    ctx.delay(2000),
    ctx.data({
      promotion,
    }),
  );
});

const updatePromotionStartDate = graphql.mutation('updatePromotionStartDate', (req, res, ctx) => {
  const { promo_id, start_date } = req.variables;

  const promotion = PROMOTION_DATA.filter((item) => item.id === promo_id)[0];

  return res(
    ctx.delay(2000),
    ctx.data({
      updatePromotionStartDate: {
        ...promotion,
        start_date,
      },
    }),
  );
});

const updatePromotionEndDate = graphql.mutation('updatePromotionEndDate', (req, res, ctx) => {
  const { promo_id, end_date } = req.variables;

  const promotion = PROMOTION_DATA.filter((item) => item.id === promo_id)[0];

  return res(
    ctx.delay(2000),
    ctx.data({
      updatePromotionEndDate: {
        ...promotion,
        end_date,
      },
    }),
  );
});

const promotions = graphql.query('promotions', (req, res, ctx) => {
  const { filters } = req.variables;
  return res(
    ctx.delay(2000),
    ctx.data({
      promotions: {
        data: PROMOTION_DATA.filter((promotion) => promotion.status === filters.status),
      },
    }),
  );
});

const promotionCounter = graphql.query('promotionCounter', (req, res, ctx) => {
  const promotionCounter = PROMOTION_DATA.reduce((acc, curr) => {
    return acc[curr.status] ? ++acc[curr.status] : (acc[curr.status] = 1), acc;
  }, {});

  return res(
    ctx.delay(2000),
    ctx.data({
      promotionCounter,
    }),
  );
});

export default [promotion, updatePromotionStartDate, updatePromotionEndDate, promotions, promotionCounter];
