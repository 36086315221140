import { useFormContext } from 'react-hook-form';
import InputFormGroup from '../../../../../../../components/_molecules/InputFormGroup';
import { FontWeight, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { AnnonymousLinksProps } from './interface';
import styles from './styles.module.scss';

export default function AnnonymousLinks({ loading }: AnnonymousLinksProps): JSX.Element {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
    }
  };

  return (
    <div className={styles['form-container']}>
      <Paragraph size={2} weight={FontWeight.semiBold}>
        How many InviteLinks do you want
      </Paragraph>
      <Paragraph size={2} weight={FontWeight.regular}>
        You can generate up to 50k InviteLinks at a time. An Interaction is only used if the InviteLinks is used by
        someone to enter. Remember to keep a record for who receives each InviteLink. If you would like a record in
        BeeLiked, you can turn on the entry form for people to fill in their own details.
      </Paragraph>
      <div className={styles['input-container']}>
        <InputFormGroup
          type="text"
          classContainer="bulk-number text-center"
          id="invites_number"
          {...register('invites_number', { required: true, min: 1, max: 100000, valueAsNumber: true })}
          error={Boolean(errors.invites_number)}
          errorMessage="Please provide a valid number of InviteLinks to generate"
          className="quantity-field"
          onKeyPress={handleKeyPress}
          disabled={loading}
        />
      </div>
    </div>
  );
}
