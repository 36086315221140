import { FontWeight, Paragraph } from '../../../componentsV2/DesignSystem/Typography';
import { PluginCarouselCardProps } from './interface';

import styles from './styles.module.scss';

export default function PluginCarouselCard({
  primary_color,
  brand_color,
  expires_in,
  expires_days,
  expires_hours,
  expires_minutes,
  promotion_background,
  button_text,
}: PluginCarouselCardProps): JSX.Element {
  const showExpiresIn = !!expires_in;
  const showExpiresDays = !!expires_days;
  const showExpiresHours = !!expires_hours;
  const showExpiresMinutes = !!expires_minutes;
  const showExpireDate = showExpiresDays || showExpiresHours || showExpiresMinutes;
  const showCounter = showExpiresIn || showExpiresDays || showExpiresHours || showExpiresMinutes;

  return (
    <div className={styles['carousel_card']}>
      <Paragraph size={1} weight={FontWeight.extraBold}>
        <div className={styles['flex-column']}>
          <span style={{ color: primary_color }}>Promotion Name</span>
          <span
            className={styles['game-type-text']}
            style={{
              color: `${primary_color}BF`,
            }}
          >
            Game Type Name
          </span>
        </div>
      </Paragraph>
      <img src={promotion_background} alt="promotion" width={276} />

      <div className={styles['timer_container']}>
        <div className={showExpiresIn ? styles['expires_tab'] : styles['hidden']}>{expires_in}</div>
        <div
          className={styles[showCounter ? 'timer_element' : 'hidden']}
          style={{
            marginTop: showExpiresIn ? '-0.6rem' : '0rem',
            backgroundColor: `${showExpireDate ? brand_color : 'transparent'}`,
          }}
        >
          <div className={showExpiresDays ? styles['timer_section'] : styles['display-none']}>
            <div className={styles['time_section']}>
              <div className={styles['timer']}>2</div>
              <div className={styles['timer']}>6</div>
              <div className={styles['timer']}>2</div>
            </div>
            <span className={styles['day_element']}>{expires_days}</span>
          </div>

          {showExpiresDays && showExpiresHours && <span className={styles['color-white']}>:</span>}

          <div className={showExpiresHours ? styles['timer_section'] : styles['display-none']}>
            <div className={styles['time_section']}>
              <div className={styles['timer']}>1</div>
              <div className={styles['timer']}>3</div>
            </div>
            <span className={styles['day_element']}>{expires_hours}</span>
          </div>

          {(showExpiresDays || showExpiresHours) && showExpiresMinutes && (
            <span className={styles['color-white']}>:</span>
          )}

          <div className={showExpiresMinutes ? styles['timer_section'] : styles['display-none']}>
            <div className={styles['time_section']}>
              <div className={styles['timer']}>5</div>
              <div className={styles['timer']}>9</div>
            </div>
            <span className={styles['day_element']}>{expires_minutes}</span>
          </div>
        </div>
      </div>

      <div
        className={styles['button_plugin']}
        style={{
          backgroundColor: brand_color,
          boxShadow: `0 0 0 1px ${brand_color}`,
        }}
      >
        {button_text}
      </div>
    </div>
  );
}
