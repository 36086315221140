import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorProps } from './interface';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import styles from './styles.module.scss';

export default function ErrorRefetch({ callback, customStyles }: ErrorProps): JSX.Element {
  const { t } = useTranslation('promotionsList', { keyPrefix: 'error_promotions_list' });

  return (
    <div className={styles['content-error']} style={{ ...customStyles }}>
      <div>
        <p>{t('message_error')}</p>
        <Button variant="secondary" onClick={callback}>
          {t('button_error_try_again')}
        </Button>
      </div>
    </div>
  );
}
