import { useEffect } from 'react';
import { REACT_APP_HUBSPOT } from '../../config/Env';
import useAuth from '../../hooks/useAuth';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import { OpenHubspotChat } from '../OpenHubspotChat';

export default function LinkSupportHubspot(): JSX.Element {
  const { getMe } = useAuth();

  const { openHubspotChat } = OpenHubspotChat({
    email: getMe.email,
    firstName: getMe.name,
    lastName: getMe.family_name,
  });

  useEffect(() => {
    if (REACT_APP_HUBSPOT) {
      openHubspotChat();
    }
  }, [REACT_APP_HUBSPOT]);

  return (
    <li>
      <Button
        firstIcon={{ children: 'help_outline' }}
        variant="tertiary"
        text={{ color: 'dark', align: 'start' }}
        onClick={openHubspotChat}
      >
        Support
      </Button>
    </li>
  );
}
