import arrayToSentence from '../../utils/arrayToSentence';
import capitalize from '../../utils/capitalize';
import { PopoverExtendedProps } from '../DesignSystem/Popover/interface';
import { PopoverUpgradeSettingsProps } from './interface';

export const popoverUpgradeSettings = ({
  planNameAllowed,
  onCancelClick,
  onUpgradeClick,
}: PopoverUpgradeSettingsProps): PopoverExtendedProps => {
  const allowedPlansCapitalize = planNameAllowed?.map((plan) => capitalize(plan));
  const planSentences = arrayToSentence(allowedPlansCapitalize);

  const title =
    planNameAllowed?.length > 1
      ? `This feature is available in the following plans: ${planSentences}.`
      : allowedPlansCapitalize[0]
      ? `This feature is only available for the ${allowedPlansCapitalize[0]} plan.`
      : 'Upgrade your plan';

  return {
    header: {
      title,
    },
    children: 'Update your plan and have this and many other features to improve your promotions',
    color: 'dark',
    size: 'sm',
    buttonsFooter: [
      {
        children: 'Cancel',
        variant: 'secondary',
        size: 'sm',
        ...(onCancelClick && { onClick: onCancelClick }),
      },
      {
        children: 'Upgrade my plan',
        size: 'sm',
        onClick: onUpgradeClick,
      },
    ],
  };
};
