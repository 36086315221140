import React from 'react';
import { ZapierZap } from '../../../../ts';
import EmptyZaps from './EmptyZaps';
import Item from './Item';
import Skeleton from '../../../../componentsV2/DesignSystem/Skeleton';

interface Props {
  zaps: ZapierZap[];
  loadingZaps: boolean;
}

export default function List({ zaps, loadingZaps }: Props): JSX.Element {
  if (!loadingZaps && zaps.length === 0) {
    return <EmptyZaps />;
  }

  return (
    <section>
      {loadingZaps
        ? Array.from({ length: 2 }, () => '').map((_, index) => <Skeleton key={index} height={14.5} />)
        : zaps.map((zap) => <Item zap={zap} key={zap.id} />)}
    </section>
  );
}
