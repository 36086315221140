import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Plan(): JSX.Element {
  const { t } = useTranslation('subscription');

  return (
    <main className="p-6">
      <h1>{t('Plan')}</h1>
    </main>
  );
}
