import { Link, NavLink } from 'react-router-dom';
import Icon from '../../../Icon';
import styles from '../styles.module.scss';
import { NavigationProps } from './interface';
import Pills from '../../../Pills';

function NavigationLink({ to, children, activeClassName, navlink, isActive, ...rest }: NavigationProps) {
  let isExternal = false;
  let redirect = '';

  if (typeof to === 'string') {
    isExternal = to.startsWith('http');
    redirect = to;
  } else if (typeof to === 'object' && 'pathname' in to) {
    const { pathname } = to;
    isExternal = pathname?.startsWith('http') ?? false;
    redirect = pathname ?? '';
  }

  if (isExternal) {
    return (
      <a href={redirect} {...rest}>
        {children}
      </a>
    );
  }

  if (navlink) {
    return (
      <NavLink activeClassName={activeClassName} to={to} isActive={isActive} {...rest}>
        {children}
      </NavLink>
    );
  }

  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
}

export default function ButtonNavigation({
  children,
  firstIcon,
  lastIcon,
  variant,
  text,
  fullWidth,
  align,
  size,
  to,
  activeClassName,
  navlink,
  disabled,
  isActive,
  pill,
  ...rest
}: NavigationProps): JSX.Element {
  const classNames = [
    styles['button'],
    !!activeClassName ? activeClassName : '',
    variant ? styles[variant] : styles['primary'],
    text?.color ? styles['text-' + text.color] : '',
    size ? styles[size] : styles['md'],
    fullWidth ? styles['fullwidth'] : '',
    align ? styles[align] : '',
    !!disabled ? styles['disabled'] : '',
  ].join(' ');

  return (
    <NavigationLink
      to={disabled ? '#' : to}
      className={classNames}
      {...rest}
      activeClassName={activeClassName}
      navlink={navlink}
      isActive={isActive}
      disabled
    >
      <div className={`${styles['content']} ${text?.align ? styles[text.align] : styles['center']}`}>
        {firstIcon?.children && (
          <Icon customClass={styles['icon']} {...firstIcon}>
            {firstIcon?.children}
          </Icon>
        )}
        {children && (
          <main>
            <span>{children}</span>
          </main>
        )}
        {lastIcon?.children && (
          <Icon customClass={styles['icon']} {...lastIcon}>
            {lastIcon?.children}
          </Icon>
        )}
        {!!pill && <Pills {...pill} />}
      </div>
    </NavigationLink>
  );
}
