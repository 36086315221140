import beelikedIcon from '../../../assets/imgs/logo.png';
import { PluginFooterProps } from './interface';

import styles from './styles.module.scss';

export default function PluginFooter({ background_color, primary_color }: PluginFooterProps): JSX.Element {
  return (
    <footer
      className={styles['footer']}
      style={{
        backgroundColor: background_color,
      }}
    >
      <div className={styles['footer-container']} style={{ color: primary_color }}>
        Powered by
      </div>
      <div className={styles['flex-center-gap']}>
        <img src={beelikedIcon} alt="beeliked icon" height={22} />
      </div>
    </footer>
  );
}
