import ButtonNavigation from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { Heading, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import styles from './styles.module.scss';

export default function SetupEmailMasking(): JSX.Element {
  return (
    <>
      <div className={styles['overlay']} />
      <div className={styles['setup-email']}>
        <Heading size={4}>Email Setup Required</Heading>
        <Paragraph size={2}>
          To enable this feature, please configure your email settings in the Email Masking section.
        </Paragraph>
        <div>
          <ButtonNavigation variant="secondary" size="sm" to="/settings/account-details#email">
            Set Up Your Email
          </ButtonNavigation>
        </div>
      </div>
    </>
  );
}
