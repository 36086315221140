import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ButtonNavigation from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import VideoPlayer from '../../../../../../componentsV2/VideoPlayer';
import { BREAKPOINTS } from '../../../../../../config/Constants';
import arrayToSentence from '../../../../../../utils/arrayToSentence';
import capitalize from '../../../../../../utils/capitalize';
import { EmptyProps } from './interface';
import styles from './styles.module.scss';

export default function Empty({ hasPermission, allowedPlans }: EmptyProps): JSX.Element {
  const { url } = useRouteMatch();

  const plans = useMemo(() => {
    const allowedPlansCapitalize = allowedPlans?.map((plan) => capitalize(plan));
    return arrayToSentence(allowedPlansCapitalize);
  }, [allowedPlans]);

  return (
    <div className={styles['video-wrapper']}>
      <Paragraph size={2}>
        For enterprise clients, we offer a ready-to-use, fully customizable rewards plugin. Integrate it into your
        portal with just a few lines of code, allowing users to view their gameplay invites and prize details—all within
        your secure application.
      </Paragraph>

      <div className={styles['display-video']}>
        <VideoPlayer
          urlVideo="https://vimeo.com/video/990573565"
          urlThumbnail="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/thumb-video-first-rewards-plugin.jpg"
          hidePlayButton
          size={[
            { breakpoint: BREAKPOINTS.XL, width: 640 },
            { breakpoint: BREAKPOINTS.LG, width: 640 },
            { breakpoint: BREAKPOINTS.MD, width: 640 },
            { breakpoint: BREAKPOINTS.SM, width: 300 },
          ]}
        />
      </div>

      {hasPermission ? (
        <div className={styles['create-new-widget']}>
          <ButtonNavigation
            firstIcon={{ children: 'featured_seasonal_and_gifts' }}
            variant="primary"
            to={`${url}/create-plugin`}
          >
            Create First Plugin
          </ButtonNavigation>
        </div>
      ) : (
        <div className={styles['update-plan-content']}>
          <Heading size={4}>{`This function is available in the following plans: ${plans}.`}</Heading>
          <Paragraph size={2}>
            Update your plan and have this and many other functions to improve your promotions
          </Paragraph>
          <div>
            <ButtonNavigation to={'/self-service'} firstIcon={{ children: 'arrow_upward' }} size="md">
              Upgrade my plan now
            </ButtonNavigation>
          </div>
        </div>
      )}
    </div>
  );
}
