import React from 'react';
import { AlertProps } from './interface';
import styles from './styles.module.scss';

export default function Alert({ children, color, callback }: AlertProps): JSX.Element {
  return (
    <div className={[styles['alert'], styles[color]].join(' ')}>
      <div className={styles['content-wrapper']}>
        <span className="material-symbols-rounded">info</span>
        {children}
      </div>
      {callback && (
        <button onClick={callback}>
          <span className="material-symbols-rounded">close</span>
        </button>
      )}
    </div>
  );
}
