import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import VideoPlayerProps from './interface';
import styles from './styles.module.scss';

export default function VideoPlayerResponsive({ urlVideo, urlThumbnail, altThumbnail }: VideoPlayerProps): JSX.Element {
  const [startVideo, setStartVideo] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer | null>(null);

  const playVideo = () => {
    const player = playerRef.current;
    player?.getInternalPlayer()?.play();
    setStartVideo(true);
  };

  return (
    <div className={styles['media']}>
      {!startVideo && (
        <div className={styles['thumbnail']} onClick={playVideo}>
          {urlThumbnail && (
            <>
              <button onClick={playVideo}>
                <span className="material-symbols-rounded">play_circle</span>
              </button>
              {urlThumbnail && <img src={urlThumbnail} alt={altThumbnail} />}
            </>
          )}
        </div>
      )}

      <ReactPlayer
        url={urlVideo}
        width="100%"
        height="100%"
        controls
        ref={playerRef}
        className={styles['react-player']}
        style={{ visibility: startVideo || !urlThumbnail ? 'visible' : 'hidden' }}
      />
    </div>
  );
}
