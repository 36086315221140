import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Input from '../../../../../componentsV2/DesignSystem/Inputs/Input';
import Select from '../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import { PillsColors } from '../../../../../componentsV2/DesignSystem/Pills/interface';
import { FontWeight, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import { REACT_APP_HIVE_URL } from '../../../../../config/Env';
import useAccount from '../../../../../hooks/useAccount';
import { PromotionVisibility } from '../../../../../ts';
import capitalize from '../../../../../utils/capitalize';
import generateSlug from '../../../../../utils/generateSlug';
import Display from './Display';
import Upload from './Upload';
import { WidgetFormProps } from './interface';
import styles from './styles.module.scss';

export default function WidgetForm({
  file,
  setFile,
  imageUrl,
  promotionData,
  promotionVisibility,
}: WidgetFormProps): JSX.Element {
  const { getAccount } = useAccount();

  const { register, control, formState, watch, setValue } = useFormContext();

  const [promo_id, name, slug] = watch(['promo_id', 'name', 'slug']);

  const promotionSelected = promotionData?.find((promotion) => promotion.id === promo_id);

  const dataExpireTime = [
    {
      label: 'Expire every 60 seconds',
      value: 60,
    },
    {
      label: 'Expire every 2 minutes',
      value: 120,
    },
  ];

  const promotionList = useMemo(() => {
    if (promotionData) {
      return promotionData
        .filter((promotion) => promotion.status.toLocaleUpperCase() === 'LIVE' || promotion.id === promo_id)
        .map((promotion) => ({
          label: promotion.name,
          value: promotion.id,
          pill: {
            children: promotion.visibility,
            color: promotion.visibility === PromotionVisibility.PUBLIC ? PillsColors.green : PillsColors.primary,
          },
        }));
    }

    return [];
  }, [promotionData]);

  useEffect(() => {
    if (formState.dirtyFields.name) {
      setValue('slug', generateSlug(name));
    }
  }, [name]);

  return (
    <>
      <div className={styles['section-name-promotion']}>
        <Input
          label="Name Widget"
          {...register('name')}
          {...(formState.errors.name && { helperText: formState.errors.name.message, color: 'danger' })}
        />
        <Controller
          control={control}
          name="promo_id"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              activeSearch
              label="Point to promotion"
              placeholder={'Select a promotion'}
              onChange={(id) => onChange(id)}
              options={promotionList}
              defaultValue={value}
              pill={{
                children: promotionSelected?.visibility ? capitalize(promotionSelected.visibility) : '',
                color: PillsColors.green,
              }}
              {...(formState.errors.promo_id && { helperText: formState.errors.promo_id.message, color: 'red-block' })}
            />
          )}
        />
      </div>

      {promo_id && (
        <div className={styles['form-share']}>
          <Input
            label="Widget URL to Share"
            readOnly={true}
            value={`${REACT_APP_HIVE_URL}/${getAccount.accountSlug}/${slug}`}
            icon={{
              name: 'copy_all',
              onClick: () => navigator.clipboard.writeText(`${REACT_APP_HIVE_URL}/${getAccount.accountSlug}/${slug}`),
            }}
          />
        </div>
      )}

      <div className={styles['grid-container']}>
        <div className={styles['form-column']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Create a widget to show your users QR Codes
          </Paragraph>
          <div className={styles['drag-drop-field']}>
            <Upload setFile={setFile} filename={file.name} extesions={['png', 'jpg']} />
          </div>

          <div className={styles['description-field']}>
            <Input
              label="Header Message"
              {...register('header_message')}
              placeholder="Enter our Spin to Win promotion"
            />
            <Input label="Color" {...register('header_message_font_color')} type="color" />
          </div>

          <div className={styles['description-field']}>
            <Input label="Message 1" {...register('message1')} placeholder="Scan to Enter" />
            <Input label="Color" {...register('message1_font_color')} type="color" />
          </div>

          <div className={styles['description-field']}>
            <Input label="Message 2" {...register('message2')} placeholder="Your QR will expire in" />
            <Input label="Color" {...register('message2_font_color')} type="color" />
          </div>

          {promotionVisibility === PromotionVisibility.PRIVATE && (
            <div className={styles['description-field']}>
              <Controller
                control={control}
                name="expire_time"
                render={({ field: { onChange, value } }) => (
                  <Select
                    label="Expire time"
                    placeholder="Select an Expire Time"
                    onChange={(id) => onChange(Number(id))}
                    options={dataExpireTime}
                    defaultValue={value}
                  />
                )}
              />
            </div>
          )}

          <div className={styles['widget-styles-title']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              Widget Styles
            </Paragraph>
          </div>

          <div className={styles['background-line']}>
            <Paragraph size={2} weight={FontWeight.semiBold} color="gray-40">
              Widget Background
            </Paragraph>

            <Input {...register('widget_background')} type="color" />
          </div>
          <div className={styles['background-line']}>
            <Paragraph size={2} weight={FontWeight.semiBold} color="gray-40">
              Background
            </Paragraph>

            <Input {...register('page_background')} type="color" />
          </div>
        </div>

        <div className={styles['preview-column']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Preview
          </Paragraph>

          <Display imageFile={imageUrl} promotionVisibility={promotionVisibility} />
        </div>
      </div>
    </>
  );
}
