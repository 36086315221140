import { graphql } from 'msw';

const collections = graphql.query('collections', (req, res, ctx) => {
  const data = [
    {
      id: 2,
      agency_id: '1',
      account_id: '3227',
      name: 'xBox Game Pass',
      slug: 'xbox-game-pass',
      test_coupon_code: 'xbox-game-pass',
      description: 'Lorem ipsum',
      is_active: true,
      created_at: new Date(),
    },
    {
      id: 1,
      agency_id: '1',
      account_id: '3227',
      name: 'Apple Gift Coupons',
      slug: 'apple-gift-coupons',
      test_coupon_code: 'apple-gift-coupons',
      description: 'Lorem ipsum',
      is_active: true,
      created_at: new Date(),
    },
  ];

  return res(
    ctx.delay(2000),
    ctx.data({
      collections: {
        data,
      },
    }),
  );
});

const coupons = graphql.query('coupons', (req, res, ctx) => {
  const { collectionId } = req.variables;

  let data = [];

  if (Number(collectionId) === 1) {
    data = [
      {
        id: 1,
        agency_id: 1,
        account_id: 3227,
        collection_id: 1,
        promotion_id: null,
        generator_id: null,
        importer_id: null,
        author_user_id: 2762,
        code: '123abc',
        used_by_type: null,
        used_by_id: null,
      },
      {
        id: 2,
        agency_id: 1,
        account_id: 3227,
        collection_id: 1,
        promotion_id: null,
        generator_id: null,
        importer_id: null,
        author_user_id: 2762,
        code: 'zxy987',
        used_by_type: null,
        used_by_id: null,
      },
    ];
  }

  return res(
    ctx.delay(2000),
    ctx.data({
      coupons: {
        data,
      },
    }),
  );
});

const createCollection = graphql.mutation('createCollection', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      createCollection: {
        id: 3,
      },
    }),
  );
});

const createCoupon = graphql.mutation('createCoupon', (req, res, ctx) => {
  const { code } = req.variables;
  return res(
    ctx.delay(2000),
    ctx.data({
      createCoupon: {
        id: code,
        code,
      },
    }),
  );
});

export default [collections, coupons, createCollection, createCoupon];
