import React from 'react';
import { ListZapTemplatesProps } from './interface';
import ItemZapTemplate from './ItemZapTemplate';

export default function ListZapTemplates({ templates }: ListZapTemplatesProps): JSX.Element {
  return (
    <section>
      {templates.map((template) => (
        <React.Fragment key={`template-${template.id}`}>
          <ItemZapTemplate template={template} />
        </React.Fragment>
      ))}
    </section>
  );
}
