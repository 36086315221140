import { IconAnimated } from '../../../../componentsV2/DesignSystem/IconAnimated';
import { Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import styles from './styles.module.scss';

export default function EmailSuccessfullySent(): JSX.Element {
  return (
    <div className={styles['email-successfully-sent']}>
      <div className={styles['container-icon']}>
        <IconAnimated wrapper={{ color: 'green' }} icon={{ name: 'check' }} />
        <div>
          <Paragraph size={2}>Your InviteLink(s) have been sent by email.</Paragraph>
        </div>
      </div>
    </div>
  );
}
