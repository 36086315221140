import { ApolloError, gql, useQuery } from '@apollo/client';

export const AGREEMENT_BY_USER = gql`
  query agreementByUser {
    agreementByUser {
      accepted
      title
      content
      acceptedDate
    }
  }
`;

interface AgreementByUser {
  accepted: boolean;
  title: string;
  content: string;
  acceptedDate: string;
}

export function useFetchAgreementByUser(skip?: boolean): {
  loading: boolean;
  data: AgreementByUser | null;
  error?: ApolloError;
  refetch?: () => void;
} {
  const { data, loading, error, refetch } = useQuery<{ agreementByUser: AgreementByUser }>(AGREEMENT_BY_USER, {
    fetchPolicy: 'no-cache',
    skip,
    onError: (error: ApolloError) => {
      console.log({
        gql: 'AGREEMENT_BY_USER.',
        apolloError: error,
      });
    },
  });

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
    };
  }

  return {
    loading: false,
    data: data?.agreementByUser || null,
    refetch,
  };
}
