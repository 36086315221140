export interface PasswordProps {
  password: string;
  passwordConfirmation: string;
}

export interface PasswordRules {
  size: boolean;
  upper: boolean;
  lower: boolean;
  special: boolean;
  equal: boolean;
}

interface ValidationProps {
  rules: PasswordRules;
}

export function validatePassword(value: string, pass: PasswordProps, rules: PasswordRules): ValidationProps {
  return {
    rules: {
      ...rules,
      lower: /[a-z]+/g.test(value),
      size: value.length >= 8,
      special: /[$&+,:;=?@#|'<>.^*()%!-]/gm.test(value),
      upper: /[A-Z]+/g.test(value),
      equal: pass.passwordConfirmation === pass.password && value.length > 0,
    },
  };
}

export function passwordConfirmation(value: string, pass: PasswordProps, rules: PasswordRules): ValidationProps {
  return {
    rules: { ...rules, equal: value == pass.password && value.length > 0 ? true : false },
  };
}
