import { Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { InviteCreationMethod } from '../../../../../../ts';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import useShareLinks from '../../ShareLinksProvider';
import styles from './styles.module.scss';

export default function ChooseVisibility(): JSX.Element {
  const { inviteMethod, setInviteMethod } = useShareLinks();

  const handleInviteMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;

    setInviteMethod(
      selectedValue === InviteCreationMethod.ANONYMOUS
        ? InviteCreationMethod.ANONYMOUS
        : InviteCreationMethod.PERSONALIZED,
    );
  };

  return (
    <>
      <Paragraph size={2}>Each link allows for one entry to your promotion.</Paragraph>
      <div className={styles['grouped-cards']}>
        <div
          className={styleModuleClasses(styles, 'card', {
            'card-selected': inviteMethod === InviteCreationMethod.PERSONALIZED,
          })}
        >
          <div className={styles['input-radio-button']}>
            <input
              type="radio"
              name="option"
              id="personalized"
              value={InviteCreationMethod.PERSONALIZED}
              onChange={handleInviteMethodChange}
              checked={inviteMethod === InviteCreationMethod.PERSONALIZED}
            />
            <div>
              <label htmlFor="personalized">Personalized</label>
            </div>
          </div>
          <div>
            <Paragraph size={2}>Personalize each link to make it specific to one individual</Paragraph>
          </div>
        </div>

        <div
          className={styleModuleClasses(styles, 'card', {
            'card-selected': inviteMethod === InviteCreationMethod.ANONYMOUS,
          })}
        >
          <div className={styles['input-radio-button']}>
            <input
              type="radio"
              name="option"
              id="anonymous"
              value={InviteCreationMethod.ANONYMOUS}
              onChange={handleInviteMethodChange}
              checked={inviteMethod === InviteCreationMethod.ANONYMOUS}
            />
            <div>
              <label htmlFor="anonymous">Anonymous</label>
            </div>
          </div>

          <div>
            <Paragraph size={2}>Anonymous links can be given to any one</Paragraph>
          </div>
        </div>
      </div>
    </>
  );
}
