import { useHistory } from 'react-router-dom';
import { NavigationProfileLinks } from '../../config/NavigationLinksData';
import usePostHogContext from '../../hooks/usePostHog';
import hasPermission from '../../utils/hasPermission';
import Dropdown from '../DesignSystem/Dropdown';
import ButtonNavigation from '../DesignSystem/Inputs/Buttons/ButtonNavigation';
import { NavigationProfileDropdownProps } from './interface';
import styles from './styles.module.scss';

export default function NavigationProfileDropdown({
  accountData,
  superAdmin,
  showSwitchAccount,
}: NavigationProfileDropdownProps): JSX.Element {
  const { resetPostHog } = usePostHogContext();
  const history = useHistory();

  const logout = () => {
    resetPostHog();
    history.push('/logout');
  };

  const items = NavigationProfileLinks.filter((option) => {
    if (option.accountPermission) {
      return hasPermission(accountData.accountPermission, option.accountPermission);
    }
    return true;
  });

  return (
    <Dropdown
      activeButton={{
        button: {
          text: { color: 'dark' },
          firstIcon: { children: 'manage_accounts' },
          variant: 'tertiary',
          lastIcon: { children: 'expand_more' },
        },
      }}
      header={{ title: accountData.accountName }}
      buttonsFooter={[
        {
          children: 'Log out',
          variant: 'tertiary',
          firstIcon: { children: 'exit_to_app', style: { transform: 'rotate(180deg)' } },
          onClick: () => logout(),
          text: { color: 'dark' },
        },
      ]}
      size="sm"
      placement="bottom"
    >
      <ul className={styles['profile-wrapper']}>
        {(superAdmin || showSwitchAccount) && (
          <li>
            <ButtonNavigation
              to="/account/choose-account"
              variant="tertiary"
              text={{ color: 'dark' }}
              firstIcon={{ children: 'change_circle' }}
            >
              Choose an Account
            </ButtonNavigation>
          </li>
        )}

        {items.map((item) => (
          <li key={`option-${item.icon}`}>
            <ButtonNavigation
              to={item.url.replace(':accountSlug', accountData.accountSlug)}
              variant="tertiary"
              text={{ color: 'dark' }}
              firstIcon={{ children: item.icon }}
              {...(item.openNewWindow && { target: '_blank' })}
            >
              {item.title}
            </ButtonNavigation>
          </li>
        ))}
      </ul>
    </Dropdown>
  );
}
