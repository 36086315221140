import { useMemo, useRef, useState } from 'react';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import Popover from '../DesignSystem/Popover';
import { PopoverExtendedProps } from '../DesignSystem/Popover/interface';
import { ButtonPopoverConfirmProps } from './interface';
import styles from './styles.module.scss';

export default function ButtonPopoverConfirm({
  children,
  onClick,
  buttonProps,
  popoverProps,
  enableMouseEnter,
}: ButtonPopoverConfirmProps): JSX.Element {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const [showPopoverAnimation, setShowPopoverAnimation] = useState<boolean>(false);
  const buttonRef = useRef(null);

  const actionButton = () => {
    handlePopover(false);
    onClick?.();
  };

  const handlePopover = (popoverAction: boolean) => {
    if (!popoverAction) {
      setShowPopoverAnimation(false);
      setTimeout(() => setShowPopover(false), 200);
    } else {
      setShowPopover(true);
      setTimeout(() => setShowPopoverAnimation(true), 200);
    }
  };

  const popover: PopoverExtendedProps = useMemo(() => {
    return {
      ...popoverProps,
      header: {
        ...popoverProps?.header,
        className: styles['popover-header'],
        button: {
          ...popoverProps?.header?.button,
          firstIcon: { children: 'close' },
          text: { color: 'gray-48' },
          customClass: styles['custom-popover-button'],
          size: 'md',
          onClick: () => handlePopover(false),
        },
      },
      buttonsFooter: [
        {
          children: 'Yes, Delete',
          size: 'sm',
          onClick: actionButton,
          fullWidth: true,
          ...popoverProps?.buttonsFooter?.[0],
        },
      ],
    };
  }, [popoverProps]);

  return (
    <>
      <Button
        ref={buttonRef}
        variant="secondary"
        size="sm"
        onClick={() => handlePopover(true)}
        {...(enableMouseEnter && { onMouseEnter: () => handlePopover(true) })}
        {...buttonProps}
      >
        {children}
      </Button>
      {showPopover && (
        <Popover
          propRef={buttonRef}
          showPopover={showPopoverAnimation}
          callback={() => handlePopover(false)}
          size="sm"
          showArrow
          color="dark"
          {...popover}
        />
      )}
    </>
  );
}
