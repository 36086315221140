import Cookies, { CookieAttributes } from 'js-cookie';
import { REACT_APP_COOKIE_DOMAIN, REACT_APP_COOKIE_PREFIX } from '../config/Env';

export const setAccountCookie = (accountId: string): void => {
  const cookieAttributes: CookieAttributes = {
    domain: REACT_APP_COOKIE_DOMAIN,
    secure: true,
    sameSite: 'None',
  };

  Cookies.set(`${REACT_APP_COOKIE_PREFIX}usr_usr_account`, accountId.toString(), cookieAttributes);
};

export const getAccountCookie = (): string | undefined => {
  return Cookies.get(`${REACT_APP_COOKIE_PREFIX}usr_usr_account`);
};

export const removeAccountCookie = (): void => {
  Cookies.remove(`${REACT_APP_COOKIE_PREFIX}usr_usr_account`, { domain: REACT_APP_COOKIE_DOMAIN });
};
