import Skeleton from '../DesignSystem/Skeleton';
import styles from './styles.module.scss';

export default function SkeletonTable(): JSX.Element {
  return (
    <>
      <header>
        {Array.from({ length: 6 }).map((_, index) => {
          const headerRandom = Math.floor(Math.random() * 71) + 25;
          return (
            <div key={`header-${index}`}>
              <Skeleton height={1} width={headerRandom} />
            </div>
          );
        })}
      </header>
      <main>
        {Array.from({ length: 6 }).map((_, index) => {
          const mainRandom = Math.floor(Math.random() * 71) + 25;
          return (
            <div className={styles['column']} key={`data-${index}`}>
              {Array.from({ length: 10 }).map((_, index) => {
                return (
                  <div key={index}>
                    <Skeleton height={1} width={mainRandom} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </main>
    </>
  );
}
