import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Icon from '../../../../componentsV2/DesignSystem/Icon';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../componentsV2/DesignSystem/Inputs/Input';
import Select from '../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import Switch from '../../../../componentsV2/DesignSystem/Inputs/Switch';
import Textarea from '../../../../componentsV2/DesignSystem/Inputs/Textarea';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { FormEmailProps } from './interface';
import useSendEmail from './SendEmailProvider';
import styles from './styles.module.scss';
import UploadEmailImage from './UploadEmailImage';

type AccordionOptions = 'greeting' | 'content' | 'enterButton' | 'inviteLink' | 'signature' | 'footerText';

export default function FormEmail({ file, setFile }: FormEmailProps): JSX.Element {
  const { senderEmail } = useSendEmail();
  const [openStates, setOpenStates] = useState<Record<AccordionOptions, boolean>>({
    greeting: true,
    content: false,
    enterButton: false,
    inviteLink: false,
    signature: false,
    footerText: false,
  });

  const {
    register,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();

  const fontsizeOption = [
    {
      value: '10px',
      label: '10px',
    },
    {
      value: '12px',
      label: '12px',
    },
    {
      value: '14px',
      label: '14px',
    },
    {
      value: '16px',
      label: '16px',
    },
    {
      value: '18px',
      label: '18px',
    },
  ];

  const [header_disable, header_alignment] = watch(['header_disable', 'header_alignment']);

  const handleAccordion = (option: AccordionOptions) => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [option]: !prevStates[option],
    }));
  };

  const handleDisableHeader = () => {
    setValue('header_disable', header_disable === 'block' ? 'none' : 'block');
  };

  const handleHeaderAlignment = (headerAlignment: string) => {
    setValue('header_alignment', headerAlignment);
  };

  return (
    <div className={styles['email-sending-options']}>
      <div className={styles['header-description']}>
        <Paragraph size={2} weight={FontWeight.semiBold}>
          Configure the email sending options
        </Paragraph>

        <Paragraph size={2}>Customize your email sending preferences . E.g. Name, subject, etc.</Paragraph>
      </div>

      <div className={styles['settings']}>
        <Paragraph size={2} weight={FontWeight.semiBold} mb={2}>
          Settings
        </Paragraph>
        <div className={styles['content']}>
          <Input
            label="From Address"
            defaultValue={senderEmail?.sender}
            value={senderEmail?.sender}
            readOnly
            inputSize="sm"
          />

          <Input
            {...register('subject')}
            label="Subject"
            placeholder="What's the subject line for this email..."
            {...(errors.subject && { helperText: 'Please provide a valid value.', color: 'danger' })}
            inputSize="sm"
          />

          <Input
            {...register('background')}
            type="color"
            inputSize="sm"
            label="Background Color"
            className={styles['input-color-size']}
          />
        </div>
      </div>

      <div className={styles['accordion']}>
        <div className={styles['accordion-title']}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Header
          </Paragraph>
          <Switch checked={header_disable === 'block' ? true : false} onChange={handleDisableHeader} />
        </div>
        <div
          className={styleModuleClasses(styles, 'accordion-body', {
            'accordion-closed': header_disable === 'none',
          })}
        >
          <div className={styles['input-section']}>
            <Paragraph size={3} weight={FontWeight.semiBold}>
              Image
            </Paragraph>
            <div className={styles['drag-drop-field']}>
              <UploadEmailImage setFile={setFile} filename={file.name} extesions={['png', 'jpg', 'svg']} />
            </div>
          </div>

          <div className={styles['header-setting']}>
            <div className={styles['alignment-buttons']}>
              <label>Align</label>
              <div>
                <Button
                  firstIcon={{ children: 'format_align_left' }}
                  size="sm"
                  variant="secondary"
                  onClick={() => handleHeaderAlignment('left')}
                  {...(header_alignment === 'left' && { customClass: styles['active'] })}
                />
                <Button
                  firstIcon={{ children: 'format_align_center' }}
                  size="sm"
                  variant="secondary"
                  onClick={() => handleHeaderAlignment('center')}
                  {...(header_alignment === 'center' && { customClass: styles['active'] })}
                />
                <Button
                  firstIcon={{ children: 'format_align_right' }}
                  size="sm"
                  variant="secondary"
                  onClick={() => handleHeaderAlignment('right')}
                  {...(header_alignment === 'right' && { customClass: styles['active'] })}
                />
              </div>
            </div>
            <div className={styles['background_button']}>
              <Input label="Background" {...register('header_background_color')} type="color" inputSize="sm" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles['accordion']}>
        <div className={styles['accordion-title']} onClick={() => handleAccordion('greeting')}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Greeting
          </Paragraph>

          <Icon>{openStates.greeting ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
        </div>
        <div
          className={styleModuleClasses(styles, 'accordion-body', {
            'accordion-closed': !openStates.greeting,
          })}
        >
          <Input
            {...register('greeting')}
            label="Content"
            {...(errors.greeting && { helperText: errors.greeting.message, color: 'danger' })}
            inputSize="sm"
          />

          <div className={styles['input-setting']}>
            <Controller
              name="greeting_font_size"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Size"
                  placeholder="Select"
                  onChange={(value) => onChange(value)}
                  options={fontsizeOption}
                  defaultValue={value}
                  size="sm"
                />
              )}
            />

            <Input label="Color" {...register('greeting_font_color')} type="color" inputSize="sm" />
          </div>
        </div>
      </div>

      <div className={styles['accordion']}>
        <div className={styles['accordion-title']} onClick={() => handleAccordion('content')}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Content
          </Paragraph>

          <Icon>{openStates.content ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
        </div>
        <div
          className={styleModuleClasses(styles, 'accordion-body', {
            'accordion-closed': !openStates.content,
          })}
        >
          <Textarea
            {...register('content')}
            label="Content"
            {...(errors.content && { helperText: errors.content.message, color: 'danger' })}
            inputSize="sm"
            rows={5}
          />
          <div className={styles['input-setting']}>
            <Controller
              name="content_font_size"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Size"
                  placeholder="Select"
                  onChange={(value) => onChange(value)}
                  options={fontsizeOption}
                  defaultValue={value}
                  size="sm"
                />
              )}
            />
            <Input label="Color" {...register('content_font_color')} type="color" inputSize="sm" />
          </div>
        </div>
      </div>

      <div className={styles['accordion']}>
        <div className={styles['accordion-title']} onClick={() => handleAccordion('enterButton')}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Enter Button
          </Paragraph>
          <Icon>{openStates.enterButton ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
        </div>
        <div
          className={styleModuleClasses(styles, 'accordion-body', {
            'accordion-closed': !openStates.enterButton,
          })}
        >
          <Input
            {...register('enter_button_text')}
            label="Content"
            {...(errors.enter_button_text && {
              helperText: errors.enter_button_text.message,
              color: 'danger',
            })}
            inputSize="sm"
          />
          <div className={styles['input-setting']}>
            <Controller
              name="enter_button_size"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Size"
                  placeholder="Select"
                  onChange={(value) => onChange(value)}
                  options={fontsizeOption}
                  defaultValue={value}
                  size="sm"
                />
              )}
            />
            <Input label="Bg" {...register('enter_button_background_color')} type="color" inputSize="sm" />
            <Input label="Color" {...register('enter_button_color')} type="color" inputSize="sm" />
          </div>
        </div>
      </div>

      <div className={styles['accordion']}>
        <div className={styles['accordion-title']} onClick={() => handleAccordion('inviteLink')}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Invite Link
          </Paragraph>
          <Icon>{openStates.inviteLink ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
        </div>
        <div
          className={styleModuleClasses(styles, 'accordion-body', {
            'accordion-closed': !openStates.inviteLink,
          })}
        >
          <div className={styles['input-setting']}>
            <Controller
              name="invitelink_font_size"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Select"
                  onChange={(value) => onChange(value)}
                  options={fontsizeOption}
                  defaultValue={value}
                  size="sm"
                />
              )}
            />
            <Input {...register('invitelink_font_color')} type="color" inputSize="sm" />
          </div>
        </div>
      </div>

      <div className={styles['accordion']}>
        <div className={styles['accordion-title']} onClick={() => handleAccordion('signature')}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Thank you message
          </Paragraph>
          <Icon>{openStates.signature ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
        </div>
        <div
          className={styleModuleClasses(styles, 'accordion-body', {
            'accordion-closed': !openStates.signature,
          })}
        >
          <Textarea {...register('signature')} label="Content" inputSize="sm" rows={3} />
          <div className={styles['input-setting']}>
            <Controller
              name="signature_font_size"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Size"
                  placeholder="Select"
                  onChange={(value) => onChange(value)}
                  options={fontsizeOption}
                  defaultValue={value}
                  size="sm"
                />
              )}
            />
            <Input label="Color" {...register('signature_font_color')} type="color" inputSize="sm" />
          </div>
        </div>
      </div>

      <div className={styles['accordion']}>
        <div className={styles['accordion-title']} onClick={() => handleAccordion('footerText')}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Footer Message
          </Paragraph>
          <Icon>{openStates.footerText ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</Icon>
        </div>
        <div
          className={styleModuleClasses(styles, 'accordion-body', {
            'accordion-closed': !openStates.footerText,
          })}
        >
          <Textarea {...register('footer_text')} label="Content" inputSize="sm" rows={3} />
          <div className={styles['input-setting']}>
            <Controller
              name="footer_text_font_size"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Size"
                  placeholder="Select"
                  onChange={(value) => onChange(value)}
                  options={fontsizeOption}
                  defaultValue={value}
                  size="sm"
                />
              )}
            />

            <Input label="Color" {...register('footer_text_font_color')} type="color" inputSize="sm" />
          </div>
        </div>
      </div>
    </div>
  );
}
