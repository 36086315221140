import React from 'react';
import { uid } from 'uid';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import { RadioBoxProps } from './interface';
import styles from './styles.module.scss';

export default React.forwardRef<HTMLInputElement, RadioBoxProps>(function RadioBox(
  { label, animation, radioSize, ...props }: RadioBoxProps,
  ref,
): JSX.Element {
  const customId = props.id || `radiobox-label-${uid()}`;
  const size = radioSize ? radioSize : undefined;
  return (
    <div className={styleModuleClasses(styles, 'wrapper-radiobox', animation, size)}>
      <input type="radio" ref={ref} id={customId} {...props} />
      {!!label && <label htmlFor={customId}>{label}</label>}
    </div>
  );
});
