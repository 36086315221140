import { ApolloError, gql, useQuery } from '@apollo/client';

const SENDER_LIST_EMAIL_BY_ACCOUNT = gql`
  query senderListEmailByAccount($accountId: ID!) {
    senderListEmailByAccount(accountId: $accountId) {
      id
      senderId
      sender
      senderName
    }
  }
`;

export interface SenderContact {
  id: number;
  senderId: number;
  sender: string;
  senderName: string;
}

export type RefetchSenderListEmailByAccount = (variables: { accountId: number }) => void;

interface UseFetchSenderListEmailByAccount {
  loading: boolean;
  data: SenderContact[] | null;
  error?: ApolloError;
  refetch?: RefetchSenderListEmailByAccount;
}

export function useFetchSenderListEmailByAccount(accountId: number): UseFetchSenderListEmailByAccount {
  const { data, loading, error, refetch } = useQuery<{ senderListEmailByAccount: SenderContact[] }>(
    SENDER_LIST_EMAIL_BY_ACCOUNT,

    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId,
      },
      onError: (error: ApolloError) => {
        console.log({
          gql: 'SENDER_LIST_EMAIL_BY_ACCOUNT',
          apolloError: error,
        });
      },
    },
  );

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
    };
  }

  return {
    loading: false,
    data: data?.senderListEmailByAccount || [],
    error,
    refetch,
  };
}
