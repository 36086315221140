import { useMemo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useParams } from 'react-router-dom';
import { REACT_APP_RECAPTCHA_V3_SITE_KEY } from '../../../../config/Env';
import useSigninContext from '../SigninContextProvider';
import ForgotPassword from './Form/ForgotPassword';
import RecoveryPassword from './Form/RecoveryPassword';
import StepInitial from './Form/StepInitial';
import StepVerifyCode from './Form/StepVerifyCode';
import Slide from './Slide/NewsSlideShow';
import { SignInParamsProps } from './interface';
import styles from './styles.module.scss';

export default function SigninWrapper(): JSX.Element {
  const { enableSteps } = useSigninContext();
  const params = useParams<SignInParamsProps>();

  const renderPassword = useMemo(() => {
    if (enableSteps.enableForgotPassword) {
      return params.step === 'recovery' ? (
        <RecoveryPassword code={params?.code} email={params.email} />
      ) : (
        <ForgotPassword />
      );
    }
    return <></>;
  }, [params, enableSteps]);

  return (
    <div className={styles['wrapper']}>
      <div className={styles['left-side']}>
        <GoogleReCaptchaProvider
          reCaptchaKey={REACT_APP_RECAPTCHA_V3_SITE_KEY}
          container={{
            parameters: {
              badge: 'inline',
            },
          }}
        >
          {renderPassword}
          {enableSteps.initial && <StepInitial />}
          {enableSteps.enableOtp && <StepVerifyCode />}
        </GoogleReCaptchaProvider>
      </div>
      <div className={styles['slide-wrapper']}>
        <Slide />
      </div>
    </div>
  );
}
