import React from 'react';
import { FormatNumberTwoNumeric } from '../Utils/Format';
import { SectionStatusProps } from './interface';
import styles from './styles.module.scss';

export function sectionStatus({ status, icon, number }: SectionStatusProps): JSX.Element | undefined {
  switch (status) {
    case 'hidden':
      return;
    case 'enabled':
    case 'disabled':
      if (!number) return;
      return (
        <span className={[styles['circle-status'], styles[status]].join(' ')}>{FormatNumberTwoNumeric(number)}</span>
      );
    case 'icon':
      return (
        <span className={[styles['circle-status'], icon?.color ? styles[icon?.color] : ''].join(' ')}>
          <span className="material-symbols-rounded">{icon?.name}</span>
        </span>
      );
  }
}
