import React, { useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  REACT_APP_API_MOCKING,
  REACT_APP_ASSETS_IMAGES,
  REACT_APP_ENVIRONMENT,
  REACT_APP_ZAPIER_AUTHORIZE,
  REACT_APP_ZAPIER_REDIRECT_URI,
} from '../../../../config/Env';
import { ZAPIER_CLIENT_ID_GQL } from '../graphql';
import openPopupCenter from '../../../../utils/openPopupCenter';
import useZapierContext from '../ZapierContextProvider';
import useAccount from '../../../../hooks/useAccount';
import { ZapierWindowMessageType } from '../../../../ts';
import styles from './styles.module.scss';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import SpinnerBox from '../../../../componentsV2/DesignSystem/SpinnerBox';
import SectionCollapsibleFull from '../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import { Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import VideoPlayer from '../../../../componentsV2/VideoPlayer';
import { BREAKPOINTS } from '../../../../config/Constants';

export default function Authentication(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'authentication' });
  const [url, setUrl] = useState<string>('');
  const { getAccount } = useAccount();
  const { promo_id, setZapierToken } = useZapierContext();

  const { loading: loadingClientId } = useQuery<{
    zapierClientId: { client_id: string };
  }>(ZAPIER_CLIENT_ID_GQL, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      console.log('[ZAPIER_CLIENT_ID_GQL]:', error);
    },
    onCompleted: (response) => {
      const parameterEncoded = window.btoa(
        JSON.stringify({
          env: REACT_APP_ENVIRONMENT,
          ...(promo_id && {
            promotion: {
              slug: getAccount.accountSlug,
              promo_id,
            },
          }),
        }),
      );

      setUrl(
        `${REACT_APP_ZAPIER_AUTHORIZE}?client_id=${response.zapierClientId.client_id}&redirect_uri=${REACT_APP_ZAPIER_REDIRECT_URI}?p=${parameterEncoded}&scope=zap&response_type=token`,
      );
    },
  });

  const openZapierPopup = () => {
    if (REACT_APP_API_MOCKING) {
      setZapierToken('123');
    } else {
      openPopupCenter(url, 'zapier-popup', 600, 680);
    }
  };

  const setZapierAuth = (e: MessageEvent) => {
    if (e.data.type === ZapierWindowMessageType.ACCESS_TOKEN) {
      setZapierToken(e.data.accessToken);
    }
  };

  useEffect(() => {
    window.addEventListener('message', setZapierAuth);
    return () => {
      window.removeEventListener('message', setZapierAuth);
    };
  }, []);

  return (
    <SectionCollapsibleFull
      expanded={true}
      header={{ title: t('Connect BeeLiked to your Zapier and automate your tasks') }}
      badge={{ status: StatusBadge.icon, icon: { name: 'groups', color: 'enabled' } }}
      disableCollapse
      {...(loadingClientId && { wrapperClassname: styles['wrapper-integration'] })}
    >
      {loadingClientId ? (
        <SpinnerBox />
      ) : (
        <div className={styles['content-box']}>
          <Paragraph size={2}>
            {t(
              'Zapier lets you connect BeeLiked with thousands of the most popular apps, so you can automate your work and have more time for what matters most—no code required.',
            )}
          </Paragraph>
          <div className={styles['wrapper-connect']}>
            <VideoPlayer
              urlVideo="https://player.vimeo.com/video/911165895"
              urlThumbnail={`${REACT_APP_ASSETS_IMAGES}thumb-zapier.jpg`}
              altThumbnail="A video teaching about our integration feature."
              size={[
                { breakpoint: BREAKPOINTS.SM, width: 650 },
                { breakpoint: BREAKPOINTS.XS, width: 300 },
              ]}
            />
            <Button firstIcon={{ children: 'lan' }} size="md" variant="primary" onClick={openZapierPopup}>
              {t('Connect to Zapier')}
            </Button>
          </div>
        </div>
      )}
    </SectionCollapsibleFull>
  );
}
