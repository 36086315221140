import { useEffect, useRef, useState } from 'react';
import styleModuleClasses from '../../../../../utils/styleModuleClasses';
import Icon from '../../../Icon';
import Overlay from '../../../Overlay';
import Pills from '../../../Pills';
import { Tags } from '../../../Tags';
import { TagsColor } from '../../../Tags/interface';
import { FontWeight, Paragraph } from '../../../Typography';
import Input from '../../Input';
import { OptionsProps, SelectMultiProps } from '../interface';
import styles from './styles.module.scss';

export default function SelectMulti({
  display = 1,
  label,
  placeholder,
  onChange,
  selectValue,
  pill,
  options,
  optionsAsPills,
  fullWidth,
}: SelectMultiProps): JSX.Element {
  const input = useRef<HTMLInputElement>(null);
  const ul = useRef<HTMLUListElement>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [newOptions, setNewOptions] = useState<OptionsProps[]>([]);

  useEffect(() => {
    if (options && options.length > 0) {
      setNewOptions(options);
    }
  }, [options]);

  const handleSelect = (value: string | undefined) => {
    if (value) {
      const newArray = [...selectValue];
      if (newArray.includes(value)) {
        onChange(newArray.filter((object) => object !== value));
      } else {
        onChange([...newArray, value]);
      }
    }
  };

  const handleFilterOptions = (e: string) => {
    const originalOptions = [...options];
    const filteredOptions = originalOptions.filter((option) =>
      option.label?.toLocaleLowerCase().includes(e.toLocaleLowerCase()),
    );
    setNewOptions(filteredOptions);
  };

  return (
    <div className={styleModuleClasses(styles, 'dropdown-wrapper', { fullwidth: fullWidth })}>
      {label && (
        <label>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            {label}
          </Paragraph>
        </label>
      )}
      {optionsAsPills ? (
        <div className={styles['wrapper-input-with-pill']} onClick={() => setShowOptions(true)}>
          {!!selectValue.length ? (
            <>
              {selectValue.slice(0, display).map((value, index) => (
                <Tags key={index} color={TagsColor.primary} size="sm">
                  {options.find((option) => option.value == value)?.label ?? ''}
                </Tags>
              ))}

              {selectValue.length > display && (
                <Tags color={TagsColor.gray} size="sm">{`+${selectValue.length - display}`}</Tags>
              )}
            </>
          ) : (
            <Paragraph size={1}>{placeholder}</Paragraph>
          )}

          <Icon>expand_more</Icon>
        </div>
      ) : (
        <div className={styles['wrapper-select']}>
          <input
            ref={input}
            placeholder={placeholder}
            className={styles['select-dropdown']}
            value={placeholder}
            readOnly
            onFocus={() => setShowOptions(true)}
          />
          {!!pill?.children && <Pills {...pill} />}
          <Icon>expand_more</Icon>
        </div>
      )}
      {showOptions &&
        (optionsAsPills ? (
          <>
            <div className={styles['dropdown-pill-select']}>
              <Input placeholder="Search" onChange={(e) => handleFilterOptions(e.target.value)} />
              <ul ref={ul} className={styleModuleClasses(styles, { focus: showOptions })}>
                {newOptions.map((outcome) => (
                  <li value={outcome.value} key={outcome.value} onClick={() => handleSelect(outcome.value?.toString())}>
                    <button
                      className={
                        outcome.value && selectValue.includes(outcome.value.toString()) ? styles['active'] : ''
                      }
                    >
                      <Paragraph size={3} weight={FontWeight.semiBold}>
                        {outcome.label}
                      </Paragraph>
                      {outcome.value && selectValue.includes(outcome.value.toString()) && (
                        <div>
                          <Icon>check</Icon>
                          <Icon>close</Icon>
                        </div>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <Overlay callback={() => setShowOptions(false)} />
          </>
        ) : (
          <div className={styles['dropdown-select']}>
            <ul ref={ul} className={styleModuleClasses(styles, { focus: showOptions })}>
              {options.map((outcome) => (
                <li value={outcome.value} key={outcome.value} onClick={() => handleSelect(outcome.label)}>
                  <input type="checkbox" checked={selectValue.some((object) => outcome.value === object)} readOnly />
                  {outcome.label}
                </li>
              ))}
            </ul>
            <Overlay callback={() => setShowOptions(false)} />
          </div>
        ))}
    </div>
  );
}
