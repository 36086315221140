/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import Icon from '../../../../../../componentsV2/DesignSystem/Icon';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { FontWeight } from '../../../../../../componentsV2/DesignSystem/Typography/interface';
import { passwordConfirmation, validatePassword } from '../../../../../../utils/validations';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import useSignupContext, { Steps } from '../../SignupContextProvider';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

export default function StepSetPassword(): JSX.Element {
  const { animations, changeStep, userData, updateUser } = useSignupContext();
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState({ password: true, passwordConfirmation: true });
  const [passwords, setPasswords] = useState({ password: '', passwordConfirmation: '' });
  const [passwordRules, setPasswordRules] = useState({
    size: false,
    upper: false,
    lower: false,
    special: false,
    equal: false,
  });

  const handlePassword = (value: string) => {
    const { rules } = validatePassword(value, passwords, passwordRules);
    setPasswords({ ...passwords, password: value });
    setPasswordRules({ ...rules });
  };

  const handlepasswordConfirmation = (value: string) => {
    const { rules } = passwordConfirmation(value, passwords, passwordRules);
    setPasswords({ ...passwords, passwordConfirmation: value });
    setPasswordRules({ ...rules });
  };

  const nextStep = (e: any) => {
    e.preventDefault();
    updateUser({ ...userData, ...passwords });
    setLoading(true);
    setTimeout(() => {
      if (Object.values(passwordRules).indexOf(false) < 0) {
        changeStep(Steps.terms, 'forward');
      }
      setLoading(false);
    }, 500);
  };

  const previousStep = () => {
    changeStep(Steps.setPassword, 'back');
  };

  return (
    <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.setPassword])}>
      <IconAnimated size="md" icon={{ name: 'lock' }} wrapper={{ color: 'primary' }} />
      <Heading size={3}>Set your password</Heading>
      <Heading size={6} weight={FontWeight.semiBold}>
        Create a secure password for your account
      </Heading>
      <form onSubmit={(e) => nextStep(e)}>
        <Input
          value={passwords.password}
          onChange={(e) => handlePassword(e.target.value)}
          type={types.password ? 'password' : 'text'}
          label="Password"
          autoComplete="off"
          placeholder="Your password"
          icon={{
            name: types.password ? 'visibility' : 'visibility_off',
            onClick: () => setTypes({ ...types, password: !types.password }),
            style: { marginRight: '.25rem' },
          }}
        />
        <Input
          value={passwords.passwordConfirmation}
          onChange={(e) => handlepasswordConfirmation(e.target.value)}
          type={types.passwordConfirmation ? 'password' : 'text'}
          label="Confirm your password"
          placeholder="Confirm your password"
          autoComplete="off"
          disabled={
            !(passwordRules.lower && passwordRules.size && passwordRules.special && passwordRules.upper) &&
            passwords.passwordConfirmation.length == 0
          }
          icon={{
            name: types.passwordConfirmation ? 'visibility' : 'visibility_off',
            onClick: () => setTypes({ ...types, passwordConfirmation: !types.passwordConfirmation }),
            style: { marginRight: '.25rem' },
          }}
        />
        <ul className={styles['password-rules-wrapper']}>
          <li className={passwordRules.size ? styles['approved'] : ''}>
            <Icon>{passwordRules.size ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 8 characters</Paragraph>
          </li>
          <li className={passwordRules.upper ? styles['approved'] : ''}>
            <Icon>{passwordRules.upper ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 in uppercase</Paragraph>
          </li>
          <li className={passwordRules.lower ? styles['approved'] : ''}>
            <Icon>{passwordRules.lower ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 in lowercase</Paragraph>
          </li>
          <li className={passwordRules.special ? styles['approved'] : ''}>
            <Icon>{passwordRules.special ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 special character (allowed: @#!$*&?)</Paragraph>
          </li>
          <li className={passwordRules.equal ? styles['approved'] : ''}>
            <Icon>{passwordRules.equal ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Passwords must be equal</Paragraph>
          </li>
        </ul>
        <div className={styles['wrapper-buttons']}>
          <Button fullWidth type="submit" loading={loading} disabled={Object.values(passwordRules).indexOf(false) >= 0}>
            Continue
          </Button>
          <Button fullWidth variant="tertiary" firstIcon={{ children: 'keyboard_backspace' }} onClick={previousStep}>
            Previous step
          </Button>
        </div>
      </form>
    </div>
  );
}
