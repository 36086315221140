import { graphql } from 'msw';

const fields = graphql.query('fields', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      fields: {
        data: [
          {
            id: 7,
            name: 'First name',
            base_field: 'first_name',
            slug: 'first-name',
          },
          {
            id: 8,
            name: 'Last name',
            base_field: 'last_name',
            slug: 'last-name',
          },
          {
            id: 9,
            name: 'Email',
            base_field: 'email',
            slug: 'email',
          },
          {
            id: 10,
            name: 'Newsletter',
            base_field: 'newsletter',
            slug: 'newsletter',
          },
          {
            id: 11,
            name: 'Terms and Conditions',
            base_field: 'terms',
            slug: 'terms-and-conditions',
          },
          {
            id: 12,
            name: 'Picture',
            base_field: 'picture',
            slug: 'picture',
          },
          {
            id: 20,
            name: 'Entry Link UID',
            base_field: 'entrylinksuid',
            slug: 'entry-link-uid',
          },
        ],
      },
    }),
  );
});

export default [fields];
