import React from 'react';
import Skeleton from '../../../../../componentsV2/DesignSystem/Skeleton';
import { SkeletonLoadingProps } from './interface';
import styles from './styles.module.scss';

export default function SkeletonLoading({ totalItems }: SkeletonLoadingProps): JSX.Element {
  return (
    <>
      {Array.from({ length: totalItems }).map((_, index) => (
        <div className={styles['coupon-wrapper']} key={index}>
          <div>
            <Skeleton height={1} width={20} />
            <Skeleton height={1} width={30} />
          </div>
          <div>
            <Skeleton height={0.25} rounded={0} />
          </div>
        </div>
      ))}
    </>
  );
}
