import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Zapier from './Zapier';

export default function Credentials(): JSX.Element {
  return (
    <Switch>
      <Route exact path="/credentials/zapier" component={Zapier} />
    </Switch>
  );
}
