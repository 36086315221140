import { ApolloError, gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { paginatorInfoFragment } from '../../../../../components/Pagination/paginator.gql';
import { SimplePaginationInfo, paginatorInfo } from '../../../../../ts';

export interface Widget {
  id: number;
  account_id: number;
  promo_id: number;
  name: string;
  slug: string;
  image_file: string;
  header_message: string;
  header_message_font_color: string;
  message1: string;
  message1_font_color: string;
  message2: string;
  message2_font_color: string;
  expire_time: number;
  widget_background: string;
  page_background: string;
  created_at: string;
  updated_at: string;
  widget_users_count: number;
  promotion_name: string;
}

export type RefetchWidget = (variables: { accountId: number }) => void;

const WIDGETS = gql`
  query widgets($accountId: ID!, $page: Int) {
    widgets(accountId: $accountId, page: $page) {
      data {
        id
        account_id
        promo_id
        name
        slug
        image_file
        header_message
        header_message_font_color
        message1
        message1_font_color
        message2
        message2_font_color
        expire_time
        widget_background
        page_background
        created_at
        updated_at
        widget_users_count
        promotion_name
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export function useFetchWidgets(
  accountId: number,
  currentPage?: number,
  limit?: number,
): {
  loading: boolean;
  data: Widget[] | null;
  error?: string;
  pagination: SimplePaginationInfo;
  refetch?: RefetchWidget;
} {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: limit || 10,
  });

  const { data, loading, error, refetch } = useQuery<{
    widgets: { data: Widget[]; paginatorInfo: paginatorInfo };
  }>(WIDGETS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      page: currentPage,
      limit,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'WIDGETS',
        variables: {
          accountId,
        },
        apolloError: error,
      });
    },
  });

  useEffect(() => {
    if (data?.widgets.paginatorInfo) {
      setPagination({
        total: data.widgets.paginatorInfo.total,
        currentPage: data.widgets.paginatorInfo.current_page,
        totalPerPage: Number(data.widgets.paginatorInfo.per_page),
      });
    }
  }, [data?.widgets.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: null,
      pagination,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error: error.message,
      pagination,
    };
  }

  return {
    loading: false,
    data: data?.widgets?.data || null,
    pagination,
    refetch,
  };
}
