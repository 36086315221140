import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { FileUploadProps } from '../../../../../components/DragDropUpload';
import Breadcrumb from '../../../../../componentsV2/DesignSystem/Breadcrumb';
import SectionCollapsibleFull from '../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../componentsV2/DesignSystem/Sections/interface';
import SpinnerBox from '../../../../../componentsV2/DesignSystem/SpinnerBox';
import useFetchPromotionsSimpleList from '../../../../../graphql/queries/useFetchPromotionsSimpleList';
import useAccount from '../../../../../hooks/useAccount';
import useToast from '../../../../../hooks/useToast';
import { PromotionVisibility } from '../../../../../ts';
import { useMutationCreateWidget } from '../useMutationCreateWidget';
import ValidationResolver from './ValidationResolver';
import WidgetForm from './WidgetForm';
import WidgetUsers from './WidgetUsers';
import { SectionsStateProps, WidgetInput } from './interface';
import styles from './styles.module.scss';
import { useFetchWidget } from './useFetchWidget';
import { useMutationUpdateWidget } from './useMutationUpdateWidget';

export default function CreateWidget(): JSX.Element {
  const [file, setFile] = useState<FileUploadProps>({ name: '', file: null });
  const [imageUrl, setImageUrl] = useState<string>();
  const [saved, setSaved] = useState<boolean>(false);
  const [sectionsStatus, setSectionsStatus] = useState<SectionsStateProps>({
    widget: {
      badge: { status: StatusBadge.enabled, icon: { name: 'done', color: 'green' }, number: 1 },
      opened: true,
      disableCollapse: false,
    },
    userPermission: {
      badge: { status: StatusBadge.disabled, icon: { name: 'done', color: 'green' }, number: 2 },
      opened: false,
      disableCollapse: true,
    },
  });
  const defaultLogoImage = 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/beeliked-logo-widget.svg';

  const history = useHistory();
  const { getAccount } = useAccount();
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();

  const { data: promotionData, loading: promotionLoading } = useFetchPromotionsSimpleList(getAccount.accountId);
  const { createWidget, data: dataWidgetCreated, loading: loadingWidgetCreated } = useMutationCreateWidget();
  const { updateWidget, data: dataWidgetUpdated, loading: loadingWidgetUpdated } = useMutationUpdateWidget();
  const { data, loading } = useFetchWidget(getAccount.accountId, Number(id));

  const methods = useForm<WidgetInput>({
    resolver: ValidationResolver,
    defaultValues: {
      id: undefined,
      name: '',
      promo_id: undefined,
    },
  });

  const { handleSubmit, setValue, watch } = methods;

  const [promo_id] = watch(['promo_id']);

  const promotionVisibility = useMemo(() => {
    if (promo_id && promotionData && promotionData.length > 0) {
      const promotion = promotionData.find((promotion) => promotion.id === promo_id);
      return promotion?.visibility;
    }
  }, [promo_id, promotionData]);

  useEffect(() => {
    if (dataWidgetCreated?.id) {
      toast('success', 'Saved Successfully');
      setValue('id', dataWidgetCreated?.id);
      setSaved(true);
      updateSection();
      history.push(`create-widget/${dataWidgetCreated.id}`);
    }
  }, [dataWidgetCreated]);

  useEffect(() => {
    if (dataWidgetUpdated?.id) {
      if (dataWidgetUpdated.image_file) {
        setImageUrl(dataWidgetUpdated.image_file);
      }
      toast('success', 'Updated Successfully');
      setSaved(true);
      updateSection();
      setValue('slug', dataWidgetUpdated.slug);
    }
  }, [dataWidgetUpdated]);

  useEffect(() => {
    if (!id) {
      setDefaultValues(
        'Enter our Spin to Win promotion',
        '#FFFFFF',
        'Scan to enter',
        '#FFFFFF',
        'Your QR will expire in',
        '#FFFFFF',
        60,
        '#0582EF',
        '#FFFFFF',
      );
    }
  }, [id]);

  // Load data
  useEffect(() => {
    if (data) {
      setValue('id', data.id);
      setValue('name', data.name);
      setValue('slug', data.slug);

      if (data.promo_id) {
        setValue('promo_id', data.promo_id);

        setDefaultValues(
          data.header_message,
          data.header_message_font_color,
          data.message1,
          data.message1_font_color,
          data.message2,
          data.message2_font_color,
          data.expire_time,
          data.widget_background,
          data.page_background,
          data?.image_file,
        );

        updateSection();
      } else {
        setDefaultValues(
          'Enter our Spin to Win promotion',
          '#FFFFFF',
          'Scan to enter',
          '#FFFFFF',
          'Your QR will expire in',
          '#FFFFFF',
          60,
          '#0582EF',
          '#FFFFFF',
        );
      }
    }
  }, [data]);

  const widgetName = useMemo(() => {
    return data?.name || 'New Widget';
  }, [data]);

  const setDefaultValues = (
    header_message?: string,
    header_message_font_color?: string,
    message1?: string,
    message1_font_color?: string,
    message2?: string,
    message2_font_color?: string,
    expire_time?: number,
    widget_background?: string,
    page_background?: string,
    image_file?: string,
  ) => {
    setValue('header_message', header_message);
    setValue('header_message_font_color', header_message_font_color);
    setValue('message1', message1);
    setValue('message1_font_color', message1_font_color);
    setValue('message2', message2);
    setValue('message2_font_color', message2_font_color);
    setValue('expire_time', expire_time);
    setValue('widget_background', widget_background);
    setValue('page_background', page_background);
    setImageUrl(image_file || defaultLogoImage);
  };

  const updateSectionOpened = (
    key: keyof SectionsStateProps,
    opened: boolean,
    status: StatusBadge,
    disableCollapse?: boolean,
  ) => {
    setSectionsStatus((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        badge: {
          ...prevState[key].badge,
          status,
        },
        opened,
        ...(disableCollapse !== undefined && { disableCollapse }),
      },
    }));
  };

  const updateSection = () => {
    updateSectionOpened('widget', true, StatusBadge.icon, false);
    updateSectionOpened('userPermission', true, StatusBadge.enabled, false);
  };

  const onSubmit = handleSubmit(async (variables) => {
    const dataWidget = { ...variables };

    if (variables.id) {
      delete dataWidget.id;
      if (file.file) {
        const imageBase64 = await getBase64(file.file);
        dataWidget.image_file = imageBase64;
      }

      updateWidget({
        accountId: getAccount.accountId,
        widgetId: variables.id,
        data: dataWidget,
      });
    } else {
      if (file.file) {
        const imageBase64 = await getBase64(file.file);
        dataWidget.image_file = imageBase64;
      }
      createWidget({
        accountId: getAccount.accountId,
        data: dataWidget,
      });
    }
  });

  const cancel = () => {
    history.push(`/share-promotions/share-widgets`);
  };

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('The FileReader result is not a string.'));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Breadcrumb backToUrl="/share-center/display-widget" items={['Widgets', widgetName]} />
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} noValidate>
          <SectionCollapsibleFull
            badge={sectionsStatus.widget.badge}
            expanded={sectionsStatus.widget.opened}
            header={{ title: 'Create a widget to show your users QR Codes' }}
            containerClassname={styles.container}
            {...(!loading && {
              footer: {
                leftButton: {
                  children: 'Cancel',
                  variant: 'secondary',
                  size: 'sm',
                  onClick: cancel,
                  disabled: loadingWidgetCreated || loadingWidgetUpdated,
                },
                rightButton: {
                  children: 'Save',
                  variant: 'primary',
                  size: 'sm',
                  type: 'submit',
                  loading: loadingWidgetCreated || loadingWidgetUpdated,
                  disabled: loadingWidgetCreated || loadingWidgetUpdated,
                },
              },
            })}
          >
            <>
              {promotionLoading || (loading && !saved) ? (
                <div className={styles['spinner-container']}>
                  <SpinnerBox />
                </div>
              ) : (
                <WidgetForm
                  file={file}
                  setFile={setFile}
                  imageUrl={imageUrl}
                  promotionData={promotionData}
                  promotionVisibility={promotionVisibility}
                />
              )}
            </>
          </SectionCollapsibleFull>
        </form>
      </FormProvider>

      {promotionVisibility === PromotionVisibility.PRIVATE && (
        <WidgetUsers sectionsStatus={sectionsStatus.userPermission} widgetId={data?.id} widgetSlug={data?.slug} />
      )}
    </>
  );
}
