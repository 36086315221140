import { ApolloClient, ApolloLink, FetchResult, InMemoryCache, Observable } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { getRefreshAuthCookie, removeAuthCookie, setAuthCookie } from '../../utils/authCookie';
import { removeZapierAccessToken } from '../../utils/zapierStorage';
import { REACT_APP_SENTRY_DSN, REACT_APP_X_API_KEY_GRAPHQL } from '../Env';
import { GET_REFRESH_TOKEN_GQL } from '../Graphql/auth.gql';
import httpLink from './HttpLink';

const getNewToken = async () => {
  const refresh_token = getRefreshAuthCookie();

  if (!refresh_token) {
    throw new Error('No refresh token available');
  }

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'x-api-key': REACT_APP_X_API_KEY_GRAPHQL,
      },
    });
    return forward(operation);
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authMiddleware.concat(httpLink),
  });

  try {
    const response = await apolloClient.mutate({
      mutation: GET_REFRESH_TOKEN_GQL,
      variables: { refresh_token },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to refresh token');
  }
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }: ErrorResponse) => {
  if (graphQLErrors && operation.operationName !== 'login') {
    const unauthorized = graphQLErrors.some(
      (error) =>
        error.message.toUpperCase().includes('FORBIDDEN') ||
        error.message.toUpperCase().includes('UNAUTHENTICATED') ||
        error.message.toUpperCase().includes('NOT AUTHENTICATED') ||
        error.message.toUpperCase().includes('INVALID TOKEN SPECIFIED') ||
        error.message.toUpperCase().includes('UNAUTHORIZED'),
    );

    if (unauthorized) {
      return new Observable<FetchResult>((observer) => {
        (async () => {
          try {
            const accessToken = await getNewToken();

            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${accessToken.refresh.access_token}`,
              },
            });
            setAuthCookie(accessToken.refresh);

            forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          } catch (error) {
            Sentry.captureException(new Error('Refresh Token Error'), (scope) => {
              scope.setExtra('operationName', operation.operationName);
              scope.setExtra('variables', JSON.stringify(operation.variables));
              scope.setExtra('apolloError', JSON.stringify(graphQLErrors[0]?.message));
              scope.setExtra('Exception Error', JSON.stringify(error));
              return scope;
            });

            console.log('Connection lost, please try logging in again!');

            removeAuthCookie();
            removeZapierAccessToken();
            const location = window.location.pathname;
            const redirect = location ? `?redirect=${location}` : '';
            window.location.href = `/signin${redirect}`;
          }
        })();
      });
    }
  }

  if (networkError) {
    if (REACT_APP_SENTRY_DSN) {
      Sentry.captureException(new Error('Network Error'), (scope) => {
        scope.setExtra('operationName', operation.operationName);
        scope.setExtra('variables', JSON.stringify(operation.variables));
        scope.setExtra('Error', JSON.stringify(networkError));
        return scope;
      });
    }
    console.log(`[Network Error]: ${networkError}`);
  }
});

export default errorLink;
