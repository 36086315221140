import React from 'react';
import { InframeContainer } from './interface';

export default function IframeContainer({ src, allow }: InframeContainer): JSX.Element {
  return (
    <div className="iframe-container">
      <iframe src={src} allowFullScreen={allow} />
    </div>
  );
}
