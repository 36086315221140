import { useRef, useState } from 'react';
import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { InviteCreationMethod } from '../../../../../../ts';
import useShareLinks from '../../ShareLinksProvider';
import CreateAnonymous from '../CreateAnonymous';
import { CreateAnonymousRef } from '../CreateAnonymous/interface';
import CreatePersonalized from '../CreatePersonalized';
import { AdvancedOptions } from '../CreatePersonalized/AdvancedOptions';
import ChooseVisibility from './ChooseVisibility';
import styles from './styles.module.scss';

export default function KindInviteLinks(): JSX.Element {
  const anonymousSubmitRef = useRef<CreateAnonymousRef>(null);
  const [loadingAnonymous, setLoadingAnonymous] = useState<boolean>(false);
  const {
    inviteMethod,
    inviteMethodPersonalized,
    setExecuteSingleForm,
    loadingPersonalized,
    generatorId,
    setExecuteInBulkForm,
    setClearFields,
    getSectionValue,
  } = useShareLinks();

  const kindOfInviteLinks = getSectionValue('kindOfInviteLinks');

  const clearFields = () => {
    setClearFields(Math.floor(Math.random() * 100));
  };

  const nextSection = () => {
    if (inviteMethod === InviteCreationMethod.PERSONALIZED) {
      if (inviteMethodPersonalized === 'individually') {
        setExecuteSingleForm(true);
      } else if (inviteMethodPersonalized === 'inbulk') {
        if (generatorId) {
          setExecuteInBulkForm(true);
        }
      }
    } else if (inviteMethod === InviteCreationMethod.ANONYMOUS) {
      if (anonymousSubmitRef.current) {
        setLoadingAnonymous(true);
        anonymousSubmitRef.current.submitForm();
      }
    }
  };

  return (
    <SectionCollapsibleFull
      badge={kindOfInviteLinks.badge}
      expanded={kindOfInviteLinks.expanded}
      header={{ title: 'Which kind of InviteLinks' }}
      containerClassname={styles['container-section']}
      {...((inviteMethodPersonalized !== null || inviteMethod === InviteCreationMethod.ANONYMOUS) && {
        footer: {
          leftButton: { children: 'Cancel', variant: 'secondary', size: 'sm', onClick: clearFields },
          rightButton: {
            children: 'Generate',
            type: 'submit',
            size: 'sm',
            onClick: nextSection,
            loading: loadingPersonalized || loadingAnonymous,
            disabled: loadingPersonalized || loadingAnonymous,
          },
        },
      })}
    >
      <ChooseVisibility />
      {inviteMethod && (
        <div className={styles['divider']}>
          {inviteMethod === InviteCreationMethod.PERSONALIZED ? (
            <CreatePersonalized />
          ) : (
            <CreateAnonymous
              ref={anonymousSubmitRef}
              loadingAnonymous={loadingAnonymous}
              setLoadingAnonymous={setLoadingAnonymous}
            />
          )}
          <AdvancedOptions />
        </div>
      )}
    </SectionCollapsibleFull>
  );
}
