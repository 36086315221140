import React from 'react';
import styles from './styles.module.scss';
import CouponCollections from './CardsDashboard/CouponCollections';
import Promotions from './CardsDashboard/Promotions';
import BeeSpoke from './CardsDashboard/BeeSpoke';
import CustomerStories from './CardsDashboard/CustomerStories';
import News from './CardsDashboard/News';
import Videos from './CardsDashboard/Videos';

export default function Dashboard(): JSX.Element {
  return (
    <div className={styles['card-wrapper']}>
      <Promotions />
      <CouponCollections />
      <BeeSpoke />
      <CustomerStories />
      <Videos />
      <News />
    </div>
  );
}
