import { ApolloError, gql, useMutation } from '@apollo/client';

const CREATE_PLUGIN = gql`
  mutation saveRewardsPlugin($accountId: ID!, $data: RewardsPluginInputData) {
    saveRewardsPlugin(accountId: $accountId, data: $data) {
      id
      name
      brand_color
      primary_color
      background_color
      typography
      image_file
      image_align
      greeting_message
      heading_message
      description_message
      countdown_text_message
      days
      hours
      minutes
      cta_text_message
      powered_by_blkd
      embed_type
      disable_floating_action_button
    }
  }
`;

interface CreatePluginResult {
  id?: number;
  name: string;
  brand_color?: string;
  primary_color?: string;
  background_color?: string;
  typography?: string;
  image_file?: string;
  image_align?: string;
  greeting_message?: string;
  heading_message?: string;
  description_message?: string;
  countdown_text_message?: string;
  days?: string;
  hours?: string;
  minutes?: string;
  cta_text_message?: string;
  powered_by_blkd?: boolean;
  embed_type?: string;
  disable_floating_action_button?: boolean;
}

export interface CreatePluginDataVariables {
  name: string;
  image_file?: string;
}

export function useMutationCreatePlugin(): {
  loading: boolean;
  saveRewardsPlugin: (variables: { accountId: number; data: CreatePluginDataVariables }) => void;
  data: CreatePluginResult | null;
} {
  const [createPluginMutation, { loading, data }] = useMutation<{ saveRewardsPlugin: CreatePluginResult }>(
    CREATE_PLUGIN,
    {
      onError: (error: ApolloError) => {
        console.log({
          gql: 'CREATE_PLUGIN',
          apolloError: error,
        });
      },
    },
  );

  const saveRewardsPlugin = (variables: { accountId: number; data: CreatePluginDataVariables }) => {
    createPluginMutation({ variables });
  };

  return {
    loading,
    saveRewardsPlugin,
    data: data?.saveRewardsPlugin || null,
  };
}
