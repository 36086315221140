import React from 'react';
import Skeleton from '../../../../../componentsV2/DesignSystem/Skeleton';
import styles from './styles.module.scss';

export default function SkeletonLoading(): JSX.Element {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <div className={styles['card-news-skeleton']} key={`skeleton-${index}`}>
          <aside>
            <Skeleton width={100} />
          </aside>
          <main>
            <Skeleton width={25} height={1} />
            <Skeleton width={100} height={1} />
            <Skeleton width={100} height={0.75} />
            <Skeleton width={80} height={0.75} />
          </main>
        </div>
      ))}
    </>
  );
}
