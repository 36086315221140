import { Redirect } from 'react-router-dom';
import SectionCollapsibleFull from '../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { useUpgradePlanPermissionSectionCollapsibleButton } from '../../../../../componentsV2/DesignSystem/Sections/hooks/useUpgradePlanPermissionSectionCollapsibleButton';
import { StatusBadge } from '../../../../../componentsV2/DesignSystem/Sections/interface';
import SpinnerBox from '../../../../../componentsV2/DesignSystem/SpinnerBox';
import useAccount from '../../../../../hooks/useAccount';
import { usePlanPermissions } from '../../../../../providers/PlanPermissionsProvider';
import Content from './Content';
import Empty from './Empty';
import styles from './styles.module.scss';
import { useFetchPlugins } from './useFetchPlugins';

export default function RewardsPluginList(): JSX.Element {
  const { getAccount } = useAccount();
  const { planPermissionByParameter } = usePlanPermissions();
  const permission = planPermissionByParameter('rewards_plugin');
  const { showButton, showButtonPopover } = useUpgradePlanPermissionSectionCollapsibleButton(permission);

  const { data, loading, refetch } = useFetchPlugins(getAccount.accountId);

  if (data && data.id) {
    return <Redirect to={`/share-center/rewards-plugin/create-plugin/${data.id}`} />;
  }

  return (
    <SectionCollapsibleFull
      badge={{ icon: { name: 'featured_seasonal_and_gifts', color: 'enabled' }, status: StatusBadge.icon }}
      expanded={true}
      header={{ title: loading ? 'Loading...' : data ? 'Rewards Plugin' : 'Create a Rewards Plugin' }}
      showButton={showButton}
      showButtonPopover={showButtonPopover}
    >
      {loading ? (
        <div className={styles['spinner-container']}>
          <SpinnerBox />
        </div>
      ) : (
        <>
          {!data || !permission.hasPermission ? (
            <Empty hasPermission={permission.hasPermission || false} allowedPlans={permission.allowedPlans} />
          ) : (
            <Content plugins={data} refetch={refetch} />
          )}
        </>
      )}
    </SectionCollapsibleFull>
  );
}
