import { REACT_APP_MONOLITH_V2 } from '../config/Env';

const replaceMonolithUrl = (text: string, accountSlug?: string): string => {
  let textUpdate = text;

  const searchString = '<a href="https://';
  const prefixIndex = text.indexOf(searchString);

  if (prefixIndex > -1) {
    const searchStringLength = searchString.length;
    const prefix = text.substring(0, prefixIndex + searchStringLength - 8);
    const textSufix = text.substring(prefixIndex + searchStringLength);
    const sufix = textSufix.substring(textSufix.indexOf('/'), text.length);
    textUpdate = prefix + REACT_APP_MONOLITH_V2 + '/v1/' + (accountSlug || '') + sufix;
  }

  return textUpdate;
};

export default replaceMonolithUrl;
