import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { IconButton } from '../../../../../../componentsV2/DesignSystem/Inputs/IconButton';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import { Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { REACT_APP_HIVE_URL } from '../../../../../../config/Env';
import useAccount from '../../../../../../hooks/useAccount';
import generateSequenceNumber from '../../../../../../utils/generateSequenceNumber';
import ValidationResolver from './ValidationResolver';
import { WidgetUserFormProps, WidgetUserInput } from './interface';
import styles from './styles.module.scss';
import { useMutationCreateWidgetUser } from './useMutationCreateWidgetUser';

export default function WidgetUserForm({
  toggleForm,
  widgetId,
  fetchWidgetUsers,
  widgetSlug,
}: WidgetUserFormProps): JSX.Element {
  const { getAccount } = useAccount();
  const { createWidgetUser, data, loading, error } = useMutationCreateWidgetUser();
  const [created, setCreated] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');

  const methods = useForm<WidgetUserInput>({
    resolver: ValidationResolver,
    defaultValues: {
      widgetId,
      username: '',
      password: '',
    },
  });

  const { handleSubmit, register, formState, setValue, setError } = methods;

  useEffect(() => {
    if (data?.id) {
      fetchWidgetUsers?.({
        accountId: getAccount.accountId,
        widgetId,
      });
      setPassword(data.password);
      setCreated(true);
    }
  }, [data]);

  useEffect(() => {
    if (error?.graphQLErrors?.[0].extensions?.code) {
      if (error?.graphQLErrors?.[0].extensions?.code === 'BAD_USER_INPUT') {
        const validations = error?.graphQLErrors?.[0].extensions?.validations as Record<string, string>;
        if (validations['username']) {
          setError('username', { message: validations['username'].toString(), type: 'validate' });
        }
      }
    }
  }, [error]);

  useEffect(() => {
    setNewPassword();
  }, []);

  const setNewPassword = () => {
    setValue('password', generateSequenceNumber());
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      event.preventDefault();
    }
  };

  const onSubmit = handleSubmit((variables) => {
    createWidgetUser?.({
      accountId: getAccount.accountId,
      ...variables,
    });
  });

  const closeCreateForm = () => {
    setPassword('');
    setCreated(false);
    toggleForm();
  };

  return (
    <>
      {!created ? (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} noValidate>
            <div className={styles['grid-container-form']}>
              <div className={styles['content-row']}>
                <div>
                  <Input
                    label="User Name"
                    placeholder="Type an user name"
                    inputSize="sm"
                    {...register('username')}
                    {...(formState.errors.username && {
                      helperText: formState.errors.username.message,
                      color: 'danger',
                    })}
                    onKeyPress={handleKeyPress}
                  />
                </div>
                <div>
                  <Input
                    label="PIN"
                    placeholder="Type a Pin"
                    inputSize="sm"
                    {...register('password')}
                    {...(formState.errors.password && {
                      helperText: formState.errors.password.message,
                      color: 'danger',
                    })}
                    icon={{
                      name: 'cached',
                      onClick: setNewPassword,
                    }}
                  />
                </div>
              </div>
              <div className={styles['content-button-row']}>
                <Button variant="secondary" onClick={toggleForm} size="sm" disabled={loading}>
                  Cancel
                </Button>
                <Button variant="primary" size="sm" type="submit" loading={loading} disabled={loading}>
                  Create
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        <div className={styles['staff-created']}>
          <div className={styles['btn-close']}>
            <IconButton onClick={closeCreateForm}>close</IconButton>
          </div>
          <div className={styles['icon-content']}>
            <IconAnimated wrapper={{ color: 'green' }} icon={{ name: 'check' }} />
          </div>
          <Paragraph size={2}>
            The Staff Link <span>London</span> has been created
          </Paragraph>
          <div className={styles['group-inputs']}>
            <Input
              label="Promotion Name"
              readOnly={true}
              inputSize="sm"
              value={`${REACT_APP_HIVE_URL}/${getAccount.accountSlug}/${widgetSlug}`}
              icon={{
                name: 'copy_all',
                onClick: () =>
                  navigator.clipboard.writeText(`${REACT_APP_HIVE_URL}/${getAccount.accountSlug}/${widgetSlug}`),
              }}
            />
            <Input
              label="Pin"
              value={password}
              icon={{
                name: 'copy_all',
                onClick: () => navigator.clipboard.writeText(`${password}`),
              }}
              readOnly={true}
              inputSize="sm"
            />
          </div>
        </div>
      )}
    </>
  );
}
