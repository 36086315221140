import { ChangeEvent, useState } from 'react';
import Alert from '../../../../componentsV2/DesignSystem/Alert';
import { Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import { UploadEmailImageProps } from './interface';
import useSendEmail from './SendEmailProvider';
import styles from './styles.module.scss';

export default function UploadEmailImage({ filename, setFile, extesions }: UploadEmailImageProps): JSX.Element {
  const { setImagePreviewUrl, imagePreviewUrl } = useSendEmail();
  const [errorValidatingExtension, setErrorValidatingExtension] = useState<string | undefined>();
  const extensionsString = extesions.map((ext) => `.${ext}`).join(',');

  const handleChangeUploadField = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorValidatingExtension(undefined);
    setImagePreviewUrl(null);
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const fileExtension = file.name.split('.').pop();
      if (!fileExtension || !extesions.includes(fileExtension)) {
        setErrorValidatingExtension('File extension is not allowed.');
        return false;
      }

      if (e.target?.files[0]?.size > 1 * 1000 * 1000) {
        setErrorValidatingExtension('File should be no larger than 6 mb.');
        return false;
      }

      setFile({
        name: file.name,
        file: file,
      });

      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
    }
  };

  return (
    <div className={styles['wrapper-upload']}>
      <div className={styles['upload']}>
        {!!errorValidatingExtension && <Alert color="danger-light">{errorValidatingExtension}</Alert>}

        {!!filename && imagePreviewUrl && (
          <div className={styles['preview']}>
            <img src={imagePreviewUrl} alt="preview" />
          </div>
        )}
        <div className={styles['center-button']}>
          <label>
            <input type="file" onChange={handleChangeUploadField} accept={extensionsString} />
            {!!filename ? 'Change Image' : 'Upload Image'}
          </label>
        </div>

        <Paragraph size={3} color="#949CB2">
          The image must be in JPG, PNG or SVG format.
        </Paragraph>
      </div>
    </div>
  );
}
