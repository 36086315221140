import React from 'react';
import classnames from 'classnames';
import { ZapierPopularAppData } from '../../../../../ts';
import useZapierContext from '../../ZapierContextProvider';
import styles from './styles.module.scss';

interface Props {
  app: ZapierPopularAppData;
}

export default function ItemApp({ app }: Props): JSX.Element {
  const { setAppSlug, appSlug } = useZapierContext();

  const setItemSlug = () => {
    setAppSlug(appSlug !== undefined && appSlug.slug === app.slug ? undefined : { name: app.name, slug: app.slug });
  };
  return (
    <div className={styles['item-app-card']}>
      <div
        className={classnames({
          'bg-primary bg-opacity-10': appSlug && app.slug === appSlug.slug,
        })}
        onClick={setItemSlug}
      >
        <div>
          <img src={app.images.url_32x32} />
        </div>
      </div>
    </div>
  );
}
