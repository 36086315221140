import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ButtonPopoverConfirm from '../../../../../../componentsV2/ButtonPopoverConfirm';
import ButtonNavigation from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../../../../hooks/useAccount';
import Thumb from './Thumb';
import { ItemProps } from './interface';
import styles from './styles.module.scss';
import { useMutationDeleteWidget } from './useMutationDeleteWidget';

export default function Item({ widget, refetch }: ItemProps): JSX.Element {
  const [disabled, setDisabled] = useState<boolean>(false);
  const { url } = useRouteMatch();
  const { getAccount } = useAccount();

  const { requestDeleteWidget, data, loading } = useMutationDeleteWidget();

  const handleDelete = (id: number) => {
    requestDeleteWidget?.(getAccount.accountId, id);
    setDisabled(true);
  };

  useEffect(() => {
    if (data?.id) {
      refetch?.({ accountId: getAccount.accountId });
    }
  }, [data]);

  return (
    <tr>
      <td>
        <div className={styles['widget-name']}>
          <Thumb
            header_message={widget.header_message}
            header_message_font_color={widget.header_message_font_color}
            message1={widget.message1}
            message1_font_color={widget.message1_font_color}
            message2={widget.message2}
            message2_font_color={widget.message2_font_color}
            expire_time={widget.expire_time}
            page_background={widget.page_background}
            widget_background={widget.widget_background}
            imageFile={
              widget.image_file ||
              'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/beeliked-logo-widget.svg'
            }
          />

          <Paragraph size={2} weight={FontWeight.semiBold}>
            {widget.name}
          </Paragraph>
        </div>
      </td>
      <td>
        <Paragraph size={2} weight={FontWeight.semiBold}>
          {widget.promotion_name}
        </Paragraph>
      </td>

      <td>
        <Paragraph size={2} weight={FontWeight.semiBold}>
          {widget.widget_users_count}
        </Paragraph>
      </td>

      <td className={styles['align-center']}>
        <div className={styles['align-buttons']}>
          <div>
            <ButtonNavigation
              firstIcon={{ children: 'edit' }}
              variant="secondary"
              to={`${url}/create-widget/${widget.id}`}
              size="sm"
              disabled={disabled}
            />
          </div>
          <div>
            <ButtonPopoverConfirm
              onClick={() => handleDelete(widget.id)}
              buttonProps={{ firstIcon: { children: 'delete', color: 'red-block' }, loading, disabled }}
              popoverProps={{
                header: {
                  title: 'Are you sure you wish to delete this?',
                },
              }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
