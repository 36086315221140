import { graphql } from 'msw';
import { add } from 'date-fns';
import jwt from 'jsonwebtoken';

const exp = add(new Date(), { minutes: 60 });

const PAYLOAD = {
  account_permissions: { 5290: 'account_admin' },
  acr: '1',
  agency_id: 1,
  'allowed-origins': ['http://api.beeliked.com'],
  aud: 'account',
  azp: 'agency_1_confidential',
  email: 'user@beeliked.com',
  email_verified: true,
  exp: Math.round(exp / 1000),
  family_name: 'User',
  given_name: 'Beeliked',
  iat: 1628164773,
  iss: 'http://keycloak:8080/auth/realms/agency_1',
  jti: '343160dc-1ed7-4505-a81a-10c678774db1',
  name: 'User Beeliked',
  preferred_username: 'user@beeliked.com',
  realm_access: {
    roles: ['account_user', 'super_admin', 'offline_access', 'uma_authorization'],
  },
  resource_access: {
    account: {
      roles: ['manage-account', 'manage-account-links', 'view-profile'],
    },
  },
  scope: 'profile email',
  session_state: 'd77e9b9c-c47a-4315-9fb2-2fc06180219f',
  sub: 'a2803f8d-d198-43ac-adbe-523922681dd4',
  typ: 'Bearer',
  user_id: 2762,
};

const login = graphql.mutation('login', (req, res, ctx) => {
  const access_token = jwt.sign(PAYLOAD, 'key');
  return res(
    ctx.delay(2000),
    ctx.data({
      login: {
        access_token,
        expires_in: 300,
        refresh_expires_in: 1800,
        refresh_token:
          'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJmYmY3ZDZmMi02ZWI3LTQ4MjYtOGRmNi0zMmM1NGJmMmE4MzMifQ.eyJleHAiOjE2MjgxNjY1NzMsImlhdCI6MTYyODE2NDc3MywianRpIjoiMjNiYjA0ZjctZTg1OC00OGIxLTk1YjAtYTVjZWVkOTNlYjAyIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrOjgwODAvYXV0aC9yZWFsbXMvYWdlbmN5XzEiLCJhdWQiOiJodHRwOi8va2V5Y2xvYWs6ODA4MC9hdXRoL3JlYWxtcy9hZ2VuY3lfMSIsInN1YiI6ImEyODAzZjhkLWQxOTgtNDNhYy1hZGJlLTUyMzkyMjY4MWRkNCIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJhZ2VuY3lfMV9jb25maWRlbnRpYWwiLCJzZXNzaW9uX3N0YXRlIjoiZDc3ZTliOWMtYzQ3YS00MzE1LTlmYjItMmZjMDYxODAyMTlmIiwic2NvcGUiOiJwcm9maWxlIGVtYWlsIn0.AFLgd9k6KiCO8u5uN0pAXtwgREPOWV0MhcQH23Qks_c',
        scope: 'profile email',
        session_state: 'd77e9b9c-c47a-4315-9fb2-2fc06180219f',
        token_type: 'Bearer',
      },
    }),
  );
});

const refresh = graphql.mutation('refresh', (req, res, ctx) => {
  const access_token = jwt.sign(PAYLOAD, 'key');
  return res(
    ctx.data({
      refresh: {
        access_token,
        expires_in: 300,
        refresh_expires_in: 1800,
        refresh_token:
          'eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJmYmY3ZDZmMi02ZWI3LTQ4MjYtOGRmNi0zMmM1NGJmMmE4MzMifQ.eyJleHAiOjE2MjgxNjY1NzMsImlhdCI6MTYyODE2NDc3MywianRpIjoiMjNiYjA0ZjctZTg1OC00OGIxLTk1YjAtYTVjZWVkOTNlYjAyIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrOjgwODAvYXV0aC9yZWFsbXMvYWdlbmN5XzEiLCJhdWQiOiJodHRwOi8va2V5Y2xvYWs6ODA4MC9hdXRoL3JlYWxtcy9hZ2VuY3lfMSIsInN1YiI6ImEyODAzZjhkLWQxOTgtNDNhYy1hZGJlLTUyMzkyMjY4MWRkNCIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJhZ2VuY3lfMV9jb25maWRlbnRpYWwiLCJzZXNzaW9uX3N0YXRlIjoiZDc3ZTliOWMtYzQ3YS00MzE1LTlmYjItMmZjMDYxODAyMTlmIiwic2NvcGUiOiJwcm9maWxlIGVtYWlsIn0.AFLgd9k6KiCO8u5uN0pAXtwgREPOWV0MhcQH23Qks_c',
        scope: 'profile email',
        session_state: 'd77e9b9c-c47a-4315-9fb2-2fc06180219f',
        token_type: 'Bearer',
      },
    }),
  );
});

export default [login, refresh];
