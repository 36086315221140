import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import PaginationItem from './PaginationItem';

interface Props {
  total: number;
  totalPerPage: number;
  currentPage: number;
  url: string;
  params?: Record<string, string>;
  hash?: string;
}

export default function Pagination({ total, totalPerPage, currentPage, url, params, hash }: Props): JSX.Element {
  const { t } = useTranslation('pagination');

  const queryString = params && new URLSearchParams(params).toString();

  const queryStringStart = queryString ? `?${queryString}` : '';
  const queryStringEnd = queryString ? `&${queryString}` : '';

  const totalPages = Math.ceil(total / totalPerPage);

  hash = hash ?? '';

  let pages = null;

  if (totalPages < 7) {
    pages = [...new Array(totalPages)].map((_, i) => i + 1);
  } else if (currentPage < 4) {
    pages = [1, 2, 3, 4, 5, '...', totalPages];
  } else if (currentPage > totalPages - 4) {
    pages = [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
  } else {
    pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  }

  return (
    <nav>
      <ul className="pagination">
        <li className={classnames('page-item', { disabled: currentPage === 1 })}>
          <Link className="page-link" to={`${url}${queryStringStart}${hash}`}>
            <span className="d-none d-sm-block">{t('First')}</span>
            <i className="fas fa-angle-double-left d-block d-sm-none py-1"></i>
          </Link>
        </li>
        <li className={classnames('page-item', { disabled: currentPage === 1 })}>
          <Link className="page-link" to={`${url}?page=${currentPage - 1}${queryStringEnd}${hash}`}>
            <span className="d-none d-sm-block">{t('Previous')}</span>
            <i className="fas fa-angle-left d-block d-sm-none py-1"></i>
          </Link>
        </li>

        {pages.map((item: number | string, index) => (
          <PaginationItem
            key={index}
            item={item}
            currentPage={currentPage}
            url={url}
            params={queryStringEnd}
            hash={hash}
          />
        ))}

        <li className={classnames('page-item', { disabled: currentPage === totalPages || !pages.length })}>
          <Link className="page-link" to={`${url}?page=${currentPage + 1}${queryStringEnd}${hash}`}>
            <span className="d-none d-sm-block">{t('Next')}</span>
            <i className="fas fa-angle-right d-block d-sm-none py-1"></i>
          </Link>
        </li>
        <li className={classnames('page-item', { disabled: currentPage === totalPages || !pages.length })}>
          <Link className="page-link" to={`${url}?page=${totalPages}${queryStringEnd}${hash}`}>
            <span className="d-none d-sm-block">{t('Last')}</span>
            <i className="fas fa-angle-double-right d-block d-sm-none py-1"></i>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
