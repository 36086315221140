import React from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { REACT_APP_API_MOCKING } from '../../config/Env';

export default function RedirectCustomRoute(props: RouteProps): JSX.Element {
  const { pathname } = useLocation();
  return (
    <Route
      path={props.path}
      render={() => <Redirect to={`/${REACT_APP_API_MOCKING ? 'dev-login' : 'signin'}?redirect=${pathname}`} />}
    />
  );
}
