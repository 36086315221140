import React from 'react';
import { useTranslation } from 'react-i18next';
import { ZapierPopularAppData } from '../../../../../ts';
import ItemApp from './ItemApp';
import { Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';

interface Props {
  apps: ZapierPopularAppData[];
  filterValue: string;
}

export default function ListApps({ apps, filterValue }: Props): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'build' });

  if (!apps.length) {
    return (
      <div className="mb-5">
        <p className="mb-0">{t('No search results found.')}</p>
      </div>
    );
  }

  return (
    <section>
      <Paragraph size={2}>{filterValue ? `${t('Result')}: ${filterValue}` : t('Popular Apps')}</Paragraph>
      <main>
        {apps.map((app, index) => (
          <React.Fragment key={`app-${index}`}>
            <ItemApp app={app} />
          </React.Fragment>
        ))}
      </main>
    </section>
  );
}
