import { useParams } from 'react-router-dom';
import { SigninContextProvider } from './SigninContextProvider';
import SigninWrapper from './SigninWrapper';
import styles from './styles.module.scss';

export default function SignIn(): JSX.Element {
  const { step } = useParams<{ step: string }>();
  return (
    <div className={styles['wrapper']}>
      <SigninContextProvider step={step}>
        <SigninWrapper />
      </SigninContextProvider>
    </div>
  );
}
