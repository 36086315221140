import { ChangeEvent, useState } from 'react';
import Alert from '../../../../../componentsV2/DesignSystem/Alert';
import { Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import { UploadPluginProps } from '../interfaces';

import styles from './styles.module.scss';

export default function UploadPluginImage({
  imagePreviewUrl,
  setImagePreviewUrl,
  filename,
  setFile,
  extesions,
}: UploadPluginProps): JSX.Element {
  const [errorValidatingExtension, setErrorValidatingExtension] = useState<boolean>(false);
  const extensionsString = extesions.map((ext) => `.${ext}`).join(',');

  const handleChangeUploadField = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorValidatingExtension(false);
    setImagePreviewUrl(null);
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const fileExtension = file.name.split('.').pop();
      if (!fileExtension) {
        setErrorValidatingExtension(true);
        return;
      }
      if (!extesions.includes(fileExtension)) {
        setErrorValidatingExtension(true);
        return;
      }

      setFile({
        name: file.name,
        file: file,
      });

      const previewUrl = URL.createObjectURL(file);
      setImagePreviewUrl(previewUrl);
    }
  };

  return (
    <div className={styles['wrapper-upload']}>
      <div className={styles['upload']}>
        {errorValidatingExtension && <Alert color="danger-light">File extension is not allowed.</Alert>}

        {!!filename && imagePreviewUrl && (
          <div style={{ marginBottom: '20px' }}>
            <img
              src={imagePreviewUrl}
              alt={`${imagePreviewUrl} preview`}
              style={{ maxWidth: '200px', maxHeight: '200px' }}
            />
          </div>
        )}
        <div className={styles['center-button']}>
          <label style={{ color: '#2396FB', cursor: 'pointer' }}>
            <input type="file" className="d-none" onChange={handleChangeUploadField} accept={extensionsString} />
            {!!filename ? 'Change Image' : 'Upload Image'}
          </label>
        </div>

        <Paragraph size={3}>
          <span style={{ color: '#949CB2' }}>
            The image must be in JPG, PNG or SVG format, and be at least 60x60 pixels
          </span>
        </Paragraph>
      </div>
    </div>
  );
}
