import React from 'react';
import { useTranslation } from 'react-i18next';
import { REACT_APP_ZAPIER_EMBED_CREATE_ZAP } from '../../../../config/Env';
import IframeContainer from '../../../../components/IframeContainer';
import SectionCollapsibleFull from '../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import { useHistory } from 'react-router-dom';

export default function CreateZapTemplate(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'create-zap-template' });
  const history = useHistory();

  return (
    <SectionCollapsibleFull
      expanded={true}
      header={{ title: t('Create a Zap Template') }}
      badge={{ status: StatusBadge.icon, icon: { name: 'groups', color: 'enabled' } }}
      disableCollapse
      showButton={{
        children: 'Back to Create a Zap',
        firstIcon: { children: 'chevron_left' },
        variant: 'secondary',
        onClick: () => history.push('/settings/integrations/build'),
      }}
    >
      <IframeContainer src={REACT_APP_ZAPIER_EMBED_CREATE_ZAP} allow={true} />
    </SectionCollapsibleFull>
  );
}
