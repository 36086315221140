import React from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import useAccount from '../../../hooks/useAccount';
import useToast from '../../../hooks/useToast';
import {
  NODE_ENV,
  REACT_APP_API_MOCKING,
  REACT_APP_API_URL,
  REACT_APP_COOKIE_DOMAIN,
  REACT_APP_COOKIE_PREFIX,
  REACT_APP_ENVIRONMENT,
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_INTERCOM_USER_HASH,
  REACT_APP_MONOLITH_V2,
  REACT_APP_X_API_KEY_GRAPHQL,
} from '../../../config/Env';
import Button from '../../../components/_atoms/Button';

import ImgError from '../../../assets/imgs/img-erro1.svg';
import ImgError2 from '../../../assets/imgs/img-erro2.svg';

export default function DevSupport(): JSX.Element {
  const { i18n, t } = useTranslation('translation');
  const history = useHistory();
  const { getMe, clearAuthorization } = useAuth();
  const { getAccount, alertMessages } = useAccount();
  const { toast } = useToast();

  const onLogout = () => {
    clearAuthorization();
    history.push('/');
  };

  const changeLanguageHandler = (language: string): void => {
    i18n.changeLanguage(language);
  };

  return (
    <main className="main-content">
      <h1 className="mb-5">{t('Developer Support-test')}</h1>
      <div className="msg-alert warning">
        <p>
          <i className="fas fa-exclamation-circle"></i> You have no active subscription. You must upgrade your plan to
          continue editing or to put your promotion live.
        </p>
        <a className="btn-link">
          <i className="fas fa-times"></i>
        </a>
      </div>
      <div className="msg-alert success">
        <p>
          <i className="fas fa-exclamation-circle"></i> You have no active subscription. You must upgrade your plan to
          continue editing or to put your promotion live.
        </p>
        <a className="btn-link">
          <i className="fas fa-times"></i>
        </a>
      </div>
      <div className="msg-alert danger">
        <p>
          <i className="fas fa-exclamation-circle"></i> You have no active subscription. You must upgrade your plan to
          continue editing or to put your promotion live.
        </p>
        <a className="btn-link">
          <i className="fas fa-times"></i>
        </a>
      </div>
      <div className="msg-alert neutral">
        <p>
          <i className="fas fa-exclamation-circle"></i> You have no active subscription. You must upgrade your plan to
          continue editing or to put your promotion live.
        </p>
        <a className="btn-link">
          <i className="fas fa-times"></i>
        </a>
      </div>
      <div className="content-box">
        <div className="msg-error">
          <img src={ImgError} />
          <p>Error Message</p>
          <a href="#" className="btn btn-secondary">
            Action
          </a>
        </div>
        <div className="msg-error">
          <img src={ImgError2} />
          <p>Error Message</p>
          <a href="#" className="btn btn-secondary">
            Action
          </a>
        </div>
      </div>
      {!!REACT_APP_API_MOCKING && <p className="bg-danger text-white">Using Mock</p>}

      {/*
        * @TODO review
        *
        <p>
          <Link to={`/${getAccount?.accountSlug}/campaign/17457/launch-control`}>
            {t('campaign:Campaign')}: <strong>Zoo Gorilla - Scratch Card [Status: {t('campaign:Draft Mode')}]</strong>
          </Link>
        </p>
      */}
      <p>
        <Link to={`/beeliked-ready-made-2-0/campaign/19702/launch-control`}>
          Change to {t('campaign:Campaign')}: <strong>spin this wheel test [Status: {t('campaign:Draft')}]</strong> ###
          ({t('Account name')}: <strong>Beeliked Ready Made 2.0</strong>)
        </Link>
      </p>
      {/*
        * @TODO review
        *
        <p>
          <Link to={`/beeliked-ready-made-2-0/campaign/19710/launch-control`}>
            {t('campaign:Campaign')}: <strong>Peel me back [Status: {t('campaign:Live Mode')}]</strong> ### (
            {t('Account name')}: <strong>Beeliked Ready Made 2.0</strong>)
          </Link>
        </p>
      */}
      {/*
        * @TODO review
        *
        <p>
          <Link to={`/test/campaign/3/launch-control`}>
            {t('campaign:Campaign')}: <strong>Pet Shop Dog - Spin [Status: {t('campaign:Archived Mode')}]</strong> ### (
            {t('Account name')}: <strong>Test</strong>)
          </Link>
        </p>
      */}

      <p className="mb-5">
        <Link to="/campaign/19706/builder">[Test] Builder</Link>
      </p>
      <p>
        <button type="button" className="btn btn-link p-0" onClick={() => changeLanguageHandler('en')}>
          {t('Change language to English')}
        </button>
      </p>
      <p>
        <button type="button" className="btn btn-link p-0" onClick={() => changeLanguageHandler('pt')}>
          {t('Change language to Portugues')}
        </button>
      </p>

      <p>
        <button
          type="button"
          className="btn btn-warning p-0"
          onClick={() => alertMessages({ variables: { accountId: getAccount.accountId } })}
        >
          {t('Show messages')}
        </button>
      </p>

      <p>
        <Link to="/account/switch-account">Switch Account</Link>
      </p>

      <p className="my-5">
        <button className="btn btn-link p-0" type="button" onClick={onLogout}>
          {t('Logout')}
        </button>
      </p>

      <div className="my-5">
        <Button type="button" color="secondary" onClick={() => [toast('success', <strong>Saved!</strong>)]}>
          Toast Success
        </Button>
        <Button
          type="button"
          color="secondary"
          onClick={() => [toast('warning', undefined, { duration: Infinity }, 'fas fa-exclamation-circle')]}
        >
          Toast Warning
        </Button>
        <Button type="button" color="secondary" onClick={() => [toast('error')]}>
          Toast Error
        </Button>
      </div>

      <div className="bg-light">
        <pre>
          <code>{JSON.stringify(getMe, null, 4)}</code>
        </pre>
        <pre>
          <code>{JSON.stringify(getAccount, null, 4)}</code>
        </pre>
      </div>

      <div className="bg-light">
        <p>
          <strong>NODE_ENV</strong>: <mark>{NODE_ENV.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_ENVIRONMENT</strong>: <mark>{REACT_APP_ENVIRONMENT.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_API_URL</strong>: <mark>{REACT_APP_API_URL.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_API_MOCKING</strong>: <mark>{REACT_APP_API_MOCKING.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_COOKIE_DOMAIN</strong>: <mark>{REACT_APP_COOKIE_DOMAIN.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_COOKIE_PREFIX</strong>: <mark>{REACT_APP_COOKIE_PREFIX.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_MONOLITH_V2</strong>: <mark>{REACT_APP_MONOLITH_V2.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_X_API_KEY_GRAPHQL</strong>: <mark>{REACT_APP_X_API_KEY_GRAPHQL.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_INTERCOM_APP_ID</strong>: <mark>{REACT_APP_INTERCOM_APP_ID.toString()}</mark>
        </p>
        <p>
          <strong>REACT_APP_INTERCOM_USER_HASH</strong>: <mark>{REACT_APP_INTERCOM_USER_HASH.toString()}</mark>
        </p>
      </div>
    </main>
  );
}
