import { IconButton } from '../Inputs/IconButton';
import { FontWeight, Paragraph } from '../Typography';
import { TagsProps } from './interface';
import styles from './styles.module.scss';

export function Tags({ children, color, onClick, size }: TagsProps): JSX.Element {
  return (
    <div
      className={[styles['tags'], styles[color ?? 'primary'], styles[size ?? 'md']].join(' ')}
      {...(!!onClick && { style: { cursor: 'pointer' } })}
    >
      <Paragraph size={3} weight={FontWeight.semiBold}>
        {children}
      </Paragraph>
      {!!onClick && <IconButton onClick={onClick}>close</IconButton>}
    </div>
  );
}
