import React from 'react';
import Dropdown from '../DesignSystem/Dropdown';
import { IconAnimated } from '../DesignSystem/IconAnimated';
import styles from './styles.module.scss';

export default function Notifications(): JSX.Element {
  /*@TODO implement with notification const [animateCount , setAnimateCount ] = useState<boolean>(false); 
    const [showInvitesDropdown, setShowInvitesDropdown] = useState<
    boolean | undefined
    >(undefined);
  */
  /*@TODO implement with notification setNotifications([
       {
         title: 'Coupon collection successfully created',
         description: 'Lorem ipsum dolor sit amet, consectetur  daing elit, sed do eiusmod tempor incasssididunt ut.',
         timestamp: '4 minutes',
         unread: true,
       },
       {
         title: 'Coupon collection successfully createdd',
         description: 'Lorem ipsum dolor sit amet, consectetur  daing elit, sed do eiusmod tempor incasssididunt ut.',
         timestamp: '4 minutes',
         unread: true,
       },
       {
         title: 'Coupon collection successfully createddd',
         description: 'Lorem ipsum dolor sit amet, consectetur  daing elit, sed do eiusmod tempor incasssididunt ut.',
         timestamp: '4 minutes',
         unread: false,
       },
     ]);*/

  return (
    <>
      <div className={`${styles['notify-wrapper']}`}>
        <Dropdown
          header={{
            title: 'Notifications',
          }}
          activeButton={{
            iconButton: {
              size: 'lg',
              children: 'notifications',
              /* @TODO implement notification customObject: (
                     <div className={styles['count-notify']}>
                       <span className={`${styles['new-number']} ${animateCount ? styles['animate'] : ''}`}>14</span>
                       <span className={`${styles['current-number']} ${animateCount ? styles['animate'] : ''}`}>13</span>
                     </div>
                   ), */
            },
          }}
          size="sm"
          placement="bottom-start"
        >
          <div className={styles['empty-notifications']}>
            <IconAnimated size="sm" icon={{ name: 'notifications' }} wrapper={{ color: 'primary' }} />
            <span>{`You don't have notifications yet`}</span>
          </div>
          {/* @TODO implement notification system 
              <ul className={styles['notifications']}>
                {notifications.map((notification, index) => (
                  <li
                    key={`notification-${notification.title}-${index}`}
                    className={`${styles['each-notification']} ${notification.unread && styles['unread']}`}
                  >
                    <aside className={styles['status-wrapper']}>
                      <span className={styles['status-circle']}></span>
                    </aside>
                    <main>
                      <h3>{notification.title}</h3>
                      <p>{notification.description}</p>
                      <span>{notification.timestamp} ago</span>
                    </main>
                    <aside className={styles['button-wrapper']}>
                      <IconButton onClick={() => console.log('@todo')}>more_vert</IconButton>
                    </aside>
                  </li>
                ))}
              </ul> */}
        </Dropdown>
      </div>
      {/* @TODO implement with notification

            <button onClick={() => handleBoolean(setShowInviteModal)} className={styles['invite-button']}>
            <div className={styles['count-notify']}>2</div>
            </button>
           */}
    </>
  );
}
