import SignUpWrapper from './SignUpWrapper';
import styles from './styles.module.scss';

export default function SignUp(): JSX.Element {
  return (
    <div className={styles['wrapper']}>
      <SignUpWrapper />
    </div>
  );
}
