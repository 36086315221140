import { gql, useLazyQuery } from '@apollo/client';
import useToast from '../../../../../hooks/useToast';
import { FiltersPramsProps } from '../../Filters/interface';

interface variablesInterface {
  accountId: number;
  userId: string;
  fields: string;
  params: FiltersPramsProps;
}

interface ExportContacts {
  loading: boolean;
  fetchExport: (variables: variablesInterface) => void;
}

const EXPORT_CONTACTS = gql`
  query exportContacts(
    $accountId: ID!
    $userId: ID!
    $fields: String
    $pagination: PaginationInput
    $filters: AudienceFilters
  ) {
    exportContacts(
      accountId: $accountId
      userId: $userId
      fields: $fields
      pagination: $pagination
      filters: $filters
    ) {
      status
      message
    }
  }
`;

export function useExportContacts(): ExportContacts {
  const { toast } = useToast();
  const [exportContacts, { loading: exportContactsLoading }] = useLazyQuery<{
    accountId: number;
    userId: number;
    fields: string;
  }>(EXPORT_CONTACTS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      toast('success', 'Contacts exported with sucess.');
    },
    onError: () => {
      toast('error', 'Error to export contacts.');
    },
  });

  const fetchExport = (variables: variablesInterface) => {
    exportContacts({ variables: variables });
  };

  if (exportContactsLoading) {
    return {
      loading: true,
      fetchExport: fetchExport,
    };
  }

  return {
    fetchExport: fetchExport,
    loading: exportContactsLoading,
  };
}
