import { ApolloError, gql, useMutation } from '@apollo/client';

const CREATE_USER_FEATURE_FLAG = gql`
  mutation createUserFeatureFlag($accountId: ID!, $featureKey: String!) {
    createUserFeatureFlag(accountId: $accountId, featureKey: $featureKey) {
      id
      feature_key
      accessed
    }
  }
`;

export enum FeatureKey {
  ShareCenter = 'share_center',
}

interface CreateUserFeatureFlagResult {
  id: number;
  feature_key: FeatureKey;
  accessed: boolean;
}

interface UseMutationCreateUserFeatureFlag {
  loading: boolean;
  requestUserFeatureFlag: (variables: { accountId: number; featureKey: FeatureKey }) => void;
  data: CreateUserFeatureFlagResult | null;
}

export function useMutationCreateUserFeatureFlag(): UseMutationCreateUserFeatureFlag {
  const [createUserFeatureFlag, { loading, data }] = useMutation<{
    createUserFeatureFlag: CreateUserFeatureFlagResult;
  }>(CREATE_USER_FEATURE_FLAG, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'CREATE_USER_FEATURE_FLAG',
        apolloError: error,
      });
    },
  });

  const requestUserFeatureFlag = (variables: { accountId: number; featureKey: FeatureKey }) => {
    createUserFeatureFlag({ variables });
  };

  return {
    loading,
    requestUserFeatureFlag,
    data: data?.createUserFeatureFlag || null,
  };
}
