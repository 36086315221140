import { ApolloError, useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Suspense, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import AlertErrorLoad from './components/AlertErrorLoad';
import SpinnerAllScreen from './components/_molecules/SpinnerAllScreen';
import { REACT_APP_SENTRY_DSN, REACT_APP_TRANSLATION_LOCAL } from './config/Env';
import { GET_TRANSLATION_LOCALES } from './config/Graphql/translation.gql';
import { AuthProvider } from './hooks/useAuth';
import { PostHogProvider } from './hooks/usePostHog';
import i18n from './i18n';

function App(): JSX.Element {
  const [spinner, setSpinner] = useState<boolean>(true);

  if (!REACT_APP_TRANSLATION_LOCAL) {
    const { data, error } = useQuery<{
      locales: { translation: Record<string, string>[] };
    }>(GET_TRANSLATION_LOCALES, {
      fetchPolicy: 'no-cache',
      variables: {
        lng: 'en',
      },
      onError: (error: ApolloError) => {
        console.log({
          gql: 'GET_TRANSLATION_LOCALES',
          variables: {
            variables: 'en',
          },
          apolloError: error,
        });
      },
    });

    useEffect(() => {
      if (data) {
        const translations = data.locales.translation;
        translations.map((translate) => {
          i18n.addResourceBundle('en', Object.keys(translate)[0], Object.values(translate)[0]);
        });
        setSpinner(false);
      }
    }, [data]);

    if (spinner && !error) {
      return <SpinnerAllScreen />;
    }

    if (error) {
      return <AlertErrorLoad action={() => window.location.reload()} />;
    }
  }

  return (
    <Suspense fallback="">
      <BrowserRouter>
        <PostHogProvider>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </PostHogProvider>
      </BrowserRouter>
      <Toaster position="top-center" />
    </Suspense>
  );
}

const appProfiler = REACT_APP_SENTRY_DSN ? Sentry.withProfiler(App) : App;

export default appProfiler;
