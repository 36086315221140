/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, useMutation } from '@apollo/client';
import { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import logoImage from '../../../../../../assets/images/beeliked.svg';
import Icon from '../../../../../../componentsV2/DesignSystem/Icon';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import Skeleton from '../../../../../../componentsV2/DesignSystem/Skeleton';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { Color, FontWeight } from '../../../../../../componentsV2/DesignSystem/Typography/interface';
import useToast from '../../../../../../hooks/useToast';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import { PasswordProps, passwordConfirmation, validatePassword } from '../../../../../../utils/validations';
import { VERIFY_INVITE_GQL } from '../../graphql';
import useSignupContext, { Steps } from '../../SignupContextProvider';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

export default function StepInviteUser(): JSX.Element {
  const { animations, changeStep, updateUser, userData, inviteData, updateInvite } = useSignupContext();
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const history = useHistory();
  const [types, setTypes] = useState({ password: true, passwordConfirmation: true });
  const { inviteCode, inviteId, accountId, agencyId } =
    useParams<{ inviteCode: string; inviteId: string; accountId: string; agencyId: string }>();
  const [passwords, setPasswords] = useState<PasswordProps>({ password: '', passwordConfirmation: '' });
  const [passwordRules, setPasswordRules] = useState({
    size: false,
    upper: false,
    lower: false,
    special: false,
    equal: false,
  });

  const handlePassword = (value: string) => {
    const { rules } = validatePassword(value, passwords, passwordRules);
    setPasswords({ ...passwords, password: value });
    setPasswordRules({ ...rules });
  };

  const handlePasswordConfirmation = (value: string) => {
    const { rules } = passwordConfirmation(value, passwords, passwordRules);
    setPasswords({ ...passwords, passwordConfirmation: value });
    setPasswordRules({ ...rules });
  };

  const [verifyInvite, { loading: loadingInitial }] = useMutation(VERIFY_INVITE_GQL, {
    onCompleted: ({ verifyInvite }) => {
      const { firstName, lastName, email, inviteCode, accountName } = verifyInvite;
      updateUser({ ...userData, firstName, lastName, email });
      updateInvite({ ...inviteData, inviteCode, accountName });
    },
    onError: (error: ApolloError) => {
      if (error?.graphQLErrors?.[0].extensions?.statusCode) {
        const code = error.graphQLErrors[0].extensions.statusCode;
        if (code === 404) {
          toast('error', 'Invite expired or invalid.');
        }
        if (code === 422) {
          const validations = error?.graphQLErrors?.[0].extensions?.validations as Record<string, string>;
          toast('error', validations?.confirm_code[0]);
        }
      } else {
        toast('error', error?.graphQLErrors?.[0].message);
      }
      history.push('/signin');
    },
  });

  const nextStep = (e: any) => {
    e.preventDefault();
    updateUser({ ...userData, ...passwords });
    setLoading(true);
    setTimeout(() => {
      if (Object.values(passwordRules).indexOf(false) < 0) {
        changeStep(Steps.terms, 'forward');
      }
      setLoading(false);
    }, 500);
  };

  useMemo(() => {
    if (inviteCode && inviteCode && inviteId && accountId && agencyId)
      verifyInvite({ variables: { code: inviteCode, inviteId: inviteId, accountId: accountId, agencyId: agencyId } });
  }, [inviteCode, inviteCode, inviteId, accountId, agencyId]);

  return (
    <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.initial])}>
      <img src={logoImage} alt="Our logo image" width={144} />
      <div className={styles['texts-wrapper']}>
        {loadingInitial ? (
          <div style={{ display: 'grid', gap: '.5rem' }}>
            <Skeleton width={80} height={1.5} rounded={2} />
            <Skeleton width={40} height={1.5} rounded={2} />
          </div>
        ) : (
          <>
            <Heading size={3} weight={FontWeight.extraBold}>
              {`Hey ${userData.firstName}, you've received`}
            </Heading>
            <Heading size={3} weight={FontWeight.extraBold}>
              an invitation to join to
            </Heading>
            <Heading size={3} color={Color.yellowBee} weight={FontWeight.extraBold}>
              {inviteData.accountName}
            </Heading>
          </>
        )}
        <Heading size={6} weight={FontWeight.semiBold}>
          You need to create a secure password for your account
        </Heading>
      </div>
      <form onSubmit={(e) => nextStep(e)}>
        <Input
          value={passwords.password}
          onChange={(e) => handlePassword(e.target.value)}
          type={types.password ? 'password' : 'text'}
          label="Password"
          placeholder="Your password"
          icon={{
            name: types.password ? 'visibility' : 'visibility_off',
            onClick: () => setTypes({ ...types, password: !types.password }),
            style: { marginRight: '.25rem' },
          }}
        />
        <Input
          value={passwords.passwordConfirmation}
          onChange={(e) => handlePasswordConfirmation(e.target.value)}
          type={types.passwordConfirmation ? 'password' : 'text'}
          label="Confirm your password"
          placeholder="Confirm your password"
          disabled={
            !(passwordRules.lower && passwordRules.size && passwordRules.special && passwordRules.upper) &&
            passwords.password.length == 0
          }
          icon={{
            name: types.passwordConfirmation ? 'visibility' : 'visibility_off',
            onClick: () => setTypes({ ...types, passwordConfirmation: !types.passwordConfirmation }),
            style: { marginRight: '.25rem' },
          }}
        />
        <ul className={styles['password-rules-wrapper']}>
          <li className={passwordRules.size ? styles['approved'] : ''}>
            <Icon>{passwordRules.size ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 8 characters</Paragraph>
          </li>
          <li className={passwordRules.upper ? styles['approved'] : ''}>
            <Icon>{passwordRules.upper ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 in uppercase</Paragraph>
          </li>
          <li className={passwordRules.lower ? styles['approved'] : ''}>
            <Icon>{passwordRules.lower ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 in lowercase</Paragraph>
          </li>
          <li className={passwordRules.special ? styles['approved'] : ''}>
            <Icon>{passwordRules.special ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 special character (allowed: @#!$*&?)</Paragraph>
          </li>
          <li className={passwordRules.equal ? styles['approved'] : ''}>
            <Icon>{passwordRules.equal ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Passwords must be equal</Paragraph>
          </li>
        </ul>
        <div className={styles['wrapper-buttons']}>
          <Button fullWidth type="submit" loading={loading} disabled={Object.values(passwordRules).indexOf(false) >= 0}>
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
}
