import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SpinnerAllScreen from '../../../components/_molecules/SpinnerAllScreen';
import SpinnerBox from '../../../componentsV2/DesignSystem/SpinnerBox';
import { REACT_APP_ADMIN_HOMEPAGE, REACT_APP_TERMS_URL } from '../../../config/Env';
import useAuth from '../../../hooks/useAuth';
import useQueryString from '../../../hooks/useQueryString';
import { getAccountCookie, removeAccountCookie } from '../../../utils/accountCookie';
import ChooseAnAccount from '../Account/ChooseAnAccount';
import styles from './styles.module.scss';
import { useLazyFetchAgreementByUser } from './useLazyFetchAgreementByUser';

export default function AccountManager(): JSX.Element {
  const { getMe, logged } = useAuth();
  const query = useQueryString();
  const history = useHistory();

  const { data: dataAgreement, refetch, loading } = useLazyFetchAgreementByUser();

  const redirection = (url: string, accepted: boolean) => {
    history.push(accepted ? url : `${REACT_APP_TERMS_URL}#terms`);
  };

  useEffect(() => {
    if (logged) {
      refetch?.();
    }
  }, [logged]);

  useEffect(() => {
    if (dataAgreement) {
      const accountIdCookie = getAccountCookie();
      if (accountIdCookie) {
        const hasAccount = Object.keys(getMe.account_permissions).find((account) => account === accountIdCookie);
        const redirect = query.get('redirect') || undefined;
        if ((hasAccount || getMe.superAdmin) && redirect) {
          redirection(redirect, dataAgreement.accepted);
        }
        if (!hasAccount && !getMe.superAdmin) {
          removeAccountCookie();
        }
      }

      if (!getMe.superAdmin) {
        if (getMe.accounts.length === 1) {
          redirection(REACT_APP_ADMIN_HOMEPAGE, dataAgreement.accepted);
        }
      }
    }
  }, [getMe, dataAgreement]);

  if (loading) {
    return <SpinnerAllScreen text="Loading" />;
  }

  return (
    <>
      <div className={styles.topBar}>
        <div className={styles.logo} />
      </div>
      <div className={styles.container}>
        {(getMe.superAdmin || getMe.accounts.length > 1) && dataAgreement ? (
          <ChooseAnAccount
            accountHighlight={false}
            parent="AccountManager"
            agreementAccepted={dataAgreement?.accepted}
          />
        ) : (
          <SpinnerBox />
        )}
      </div>
    </>
  );
}
