import React from 'react';
import Lottie from 'lottie-react';
import animationLoading from './loading-lottie.json';
import styles from './styles.module.scss';

export default function SpinnerBox(): JSX.Element {
  return (
    <div className={styles.spinnerBox}>
      <Lottie
        loop={true}
        autoPlay={true}
        animationData={animationLoading}
        style={{ height: '100px', width: '100px' }}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
      />
    </div>
  );
}
