import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ImgForbiden from '../../assets/imgs/img-forbiden.svg';
import Button from '../../components/_atoms/Button';
import { Heading } from '../../componentsV2/DesignSystem/Typography';

interface Props {
  title?: string;
}

export default function Forbidden({ title }: Props): JSX.Element {
  const { t } = useTranslation('forbidden');
  const history = useHistory();

  return (
    <main className="main-content p-0">
      {title && <Heading size={2}>{title}</Heading>}
      <div className="content-box msg-error">
        <img src={ImgForbiden} />
        <h1>{t('Forbidden')}</h1>
        <p>{t('You do not have permission to access this page.')}</p>
        <Button type="button" color="primary" onClick={() => history.push('/')}>
          {t('Go Back')}
        </Button>
      </div>
    </main>
  );
}
