import { useQuery, gql, ApolloError } from '@apollo/client';
import { SelfServicePlans } from './interface';

const SELFSERVICE_PLANS = gql`
  query selfServicePlans {
    selfServicePlans {
      id
      type
      priceMonthly
      priceAnnual
      description
      entryAllowances {
        id
        quantity
        prices {
          currency
          priceMonthly
          priceAnnual
          totalPriceMonthly
          totalPriceAnnual
        }
      }
    }
  }
`;

export function useFetchSelfServicePlans(): {
  loading: boolean;
  data: SelfServicePlans[] | null;
  error?: string;
} {
  const { data, loading, error } = useQuery<{ selfServicePlans: SelfServicePlans[] }>(SELFSERVICE_PLANS, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      console.log({
        gql: 'SELFSERVICE_PLANS',
        apolloError: error,
      });
    },
  });

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error: error.message,
    };
  }

  return {
    loading: false,
    data: data?.selfServicePlans || null,
  };
}
