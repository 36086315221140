import { gql } from '@apollo/client';

export const LIST_SIGNIN_NEWS_SLIDESHOW = gql`
  query news {
    news(page: 1, pageSize: 10) {
      data {
        id
        title
        description
        pill {
          color
          children
        }
        background {
          src
          alt
        }
        image {
          src
          alt
        }
      }
    }
  }
`;
