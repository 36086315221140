import React, { useMemo } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import useAccount from '../../../../../../hooks/useAccount';
import CarouselVideo from '../../../../../../componentsV2/DesignSystem/CarouselVideo';
import { DASHBOARD_TUTORIALS } from './graphql';
import { DashboardTutorialData } from './interface';
import ErrorRefetch from '../../../../../../componentsV2/ErrorRefetch';

export default function TutorialsCarousel(): JSX.Element {
  const { getAccount } = useAccount();

  const {
    data: dataTutorials,
    loading: loadingTutorials,
    error: errorTutorials,
    refetch: refetchTutotias,
  } = useQuery<{
    dashboardTutorials: { data: DashboardTutorialData[] };
  }>(DASHBOARD_TUTORIALS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      accountId: getAccount.accountId,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const tutorials = useMemo(() => {
    const dashboardTutorials = dataTutorials?.dashboardTutorials?.data;

    return dashboardTutorials
      ? dashboardTutorials.map((tutorial) => ({
          id: tutorial.id,
          title: tutorial.title,
          media: {
            src: tutorial.video_url,
            thumbnail: tutorial.media?.image,
          },
        }))
      : [];
  }, [dataTutorials?.dashboardTutorials?.data]);

  const refetchQuery = () => {
    refetchTutotias();
  };

  if (errorTutorials) {
    return <ErrorRefetch callback={refetchQuery} customStyles={{ height: '17.25rem' }} />;
  }

  return <CarouselVideo isLoading={loadingTutorials} medias={tutorials} />;
}
