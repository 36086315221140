export enum AppEnvironment {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export enum OrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}
