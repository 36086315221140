import React, { useState } from 'react';
import SlideShow from './Slide';
import styles from './styles.module.scss';
import { SlideData, SlideProps } from './interface';
import { IconButton } from '../../../../../../componentsV2/DesignSystem/Inputs/IconButton';
import { ApolloError, useQuery } from '@apollo/client';
import { LIST_SIGNIN_NEWS_SLIDESHOW } from './graphql';

export default function Slide({ style }: SlideProps): JSX.Element {
  const [position, setPosition] = useState(0);

  const { data } = useQuery<{
    news: { data: SlideData[]; paginatorMeta: { total: number } };
  }>(LIST_SIGNIN_NEWS_SLIDESHOW, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error: ApolloError) => {
      console.log({
        gql: 'LIST_SIGNIN_NEWS_SLIDESHOW',
        apolloError: error,
      });
    },
  });

  function nextTestimonial() {
    setPosition((prev) => prev + 1);
  }
  function previousTestimonial() {
    if (data) {
      if (position == 0) setPosition(data.news.data.length - 1);
      setPosition((prev) => prev - 1);
    }
  }

  return (
    <main className={styles['testimonials-wrapper']} style={style}>
      {data?.news.data.map((eachNews, index) => (
        <SlideShow
          key={index}
          {...eachNews}
          animation={position % data?.news.data.length == index ? styles['fade-in'] : styles['fade-out']}
        />
      ))}
      <div className={styles['wrapper-buttons']}>
        <IconButton onClick={previousTestimonial}>chevron_left</IconButton>
        <IconButton onClick={nextTestimonial}>chevron_right</IconButton>
      </div>
    </main>
  );
}
