import React from 'react';
import { useTranslation } from 'react-i18next';
import BlkdSpinner from '../../../assets/imgs/blkd-spinner.svg';
import styles from './styles.module.scss';

interface Props {
  text?: string;
  noText?: boolean;
}

export default function SpinnerInline({ text, noText }: Props): JSX.Element {
  const { t } = useTranslation('translation');

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className={styles['spinner-inline']}>
        <object type="image/svg+xml" data={BlkdSpinner} />
        {!noText && (text ?? t('Loading'))}
      </div>
    </div>
  );
}
