import { TableHeaderElement } from '../../../../../../../componentsV2/TableColumnsControl/interface';

export const DEFAULT_COLUMNS: TableHeaderElement[] = [
  {
    id: 'created_at',
    base_field: 'created_at',
    type: 'text',
    enabled: true,
    name: 'Created at',
    order: 1,
  },
  {
    id: 'invitelinks',
    base_field: 'invitelinks',
    type: 'text',
    enabled: true,
    name: 'InviteLinks',
    order: 2,
    width: '30rem',
  },
  {
    id: 'code',
    base_field: 'code',
    type: 'text',
    enabled: true,
    name: 'Invite Code',
    order: 3,
  },
  {
    id: 'email',
    base_field: 'email',
    type: 'text',
    enabled: true,
    name: 'Email',
    order: 4,
    width: '15rem',
  },
  {
    id: 'expire_date',
    base_field: 'expire_date',
    type: 'text',
    enabled: true,
    name: 'Expire Date',
    order: 5,
  },
];
