import { useFormContext } from 'react-hook-form';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import EmailContent from './EmailContent';
import FormEmail from './FormEmail';
import { PreviewSendProps } from './interface';
import useSendEmail from './SendEmailProvider';
import SendEmailTest from './SendEmailTest';
import styles from './styles.module.scss';

export default function PreviewSend({ invitelinkUrl, firstName, code, file, setFile }: PreviewSendProps): JSX.Element {
  const { senderEmail } = useSendEmail();

  const { watch } = useFormContext();

  const [subject] = watch(['subject']);

  return (
    <>
      <div className={styles['column']}>
        <FormEmail file={file} setFile={setFile} />
      </div>
      <div className={styles['column']}>
        <div className={styleModuleClasses(styles, 'column-container', 'preview-row')}>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            Email Preview
          </Paragraph>

          <div className={styles['preview-header']}>
            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                From Address
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {senderEmail?.sender}
              </Paragraph>
            </div>

            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                From Name
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {senderEmail?.senderName}
              </Paragraph>
            </div>

            <div>
              <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                Subject
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                {subject || <div className={styles['placeholder']}>What{"'"}s the subject line for this email...</div>}
              </Paragraph>
            </div>
          </div>

          <EmailContent userName={firstName} inviteLink={invitelinkUrl} />

          <SendEmailTest code={code} file={file} />
        </div>
      </div>
    </>
  );
}
