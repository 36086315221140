import { createContext, useContext, useMemo, useState } from 'react';
import { getAccountCookie } from '../../../../utils/accountCookie';

interface SwitchAccount {
  handleViewOption: (section: Section, typeOfList: TypeOfList) => void;
  getTypeAll: TypeOfList;
  getTypeFavorite: TypeOfList;
  accountIdFromCookie?: number;
  accepted: boolean;
  highlight: boolean;
  disabledAll: boolean;
  setDisabledAll: (disabledAll: boolean) => void;
}

export type TypeOfList = 'grid' | 'list';

type Section = 'all' | 'favorite';

interface ViewOption {
  section: Section;
  typeOfList: TypeOfList;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  accountHighlight: boolean;
  agreementAccepted?: boolean;
}

const SwitchAccount = createContext({});

export function SwitchAccountContextProvider({ children, accountHighlight, agreementAccepted }: Props): JSX.Element {
  const [disabledAll, setDisabledAll] = useState<boolean>(false);
  const [viewOption, setViewOption] = useState<ViewOption[]>([
    {
      section: 'all',
      typeOfList: 'grid',
    },
    {
      section: 'favorite',
      typeOfList: 'grid',
    },
  ]);

  const accountIdFromCookie = useMemo(() => {
    return accountHighlight && getAccountCookie() ? Number(getAccountCookie()) : undefined;
  }, [accountHighlight]);

  const highlight = useMemo(() => accountHighlight, [accountHighlight]);

  const handleViewOption = (section: Section, typeOfList: TypeOfList) => {
    setViewOption((prevOptions) =>
      prevOptions.map((option) => (option.section === section ? { ...option, typeOfList: typeOfList } : option)),
    );
  };

  const getTypeOfList = (section: Section): TypeOfList => {
    const option = viewOption.find((opt) => opt.section === section);
    return option?.typeOfList || 'grid';
  };

  const getTypeAll = useMemo(() => getTypeOfList('all'), [viewOption]);

  const getTypeFavorite = useMemo(() => getTypeOfList('favorite'), [viewOption]);

  const accepted = useMemo(() => agreementAccepted, [agreementAccepted]);

  return (
    <SwitchAccount.Provider
      value={{
        handleViewOption,
        getTypeAll,
        getTypeFavorite,
        accountIdFromCookie,
        accepted,
        highlight,
        disabledAll,
        setDisabledAll,
      }}
    >
      {children}
    </SwitchAccount.Provider>
  );
}

export default function useSwitchAccount(): SwitchAccount {
  const context = useContext(SwitchAccount) as SwitchAccount;
  return context;
}
