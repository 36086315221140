import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AlertNoContent from '../../../../../components/AlertNoContent';
import useZapierContext from '../../ZapierContextProvider';

export default function EmptyZapTemplates(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'build' });
  const { PATH_ZAPIER } = useZapierContext();

  return (
    <AlertNoContent>
      <p className="mb-4">{t('There is no Zap Template registered.')}</p>
      <Link to={`${PATH_ZAPIER}/create-zap-template`} className="btn btn-secondary">
        {t('Create a new Zap Template')}
      </Link>
    </AlertNoContent>
  );
}
