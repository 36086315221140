import { useEffect, useMemo, useState } from 'react';
import TableColumnsControl, {
  buildTableResult,
  filterTableColumns,
} from '../../../../../componentsV2/TableColumnsControl';
import { TableDataCellSerialized, TableHeaderElement } from '../../../../../componentsV2/TableColumnsControl/interface';
import { TableContentPros } from './interface';
import useQueryParams from '../../../../../hooks/useQueryParams';
import useQueryString from '../../../../../hooks/useQueryString';
import { DEFAULT_COLUMNS } from './DefaultColumns';
import { FieldData, InviteData } from '../../../../../ts';
import capitalize from '../../../../../utils/capitalize';
import {
  FormatDateDayLongMonthYear,
  FormatDateDayLongMonthYearShortTime,
} from '../../../../../componentsV2/DesignSystem/Utils/Format';
import { PillsColors } from '../../../../../componentsV2/DesignSystem/Pills/interface';
import { Button } from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import ButtonCopy from '../../../../../componentsV2/ButtonCopy';
import ButtonPopoverConfirm from '../../../../../componentsV2/ButtonPopoverConfirm';
import { useExpireInviteLinkMutation } from '../useExpireInviteLinkMutation';
import useAccount from '../../../../../hooks/useAccount';
import PreviewEmailModal from '../PreviewEmailModal';
import styles from './styles.module.scss';

export default function TableContent({
  data: dataInviteLink,
  listFields,
  dataLoading,
  exportSelectedCsv,
  setExportCsv,
  fetchInviteLinks,
}: TableContentPros): JSX.Element {
  const [serializedTable, setSerializedTable] = useState<TableHeaderElement[]>();
  const [, setInvitesSelected] = useState<string[]>([]);
  const [messageIdPreview, setMessageIdPreview] = useState<number | undefined>();
  const [deleteInviteLinkId, setDeleteInviteLinkId] = useState<string>();
  const { getAccount } = useAccount();
  const { requestExpirationDate, data: dataExpirationDate } = useExpireInviteLinkMutation();

  const query = useQueryString();

  const addQueryParam = useQueryParams();

  const handleCheckboxSelected = (itensSelected: string[]) => {
    setInvitesSelected(itensSelected);
  };

  const toggleColumns = (data?: TableHeaderElement[], columns?: string) => {
    setSerializedTable(data);
    addQueryParam({ columns });
  };

  const getSerealized = (data: InviteData[], fields: FieldData[]) => {
    return data.map((value) => {
      const serealized: TableDataCellSerialized = {
        created_at: FormatDateDayLongMonthYearShortTime(new Date(value.created_at)),
        type: capitalize(value.type),
        id: value.code,
        uid: value.uid,
        code: value.code,
        generator_name: value.generator_name,
        promotion: value.promotion,
        used_at: value.is_used
          ? JSON.stringify({
              pill: {
                firstIcon: { size: 'md', children: 'credit_score', color: 'gray-space' },
                children: FormatDateDayLongMonthYear(new Date(value.used_at)),
                color: PillsColors.gray,
              },
            })
          : JSON.stringify({
              pill: {
                firstIcon: { size: 'md', children: 'credit_card', color: 'primary' },
                children: 'Unused',
                color: PillsColors.gray,
              },
            }),
        expire_date: value.expire_date ? FormatDateDayLongMonthYear(new Date(value.expire_date)) : ' - ',
        user_id: value.created_by,
        message_request_status: value.message_request_status,
        message_request_timestamp:
          value.message_request_status == 'processed'
            ? JSON.stringify({
                pill: {
                  firstIcon: { size: 'md', children: 'mark_email_read' },
                  children: FormatDateDayLongMonthYear(new Date(value.message_request_timestamp)),
                  color: PillsColors.green,
                },
              })
            : value.message_request_status == 'failed'
            ? JSON.stringify({
                pill: {
                  firstIcon: { size: 'md', children: 'mark_email_unread' },
                  children: 'Failed',
                  color: PillsColors.red,
                },
              })
            : value.message_request_status == 'pending'
            ? JSON.stringify({
                pill: {
                  firstIcon: { size: 'md', children: 'mark_email_unread' },
                  children: 'Processing...',
                  color: PillsColors.yellow,
                },
              })
            : '',
        url: value.url,
      };

      fields.forEach((field) => {
        serealized[field.slug] = value.data?.[field.slug] || '';
      });

      return serealized;
    });
  };

  useEffect(() => {
    if (dataExpirationDate?.id) {
      fetchInviteLinks({
        accountId: getAccount.accountId,
        page: Number(query.get('page')) || 1,
      });
    }
  }, [dataExpirationDate?.id]);

  const deleteInviteLink = (code: string) => {
    setDeleteInviteLinkId(code);
    requestExpirationDate({
      agencyId: getAccount.agencyId,
      accountId: getAccount.accountId,
      code,
    });
  };

  const tableActionButtons = useMemo(() => {
    if (dataInviteLink) {
      return dataInviteLink.map((invite) => {
        return (
          <>
            {!!invite.message_id && (
              <Button
                onClick={() => setMessageIdPreview(invite.message_id)}
                firstIcon={{ children: 'drafts' }}
                variant="secondary"
                size="sm"
              />
            )}
            <ButtonCopy
              text={`${invite.url}?inv=${invite.code}`}
              hoverMessage="Copy InviteLink URL"
              onClickMessage="Copied InviteLink URL"
            />
            <ButtonPopoverConfirm
              buttonProps={{
                variant: 'secondary',
                firstIcon: { children: 'expire', customFont: true, customClass: styles['custom-icon'] },
                size: 'sm',
                disabled: deleteInviteLinkId === invite.code || invite.expired,
                loading: deleteInviteLinkId === invite.code,
              }}
              popoverProps={{
                header: { title: 'Are you sure you want to expire this invitelink?' },
                placement: 'left-start',
                buttonsFooter: [
                  {
                    children: 'Yes, Expire',
                  },
                ],
              }}
              onClick={() => {
                deleteInviteLink(invite.code);
              }}
            />
          </>
        );
      });
    }
    return [];
  }, [dataInviteLink, deleteInviteLinkId]);

  useEffect(() => {
    if (!dataLoading) {
      const dataSerealized = getSerealized(dataInviteLink, listFields);
      let result: TableHeaderElement[] = buildTableResult(DEFAULT_COLUMNS, dataSerealized);
      result = filterTableColumns(result, query.get('columns') || undefined);
      setSerializedTable(result);
      setExportCsv(result.map((entry) => Object.values(entry)));
    }
  }, [dataInviteLink, dataLoading]);

  return (
    <>
      <TableColumnsControl
        firstColumnFixed
        lastColumnFixed
        column={serializedTable}
        actionButtons={tableActionButtons}
        toggleColumns={toggleColumns}
        loading={dataLoading}
        exportSelectedCsv={exportSelectedCsv}
        selectLine={{
          handleCheckboxSelected,
          columnId: 'code',
        }}
      />
      {messageIdPreview && <PreviewEmailModal setMessageIdPreview={setMessageIdPreview} messageId={messageIdPreview} />}
    </>
  );
}
