import { Heading } from '../../../../../../componentsV2/DesignSystem/Typography';
import { FontWeight } from '../../../../../../componentsV2/DesignSystem/Typography/interface';
import { InitialProps } from './interface';
import styles from './styles.module.scss';

export default function Initial({ style }: InitialProps): JSX.Element {
  const array = [
    {
      src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/mcafee.png',
      alt: 'McAfee logo',
    },
    {
      src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/nespresso.png',
      alt: 'Nespresso logo',
    },
    {
      src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/penguim.png',
      alt: 'Penguim logo',
    },
    {
      src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/adidas.png',
      alt: 'Adidas logo',
    },
    {
      src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/start.png',
      alt: 'Star Alliance logo',
    },
    {
      src: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/persil.png',
      alt: 'Pesil logo',
    },
  ];
  return (
    <main className={styles['testimonial-wrapper']} style={style}>
      <aside>
        <Heading size={2} weight={FontWeight.extraBold}>
          {`Trusted to power promotions for many of the world's leading brands`}
        </Heading>
        <Heading size={5} weight={FontWeight.regular}>
          {`We're proud to have helped these successful`}
          <br />
          {`campaigns come to life.`}
        </Heading>
        <div>
          {array.map(({ src, alt }) => (
            <img key={alt} src={src} alt={alt} />
          ))}
        </div>
      </aside>
    </main>
  );
}
