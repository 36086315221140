import styleModuleClasses from '../../../utils/styleModuleClasses';
import { Button } from '../Inputs/Buttons/ButtonAction';
import { IconButton } from '../Inputs/IconButton';
import Overlay from '../Overlay';
import { ModalProps, ModalSize } from './interface';
import styles from './styles.module.scss';

export default function Modal({
  title,
  size,
  children,
  leftButton,
  rightButton,
  footerDescription,
  position,
  spacingOverlay,
  callback,
  customClass,
}: ModalProps): JSX.Element {
  function handleClose() {
    const body = document.querySelector('body');
    const isThereOverlay = document.querySelectorAll('.selector-overlay');
    callback();
    if (body && isThereOverlay.length <= 1) body.style.overflowY = '';
  }
  return (
    <>
      <Overlay
        spacing={spacingOverlay}
        position={902}
        color="rgba(0, 0, 0, 0.7)"
        backgroundBlur="2.5px"
        callback={callback}
      />
      <div
        className={styleModuleClasses(styles, 'modal', 'fade-in', 'big', customClass, {
          [size as ModalSize]: !!size,
        })}
        style={
          position
            ? {
                top: position.top,
                right: position.right,
                bottom: position.bottom,
                left: position.left,
              }
            : { left: '50%', top: '50%', transform: 'translate(-50%, -50%)', display: 'flex', flexDirection: 'column' }
        }
      >
        <header>
          <title>{title}</title>
          <IconButton onClick={handleClose}>close</IconButton>
        </header>
        <main>{children}</main>
        {footerDescription ||
          ((leftButton?.children || rightButton?.children) && (
            <footer className={!leftButton && rightButton ? 'justify-content-end' : 'justify-content-between'}>
              {footerDescription && footerDescription}
              {leftButton && <Button {...leftButton} />}
              {rightButton && <Button {...rightButton} />}
            </footer>
          ))}
      </div>
    </>
  );
}
