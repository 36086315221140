import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/_atoms/Button';
import InputFormGroup from '../../../components/_molecules/InputFormGroup';
import styles from './styles.module.scss';

export default function MaskingDomain(): JSX.Element {
  const [domainCollapse, setDomainCollapse] = useState<boolean>();
  const { t } = useTranslation();

  const toggleDomain = () => setDomainCollapse((domainCollapse) => !domainCollapse);

  return (
    <main className="main-content">
      <div className={styles['mask-domain']}>
        <h1>{t('Masking your domain')}</h1>

        {/* <p>
          {t(
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          )}
        </p> */}

        <div className="content-box">
          <h3>{t('Current domain')}</h3>
          <p className="mb-0">https://beeliked-ready-made-2-0.oooh.local</p>
        </div>

        <div className="content-box">
          <h3>{t('Disguise the microsite domain')}</h3>
          <p>
            {t(
              'To disguise the domain of your microsite, you can set up a subdomain which points to our servers. It’s best to keep this fairly generic so that it can be reused for future campaigns. Go for something like: promo.yourdomain.com or social.yourdomain.com etc.',
            )}
          </p>
          {!domainCollapse ? (
            <Button type="button" color="secondary" onClick={toggleDomain}>
              {t('Add a domain')}
            </Button>
          ) : (
            <div className={styles['config-url']}>
              <InputFormGroup label="Domain Url" placeholder="eg. http://competition.yourdomain.com" />
              <div className="d-flex mt-3">
                <Button type="button" color="primary">
                  {t('Save')}
                </Button>
              </div>
              <div className={styles['tech-list']}>
                <p>{t('Please note this is an advanced feature that requires some technical knowledge:')}</p>
                <ul>
                  <li>{t('Please note this is an advanced feature that requires some technical knowledge:')}</li>
                  <li>
                    {t(
                      'It is only possible to test domain masking and custom social apps when the campaign status is Live',
                    )}
                  </li>
                  <li>{t('Please contact your Account Manager for further details.')}</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
