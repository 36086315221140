import Audience from './Audience';
import Account from './Account';
import Auth from './Auth';
import AlertMessages from './AlertMessages';
import Coupon from './Coupon';
import Promotion from './Promotion';
import InviteLinks from './InviteLinks';
import Zapier from './Zapier';

const Handlers = [
  ...Audience,
  ...Account,
  ...Auth,
  ...Promotion,
  ...InviteLinks,
  ...AlertMessages,
  ...Coupon,
  ...Zapier,
];

export default Handlers;
