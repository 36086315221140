import { useRef, useState } from 'react';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Popover from '../../../../../../../componentsV2/DesignSystem/Popover';
import { Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { PopoverDetailsProps } from './interface';
import styles from './styles.module.scss';

export default function PopoverDetails({ invite }: PopoverDetailsProps): JSX.Element {
  const buttonPopoverDetailsRef = useRef<HTMLButtonElement>(null);

  const [showPopoverDetails, setShowPopoverDetails] = useState<boolean>(false);
  const [showPopoverAnimation, setShowPopoverAnimation] = useState<boolean>(false);
  const [showPopoverByClick, setShowPopoverByClick] = useState<boolean>(false);

  const handlePopoverDetails = (show: boolean) => {
    if (show) {
      setShowPopoverDetails(true);
      setTimeout(() => setShowPopoverAnimation(true), 200);
    } else {
      setShowPopoverAnimation(false);
      setTimeout(() => setShowPopoverDetails(false), 200);
    }
  };

  const handleShowPopoverByClick = () => {
    const popover = !showPopoverByClick;
    setShowPopoverByClick(popover);
    handlePopoverDetails(popover);
  };

  return (
    <>
      {invite.data && invite.data.length > 0 && (
        <>
          <Button
            firstIcon={{ children: 'person' }}
            size="sm"
            variant="secondary"
            onClick={handleShowPopoverByClick}
            {...(!showPopoverByClick && {
              onMouseEnter: () => handlePopoverDetails(true),
              onMouseLeave: () => handlePopoverDetails(false),
            })}
            ref={buttonPopoverDetailsRef}
          />
          {showPopoverDetails && (
            <Popover
              hiddenOverlay={!showPopoverByClick}
              propRef={buttonPopoverDetailsRef}
              showPopover={showPopoverAnimation}
              callback={() => handleShowPopoverByClick()}
              size="sm"
              header={{ title: `Details`, className: styles['popover-header'] }}
              showArrow
              color="dark"
              mainStyle={{ backgroundColor: 'transparent', paddingBottom: '0.75rem' }}
            >
              <>
                {invite.data?.map((person, index) => (
                  <div className={styles['popover-grid-row']} key={`person-${index}`}>
                    <div>
                      <Paragraph size={3}>{person.name}</Paragraph>
                    </div>
                    <div>
                      <Paragraph size={3}>{person.value}</Paragraph>
                    </div>
                  </div>
                ))}
              </>
            </Popover>
          )}
        </>
      )}
    </>
  );
}
