import React from 'react';
import { ZapierZap } from '../../../../ts';
import useZapierContext from '../ZapierContextProvider';
import styles from './styles.module.scss';
import ButtonNavigation from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';

interface Props {
  zap: ZapierZap;
}

export default function Item({ zap }: Props): JSX.Element {
  const { PATH_ZAPIER } = useZapierContext();

  return (
    <div>
      <div className={styles['wrapper-template']}>
        <div className={styles['align-logos-integrations']}>
          {zap.steps.map((step, index) => (
            <React.Fragment key={`step-${index}`}>
              <div className="logos-integrations">
                <img height="25" src={step?.app?.images?.url_64x64} />
              </div>
            </React.Fragment>
          ))}
        </div>
        <footer>
          <div className={styles['content-hover']}>
            <ButtonNavigation size="md" variant="secondary" to={`${PATH_ZAPIER}/edit/${zap.id}`}>
              Edit
            </ButtonNavigation>
          </div>
          <div className={styles['description']}>
            <div className="crop-text-line-1">
              <strong>{zap.title !== '' ? zap.title : 'No title'}</strong>
            </div>
            <div className="crop-text-line-2">{zap.state || 'No description'}</div>
          </div>
        </footer>
      </div>
    </div>
  );
}
