import { ApolloError, useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import logoImage from '../../../../../../assets/images/beeliked.svg';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import ButtonNavigation from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { FontWeight } from '../../../../../../componentsV2/DesignSystem/Typography/interface';
import useToast from '../../../../../../hooks/useToast';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import useSignupContext, { Steps } from '../../SignupContextProvider';
import { GENERATE_CODE_GQL } from '../../graphql';
import animate from '../../styles.module.scss';
import ValidationResolver from './ValidationResolver';
import styles from './styles.module.scss';

export default function StepInitial(): JSX.Element {
  const { animations, changeStep, updateUser, userData } = useSignupContext();
  const { toast } = useToast();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      return await executeRecaptcha();
    }
  }, [executeRecaptcha]);

  const {
    register,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: ValidationResolver,
  });

  const [generateCode, { loading }] = useMutation(GENERATE_CODE_GQL, {
    onCompleted: () => {
      updateUser({
        firstName: getValues('firstName'),
        lastName: getValues('lastName'),
        email: getValues('email'),
        accountName: getValues('account'),
        password: userData.password,
        passwordConfirmation: userData.passwordConfirmation,
      });
      changeStep(Steps.verifyCode, 'forward');
    },
    onError: (error: ApolloError) => {
      toast('error', error.message);
    },
  });

  const nextStep = handleSubmit(async (variables) => {
    const reCaptcha = await handleReCaptchaVerify();
    generateCode({ variables: { email: variables.email, reCaptcha } });
  });

  const watchFields = watch(['firstName', 'lastName', 'email', 'account']);

  const enableContinueButton = useMemo(() => {
    return watchFields.findIndex((field) => !field) > -1;
  }, [watchFields]);

  return (
    <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.initial])}>
      <img src={logoImage} alt="Our logo image" width={144} />
      <div className={styles['texts-wrapper']}>
        <Heading size={2}>{`Hey! Let's start setting up your account`}</Heading>
        <Heading size={6} weight={FontWeight.regular}>
          Creating a Beeliked account is fast, free, no credit card required.
        </Heading>
      </div>
      <form onSubmit={nextStep}>
        <Input
          label="First name"
          {...(errors?.firstName?.message && { color: 'danger', helperText: errors.firstName.message })}
          {...register('firstName')}
        />
        <Input
          label="Last name"
          {...(errors?.lastName?.message && { color: 'danger', helperText: errors.lastName.message })}
          {...register('lastName')}
        />
        <Input
          label="Email"
          {...(errors?.email?.message && { color: 'danger', helperText: errors.email.message })}
          {...register('email')}
        />
        <Input
          label="Account name - this name is used to identify your workspace"
          {...(errors?.account?.message && { color: 'danger', helperText: errors.account.message })}
          {...register('account')}
        />
        <Button fullWidth size="md" type="submit" loading={loading} disabled={enableContinueButton}>
          Continue
        </Button>
      </form>
      {/*
          @TODO
          <Paragraph size={2}> Or continue with</Paragraph>
          <div className={styles['social-media-login']}>
            <Button variant="secondary" fullWidth>
              Google
            </Button>
            <Button variant="secondary" fullWidth>
              LinkedIn
            </Button>
          </div>
        */}
      <div className={styles['sign-text']}>
        <Paragraph size={2}>Already have an account?</Paragraph>
        <ButtonNavigation to={`/signin`} variant="tertiary">
          Sign in
        </ButtonNavigation>
      </div>
    </div>
  );
}
