import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateWidget from './CreateWidget';
import WidgetList from './WidgetList';

export default function ShareWidgets(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={WidgetList} />
      <Route exact path={`${path}/create-widget/:id?`} component={CreateWidget} />
    </Switch>
  );
}
