import { gql } from '@apollo/client';

export const DASHBOARD_NEWS = gql`
  query dashboardNews {
    dashboardNews(pageSize: 4) {
      data {
        id
        title
        description
        url
        media {
          image_alt
          image
        }
        label {
          title
        }
      }
    }
  }
`;
