import { ApolloError, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import logoImage from '../../../../../../assets/images/beeliked.svg';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import ButtonNavigation from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import CheckBox from '../../../../../../componentsV2/DesignSystem/Inputs/CheckBox';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import { FontWeight, Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import useAuthContext from '../../../../../../hooks/useAuth';
import useQueryString from '../../../../../../hooks/useQueryString';
import useToast from '../../../../../../hooks/useToast';
import useSigninContext, { StepsEnum } from '../../../SigninContextProvider';
import { LOGIN_GQL } from '../../../graphql';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

export default function StepInitial(): JSX.Element {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setAuthorization } = useAuthContext();
  const [types, setTypes] = useState(true);
  const { t } = useTranslation('auth');
  const { toast } = useToast();
  const { animations, changeStep, enableSteps, setEnableSteps, updateLoginCredentials } = useSigninContext();
  const history = useHistory();
  const query = useQueryString();
  const formRef = useRef<HTMLFormElement>(null);
  const [remember, setRemember] = useState<boolean>(false);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const initialSchema = yup.object({
    email: yup.string().email('This email is not valid').required('This field is required'),
    password: yup.string().required('This field is required'),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(initialSchema),
    reValidateMode: 'onSubmit',
  });

  const email = register('email');

  useEffect(() => {
    if (animations.initial == 'center') {
      setTimeout(() => formRef?.current?.querySelectorAll('input')[0].focus(), 500);
      if (localStorage.getItem('beeliked-email')) {
        setRemember(true);
        checkboxRef?.current?.setAttribute('checked', 'true');
        setValue('email', localStorage.getItem('beeliked-email') ?? '');
        formRef?.current?.querySelectorAll('input')[1].focus();
      }
    }
  }, [animations]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      return await executeRecaptcha();
    }
  }, [executeRecaptcha]);

  const goToOtp = () => {
    if (remember) {
      localStorage.setItem('beeliked-email', getValues('email'));
    } else {
      localStorage.removeItem('beeliked-email');
    }
    setEnableSteps({ ...enableSteps, enableOtp: true });
    setTimeout(() => {
      if (Object.keys(errors).length == 0) {
        changeStep(StepsEnum.otp, true);
      }
    }, 500);
  };

  const goToForgot = () => [
    clearErrors(),
    setValue('email', ''),
    setValue('password', ''),
    setEnableSteps({ ...enableSteps, enableForgotPassword: true }),
    setTimeout(() => {
      changeStep(StepsEnum.forgotPassword, true);
    }, 500),
  ];

  const [login, { loading }] = useMutation(LOGIN_GQL, {
    onCompleted: (response) => {
      goToOtp();
      if (response.login.status === null) {
        setAuthorization(response.login);
        const urlParamenters = query.get('redirect') ? `?redirect=${query.get('redirect')}` : '';
        const redirect = `/account-manager${urlParamenters}`;
        history.push(redirect);
      }
    },
    onError: (error: ApolloError) => {
      const { extensions } = error?.graphQLErrors?.[0];
      if (extensions?.code === 'BAD_USER_INPUT') {
        const validations = extensions?.errors as Record<string, string>;
        Object.keys(validations).forEach((key: string) => {
          const fieldName = key.toLocaleLowerCase();
          switch (fieldName) {
            case 'email':
            case 'password':
              setError(fieldName, { message: validations[key], type: 'validate' });
              break;
            case 'recaptchatoken':
              toast('error', validations[key]);
              break;
          }
        });
      } else if (extensions?.code === 'UNAUTHENTICATED') {
        toast('error', t('That email and password combination is incorrect.'));
      } else {
        console.log(error.message);
      }
    },
  });

  const onSubmit = handleSubmit(async ({ email, password }) => {
    updateLoginCredentials({ email, password });

    const reCaptchaToken = await handleReCaptchaVerify();

    login({
      variables: {
        email,
        password,
        reCaptchaToken,
      },
    });
  });

  return (
    <>
      <div className={[styles['form-wrapper'], animate[animations.initial]].join(' ')}>
        <img src={logoImage} alt="Our logo image" width={144} />
        <div className={styles['texts-wrapper']}>
          <Heading size={2}>{`Welcome back`}</Heading>
          <Heading size={6} weight={FontWeight.regular}>
            Please enter your details
          </Heading>
        </div>
        <form onSubmit={onSubmit} ref={formRef}>
          <Input
            label="Email"
            placeholder="Your email adress"
            {...(errors?.email?.message && { color: 'danger', helperText: errors.email.message })}
            {...email}
            onChange={(e) => {
              email.onChange(e);
              clearErrors('email');
            }}
          />
          <Input
            type={types ? 'password' : 'text'}
            label="Password"
            placeholder="Your password"
            {...(errors?.password?.message && { color: 'danger', helperText: errors.password.message })}
            {...register('password')}
            icon={{
              name: types ? 'visibility' : 'visibility_off',
              onClick: () => setTypes(!types),
              style: { marginRight: '.25rem' },
            }}
            autoComplete="false"
          />

          <section>
            <CheckBox label={'Remember me'} ref={checkboxRef} onClick={() => setRemember(!remember)} />
            <Button variant="tertiary" onClick={goToForgot}>
              Forgot your password?
            </Button>
          </section>
          <Button fullWidth size="md" type="submit" loading={loading}>
            Login
          </Button>
        </form>
        {/* @TODO
      <Paragraph size={2}> Or continue with</Paragraph>
      <div className={styles['social-media-login']}>
        <Button variant="secondary" fullWidth>
          Google
        </Button>
        <Button variant="secondary" fullWidth>
          LinkedIn
        </Button>
      </div> */}
        <div className={styles['sign-text']}>
          <Paragraph size={2}>{`Don't have an account yet?`}</Paragraph>
          <ButtonNavigation to={'/signup'} variant="tertiary">
            Sign up
          </ButtonNavigation>
        </div>
      </div>
    </>
  );
}
