import React from 'react';
import { useTranslation } from 'react-i18next';
import ImgNotFound from '../../assets/imgs/img-404.svg';
import { REACT_APP_MONOLITH_V2 } from '../../config/Env';

export default function NotFound(): JSX.Element {
  const { t } = useTranslation('notFound');

  return (
    <main className="main-content">
      <div className="">
        <div className="content-box msg-error mt-5">
          <img src={ImgNotFound} />
          <h2>{t('Looks like were a little lost')}</h2>
          <p>{t('Never mind, go back home and start again.')}</p>
          <a href={REACT_APP_MONOLITH_V2} className="btn btn-primary">
            {t('Go to Home')}
          </a>
        </div>
      </div>
    </main>
  );
}
