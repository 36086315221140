import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { REACT_APP_TRANSLATION_LOCAL } from './config/Env';

let properties = undefined;

if (REACT_APP_TRANSLATION_LOCAL) {
  i18n.use(Backend);

  properties = {
    ns: [
      'account',
      'auth',
      'developer',
      'dragDropUpload',
      'editUserDetails',
      'pagination',
      'subscription',
      'template',
      'progressBar',
      'promotionsContentLaunch',
      'promotionsContentShare',
      'promotionsContentTop',
      'promotionsList',
      'forbidden',
      'notFound',
      'rewards',
      'zapier',
    ],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
      requestOptions: {
        cache: 'no-cache',
      },
    },
  };
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      load: 'languageOnly',
      fallbackLng: 'en',
      debug: false,
      nonExplicitSupportedLngs: true,
      interpolation: {
        escapeValue: false,
      },
      ...properties,
    },
    () => {
      if (i18n.language.startsWith('en-')) {
        i18n.changeLanguage('en');
      }
    },
  );

export default i18n;
