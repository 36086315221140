import { KeyboardEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ButtonPopoverConfirm from '../../../../../../../componentsV2/ButtonPopoverConfirm';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../../../componentsV2/DesignSystem/Inputs/Input';
import RadioBox from '../../../../../../../componentsV2/DesignSystem/Inputs/RadioBox';
import Select from '../../../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import useShareLinks from '../../../ShareLinksProvider';
import { ItemProps } from './interface';
import styles from './styles.module.scss';

export default function Item({
  index,
  removeField,
  listFields,
  updateField,
  handleInputField,
  showUidField,
}: ItemProps): JSX.Element {
  const [edit, setEdit] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { setUidField, uidField } = useShareLinks();

  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  const listFieldsFormated =
    listFields?.map((filed) => ({
      label: filed.name,
      value: filed.slug,
    })) || [];

  const editField = () => {
    setEdit(true);
  };

  const saveField = () => {
    setError('');
    const value = getValues(`selectEdit-${index}`);
    if (!value) {
      setError('Please provide a valid value.');
    } else {
      setEdit(false);
      updateField(index, value);
    }
  };

  const deleteField = () => {
    setEdit(false);
    removeField(index);
  };

  const handleTabPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      const nextInput = document.getElementById(`fields.${index + 1}.value`);
      if (nextInput) {
        nextInput.focus();
      } else {
        document.getElementById('add-new-field')?.focus();
      }
    }
  };

  const handleUidField = (value: string) => {
    setUidField(value);
  };

  const keyValue = getValues(`fields.${index}.slug`);
  const field = listFields?.find((fieldValue) => fieldValue.slug === keyValue);

  return (
    <div className={styles['content-new-field']}>
      <div className={styles['select-content']}>
        {edit ? (
          <Controller
            control={control}
            name={`selectEdit-${index}`}
            rules={{ required: true }}
            defaultValue={keyValue}
            render={({ field: { onChange, value } }) => (
              <Select
                label="Select new field"
                placeholder="Select"
                onChange={(value) => onChange(value)}
                options={listFieldsFormated}
                size="sm"
                defaultValue={value}
                {...(!!error && {
                  helperText: error,
                  color: 'red-block',
                })}
              />
            )}
          />
        ) : (
          <Controller
            key={index}
            name={`fields.${index}.value`}
            control={control}
            defaultValue=""
            rules={{ required: 'Please provide a valid value.' }}
            render={({ field }) => (
              <Input
                {...field}
                label={listFieldsFormated?.find((fieldValue) => fieldValue.value === keyValue)?.label}
                {...(Boolean(errors.fields?.[index]?.value) && {
                  helperText: errors.fields?.[index]?.value.message,
                  color: 'danger',
                })}
                inputSize="sm"
                id={`fields.${index}.value`}
                onFocus={() => handleInputField('focus')}
                onKeyDown={handleTabPress}
              />
            )}
          />
        )}

        <div className={styles['wrapper-buttons']}>
          {edit ? (
            <>
              <Button variant="secondary" firstIcon={{ children: 'check' }} size="sm" onClick={saveField} />
              <ButtonPopoverConfirm
                buttonProps={{
                  variant: 'secondary',
                  firstIcon: { children: 'delete', color: 'red-block' },
                  size: 'sm',
                }}
                popoverProps={{ header: { title: 'Are you sure you want to delete this field?' } }}
                onClick={() => deleteField()}
              />
            </>
          ) : (
            <Button variant="secondary" firstIcon={{ children: 'edit' }} size="sm" onClick={() => editField()} />
          )}
        </div>
      </div>
      {!edit && field?.is_uid_field && showUidField && (
        <div className={styles['uid-field']}>
          <RadioBox
            name="uid-fiel-name"
            label="UID Field"
            onChange={() => handleUidField(keyValue)}
            checked={keyValue === uidField}
            radioSize="md"
          />
        </div>
      )}
    </div>
  );
}
