import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateRewardsPlugin from './CreateRewardsPlugin';
import RewardsPluginList from './RewardsPluginList';

export default function RewardsPlugin(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={RewardsPluginList} />
      <Route exact path={`${path}/create-plugin/:id?`} component={CreateRewardsPlugin} />
    </Switch>
  );
}
