import { ReactNode } from 'react';
import { ActionProps } from '../Inputs/Buttons/ButtonAction/interface';
import { NavigationProps } from '../Inputs/Buttons/ButtonNavigation/interface';
import { PopoverExtendedProps } from '../Popover/interface';

export interface CardProps {
  size?: 'sm' | 'md' | 'lg';
  header: { title: string; breakLineOnMobile?: boolean; headerContent?: ReactNode; removeFillTitle?: boolean };
  animation?: { type: CardAnimations; duration: string };
  identify?: string;
  children: ReactNode;
  footer?: { align?: string; buttons?: NavigationProps[]; leftButton?: ActionProps; rightButton?: ActionProps };
  border?: boolean;
  showButton?: ActionProps;
  showButtonPopover?: PopoverExtendedProps;
}

export enum CardAnimations {
  top = 'slide-from-top',
  right = 'slide-from-right',
  bottom = 'slide-from-bottom',
}
