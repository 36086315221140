import Dropdown from '../../../../../componentsV2/DesignSystem/Dropdown';
import { Button } from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { FontWeight, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';

import styles from '../styles.module.scss';
import { DropdownSelectFiltersProps } from './interface';

export function DropdownSelectFilters({ filters, toggleFilters }: DropdownSelectFiltersProps): JSX.Element {
  return (
    <Dropdown
      activeButton={{
        button: {
          variant: 'secondary',
          firstIcon: { children: 'filter_list' },
          lastIcon: { children: 'expand_more', color: 'gray-56' },
          children: 'Filters',
        },
      }}
      size="xs"
      placement="bottom-start"
    >
      <div className={styles['wrapper-manage-filters']}>
        {filters.map((filter) => (
          <Button
            fullWidth
            variant="tertiary"
            text={{ color: 'dark', align: 'between' }}
            lastIcon={{
              children: filter.enable ? 'visibility' : 'visibility_off',
              ...(filter.enable && { color: 'primary' }),
            }}
            key={filter.id}
            onClick={() => toggleFilters(filter.id)}
          >
            <Paragraph size={2} weight={FontWeight.semiBold}>
              {filter.name}
            </Paragraph>
          </Button>
        ))}
      </div>
    </Dropdown>
  );
}
