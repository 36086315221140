import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import ButtonNavigation from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import Modal from '../../../../../../componentsV2/DesignSystem/Modal';
import styles from '../styles.module.scss';

export default function NoDraftPromotions(): JSX.Element {
  const [showModal, setShowModal] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer | null>(null);

  return (
    <>
      {showModal && (
        <Modal title="Tutorial coupon collections" callback={() => setShowModal((prev) => !prev)}>
          <ReactPlayer
            url="https://vimeo.com/817678310"
            width="100%"
            style={{ maxHeight: 440 }}
            height="50vw"
            controls
            ref={playerRef}
          />
        </Modal>
      )}
      <div className={styles['wrapper-no-promotions']}>
        <main>
          <h3>You have no draft promotions yet</h3>
          <span>
            See what you can create by trying out a new game of chance. You can customize it as much as you like until
            it’s ready to launch.
          </span>
          <div className={styles['buttons-wrapper']}>
            <ButtonNavigation fullWidth variant="primary" to={'/promotions/create'}>
              Create Promotion
            </ButtonNavigation>
            <Button
              fullWidth
              variant="tertiary"
              firstIcon={{ children: 'ondemand_video' }}
              size="sm"
              onClick={() => setShowModal((prev) => !prev)}
            >
              See what you can do
            </Button>
          </div>
        </main>
        <aside>
          <img
            src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/you-have-no-promotions.png"
            alt="You have no promotion"
          />
        </aside>
      </div>
    </>
  );
}
