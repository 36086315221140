/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import { FontWeight } from '../../../../../../componentsV2/DesignSystem/Typography/interface';
import useToast from '../../../../../../hooks/useToast';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import useSignupContext, { Steps } from '../../SignupContextProvider';
import { GENERATE_CODE_GQL, VERIFY_CODE_GQL } from '../../graphql';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

export default function StepVerifyCode(): JSX.Element {
  const [showInput, setShowInput] = useState<boolean>(false);
  const { animations, changeStep, userData, updateUser } = useSignupContext();
  const { verifyCode } = useParams<{ verifyCode: string }>();
  const button = useRef<HTMLButtonElement>(null);
  const [newEmail, setNewEmail] = useState<string>('');
  const { toast } = useToast();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      return await executeRecaptcha();
    }
  }, [executeRecaptcha]);

  const initialSchema = yup.object({
    confirm_code: yup
      .string()
      .required('The code field is required')
      .length(6, 'The code must have exactly 6 characters'),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(initialSchema),
    reValidateMode: 'onSubmit',
  });

  const code = register('confirm_code');

  useEffect(() => {
    if (verifyCode) {
      setValue('confirm_code', verifyCode);
    }
  }, [verifyCode]);

  useMemo(() => {
    setNewEmail(userData.email);
  }, [userData]);

  const confirmCode = watch('confirm_code');

  const enableContinueButton = useMemo(() => {
    return confirmCode && confirmCode.length === 6;
  }, [confirmCode]);

  const [verify, { loading }] = useMutation(VERIFY_CODE_GQL, {
    onCompleted: () => {
      changeStep(Steps.setPassword, 'forward');
    },
    onError: (error: ApolloError) => {
      if (error?.graphQLErrors?.[0].extensions?.code) {
        if (error?.graphQLErrors?.[0].extensions?.code === 'BAD_USER_INPUT') {
          const validations = error?.graphQLErrors?.[0].extensions?.errors as Record<string, string>;
          if (validations && validations['confirm_code']) {
            setError('confirm_code', { message: validations['confirm_code'].toString(), type: 'validate' });
          }
        }
      }
    },
  });

  const handleEdit = () => {
    setShowInput(!showInput);
  };

  const [generate, { loading: newEmailLoading }] = useMutation(GENERATE_CODE_GQL, {
    onCompleted: () => {
      toast('success', 'Code generated with success, please check your email!');
      setShowInput(false);
    },
    onError: () => {
      toast('error', 'Error to generate verification code, please try to resend.');
    },
  });

  const sendCodeNewEmail = async () => {
    if (newEmail.trim().length == 0) {
      return setNewEmail(userData.email);
    }
    const reCaptcha = await handleReCaptchaVerify();
    updateUser({ ...userData, email: newEmail });
    generate({ variables: { email: userData.email, reCaptcha } });
  };

  const nextStep = handleSubmit((variables) =>
    verify({
      variables: {
        email: userData.email,
        code: variables.confirm_code,
      },
    }),
  );

  const previousStep = () => changeStep(Steps.verifyCode, 'back');

  return (
    <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.verifyCode])}>
      <IconAnimated size="md" icon={{ name: 'email' }} wrapper={{ color: 'primary' }} />
      <Heading size={3}>Check your email</Heading>
      <div className={styles['wrapper-paragraph']}>
        <Heading size={6} weight={FontWeight.semiBold}>
          We sent a verification code to:
        </Heading>
        <div>
          {showInput ? (
            <>
              <Input
                placeholder="Your email adress"
                inputSize="sm"
                fullwidth
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              <Button
                firstIcon={{ children: 'check' }}
                size="sm"
                variant="primary"
                loading={newEmailLoading}
                onClick={sendCodeNewEmail}
              />
              <Button
                firstIcon={{ children: 'close' }}
                size="sm"
                variant="tertiary"
                onClick={() => [handleEdit(), setNewEmail(userData.email)]}
              />
            </>
          ) : (
            <>
              <Heading size={6} weight={FontWeight.regular}>
                {userData.email}
              </Heading>
              {/*
                @TODO: need to be reviewed
                <Button firstIcon={{ children: 'edit' }} size="sm" variant="tertiary" onClick={handleEdit} />
              */}
            </>
          )}
        </div>
      </div>
      <form onSubmit={nextStep}>
        {/* <TwoFAInput
          register={register}
          setValue={setValue}
          setFocus={setFocus}
          trigger={trigger}
          button={button.current}
        /> */}

        <div className={styles['wrapper-buttons']}>
          <Input
            {...(errors.confirm_code?.message && { color: 'danger', helperText: errors.confirm_code.message })}
            {...code}
            onChange={(e) => {
              code.onChange(e);
              clearErrors('confirm_code');
            }}
            fullwidth
            className={styles['wrapper-verify-code']}
            maxLength={6}
          />
          <Button fullWidth ref={button} type="submit" loading={loading} disabled={!enableContinueButton}>
            Continue
          </Button>
          <Button fullWidth variant="tertiary" firstIcon={{ children: 'keyboard_backspace' }} onClick={previousStep}>
            Previous step
          </Button>
        </div>
      </form>
      <div className={styles['resend-text-wrapper']}>
        <Paragraph size={2} weight={FontWeight.semiBold}>{`Didn't receive the email? `}</Paragraph>
        <Button variant="tertiary" onClick={sendCodeNewEmail}>
          Click to resend
        </Button>
      </div>
    </div>
  );
}
