import React, { useMemo, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { PromoteListData, PromoteStatus, PromotionCounter } from '../../../../../ts';
import useAccount from '../../../../../hooks/useAccount';
import { LIST_PROMOTION_GQL, PROMOTION_COUNTER } from '../../../Campaign/List/graphql';
import Card from '../../../../../componentsV2/DesignSystem/Card';
import PromotionCounterNav from './PromotionCounterNav';
import SkeletonLoading from './PromotionContent/SkeletonLoading';
import SkeletonLoadingHeader from './PromotionContentHeader/SkeletonLoading';
import PromotionContent from './PromotionContent';
import NoPromotions from './NoPromotions';
import PromotionContentHeader from './PromotionContentHeader';
import styles from './styles.module.scss';
import ErrorRefetch from '../../../../../componentsV2/ErrorRefetch';

export default function Promotions(): JSX.Element {
  const { getAccount } = useAccount();
  const [promoteStatusSelected, setPromoteStatusSelected] = useState<PromoteStatus>();

  const {
    data: dataPromotionCounter,
    loading: loadingCounter,
    error: errorCounter,
    refetch: refetchCounter,
  } = useQuery<{
    promotionCounter: PromotionCounter;
  }>(PROMOTION_COUNTER, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId: getAccount.accountId,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'PROMOTION_COUNTER',
        variables: {
          accountId: getAccount.accountId,
        },
        apolloError: error,
      });
    },
  });

  const promotionStatusDefault = useMemo(() => {
    if (!dataPromotionCounter) {
      return;
    }

    if (dataPromotionCounter.promotionCounter.live) {
      return PromoteStatus.LIVE;
    } else if (dataPromotionCounter.promotionCounter.draft) {
      return PromoteStatus.DRAFT;
    } else if (dataPromotionCounter.promotionCounter.ended) {
      return PromoteStatus.ENDED;
    }
    return PromoteStatus.LIVE;
  }, [dataPromotionCounter]);

  const promotionsStatusList = useMemo(() => {
    if (!promotionStatusDefault) {
      return;
    }

    const values = dataPromotionCounter?.promotionCounter;

    if (values?.live === 0 && values?.draft === 0 && values?.ended === 0) {
      return;
    }

    return [
      {
        title: 'Live',
        quantity: values?.live ?? 0,
        selected: promotionStatusDefault === PromoteStatus.LIVE,
        onClick: () => handlePromotion(PromoteStatus.LIVE),
        status: PromoteStatus.LIVE,
      },
      {
        title: 'Draft',
        quantity: values?.draft ?? 0,
        selected: promotionStatusDefault === PromoteStatus.DRAFT,
        onClick: () => handlePromotion(PromoteStatus.DRAFT),
        status: PromoteStatus.DRAFT,
      },
      {
        title: 'Ended',
        quantity: values?.ended ?? 0,
        selected: promotionStatusDefault === PromoteStatus.ENDED,
        onClick: () => handlePromotion(PromoteStatus.ENDED),
        status: PromoteStatus.ENDED,
      },
    ];
  }, [dataPromotionCounter, promotionStatusDefault]);

  const {
    data: dataPromotions,
    loading: loadingPromotions,
    error: errorPromotions,
    refetch: refetchPromotion,
  } = useQuery<{ promotions: { data: PromoteListData[] } }>(LIST_PROMOTION_GQL, {
    fetchPolicy: 'no-cache',
    skip: !dataPromotionCounter && !promotionsStatusList,
    notifyOnNetworkStatusChange: true,
    variables: {
      account_id: getAccount.accountId,
      filters: {
        status: promotionStatusDefault,
      },
      limit: 3,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'LIST_PROMOTION_GQL',
        variables: {
          accountId: getAccount.accountId,
          status: promotionStatusDefault,
        },
        apolloError: error,
      });
    },
  });

  const refetchQueries = () => {
    refetchCounter({
      accountId: getAccount.accountId,
    });
    refetchPromotion({
      account_id: getAccount.accountId,
      filters: { status: promotionStatusDefault },
    });
  };

  const handlePromotion = (status: PromoteStatus) => {
    setPromoteStatusSelected(status);
    refetchPromotion({
      account_id: getAccount.accountId,
      filters: { status },
    });
  };

  const promotions = useMemo(() => {
    return dataPromotions?.promotions?.data ?? [];
  }, [dataPromotions?.promotions?.data]);

  const showHeader = useMemo(() => {
    if (!promotionsStatusList) {
      return false;
    }
    return promotionsStatusList?.find((item) => {
      const status = promoteStatusSelected ? promoteStatusSelected : promotionStatusDefault;
      return item.status === status;
    })?.quantity;
  }, [promoteStatusSelected, promotionStatusDefault]);

  if (errorCounter || errorPromotions) {
    return (
      <Card
        identify="promotions"
        size="lg"
        header={{
          title: 'Promotions',
        }}
      >
        <ErrorRefetch callback={refetchQueries} />
      </Card>
    );
  }

  if (!promotionsStatusList && !loadingCounter) {
    return <NoPromotions />;
  }

  return (
    <Card
      identify="promotions"
      size="lg"
      header={{
        title: 'Promotions',
        breakLineOnMobile: true,
        headerContent: (
          <PromotionCounterNav
            promotionsCounterList={promotionsStatusList}
            loading={loadingCounter}
            selected={promoteStatusSelected}
          />
        ),
      }}
      footer={{
        buttons: [
          {
            children: 'View All',
            variant: 'tertiary',
            lastIcon: { children: 'chevron_right' },
            to: `promotions#${promoteStatusSelected ? promoteStatusSelected : promotionStatusDefault}`,
          },
        ],
      }}
    >
      <div className={styles['promotions-wrapper']}>
        {loadingCounter && !promotionsStatusList ? (
          <SkeletonLoadingHeader />
        ) : (
          <PromotionContentHeader show={Boolean(showHeader || loadingPromotions)} />
        )}

        <ul className={styles['promotions-grid']}>
          {loadingPromotions || loadingCounter ? (
            <SkeletonLoading />
          ) : (
            <PromotionContent
              promotions={promotions}
              status={promoteStatusSelected ? promoteStatusSelected : promotionStatusDefault || PromoteStatus.DRAFT}
            />
          )}
        </ul>
      </div>
    </Card>
  );
}
