import { NavigationProfileLink } from './interface';

const NavigationProfileLinks: NavigationProfileLink[] = [
  {
    icon: 'view_carousel',
    title: 'Subscription',
    url: `/self-service`,
  },
  {
    icon: 'supervisor_account',
    title: 'Manage users',
    url: `/settings/manage-users`,
  },
];

export default NavigationProfileLinks;
