import { useEffect, useState } from 'react';
import { REACT_APP_ASSETS_IMAGES } from '../../config/Env';
import useAccount from '../../hooks/useAccount';
import styleModuleClasses from '../../utils/styleModuleClasses';
import styles from './styles.module.scss';

export default function RewardPluginNotification(): JSX.Element {
  const { getAccount } = useAccount();
  const [loading, setLoading] = useState(false);
  const [promotionCount, setPromotionCount] = useState<string>('0');

  const openRewardPluginModal = () => {
    window.postMessage({ action: 'openRewardPlugin', embed: 'Popup' }, '*');
  };

  const setRewardPluginPromotionCount = (total: number) => {
    const count = total > 99 ? '99+' : total.toString();
    setPromotionCount(count);
  };

  const handleMessage = (event: MessageEvent) => {
    if (event.data.action === 'updateRewardPluginInviteCount') {
      const { numberOfInvites } = event.data;
      setRewardPluginPromotionCount(numberOfInvites);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (!getAccount.rewardPlugin) {
      return;
    }
    setRewardPluginPromotionCount(getAccount.rewardPlugin.promotionCount);
  }, [getAccount.rewardPlugin]);

  useEffect(() => {
    const pictures = [
      `${REACT_APP_ASSETS_IMAGES}reward-icon-on.png`,
      `${REACT_APP_ASSETS_IMAGES}reward-background-on.png`,
    ];
    pictures.forEach((picture) => {
      const img = new Image();
      img.src = picture;
    });
    setTimeout(() => setLoading(true), 2000);
  }, []);

  return (
    <div
      className={styleModuleClasses(styles, 'reward-plugin-wrapper', { glow: loading && promotionCount !== '0' })}
      onClick={openRewardPluginModal}
    >
      {promotionCount !== '0' ? (
        <>
          <img
            src={`${REACT_APP_ASSETS_IMAGES}reward-icon-on.png`}
            alt="Reward Plugin"
            className={styleModuleClasses(styles, 'gift', { giftAnimation: loading, slideDown: loading })}
          />
          <img
            src={`${REACT_APP_ASSETS_IMAGES}reward-background-on.png`}
            alt="Reward Plugin"
            className={styleModuleClasses(styles, 'hexagon', { backgroundAnimation: loading })}
          />
          <div className={styleModuleClasses(styles, 'count-notify', { fadeIn: loading })}>
            <span>{promotionCount}</span>
          </div>
        </>
      ) : (
        <img src={`${REACT_APP_ASSETS_IMAGES}reward-plugin-off.png`} alt="Reward Plugin" />
      )}
    </div>
  );
}
