import React from 'react';
import { useTranslation } from 'react-i18next';
import Apps from './Apps';
import Templates from './Templates';
import styles from './styles.module.scss';
import SectionCollapsibleFull from '../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import { useHistory } from 'react-router-dom';

export default function Build(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'build' });
  const history = useHistory();

  return (
    <SectionCollapsibleFull
      expanded={true}
      header={{ title: t('Create a Zap') }}
      badge={{ status: StatusBadge.icon, icon: { name: 'groups', color: 'enabled' } }}
      disableCollapse
      wrapperClassname={styles['wrapper-integration']}
      showButton={{
        children: 'Back to My Zaps',
        firstIcon: { children: 'chevron_left' },
        variant: 'secondary',
        onClick: () => history.push('/settings/integrations'),
      }}
    >
      <Apps />
      <Templates />
    </SectionCollapsibleFull>
  );
}
