import { IconAnimated } from '../../../../../componentsV2/DesignSystem/IconAnimated';
import styles from './styles.module.scss';

interface AccountsEmptyProps {
  search?: boolean;
}

export default function AccountsEmpty({ search }: AccountsEmptyProps): JSX.Element {
  return (
    <div className={styles['empty-message']}>
      <IconAnimated wrapper={{ color: 'primary' }} icon={{ name: search ? 'search_off' : 'videogame_asset_off' }} />
      <h4>{search ? 'No accounts found that match the search criteria.' : 'There are no account to show here.'}</h4>
    </div>
  );
}
