import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertNoContent from '../../../../components/AlertNoContent';
import useZapierContext from '../ZapierContextProvider';
import styles from './styles.module.scss';
import ButtonNavigation from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';

export default function EmptyZaps(): JSX.Element {
  const { t } = useTranslation('zapier', { keyPrefix: 'my-zaps' });
  const { PATH_ZAPIER } = useZapierContext();

  return (
    <AlertNoContent className={styles['no-content']}>
      <p>{t('There is no zap registered.')}</p>
      <ButtonNavigation variant="secondary" to={`${PATH_ZAPIER}/build`}>
        {t('Create a new Zap')}
      </ButtonNavigation>
    </AlertNoContent>
  );
}
