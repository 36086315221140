import { ApolloError, gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { paginatorInfoFragment } from '../../components/Pagination/paginator.gql';
import { InviteInbulkGenerator, SimplePaginationInfo, paginatorInfo } from '../../ts';

const INVITELINKS_GENERATOR = gql`
  query inviteLinksGenerator(
    $agency_id: ID!
    $account_id: ID!
    $promo_id: ID
    $page: Int
    $filters: InviteLinksInBulkFilter
    $limit: Int
  ) {
    inviteLinksGenerator(
      agency_id: $agency_id
      account_id: $account_id
      promo_id: $promo_id
      page: $page
      filters: $filters
      limit: $limit
    ) {
      data {
        id
        agency_id
        account_id
        name
        promotion_id
        user_id
        type
        url
        quantity
        generated_quantity
        origin_file
        generated_file
        file_headers
        failed_rows
        failed_attempts
        failed_message
        expires_at
        status
        generated_at
        created_at
        user {
          first_name
          last_name
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

interface InviteLinkGeneratorFilters {
  order?: string;
  sort?: string;
}

interface InvitesGeneratorResult {
  loading: boolean;
  data: InviteInbulkGenerator[];
  pagination: SimplePaginationInfo;
}

export function useFetchInvitesGenerator(
  agencyId: number,
  accountId: number,
  promoId: number | undefined,
  currentPage: number,
  filters: InviteLinkGeneratorFilters | undefined,
  limit?: number,
): InvitesGeneratorResult {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: 10,
  });

  const { data, loading } = useQuery<{
    inviteLinksGenerator: { data: InviteInbulkGenerator[]; paginatorInfo: paginatorInfo };
  }>(INVITELINKS_GENERATOR, {
    fetchPolicy: 'no-cache',
    variables: {
      agency_id: agencyId,
      account_id: accountId,
      promo_id: promoId,
      page: currentPage,
      filters,
      limit,
    },
    onError: (error: ApolloError) => {
      alert(error.message);
    },
  });

  useEffect(() => {
    if (data?.inviteLinksGenerator.paginatorInfo) {
      setPagination({
        total: data.inviteLinksGenerator.paginatorInfo.total,
        currentPage: data.inviteLinksGenerator.paginatorInfo.current_page,
        totalPerPage: Number(data.inviteLinksGenerator.paginatorInfo.per_page),
      });
    }
  }, [data?.inviteLinksGenerator.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: [],
      pagination,
    };
  }

  return {
    loading: false,
    data: data?.inviteLinksGenerator?.data || [],
    pagination,
  };
}
