import { useEffect, useState } from 'react';
import Card from '../../../../../componentsV2/DesignSystem/Card';
import { CardAnimations } from '../../../../../componentsV2/DesignSystem/Card/interface';
import { Button } from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Modal from '../../../../../componentsV2/DesignSystem/Modal';
import Skeleton from '../../../../../componentsV2/DesignSystem/Skeleton';
import { FontWeight, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import { InvitesContentProps } from './interface';
import styles from './styles.module.scss';

export default function BeeSpoke(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true);
  const [scheduleModal, setScheduleModal] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoading(false), 4500);
  }, []);

  const invitesContent: InvitesContentProps = {
    main: {
      title: 'Win prizes simply for using BeeLiked',
      description:
        'Boost your business with us! We create stunning designs, manage your campaigns, and provide priority support. Achieve success now!',
    },
    aside: {
      image: {
        image_url: 'https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/beespoke-image.svg',
        image_alt: 'BeeSpoke Solutions image',
      },
    },
  };

  return (
    <>
      <Card
        identify="invites"
        size="sm"
        animation={{ type: CardAnimations.top, duration: '2s' }}
        header={{ title: 'BeeSpoke Solutions' }}
      >
        <div className={styles['invites-wrapper']}>
          <main>
            {loading ? (
              <>
                <Skeleton width={70} height={0.75} />
                <Skeleton width={90} height={0.75} />
                <Skeleton width={45} height={0.75} />

                <Button variant="primary" disabled loading size="sm">
                  Schedule a call
                </Button>
              </>
            ) : (
              <>
                <Paragraph size={2} weight={FontWeight.semiBold}>
                  {invitesContent.main?.description}
                </Paragraph>
                <Button variant="primary" size="sm" onClick={() => setScheduleModal(true)}>
                  Schedule a call
                </Button>
              </>
            )}
          </main>
          <aside>
            {loading ? (
              <Skeleton />
            ) : (
              <img src={invitesContent.aside.image.image_url} alt={invitesContent.aside.image.image_alt} />
            )}
          </aside>
        </div>
      </Card>
      {scheduleModal && (
        <Modal title="Schedule" callback={() => setScheduleModal(false)} size="fullscreen">
          <iframe src="https://support.beeliked.com/meetings/damian-dutton/beeliked-solutions" height="100%"></iframe>
        </Modal>
      )}
    </>
  );
}
