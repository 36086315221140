import { lazy } from 'react';
import Account from '../../pages/Admin/Account';
import CheckAccount from '../../pages/Admin/Account/CheckAccount';
import Audience from '../../pages/Admin/Audience';
import Dashboard from '../../pages/Admin/Dashboard';
import Logout from '../../pages/Admin/Logout';
import ShareCenter from '../../pages/Admin/ShareCenter';
import Zapier from '../../pages/Admin/Zapier';
import Forbidden from '../../pages/Forbidden';
import Reload from '../../pages/Reload';

const Campaign = lazy(() => import('../../pages/Admin/Campaign'));
const Developer = lazy(() => import('../../pages/Admin/Developer'));
const Rewards = lazy(() => import('../../pages/Admin/Rewards'));
const CreatePromotion = lazy(() => import('../../pages/Admin/CreatePromotion'));
const SelfService = lazy(() => import('../../pages/Admin/SelfService'));
const Settings = lazy(() => import('../../pages/Admin/Settings'));

export const ROUTES_ADMIN = [
  {
    component: Settings,
    name: 'Settings',
    path: ['/settings', '/:slug/settings'],
  },
  {
    component: Dashboard,
    name: 'Dashboard',
    path: ['/dashboard', '/:slug/dashboard'],
  },
  {
    component: CreatePromotion,
    name: CreatePromotion,
    path: ['/promotions/create', '/:slug/promotions/create'],
  },
  {
    component: Campaign,
    name: 'Promotions',
    path: ['/promotions', '/:slug/promotions'],
  },
  {
    component: Account,
    name: 'Account',
    path: ['/account', '/:slug/account'],
  },
  {
    component: ShareCenter,
    name: 'Share Center',
    path: ['/share-center', '/:slug/share-center'],
  },
  {
    component: Audience,
    name: 'Data Center',
    path: ['/data-center', '/:slug/data-center'],
  },
  {
    component: Forbidden,
    name: 'Forbidden',
    path: '/forbidden',
  },
  {
    component: CheckAccount,
    name: 'CheckAccount',
    path: '/r/:slug',
  },
  {
    component: Developer,
    path: '/developer',
  },
  {
    component: Zapier,
    path: '/zapier',
  },
  {
    component: Rewards,
    path: '/rewards',
  },
  {
    component: SelfService,
    path: '/self-service',
  },
  {
    component: Reload,
    path: '/reload',
    exact: true,
  },
  {
    component: Logout,
    path: '/logout',
    exact: true,
  },
];
