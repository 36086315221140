import React, { forwardRef } from 'react';
import { IconButtonProps } from './interface';
import styles from './styles.module.scss';
import Icon from '../../Icon';

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, customObject, size, classname, text, fill, ...rest }: IconButtonProps, ref?) => (
    <button
      ref={ref}
      className={[styles['icon-button'], size ? styles[size] : styles['md'], classname].join(' ')}
      {...rest}
    >
      {customObject && customObject}
      <Icon fill={fill}>{children}</Icon>
      {text && <strong>{text}</strong>}
    </button>
  ),
);

IconButton.displayName = 'IconButton';

export { IconButton };
