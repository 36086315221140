import { useEffect, useState } from 'react';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import SpinnerBox from '../../../../../../componentsV2/DesignSystem/SpinnerBox';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import Pagination from '../../../../../../componentsV2/Pagination';
import useAccount from '../../../../../../hooks/useAccount';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import List from './List';
import WidgetUserForm from './WidgetUserForm';
import { ContentProps } from './interface';
import styles from './styles.module.scss';
import { useFetchWidgetUsers } from './useFetchWidgetUsers';

export default function Content({ widgetId, widgetSlug }: ContentProps): JSX.Element {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { getAccount } = useAccount();
  const { data, fetchWidgetUsers, loading, pagination } = useFetchWidgetUsers(getAccount.accountId, widgetId);

  useEffect(() => {
    if (pagination?.currentPage) {
      setCurrentPage(pagination?.currentPage);
    }
  }, []);

  useEffect(() => {
    if (currentPage > 1 && data !== null && data.length === 0) {
      handlePagination(currentPage - 1);
    }
  }, [data]);

  const toggleForm = () => setShowForm(!showForm);

  const handlePagination = (page: number) => {
    setCurrentPage(page);

    fetchWidgetUsers?.({
      accountId: getAccount.accountId,
      widgetId,
      page,
    });
  };

  return (
    <>
      <Paragraph size={2} weight={FontWeight.semiBold}>
        Grant secure, individual access to team members so they can generate unique QR codes from their devices to
        invite customers to play. This ensures accountability, eliminates shared access codes, and allows you to track
        and reward their success.
      </Paragraph>

      {showForm && !loading && (
        <WidgetUserForm
          toggleForm={toggleForm}
          widgetId={widgetId}
          fetchWidgetUsers={fetchWidgetUsers}
          widgetSlug={widgetSlug}
        />
      )}

      {!loading ? (
        <>
          {!showForm && (
            <div
              className={styleModuleClasses(styles, {
                'button-create-center': !data?.length,
                'button-create-left': !!data?.length,
              })}
            >
              <Button firstIcon={{ children: 'link' }} variant="secondary" size="sm" onClick={toggleForm}>
                {!!data?.length ? 'Create new user' : 'Create your first user'}
              </Button>
            </div>
          )}
          {!!data?.length && <List widgetUsers={data} widgetId={widgetId} fetchWidgetUsers={fetchWidgetUsers} />}
          <footer>
            <Pagination
              total={pagination?.total}
              currentPage={currentPage}
              totalPerPage={pagination?.totalPerPage || 10}
              setPage={handlePagination}
            />
          </footer>
        </>
      ) : (
        <>
          <div className={styles['spinner-container']}>
            <SpinnerBox />
          </div>
        </>
      )}
    </>
  );
}
