import { gql } from '@apollo/client';

export const ACCOUNTS = gql`
  query accounts($agency_id: ID!, $user_id: ID) {
    accounts(agency_id: $agency_id, user_id: $user_id) {
      id
      name
    }
  }
`;

export const GET_ACCOUNT_SLUG = gql`
  query account($agency_id: ID!, $account_id: ID!) {
    account(agency_id: $agency_id, account_id: $account_id) {
      slug
    }
  }
`;
