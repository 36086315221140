import { ChangeEvent, useEffect, useState } from 'react';
import ButtonPopoverUpgradePlan from '../../../../../../../componentsV2/ButtonPopoverUpgradePlan';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../../../componentsV2/DesignSystem/Inputs/Input';
import Switch from '../../../../../../../componentsV2/DesignSystem/Inputs/Switch';
import { FontWeight, Paragraph } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { usePlanPermissions } from '../../../../../../../providers/PlanPermissionsProvider';
import styleModuleClasses from '../../../../../../../utils/styleModuleClasses';
import useShareLinks from '../../../ShareLinksProvider';
import styles from './styles.module.scss';

export function AdvancedOptions(): JSX.Element {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [advancedOptionOpen, setAdvancedOptionOpen] = useState<boolean>(false);

  const { hasPermission } = usePlanPermissions();
  const planPermission = hasPermission('inviteLink_expiry_settings');

  const { expireDays, setExpireDays } = useShareLinks();

  useEffect(() => {
    return () => {
      setExpireDays(0);
    };
  }, [setExpireDays]);

  const handleCheckboxChange = () => {
    setExpireDays(isChecked ? 0 : 1);
    setIsChecked(!isChecked);
  };

  const handleAdvancedOptions = () => {
    if (advancedOptionOpen) {
      setIsChecked(false);
      setExpireDays(0);
    }
    setAdvancedOptionOpen(!advancedOptionOpen);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault();
    }
  };

  const handleSetDays = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setExpireDays(Number(value));
  };

  return (
    <div className={styles['advanced-options']}>
      <Button
        firstIcon={{ children: advancedOptionOpen ? 'keyboard_arrow_up' : 'chevron_right' }}
        variant="tertiary"
        onClick={handleAdvancedOptions}
      >
        Advanced Options
      </Button>

      <div
        className={styleModuleClasses(styles, 'container-card', {
          show: advancedOptionOpen,
        })}
      >
        <div>
          <div>
            <div className={styles['switch-group']}>
              <Switch
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={planPermission?.hasPermission ? false : true}
              />
              <Paragraph size={1} weight={FontWeight.extraBold}>
                Link Expiry Settings
              </Paragraph>
            </div>

            <Paragraph size={2}>
              Select how many days after an InviteLink is created that it expires and can no longer be used to enter the
              promotion.
            </Paragraph>
          </div>
          <div>
            {planPermission && !planPermission.hasPermission && (
              <ButtonPopoverUpgradePlan
                activated={planPermission.hasPermission}
                hideButtonDescription
                planNameAllowed={planPermission.allowedPlans}
                buttonSize="sm"
                buttonProps={{ customClass: styles['btn-plan-button'] }}
              />
            )}
          </div>
        </div>

        <div
          className={styleModuleClasses(styles, 'days-to-expire-input', {
            show: isChecked,
          })}
        >
          <Input
            label="How many days to expire"
            onKeyPress={handleKeyPress}
            value={expireDays.toString()}
            onChange={handleSetDays}
            inputSize="sm"
            {...(expireDays > 365 && {
              helperText: 'The expiration days limit must not exceed 365 days.',
              color: 'danger',
            })}
          />
        </div>
      </div>
    </div>
  );
}
