import useAccount from '../../../../hooks/useAccount';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQueryString from '../../../../hooks/useQueryString';
import Content from './Content';
import Filters from '../Filters';
import styles from './styles.module.scss';
import useFetchPromotionsSimpleList from '../../../../graphql/queries/useFetchPromotionsSimpleList';
import { ContactsFilters, useFetchContacts } from './useFetchContacts';
import { setFirstLastDate } from '../utils';
import { CONTACTS_FILTER_LIST } from '../Filters/FilterList';
import FILTER_LIST from '../Filters/DefaultFilterList';

export function Contacts(): JSX.Element {
  const { getAccount } = useAccount();

  const query = useQueryString();
  const addQueryParam = useQueryParams();

  const queryValues = {
    orderColumn: query.get('orderColumn') || 'created_at',
    sortColumn: query.get('sortColumn') || 'desc',
    currentPage: Number(query.get('page')) || 1,
    tableColumns: query.get('columns') || undefined,
  };

  const filters = {
    search: query.get('search') || undefined,
    promo_id: query.get('promo_id') || undefined,
    number_entries: query.get('number_entries') || undefined,
    created_at: query.get('created_at') || setFirstLastDate(),
  };

  const params: ContactsFilters = {
    order: queryValues.orderColumn,
    sort: queryValues.sortColumn,
    ...filters,
  };

  const {
    data,
    loading: dataLoading,
    pagination,
  } = useFetchContacts(getAccount.accountId, { page: queryValues.currentPage }, params);

  const { data: promotionData, loading: promotionLoading } = useFetchPromotionsSimpleList(getAccount.accountId);

  const handlePagination = (page: number) => {
    addQueryParam({ page });
  };

  const searchEntries = (field: string, value: string) => {
    const searchFilter = value ? { [field]: value } : { [field]: '' };
    const filters = {
      ...searchFilter,
    };
    addQueryParam({ ...filters, page: 1 });
  };

  const clearUrlFilters = () => {
    addQueryParam({
      order: undefined,
      sort: undefined,
      columns: undefined,
      search: undefined,
      promo_id: undefined,
      page: 1,
    });
  };

  return (
    <section className={styles['wrapper']}>
      <Filters
        search={searchEntries}
        params={params}
        clearUrlFilters={clearUrlFilters}
        data={promotionData}
        loadingData={promotionLoading}
        filterList={[...FILTER_LIST, ...CONTACTS_FILTER_LIST]}
      />
      <Content
        data={data}
        dataLoading={dataLoading}
        pagination={pagination}
        handlePagination={handlePagination}
        currentPage={queryValues.currentPage}
        search={searchEntries}
        params={params}
      />
    </section>
  );
}
