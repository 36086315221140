import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const initialSchema = yup.object({
  subject: yup.string().required('The Subject field is required.'),
});

const ValidationResolver = yupResolver(initialSchema);

export default ValidationResolver;
