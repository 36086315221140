export const setZapierAccessToken = (accessToken: string): void => {
  localStorage.setItem('zapierAccessToken', accessToken);
};

export const getZapierAccessToken = (): string | null => {
  return localStorage.getItem('zapierAccessToken');
};

export const removeZapierAccessToken = (): void => {
  localStorage.removeItem('zapierAccessToken');
};
