import { ActionProps } from '../Inputs/Buttons/ButtonAction/interface';
import { PillsProps } from '../Pills/interface';
import { PopoverExtendedProps } from '../Popover/interface';

export interface SectionCollapsibleFullProps<T = undefined> {
  id?: string;
  header: Header;
  footer?: { leftButton?: ActionProps; rightButton: ActionProps };
  badge?: SectionStatusProps;
  showButton?: ActionProps | boolean;
  wrapperClassname?: string;
  expanded: boolean;
  handleCollapse?: T extends undefined ? () => void : (...args: T[]) => void;
  children: JSX.Element | JSX.Element[];
  disableCollapse?: boolean;
  forceCollapse?: boolean;
  containerClassname?: string;
  showButtonPopover?: PopoverExtendedProps;
}

export interface SectionCollapsibleHalfProps extends SectionCollapsibleFullProps {
  evenClosed?: evenClosedProps;
}

interface evenClosedProps {
  content: JSX.Element;
  hiddenWhenOpened?: boolean;
}

interface Header {
  title?: string;
  pill?: PillsProps;
  loading?: boolean;
}

export interface SectionStatusProps {
  status: StatusBadge;
  number?: number;
  icon?: {
    name: string;
    color?: string;
  };
}

export enum StatusBadge {
  disabled = 'disabled',
  enabled = 'enabled',
  hidden = 'hidden',
  icon = 'icon',
}

export interface SectionStateProps {
  badge: { status: StatusBadge; icon?: { name: string; color: string }; number?: number };
  sectionStatus: boolean;
}
