import { format } from 'date-fns';
import { useEffect } from 'react';
import ButtonCopy from '../../../../../../componentsV2/ButtonCopy';
import ButtonPopoverConfirm from '../../../../../../componentsV2/ButtonPopoverConfirm';
import useAccount from '../../../../../../hooks/useAccount';
import generateSequenceNumber from '../../../../../../utils/generateSequenceNumber';
import { ItemProps } from './interface';
import styles from './styles.module.scss';
import { useMutationDeleteWidgetUser } from './useMutationDeleteWidgetUser';
import { useMutationUpdateWidgetUser } from './useMutationUpdateWidgetUser';

export default function Item({ widgetUser, widgetId, refresh }: ItemProps): JSX.Element {
  const { getAccount } = useAccount();

  const { updateWidgetUser, data: dataUpdated, loading: loadingUpdated } = useMutationUpdateWidgetUser();
  const { deleteWidgetUser, data: dataDeleted, loading: loadingDeleted } = useMutationDeleteWidgetUser();

  useEffect(() => {
    if (dataDeleted?.id || dataUpdated?.id) {
      refresh();
    }
  }, [dataDeleted, dataUpdated]);

  const handleDelete = (id: number) => {
    deleteWidgetUser?.(getAccount.accountId, widgetId, id);
  };

  const generateNewPassword = (id: number) => {
    updateWidgetUser?.(getAccount.accountId, widgetId, id, generateSequenceNumber());
  };

  return (
    <tr>
      <td>{widgetUser.username}</td>
      <td>{format(new Date(widgetUser.updated_at), 'dd MMM yyyy - HH:mm')}</td>
      <td>{widgetUser.totalInvitelink}</td>
      <td>{widgetUser.password}</td>
      <td className={styles['align-right']}>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '0.5rem' }}>
          <div>
            <ButtonCopy text={widgetUser.password} />
          </div>
          <div>
            <ButtonPopoverConfirm
              onClick={() => generateNewPassword(widgetUser.id)}
              buttonProps={{ firstIcon: { children: 'cached' }, loading: loadingUpdated, disabled: loadingUpdated }}
              popoverProps={{
                header: {
                  title: 'Are you sure you wish to generate new PIN?',
                },
              }}
            />
          </div>
          <div>
            <ButtonPopoverConfirm
              onClick={() => handleDelete(widgetUser.id)}
              buttonProps={{
                firstIcon: { children: 'delete', color: 'red-block' },
                loading: loadingDeleted,
                disabled: loadingDeleted,
              }}
              popoverProps={{
                header: {
                  title: 'Are you sure you wish to delete this?',
                },
              }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
