import React from 'react';
import Skeleton from '../../../../../../componentsV2/DesignSystem/Skeleton';
import styles from './styles.module.scss';

export default function SkeletonLoading(): JSX.Element {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <div className={styles['promotion-wrapper']} key={`promotion-wrapper-${index}`}>
          <div className={styles['promotion-name-wrapper']}>
            <Skeleton width={70} height={1} />
            <Skeleton width={40} height={0.75} />
          </div>
          <div className={styles['promotion-type-wrapper']}>
            <Skeleton width={70} height={1} />
          </div>
          <div className={styles['promotion-liveon-wrapper']}>
            <Skeleton width={80} height={1} />
          </div>
          <div className={styles['promotion-entries-wrapper']}>
            <Skeleton width={70} height={1} />
          </div>
          <div className={styles['promotion-actions-wrapper']}>
            <Skeleton width={90} height={1} />
          </div>
        </div>
      ))}
    </>
  );
}
