import { useParams } from 'react-router-dom';
import ProgressStep from './Form/ProgressStep';
import StepAccountCreated from './Form/StepAccountCreated';
import StepInitial from './Form/StepInitial';
import StepInviteUser from './Form/StepInviteuser';
import StepSetPassword from './Form/StepSetPassword';
import StepTerms from './Form/StepTerms';
import StepVerifyCode from './Form/StepVerifyCode';
import { SignupContextProvider } from './SignupContextProvider';
import Slide from './Slide';
import styles from './styles.module.scss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { REACT_APP_RECAPTCHA_V3_SITE_KEY } from '../../../../config/Env';

export default function SignUpWrapper(): JSX.Element {
  const { inviteCode } = useParams<{ inviteCode: string }>();
  return (
    <div className={styles['wrapper']}>
      <SignupContextProvider>
        <div className={styles['left-side']}>
          {inviteCode ? (
            <>
              <StepInviteUser />
              <StepTerms />
              <StepAccountCreated />
            </>
          ) : (
            <>
              <GoogleReCaptchaProvider
                reCaptchaKey={REACT_APP_RECAPTCHA_V3_SITE_KEY}
                container={{
                  parameters: {
                    badge: 'inline',
                  },
                }}
              >
                <StepInitial />
                <StepVerifyCode />
              </GoogleReCaptchaProvider>
              <StepSetPassword />
              <StepTerms />
              <StepAccountCreated />
              <ProgressStep />
            </>
          )}
        </div>
        <div className={styles['slide-wrapper']}>
          <Slide />
        </div>
      </SignupContextProvider>
    </div>
  );
}
