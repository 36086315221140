import useAccount from '../../../../../../hooks/useAccount';
import Item from './Item';
import { ListProps } from './interface';
import styles from './styles.module.scss';

export default function List({ widgetUsers, widgetId, fetchWidgetUsers }: ListProps): JSX.Element {
  const { getAccount } = useAccount();

  const refresh = () => {
    fetchWidgetUsers?.({ accountId: getAccount.accountId, widgetId });
  };

  return (
    <table width={'100%'} className={styles['stafflinks-table']}>
      <thead>
        <tr>
          <th>User Name</th>
          <th>Generated at</th>
          <th>InviteLinks</th>
          <th>Pin</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {widgetUsers.map((user) => (
          <Item key={`user-${user.id}`} widgetUser={user} widgetId={widgetId} refresh={refresh} />
        ))}
      </tbody>
    </table>
  );
}
