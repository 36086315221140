import * as Sentry from '@sentry/react';
import { Fragment, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, matchPath, useHistory, useLocation } from 'react-router-dom';
import AlertErrorLoad from '../../components/AlertErrorLoad';
import { BannerNotifications } from '../../components/BannerNotifications';
import SpinnerAllScreen from '../../components/_molecules/SpinnerAllScreen';
import MainNavigation from '../../componentsV2/DesignSystem/NavigationMain';
import { REACT_APP_ADMIN_HOMEPAGE, REACT_APP_ENVIRONMENT, REACT_APP_SENTRY_DSN } from '../../config/Env';
import { ROUTES_ADMIN } from '../../config/Routes';
import { ROUTES_DEV } from '../../config/Routes/Dev';
import useAccount, { AccountProvider } from '../../hooks/useAccount';
import useAuth from '../../hooks/useAuth';
import { useFetchPlanPermissions } from '../../hooks/useFetchPlanPermissions';
import usePostHogContext from '../../hooks/usePostHog';
import { HubspotConversationsProvider } from '../../providers/HubspotConversationsProvider';
import { PlanPermissionsProvider } from '../../providers/PlanPermissionsProvider';
import AccountManager from './AccountManager';

function AdminContainer(): JSX.Element {
  const [pluginLoaded, setPluginLoaded] = useState<boolean>(false);
  const { t } = useTranslation('translation');
  const { loadingAccount, getAccount } = useAccount();
  const { getMe, clearAuthorization } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { initializePostHog } = usePostHogContext();

  const {
    data: dataPlanPermission,
    loading: loadingPlanPermission,
    refetch: refetchPlanPermission,
  } = useFetchPlanPermissions(getAccount?.accountId);

  useEffect(() => {
    if (getAccount) {
      initializePostHog(getAccount.agencyId, getAccount.accountId, {
        userId: getMe.user_id,
        email: getMe.email,
        firstName: getMe.given_name,
        lastName: getMe.family_name,
      });
    }
  }, [getAccount]);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement && getAccount) {
      rootElement.setAttribute('data-id', getAccount.accountId.toString());
    }
  }, [getAccount]);

  useEffect(() => {
    if (getAccount?.rewardPlugin && !pluginLoaded) {
      setPluginLoaded(true);
      const script = document.createElement('script');
      script.src = `${getAccount.rewardPlugin.url}?rand=${Date.now()}`;
      script.setAttribute('data-app-uuid', getAccount.rewardPlugin.appUuid);
      script.setAttribute('data-name', getAccount.rewardPlugin.dataName);
      script.setAttribute('data-uid', getAccount.rewardPlugin.dataUid);
      script.setAttribute('data-hmac', getAccount.rewardPlugin.dataHmac);

      const appendScript = () => {
        document.body.appendChild(script);
      };

      if (document.readyState === 'complete') {
        appendScript();
      } else {
        window.addEventListener('load', appendScript);
      }

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script);
        }
        window.removeEventListener('load', appendScript);
      };
    }
  }, [getAccount?.rewardPlugin]);

  if (loadingAccount || loadingPlanPermission) {
    return <SpinnerAllScreen text={t('Loading')} />;
  }

  if (!getAccount) {
    const onLogout = () => {
      clearAuthorization();
      history.push('/');
    };

    return <AlertErrorLoad action={() => onLogout()} />;
  }

  if (REACT_APP_SENTRY_DSN) {
    Sentry.configureScope((scope) => {
      scope.setExtras({
        accountID: getAccount.accountId,
      });
      scope.setUser({
        id: getMe.user_id.toString(),
        email: getMe.email,
      });
    });
  }

  const match = matchPath<{ promo_id: string }>(location.pathname, {
    path: '/:slug/promotions/:promo_id',
    exact: false,
  });

  return (
    <PlanPermissionsProvider permissions={dataPlanPermission} refetchPlanPermission={refetchPlanPermission}>
      <HubspotConversationsProvider identificationEmail={getMe.email}>
        <MainNavigation>
          <>
            {!match && <BannerNotifications />}
            <Suspense fallback="">
              <Switch>
                {ROUTES_ADMIN.map((route, index) => (
                  <Route key={index} {...route} />
                ))}
                {REACT_APP_ENVIRONMENT !== 'production' &&
                  ROUTES_DEV.map((route, index) => <Route key={index} {...route} />)}
                <Redirect to={REACT_APP_ADMIN_HOMEPAGE} />
              </Switch>
            </Suspense>
          </>
        </MainNavigation>
      </HubspotConversationsProvider>
    </PlanPermissionsProvider>
  );
}

export default function Admin(): JSX.Element {
  const location = useLocation();
  const { getMe } = useAuth();

  if (!getMe) {
    return <Fragment />;
  }

  if (['/account-manager', '/signin'].includes(location.pathname) || location.pathname.startsWith('/signup')) {
    return (
      <Switch>
        <Route exact path="/account-manager" component={AccountManager} />
      </Switch>
    );
  }

  return (
    <AccountProvider getMe={getMe}>
      <AdminContainer />
    </AccountProvider>
  );
}
