import React from 'react';

interface Props {
  action: () => void;
}

export default function AlertErrorLoad({ action }: Props): JSX.Element {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-4">
      <h4>Oops! Something went wrong.</h4>

      <p>
        <button className="btn btn-primary" type="button" onClick={() => action()}>
          Try again
        </button>
      </p>
    </div>
  );
}
