import ButtonNavigation from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { Heading, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import VideoPlayerResponsive from '../../../../../componentsV2/VideoPlayerResponsive';
import styles from './styles.module.scss';

export default function Empty(): JSX.Element {
  return (
    <div className={styles['video-wrapper']}>
      <div className={styles['video-content']}>
        <div className={styles['display-video']}>
          <VideoPlayerResponsive urlVideo="https://vimeo.com/1005055380" />
        </div>
      </div>
      <div className={styles['update-plan-content']}>
        <Heading size={4}>You have no promotions yet</Heading>
        <Paragraph size={2}>
          Simply click to create a promotion and choose a type of game of chance you’d like to create. You can then
          select a ready made designs to customize with your own text and designs until it is ready to launch.
        </Paragraph>
        <div>
          <ButtonNavigation to={'/promotions/create'} firstIcon={{ children: 'videogame_asset' }} size="md">
            Create Promotion
          </ButtonNavigation>
        </div>
      </div>
    </div>
  );
}
