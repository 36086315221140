import { FilterList } from './interface';

const FILTER_LIST: FilterList[] = [
  {
    id: 'created_at',
    name: 'Date Time',
    options: [
      { label: 'All Time', value: '' },
      { label: 'Today', value: 'today' },
      { label: 'This Week', value: 'this_week' },
      { label: 'This Month', value: 'this_month' },
    ],
    enable: false,
    multi: false,
  },
  {
    id: 'user_id',
    name: 'Created by',
    options: [],
    enable: false,
    multi: true,
  },
  {
    id: 'promotion_id',
    name: 'Promotion',
    options: [],
    enable: false,
    multi: true,
  },
  {
    id: 'invite_type',
    name: 'Type',
    options: [
      { label: 'All', value: '' },
      { label: 'Personalised', value: 'personalised' },
      { label: 'Anonymous', value: 'anonymous' },
    ],
    enable: false,
  },
  {
    id: 'invite_used',
    name: 'Invite Status',
    options: [
      { label: 'All', value: '' },
      { label: 'Used', value: 'used' },
      { label: 'Unused', value: 'unused' },
    ],
    enable: false,
  },
  {
    id: 'generator_id',
    name: 'List Name',
    options: [],
    enable: false,
    multi: true,
  },
];

export default FILTER_LIST;
