import { ApolloError, gql, useMutation } from '@apollo/client';

const DELETE_WIDGET_USER = gql`
  mutation deleteWidgetUser($accountId: ID!, $widgetId: ID!, $widgetUserId: ID!) {
    deleteWidgetUser(accountId: $accountId, widgetId: $widgetId, widgetUserId: $widgetUserId) {
      id
      message
    }
  }
`;

interface DeleteWidgetUser {
  id: number;
  message: string;
}

export function useMutationDeleteWidgetUser(): {
  loading: boolean;
  deleteWidgetUser?: (accountId: number, widgetId: number, widgetUserId: number) => void;
  data: DeleteWidgetUser | null;
} {
  const [deleteWidgetUserkMutation, { loading, data, error }] = useMutation<{ deleteWidgetUser: DeleteWidgetUser }>(
    DELETE_WIDGET_USER,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => {
        console.log({
          gql: 'DELETE_WIDGET_USER',
          apolloError: error,
        });
      },
    },
  );

  const deleteWidgetUser = (accountId: number, widgetId: number, widgetUserId: number) => {
    deleteWidgetUserkMutation({
      variables: {
        accountId,
        widgetId,
        widgetUserId,
      },
    });
  };

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
    };
  }

  return {
    loading: false,
    data: data?.deleteWidgetUser || null,
    deleteWidgetUser,
  };
}
