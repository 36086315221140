import { gql } from '@apollo/client';

export const GET_REFRESH_TOKEN_GQL = gql`
  mutation refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      access_token
      expires_in
      refresh_token
      refresh_expires_in
    }
  }
`;
