import { graphql } from 'msw';

const ACCOUNT_DATA = [
  { id: 3227, name: 'BeeLiked Ready Made 2.0', slug: 'beeliked-ready-made-2-0' },
  { id: 3781, name: 'Test', slug: 'test' },
  { id: 5290, name: 'Default Account', slug: 'default-account' },
];

const ACCOUNT_DEFAULT_DATA = 5290;

const accountFindBySlug = graphql.query('accountFindBySlug', (req, res, ctx) => {
  const { account_slug } = req.variables;

  const account = ACCOUNT_DATA.find((item) => item.slug === account_slug);

  return res(
    ctx.delay(2000),
    ctx.data({
      accountFindBySlug: {
        id: account.id ?? ACCOUNT_DEFAULT_DATA,
        agency_id: 1,
      },
    }),
  );
});

/**
 * Account Endpoint
 *
 * plan_id=1 => trial
 * plan_id=2 => Hibernate
 * plan_id=100 => Started
 * plan_id=200 => Professional
 * plan_id=500 => Enterprise
 */
const account = graphql.query('account', (req, res, ctx) => {
  const { account_id } = req.variables;

  const id = account_id ?? ACCOUNT_DEFAULT_DATA;

  const account = ACCOUNT_DATA.find((item) => Number(item.id) === Number(id));

  return res(
    ctx.delay(2000),
    ctx.data({
      account: {
        ...account,
        agency_id: 1,
        image: null,
        config_timezone: 'Europe/London',
        billingStatus: {
          status: 'actived',
        },
        summary: {
          id: 1,
          plan_id: 100,
          interaction_cycle_allowance: 5000,
          interaction_cycle_used: 1100,
          interaction_credit: 5,
          interaction_credit_used: 12,
          subscription_cycle_expires_at: '2022-12-05 12:44:59',
          subscription_cycle_starts_at: '2022-01-05 12:44:59',
        },
      },
    }),
  );
});

const accounts = graphql.query('accounts', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      accounts: ACCOUNT_DATA,
    }),
  );
});

const apiKey = graphql.query('apiKey', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      apiKey: null,
    }),
  );
});

const createApiKey = graphql.mutation('createApiKey', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      createApiKey: {
        id: 1,
        service_client_id: 'f4c9ae54-b41a-4827-b647-1a9b255b35e1',
        service_secret: 'n8HzkW6FMzfQopnpZAzcgCVOIKXYnS',
      },
    }),
  );
});

const removeApiKey = graphql.mutation('removeApiKey', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      removeApiKey: {
        message: 'Removed',
      },
    }),
  );
});

export default [accountFindBySlug, account, accounts, apiKey, createApiKey, removeApiKey];
