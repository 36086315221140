import { graphql } from 'msw';

const alertMessages = graphql.query('alertMessages', (req, res, ctx) => {
  return res(
    ctx.delay(2000),
    ctx.data({
      alertMessages: [
        {
          alertMessageType: 'alert-info',
          icon_class: null,
          key: 'email_confirm',
          text: 'Before you can go-live with your promotion you will need to <b>verify your email address</b>. Didn\'t receive a verify link? Check your spam folder too or <a href="https://app.dev.beeliked.com/email-confirm-send" class="email-confirm-btn">click here to re-send email</a>.',
        },
      ],
    }),
  );
});

export default [alertMessages];
