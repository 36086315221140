import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../../components/Pagination/paginator.gql';

export const LIST_PROMOTION_GQL = gql`
  query promotions($account_id: ID!, $page: Int, $filters: PromotionsFilter, $limit: Int) {
    promotions(account_id: $account_id, page: $page, filters: $filters, limit: $limit) {
      data {
        id
        name
        start_date
        end_date
        visibility
        status
        campaign_name
        entry_count
        microsite_url
        passcode
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const PROMOTION_COUNTER = gql`
  query promotionCounter($accountId: ID!) {
    promotionCounter(accountId: $accountId) {
      draft
      live
      ended
    }
  }
`;

export const DELETE_PROMOTION = gql`
  mutation deletePromotion($accountId: ID!, $promoId: ID!) {
    deletePromotion(accountId: $accountId, promoId: $promoId) {
      status
    }
  }
`;
