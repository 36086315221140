import { createContext, useContext, useEffect, useState } from 'react';

export interface StepPosition {
  forgotPassword: string;
  initial: string;
  otp: string;
}

export enum StepsEnum {
  forgotPassword,
  otp,
}

interface StepsName {
  enableOtp: boolean;
  initial: boolean;
  enableForgotPassword: boolean;
}

interface SigninContextProps {
  animations: StepPosition;
  enableSteps: StepsName;
  setEnableSteps: (value: StepsName) => void;
  loginCredentials: LoginCredentials;
  updateLoginCredentials: (data: LoginCredentials) => void;
  changeStep: (step: StepsEnum, flow: boolean) => void;
}

interface LoginCredentials {
  email: string;
  password: string;
  otp?: string;
}

interface Props {
  children: JSX.Element | JSX.Element[];
  step?: string;
}

const SigninContext = createContext({});

export function SigninContextProvider({ children, step }: Props): JSX.Element {
  const [enableSlide, setEnableSlide] = useState<boolean>(false);
  const [enableSteps, setEnableSteps] = useState<StepsName>({
    enableOtp: false,
    initial: false,
    enableForgotPassword: false,
  });

  const [loginCredentials, setLoginCredentials] = useState<LoginCredentials>();
  const [animations, setAnimations] = useState<StepPosition>({} as StepPosition);

  useEffect(() => {
    const isForgot = step === 'forgot' || step === 'recovery';
    const updatedEnableSteps = isForgot
      ? { ...enableSteps, enableForgotPassword: true }
      : { ...enableSteps, initial: true };

    const updatedAnimations = {
      forgotPassword: isForgot ? 'center' : 'left',
      initial: isForgot ? 'right' : 'center',
      otp: 'right',
    };

    setEnableSteps(updatedEnableSteps);
    setAnimations(updatedAnimations);

    return () => {
      setEnableSteps({
        enableOtp: false,
        initial: false,
        enableForgotPassword: false,
      });
      setAnimations({
        forgotPassword: '',
        initial: '',
        otp: '',
      });
    };
  }, [step]);

  const changeStep = (step: StepsEnum, forward: boolean) => {
    switch (step) {
      case 0:
        if (forward) {
          setAnimations({ ...animations, forgotPassword: 'center', initial: 'right' });
          setEnableSlide(false);
        } else {
          setAnimations({ forgotPassword: 'left', initial: 'center', otp: 'right' });
          setEnableSlide(true);
        }
        break;
      case 1:
        if (forward) {
          setAnimations({ ...animations, initial: 'left', otp: 'center' });
          setEnableSlide(true);
        } else {
          setAnimations({ ...animations, initial: 'center', otp: 'right' });
          setEnableSlide(false);
        }
        break;
      default:
    }
  };

  const updateLoginCredentials = (data: LoginCredentials) =>
    setLoginCredentials({ email: data?.email, password: data?.password, otp: data?.otp });

  return (
    <SigninContext.Provider
      value={{
        animations,
        enableSlide,
        enableSteps,
        setEnableSteps,
        loginCredentials,
        updateLoginCredentials,
        changeStep,
      }}
    >
      {children}
    </SigninContext.Provider>
  );
}

export default function useSigninContext(): SigninContextProps {
  const context = useContext(SigninContext) as SigninContextProps;
  return context;
}
