import { AccountPermission } from '../../ts';
import { NavigationMobileLink } from './interface';

const NavigationMobileLinks: NavigationMobileLink = {
  main: [
    {
      id: 'dashboard',
      icon: 'dashboard',
      title: 'Home',
      type: 'link',
      action: '/dashboard',
    },
    {
      id: 'promotions',
      icon: 'videogame_asset',
      title: 'Promotions',
      type: 'link',
      action: '/promotions',
    },
    {
      icon: 'add_circle',
      title: 'New Promo',
      cta: true,
      type: 'link',
      action: '/promotions/create',
    },
    {
      id: 'rewards',
      icon: 'local_activity',
      title: ' Rewards',
      type: 'link',
      action: '/rewards/coupons',
    },
    {
      icon: 'menu',
      title: 'menu',
      type: 'function',
      action: 'setMobileNavCollapsed',
    },
  ],
  hidden: [
    {
      icon: 'groups',
      title: 'Data Center',
      type: 'redirect',
      action: `/audience`,
    },
    {
      icon: 'view_carousel',
      title: 'Subscription',
      type: 'link',
      action: `/self-service`,
      accountPermission: AccountPermission.account_manager,
    },
    {
      icon: 'help_outline',
      title: ' Support',
      type: 'function',
      action: 'openHubspotChat',
    },
    {
      id: 'developer',
      icon: 'terminal',
      title: 'Developers',
      type: 'link',
      action: '/developer',
    },
    {
      id: 'settings',
      icon: 'settings',
      title: 'Settings',
      type: 'link',
      action: '/settings/your-details',
    },
  ],
};

export default NavigationMobileLinks;
