import { ApolloError, gql, useQuery } from '@apollo/client';

const USER_FEATURE_FLAG_FIND_BY_KEY = gql`
  query userFeatureFlagFindByKey($accountId: ID!, $featureKey: String!) {
    userFeatureFlagFindByKey(accountId: $accountId, featureKey: $featureKey) {
      id
      feature_key
      accessed
    }
  }
`;

export enum FeatureKey {
  ShareCenter = 'share_center',
}

interface UserFeatureFlagFindByKey {
  id: number;
  feature_key: string;
  accessed: boolean;
}

export type RefetchUserFeatureFlagFindByKey = (variables: { accountId: number; featureKey: FeatureKey }) => void;

interface UseFetchUserFeatureFlagFindByKey {
  loading: boolean;
  data: UserFeatureFlagFindByKey | null;
  error?: ApolloError;
  refetch?: RefetchUserFeatureFlagFindByKey;
}

export default function useFetchUserFeatureFlagFindByKey(
  accountId: number,
  featureKey: FeatureKey,
): UseFetchUserFeatureFlagFindByKey {
  const { data, loading, error, refetch } = useQuery<{ userFeatureFlagFindByKey: UserFeatureFlagFindByKey }>(
    USER_FEATURE_FLAG_FIND_BY_KEY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId,
        featureKey,
      },
      onError: (error: ApolloError) => {
        console.log({
          gql: 'USER_FEATURE_FLAG_FIND_BY_KEY',
          apolloError: error,
        });
      },
    },
  );

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
      refetch,
    };
  }

  return {
    loading: false,
    data: data?.userFeatureFlagFindByKey || null,
    refetch,
  };
}
