import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import Content from './Content';
import { WidgetUserProps } from './interface';
import styles from './styles.module.scss';

export default function WidgetUsers({ sectionsStatus, widgetId, widgetSlug }: WidgetUserProps): JSX.Element {
  return (
    <SectionCollapsibleFull
      badge={sectionsStatus.badge}
      expanded={sectionsStatus.opened}
      header={{ title: 'Trusted Users' }}
      wrapperClassname={styles.wrapper}
    >
      <>{!!widgetId && <Content widgetId={widgetId} widgetSlug={widgetSlug} />}</>
    </SectionCollapsibleFull>
  );
}
