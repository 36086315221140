import SelectPromotion from './SelectPromotion';
import { ShareLinksProvider } from './ShareLinksProvider';
import VisibilityContent from './VisibilityContent';

export default function ShareLinks(): JSX.Element {
  return (
    <ShareLinksProvider>
      <SelectPromotion />
      <VisibilityContent />
    </ShareLinksProvider>
  );
}
