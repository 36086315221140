import { User } from '.';

export interface InviteData {
  id: number;
  agency_id: number;
  account_id: number;
  code: string;
  url: string;
  promotion_id: number;
  data: Record<string, string>;
  uid: string;
  uid_field_slug: string;
  generator_id: number;
  expires_at: string;
  created_at: string;
  updated_at: string;
  used_at: string;
  is_used: boolean;
  promotion: string;
  generator_name: string;
  type: InviteCreationMethod;
  expired: boolean;
  user_id: number;
  created_by: string;
  message_request_timestamp: string;
  message_request_status: string;
  message_request_id: number;
  message_id?: number;
  expire_date?: string;
}

export interface InvitateCreated {
  id: number;
  agency_id: number;
  account_id: number;
  code: string;
  url: string;
  promotion_id: number;
  data: string;
  uid: string;
  created_at: string;
  updated_at: string;
  expires_at: string;
  expire_date: string;
}

export interface ShareInviteIndividually {
  agency_id: number;
  promo_id: string;
  fields: ShareInviteIndividuallyField[];
}

export interface ShareInviteIndividuallyField {
  slug: string;
  value: string;
}

export interface ShareInviteAnonymous {
  agency_id: number;
  promo_id: string;
  invites_number?: number;
}

export enum InviteInbulkType {
  QUANTITY = 'quantity',
  FILE = 'file',
}

export interface InviteInbulkFileCreated {
  id: number;
  agency_id: number;
  account_id: number;
  promotion_id: number;
  url: string;
  user_id: number;
  type: InviteInbulkType;
  status: number;
  updated_at: string;
  created_at: string;
  origin_file: string;
  file_headers: string[];
  quantity: number;
}

export interface FieldsInviteFileMapper {
  slug: string;
  order: number;
}

export interface InviteInbulkFileMapperInput {
  agency_id: number;
  account_id: number;
  generator_id: number | null;
  data: FieldsInviteFileMapper[];
  disabled?: string[];
  disabledField?: boolean[];
  name?: string;
}

export interface InviteInbulkGenerator {
  id: number;
  agency_id: number;
  account_id: number;
  name: string;
  promotion_id: number;
  user_id: number;
  type: InviteInbulkType;
  url: string;
  quantity: number;
  generated_quantity: number;
  origin_file: string;
  generated_file: string;
  file_headers: string[];
  failed_rows: number;
  failed_attempts: number;
  failed_message: string;
  expires_at: string;
  status: number;
  generated_at: string;
  created_at: string;
  updated_at: string;
  user: User;
}

export interface InviteLinkDownloadLink {
  temporary_url: string;
}

export interface InviteLinkExport {
  temporary_url: string;
}

interface InviteDataFields {
  name: string;
  value: string;
}

export interface InviteCreatedFormated {
  url: string;
  data?: InviteDataFields[];
  created_at?: string;
  uid?: string;
  code: string;
  expires_at?: string;
  expire_date?: string;
}

export interface CommonColumnsIndividuallyList {
  id: string;
  name: string;
  className?: string;
}

export enum InviteCreationMethod {
  PERSONALIZED = 'personalized',
  ANONYMOUS = 'anonymous',
}
