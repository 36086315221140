import React, { useMemo } from 'react';
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import classnames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import useAccount, { AccountVariables } from '../../../../hooks/useAccount';
import useQueryString from '../../../../hooks/useQueryString';
import { ACCOUNTS, GET_ACCOUNT_SLUG } from '../graphql';
import { AccountData } from '../../../../ts';
import SpinnerInline from '../../../../components/_molecules/SpinnerInline';
import Button from '../../../../components/_atoms/Button';
import styles from './styles.module.scss';

export default function SwitchAccount(): JSX.Element {
  const { t } = useTranslation('account', { keyPrefix: 'switch-account' });
  const history = useHistory();
  const { getMe } = useAuth();
  const { getAccount } = useAccount();
  const query = useQueryString();

  const methods = useForm<AccountVariables>({
    defaultValues: {
      agency_id: getAccount.agencyId,
      account_id: getAccount.accountId,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const { data: dataAccounts, loading: loadingAccounts } = useQuery<{
    accounts: AccountData[];
  }>(ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: {
      agency_id: getMe.agency_id,
      user_id: getMe.superAdmin ? undefined : getMe.user_id,
    },
    onError: () => {
      console.log('[Error]: ACCOUNTS');
    },
  });

  const listAccounts: AccountData[] = useMemo(() => dataAccounts?.accounts ?? [], [dataAccounts?.accounts]);

  const [account] = useLazyQuery<{ account: AccountData }>(GET_ACCOUNT_SLUG, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      history.push(`/r/${response.account.slug}/account/switch-account?submitted=1`);
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const onSubmit = handleSubmit((variables) => {
    account({
      variables: {
        account_id: variables.account_id,
        agency_id: getMe.agency_id,
      },
    });
  });

  return (
    <main className="main-content">
      <div className={styles['mask-domain']}>
        <h1>{t('Switch Account')}</h1>

        <div className="content-box">
          {loadingAccounts ? (
            <SpinnerInline />
          ) : (
            <form onSubmit={onSubmit} noValidate>
              {query.get('submitted') && (
                <div className="alert alert-success alert-dismissible fade show">
                  {t('Account successfully changed')}
                </div>
              )}

              <div className="form-group mb-3">
                <label className="form-label">{t('Account')}</label>
                <Controller
                  control={control}
                  name="account_id"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={listAccounts}
                      placeholder={t('Choose an Account')}
                      getOptionValue={(e) => e.id.toString()}
                      getOptionLabel={(e) => e.name}
                      onChange={(e) => onChange(e?.id)}
                      isSearchable
                      isClearable
                      defaultValue={listAccounts?.filter((item) => item.id === value)}
                      classNamePrefix="blkd-react-select"
                      className={classnames('blkd-react-select-container', {
                        'is-invalid': errors.account_id,
                      })}
                    />
                  )}
                />
                {errors.account_id && (
                  <div className="form-text text-danger">{t('Please select a valid account.')}</div>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button type="submit" className="btn-primary">
                  {t('Change')}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </main>
  );
}
