import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import apolloClient from './config/ApolloClient';
import { REACT_APP_API_MOCKING, REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_ENVIRONMENT } from './config/Env';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';

if (REACT_APP_API_MOCKING) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}

if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    sampleRate: 1,
    tracesSampleRate: 0,
    environment: REACT_APP_SENTRY_ENVIRONMENT,
  });
}

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <App />
  </ApolloProvider>,
  document.getElementById('root'),
);
