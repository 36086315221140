import { ApolloError, gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { paginatorInfoFragment } from '../../../../../../components/Pagination/paginator.gql';
import { SimplePaginationInfo, paginatorInfo } from '../../../../../../ts';

const WIDGET_USERS = gql`
  query widgetUsers($accountId: ID!, $widgetId: ID!, $page: Int) {
    widgetUsers(accountId: $accountId, widgetId: $widgetId, page: $page) {
      data {
        id
        username
        password
        updated_at
        totalInvitelink
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export interface WidgetUser {
  id: number;
  username: string;
  password: string;
  updated_at: string;
  totalInvitelink: number;
}

export interface WidgetUsersVariables {
  accountId: number;
  widgetId: number;
  page?: number;
}

export function useFetchWidgetUsers(
  accountId: number,
  widgetId: number,
): {
  loading: boolean;
  data: WidgetUser[] | null;
  error?: ApolloError;
  pagination: SimplePaginationInfo;
  fetchWidgetUsers?: (variables: WidgetUsersVariables) => void;
} {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: 20,
  });

  const { data, loading, error, refetch } = useQuery<{
    widgetUsers: { data: WidgetUser[]; paginatorInfo: paginatorInfo };
  }>(WIDGET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      widgetId,
    },
    onError: (error: ApolloError) => {
      console.log({
        gql: 'WIDGET_USERS',
        apolloError: error,
      });
    },
  });

  const fetchWidgetUsers = (variables: WidgetUsersVariables) => {
    refetch(variables);
  };

  useEffect(() => {
    if (data?.widgetUsers.paginatorInfo) {
      setPagination({
        total: data.widgetUsers.paginatorInfo.total,
        currentPage: data.widgetUsers.paginatorInfo.current_page,
        totalPerPage: Number(data.widgetUsers.paginatorInfo.per_page),
      });
    }
  }, [data?.widgetUsers.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: null,
      pagination,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
      pagination,
    };
  }

  return {
    loading: false,
    data: data?.widgetUsers?.data || null,
    pagination,
    fetchWidgetUsers,
  };
}
