import { ApolloError, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PaginationInput, SimplePaginationInfo, paginatorInfo } from '../../../../ts';
import { CONTACTS } from './contacts.gql';

export interface ContactsFilters {
  order?: string;
  sort?: string;
  promo_id?: string;
  search?: string;
}

export interface ContactsVariables {
  sort: 'asc' | 'desc';
  key: string;
  pagination?: PaginationInput;
}

export interface ContactsProps {
  id: string;
  member_id?: string;
  avatar_image: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company: string;
  promo_id: number;
  outcome_id: number;
  created_at: string;
  promotions_name: string[];
  promotions_entries: string;
  inviteCode: string;
  outcome_title: string;
  segment_label: string;
  segment_id: string;
  has_prize: boolean;
}

interface ContactsResult {
  loading: boolean;
  data: ContactsProps[];
  pagination: SimplePaginationInfo;
  fetchContacts: (variables?: ContactsVariables) => void;
}

export function useFetchContacts(
  accountId: number,
  { page, limit }: PaginationInput,
  filters: ContactsFilters,
): ContactsResult {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: 10,
  });

  const { data, loading, refetch } = useQuery<{
    contacts: { data: ContactsProps[]; paginatorInfo: paginatorInfo };
  }>(CONTACTS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      pagination: {
        page,
        limit,
      },
      filters,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const fetchContacts = (variables?: ContactsVariables) => {
    refetch(variables);
  };

  useEffect(() => {
    if (data?.contacts.paginatorInfo) {
      setPagination({
        total: data.contacts.paginatorInfo.total,
        currentPage: data.contacts.paginatorInfo.current_page,
        totalPerPage: Number(data.contacts.paginatorInfo.per_page),
      });
    }
  }, [data?.contacts.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: [],
      pagination,
      fetchContacts,
    };
  }

  return {
    loading: false,
    data: data?.contacts?.data || [],
    pagination,
    fetchContacts,
  };
}
