import React from 'react';
import Card from '../../../../../componentsV2/DesignSystem/Card';
import { CardAnimations } from '../../../../../componentsV2/DesignSystem/Card/interface';
import ButtonNavigation from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import VideoPlayer from '../../../../../componentsV2/VideoPlayer';
import { BREAKPOINTS } from '../../../../../config/Constants';
import { Paragraph, FontWeight, Heading } from '../../../../../componentsV2/DesignSystem/Typography';
import styles from './styles.module.scss';

export default function NoPromotions(): JSX.Element {
  return (
    <Card
      identify="promotions"
      size="sm"
      header={{
        title: 'Promotions',
      }}
      animation={{ type: CardAnimations.top, duration: '.5s' }}
    >
      <div className={styles['wrapper-no-promotions']}>
        <main>
          <div>
            <VideoPlayer
              urlVideo="https://player.vimeo.com/video/873262371"
              size={[
                { breakpoint: BREAKPOINTS.SM, width: 406 },
                { breakpoint: BREAKPOINTS.XS, width: 300 },
              ]}
              urlThumbnail="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/thumb-first-promo.jpg"
              altThumbnail="You have no promotions yet"
            />
          </div>
          <div>
            <Heading size={4} weight={FontWeight.extraBold}>
              You have no promotions yet
            </Heading>
            <Paragraph size={2}>
              Simply click to create a promotion and choose a type of game of chance you’d like to create. You can then
              select a ready made designs to customize with your own text and designs until it’s ready to launch.
            </Paragraph>
          </div>
          <div>
            <ButtonNavigation
              firstIcon={{ children: 'sports_esports' }}
              variant="primary"
              to="/promotions/create"
              size="md"
            >
              Create Promotion
            </ButtonNavigation>
          </div>
        </main>
      </div>
    </Card>
  );
}
