/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { FontWeight, Heading } from '../../../../../../componentsV2/DesignSystem/Typography';
import { REACT_APP_ADMIN_HOMEPAGE } from '../../../../../../config/Env';
import useAuthContext from '../../../../../../hooks/useAuth';
import useQueryString from '../../../../../../hooks/useQueryString';
import useSigninContext, { StepsEnum } from '../../../SigninContextProvider';
import { LOGIN_GQL } from '../../../graphql';
import TwoFAInput from '../../../../../../componentsV2/2FAInput';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';
import useToast from '../../../../../../hooks/useToast';

export default function StepVerifyCode(): JSX.Element {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { animations, changeStep, loginCredentials, enableSteps, setEnableSteps } = useSigninContext();
  const { setAuthorization } = useAuthContext();
  const history = useHistory();
  const query = useQueryString();
  const button = useRef<HTMLButtonElement>(null);
  const { toast } = useToast();

  const initialSchema = yup.object({
    first: yup.string().required('This field is required'),
    second: yup.string().required('This field is required'),
    third: yup.string().required('This field is required'),
    fourth: yup.string().required('This field is required'),
    fifth: yup.string().required('This field is required'),
    sixth: yup.string().required('This field is required'),
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    trigger,
    setFocus,
    register,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(initialSchema),
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      return await executeRecaptcha();
    }
  }, [executeRecaptcha]);

  const [login, { loading }] = useMutation(LOGIN_GQL, {
    onCompleted: (response) => {
      if (response.login.status === null) {
        setAuthorization(response.login);
        const redirect = query.get('redirect') ?? REACT_APP_ADMIN_HOMEPAGE;
        history.push(redirect);
      }
    },
    onError: (error: ApolloError) => {
      const { extensions } = error?.graphQLErrors?.[0];
      if (extensions?.code === 'BAD_USER_INPUT') {
        const validations = extensions?.errors as Record<string, string>;
        Object.keys(validations).forEach((key: string) => {
          switch (key) {
            case 'email':
            case 'password':
              toast('error', 'OTP code invalid');
              break;
            case 'recaptchaToken':
              alert(validations[key]);
              break;
          }
        });
      } else if (extensions?.code === 'UNAUTHENTICATED') {
        toast('error', 'OTP code invalid');
      } else {
        console.log(error.message);
      }
    },
  });

  const onSubmit = handleSubmit(async () => {
    const reCaptchaToken = await handleReCaptchaVerify();

    login({
      variables: {
        email: loginCredentials.email,
        password: loginCredentials.password,
        otp: getValues(['first', 'second', 'third', 'fourth', 'fifth', 'sixth']).join(''),
        reCaptchaToken,
      },
    });
  });

  const goToInitial = () => [
    changeStep(StepsEnum.otp, false),
    setTimeout(() => setEnableSteps({ ...enableSteps, enableOtp: false }), 500),
  ];

  return (
    <div className={[styles['form-wrapper'], animate[animations.otp]].join(' ')}>
      <IconAnimated size="md" icon={{ name: 'pin' }} wrapper={{ color: 'primary' }} />
      <Heading size={3}>Authentication code</Heading>
      <div className={styles['wrapper-paragraph']}>
        <Heading size={6} weight={FontWeight.semiBold}>
          Enter your code:
        </Heading>
      </div>
      <form onSubmit={onSubmit}>
        <TwoFAInput
          register={register}
          setValue={setValue}
          setFocus={setFocus}
          trigger={trigger}
          button={button.current}
        />
        <div className={styles['wrapper-buttons']}>
          <Button fullWidth ref={button} type="submit" loading={loading} disabled={!isValid}>
            Continue
          </Button>
          <Button fullWidth variant="tertiary" firstIcon={{ children: 'keyboard_backspace' }} onClick={goToInitial}>
            Previous step
          </Button>
        </div>
      </form>
    </div>
  );
}
