import { gql } from '@apollo/client';

export const LOGIN_GQL = gql`
  mutation login($email: String!, $password: String!, $otp: String, $reCaptchaToken: String) {
    login(email: $email, password: $password, otp: $otp, reCaptchaToken: $reCaptchaToken) {
      access_token
      expires_in
      refresh_expires_in
      refresh_token
      scope
      session_state
      token_type
      status
    }
  }
`;

export const FORGOT_PASSWORD_GQL = gql`
  mutation forgotPassword($email: String!, $reCaptchaToken: String!) {
    forgotPassword(email: $email, reCaptchaToken: $reCaptchaToken) {
      status
      message
    }
  }
`;
export const RESET_PASSWORD_GQL = gql`
  mutation resetPassword($email: String!, $code: String!, $password: String!, $passwordConfirmation: String!) {
    resetPassword(email: $email, code: $code, password: $password, passwordConfirmation: $passwordConfirmation) {
      status
      message
    }
  }
`;

export const LOGOUT_GQL = gql`
  mutation logout {
    logout {
      message
    }
  }
`;
