import useSignupContext from '../../SignupContextProvider';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

export default function ProgressStep(): JSX.Element {
  const { animations } = useSignupContext();

  const validationStep = (step: string) => {
    switch (step) {
      case 'left':
        return styles['previous'];
      case 'center':
        return styles['current'];
      case 'right':
        return '';
    }
  };

  return (
    <div className={[styles['progress-steps-wrapper'], animate[animations.stepProgress]].join(' ')}>
      <div className={styles['previous']} />
      <div className={validationStep(animations.verifyCode)} />
      <div className={validationStep(animations.setPassword)} />
      <div className={validationStep(animations.terms)} />
      <div className={validationStep(animations.accountCreated)} />
    </div>
  );
}
