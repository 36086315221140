import { ApolloError, useQuery } from '@apollo/client';
import { PaginationInput, paginatorInfo } from '../../../../ts';
import { CONTACTS_FIELDS } from './contacts.gql';
import { TableHeaderElement } from '../../../../componentsV2/TableColumnsControl/interface';

export interface ContactsFilters {
  order?: string;
  sort?: string;
  promo_id?: string;
  search?: string;
}

export interface ContactsVariables {
  accountId: number;
  pagination?: PaginationInput;
}

interface ContactsResult {
  loading: boolean;
  data: TableHeaderElement[];
  fetchFields: (variables: ContactsVariables) => void;
}

export function useFetchContactsFields(accountId: number): ContactsResult {
  const { data, loading, refetch } = useQuery<{
    contactsFields: { data: TableHeaderElement[]; paginatorInfo: paginatorInfo };
  }>(CONTACTS_FIELDS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const fetchFields = (variables: ContactsVariables) => {
    refetch(variables);
  };

  if (loading) {
    return {
      loading: true,
      data: [],
      fetchFields,
    };
  }

  return {
    loading: false,
    data: data?.contactsFields?.data || [],
    fetchFields,
  };
}
