const scrollToElement = (elementId?: string, offset = 0): void => {
  const targetElement = elementId ? document.getElementById(elementId) : null;

  const targetPosition = targetElement ? targetElement.getBoundingClientRect().top + window.scrollY - offset : 0;

  window.scrollTo({
    top: targetPosition,
    behavior: 'smooth',
  });
};

export default scrollToElement;
