import { ApolloError, gql, useQuery } from '@apollo/client';

const SENDER_MESSAGE = gql`
  query senderMessage($accountId: ID!, $messageId: ID!) {
    senderMessage(accountId: $accountId, messageId: $messageId) {
      id
      recipient
      recipientName
      subject
      content
      sender
      senderName
    }
  }
`;

interface SenderMessage {
  id: number;
  recipient: string;
  recipientName: string;
  subject: string;
  content: string;
  sender: string;
  senderName: string;
}

interface UseFetchSenderMessage {
  loading: boolean;
  data: SenderMessage | null;
  error?: ApolloError;
}

export function useFetchSenderMessage(accountId: number, messageId: number): UseFetchSenderMessage {
  const { data, loading, error } = useQuery<{
    senderMessage: SenderMessage;
  }>(SENDER_MESSAGE, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      messageId,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
    };
  }

  return {
    loading: false,
    data: data?.senderMessage || null,
  };
}
