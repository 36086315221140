import { ApolloError, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import useToast from '../../../../../../hooks/useToast';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import useSigninContext, { StepsEnum } from '../../../SigninContextProvider';
import { FORGOT_PASSWORD_GQL } from '../../../graphql';
import animate from '../../styles.module.scss';
import styles from './styles.module.scss';

export default function ForgotPassword(): JSX.Element {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { animations, changeStep, setEnableSteps, enableSteps } = useSigninContext();
  const { toast } = useToast();
  const formRef = useRef<HTMLFormElement>(null);
  const initialSchema = yup.object({
    email: yup.string().email('This email is not valid').required('This field is required'),
  });

  useEffect(() => {
    if (animations.forgotPassword == 'center') {
      formRef?.current?.querySelectorAll('input')[0].focus();
    }
  }, [animations]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (executeRecaptcha) {
      return await executeRecaptcha();
    }
  }, [executeRecaptcha]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    clearErrors,
  } = useForm({
    resolver: yupResolver(initialSchema),
    reValidateMode: 'onSubmit',
  });

  const email = register('email');

  const goToInitial = () => {
    setEnableSteps({ ...enableSteps, initial: true }),
      setTimeout(() => {
        changeStep(StepsEnum.forgotPassword, false);
      }, 500);
  };

  const [forgot, { loading }] = useMutation(FORGOT_PASSWORD_GQL, {
    onCompleted: (response) => {
      toast('success', response.forgotPassword.message);
      reset({ email: '' });
      goToInitial();
    },
    onError: (error: ApolloError) => {
      if (error?.graphQLErrors?.[0].extensions?.code) {
        if (error?.graphQLErrors?.[0].extensions?.code === 'BAD_USER_INPUT') {
          const validations = error?.graphQLErrors?.[0].extensions?.validations as Record<string, string>;
          Object.keys(validations).forEach((key: string) => {
            const fieldName = key.toLocaleLowerCase() as 'recaptchatoken';
            if (fieldName === 'recaptchatoken') {
              toast('error', validations[key].toString());
            }
          });
        }
      }
    },
  });

  const onSubmit = handleSubmit(async (variables) => {
    const reCaptchaToken = await handleReCaptchaVerify();

    const variablesWithToken = {
      ...variables,
      reCaptchaToken,
    };
    forgot({ variables: variablesWithToken });
  });

  return (
    <>
      <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.forgotPassword])}>
        <IconAnimated size="md" icon={{ name: 'lock' }} wrapper={{ color: 'primary' }} />
        <Heading size={3}>Forgot password</Heading>
        <form onSubmit={onSubmit} ref={formRef}>
          <Input
            label="Email"
            placeholder="contact@beeliked.com"
            {...(errors?.email?.message && { color: 'danger', helperText: errors.email.message })}
            {...email}
            onChange={(e) => {
              email.onChange(e);
              clearErrors('email');
            }}
          />
          <Button fullWidth size="md" type="submit" loading={loading} disabled={!isValid}>
            Recovery password
          </Button>
        </form>
        <div className={styles['sign-text']}>
          <Paragraph size={2}>{`Remember your password?`}</Paragraph>
          <Button variant="tertiary" onClick={goToInitial}>
            Sign in
          </Button>
        </div>
      </div>
    </>
  );
}
