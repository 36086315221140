import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryString from '../../hooks/useQueryString';

export default function Reload(): JSX.Element {
  const history = useHistory();
  const query = useQueryString();
  const page = query.get('page');

  useEffect(() => {
    if (page) {
      history.push(`/${page}`);
    }
  }, [page]);

  return <></>;
}
