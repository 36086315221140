import { ApolloError, gql, useMutation } from '@apollo/client';

interface ExportInviteLinks {
  status: string;
  message: string;
  random: number;
}

interface InvitesFilters {
  code?: string;
  entry_search?: string;
  order?: string;
  sort?: string;
  user_id?: string;
  promotion_id?: string;
  created_at?: string;
  invite_type?: string;
  invite_used?: string;
  generator_id?: string;
}

const EXPORT_CSV_BY_ACCOUNT = gql`
  mutation exportCsvByAccount($accountId: ID!, $filters: InviteLinksByAccountFilter, $columnFields: String) {
    exportCsvByAccount(accountId: $accountId, filters: $filters, columnFields: $columnFields) {
      status
      message
      random
    }
  }
`;

export function useExportInviteLinksMutation(): {
  loading: boolean;
  exportInviteLinks?: (accountId: number, filters?: InvitesFilters, columnFields?: string) => void;
  data: ExportInviteLinks | null;
  error?: ApolloError;
} {
  const [exportCsvByAccountMutation, { loading, data, error }] = useMutation<{ exportCsvByAccount: ExportInviteLinks }>(
    EXPORT_CSV_BY_ACCOUNT,
    {
      onError: (error: ApolloError) => {
        console.log({
          gql: 'EXPORT_CSV_BY_ACCOUNT',
          apolloError: error,
        });
      },
    },
  );

  const exportInviteLinks = (accountId: number, filters?: InvitesFilters, columnFields?: string) => {
    exportCsvByAccountMutation({ variables: { accountId, filters, columnFields } });
  };

  if (error) {
    return {
      error,
      loading: false,
      data: null,
    };
  }

  return {
    loading,
    exportInviteLinks,
    data: data?.exportCsvByAccount || null,
  };
}
