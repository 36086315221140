import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import useAccount from '../../../../../hooks/useAccount';
import { CollectionData, paginatorInfo } from '../../../../../ts';
import { useQuery } from '@apollo/client';
import { LIST_COLLECTIONS } from '../../../Rewards/Coupons/graphql';
import Card from '../../../../../componentsV2/DesignSystem/Card';
import { CardAnimations } from '../../../../../componentsV2/DesignSystem/Card/interface';
import SkeletonLoading from './SkeletonLoading';
import CollectionList from './CollectionList';
import ErrorRefetch from '../../../../../componentsV2/ErrorRefetch';

export default function CouponCollections(): JSX.Element {
  const { getAccount } = useAccount();

  const TOTAL_ITEMS = 6;

  const {
    data: dataCollections,
    loading: loadingCollections,
    error: errorCollections,
    refetch: refetchCollections,
  } = useQuery<{
    collections: { data: CollectionData[]; paginatorInfo: paginatorInfo };
  }>(LIST_COLLECTIONS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      agency_id: getAccount.agencyId,
      account_id: getAccount.accountId,
      limit: TOTAL_ITEMS,
    },
    onError: () => {
      console.log('[Error]: LIST_COLLECTIONS');
    },
  });

  const collections = useMemo(() => {
    return dataCollections?.collections?.data ?? [];
  }, [dataCollections?.collections?.data]);

  const refetchQuery = () => {
    refetchCollections();
  };
  return (
    <Card
      identify="coupons"
      size="md"
      animation={{ type: CardAnimations.top, duration: '1s' }}
      header={{ title: 'Coupon Collections' }}
      footer={{
        buttons:
          collections.length > 0
            ? [
                {
                  children: 'View All',
                  variant: 'tertiary',
                  lastIcon: { children: 'chevron_right' },
                  to: '/rewards/coupons',
                },
              ]
            : [],
      }}
    >
      {errorCollections ? (
        <ErrorRefetch callback={refetchQuery} />
      ) : (
        <div className={styles['coupons-wrapper']}>
          {loadingCollections ? (
            <SkeletonLoading totalItems={TOTAL_ITEMS} />
          ) : (
            <CollectionList collections={collections} />
          )}
        </div>
      )}
    </Card>
  );
}
