import useAccount from '../../../../hooks/useAccount';
import useQueryParams from '../../../../hooks/useQueryParams';
import useQueryString from '../../../../hooks/useQueryString';
import Content from './Content';
import Filters from '../Filters';
import styles from './styles.module.scss';
import { EntriesFilters, useFetchEntries } from './useFetchEntries';
import useFetchPromotionsSimpleList from '../../../../graphql/queries/useFetchPromotionsSimpleList';
import { ENTRIES_FILTER_LIST } from '../Filters/FilterList';
import { ApolloError, useQuery } from '@apollo/client';
import { paginatorInfo } from '../../../../ts';
import { Campaigns } from '../../CreatePromotion/SectionCampaigns/interface';
import { CAMPAIGNS } from './entries.gql';
import FILTER_LIST from '../Filters/DefaultFilterList';
import { setFirstLastDate } from '../utils';

export default function Entries(): JSX.Element {
  const { getAccount } = useAccount();
  const query = useQueryString();
  const addQueryParam = useQueryParams();

  const { data: promotionData, loading: promotionLoading } = useFetchPromotionsSimpleList(getAccount.accountId);
  const { data: gameTypeData, loading: gameTypeLoading } = useQuery<{
    campaigns: { data: Campaigns[]; paginatorInfo: paginatorInfo };
  }>(CAMPAIGNS, {
    variables: { accountId: getAccount.accountId, page: 1, pageLimit: 100 },
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      console.log({
        gql: 'CAMPAIGNS',
        apolloError: error,
      });
    },
  });
  const queryValues = {
    orderColumn: query.get('orderColumn') || 'created_at',
    sortColumn: query.get('sortColumn') || 'desc',
    currentPage: Number(query.get('page')) || 1,
    tableColumns: query.get('columns') || undefined,
  };

  const filters = {
    search: query.get('search') || undefined,
    promo_id: query.get('promo_id') || undefined,
    date: query.get('date') || undefined,
    created_at: query.get('created_at') || setFirstLastDate(),
    status: query.get('status') || undefined,
    visibility: query.get('visibility') || undefined,
    game_type: query.get('game_type') || undefined,
  };

  const params: EntriesFilters = {
    order: queryValues.orderColumn,
    sort: queryValues.sortColumn,
    ...filters,
  };

  const {
    data,
    loading: dataLoading,
    pagination,
    fetchEntries,
  } = useFetchEntries(getAccount.accountId, { page: queryValues.currentPage }, params);

  const handlePagination = (page: number) => {
    addQueryParam({ page });
  };

  const searchEntries = (field: string, value: string) => {
    const searchFilter = value ? { [field]: value } : { [field]: '' };
    const filters = {
      ...searchFilter,
    };
    addQueryParam({ ...filters, page: 1 });
  };

  const clearUrlFilters = () => {
    addQueryParam({
      order: undefined,
      sort: undefined,
      columns: undefined,
      search: undefined,
      promo_id: undefined,
      status: undefined,
      visibility: undefined,
      game_type: undefined,
      created_at: setFirstLastDate(),
      page: 1,
    });
  };

  return (
    <section className={styles['wrapper']}>
      {/*@todo <Button onClick={() => fetchEntries({ accountId: getAccount.accountId })}>Refetch</Button> */}
      <Filters
        search={searchEntries}
        params={params}
        clearUrlFilters={clearUrlFilters}
        data={promotionData}
        loadingData={promotionLoading}
        gameTypeData={gameTypeData?.campaigns.data}
        gameTypeLoading={gameTypeLoading}
        filterList={[...FILTER_LIST, ...ENTRIES_FILTER_LIST]}
      />
      <Content
        data={data}
        dataLoading={dataLoading}
        refetch={fetchEntries}
        pagination={pagination}
        handlePagination={handlePagination}
        currentPage={queryValues.currentPage}
        search={searchEntries}
        params={params}
      />
    </section>
  );
}
