import React, { useMemo, useState } from 'react';
import Testimonial from './Testimonial';
import styles from './styles.module.scss';
import { TestimonialsData, TestimonialsProps } from './interface';
import { IconButton } from '../../../../../../componentsV2/DesignSystem/Inputs/IconButton';
import { LIST_SIGNUP_TESTIMONIALS } from './graphql';
import { ApolloError, useQuery } from '@apollo/client';

export default function Testimonials({ style }: TestimonialsProps): JSX.Element {
  const [position, setPosition] = useState(0);
  const [slideData, setSlideData] = useState<TestimonialsData[]>([] as TestimonialsData[]);

  const { data } = useQuery<{
    testimonials: { data: TestimonialsData[]; paginatorMeta: { total: number } };
  }>(LIST_SIGNUP_TESTIMONIALS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => setSlideData(result.testimonials.data),
    onError: (error: ApolloError) => {
      console.log({
        gql: 'LIST_SIGNUP_TESTIMONIALS',
        apolloError: error,
      });
    },
  });

  function nextTestimonial() {
    setPosition((prev) => prev + 1);
  }
  function previousTestimonial() {
    if (position == 0) setPosition(slideData.length - 1);
    setPosition((prev) => prev - 1);
  }

  useMemo(() => {
    if (!!data?.testimonials?.data) setSlideData(data?.testimonials?.data);
  }, [data]);

  return (
    <main className={styles['testimonials-wrapper']} style={style}>
      {slideData?.map((testimonial, index) => (
        <Testimonial
          key={index}
          {...testimonial}
          animation={position % slideData.length == index ? styles['fade-in'] : styles['fade-out']}
        />
      ))}
      <div className={styles['wrapper-buttons']}>
        <IconButton onClick={previousTestimonial}>chevron_left</IconButton>
        <IconButton onClick={nextTestimonial}>chevron_right</IconButton>
      </div>
    </main>
  );
}
