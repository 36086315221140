export function FormatInCurrency(currency: string, amount: number, maximumFractionDigits = 0): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits,
  }).format(amount);
}

export function FormatDateMonthYear(date: Date | number): string {
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    year: '2-digit',
  }).format(date);
}

export function FormatDateDayMonthYear(date: Date | number): string {
  return new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date);
}

export function FormatDateDayLongMonthYear(date: Date | number): string {
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
  }).format(date);
}

export function FormatDateDayLongMonthYearShortTime(date: Date | number): string {
  return new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(date);
}

export function FormatNumberComma(amount?: number): string | undefined {
  return amount !== undefined ? new Intl.NumberFormat('en-UK').format(amount) : undefined;
}

export function FormatNumberTwoNumeric(amount: number): string {
  return amount.toString().padStart(2, '0');
}
