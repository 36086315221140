import React, { useMemo, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ZapierZapTemplatesData } from '../../../../../ts';
import { LIST_ZAP_TEMPLATES_GQL } from '../../graphql';
import ListZapTemplates from './ListZapTemplates';
import EmptyZapTemplates from './EmptyZaps';
import useZapierContext from '../../ZapierContextProvider';
import LoadMoreButton from './LoadMoreButton';
import { FontWeight, Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import styles from './styles.module.scss';
import { Tags } from '../../../../../componentsV2/DesignSystem/Tags';
import { TagsColor } from '../../../../../componentsV2/DesignSystem/Tags/interface';
import Skeleton from '../../../../../componentsV2/DesignSystem/Skeleton';

export default function Templates(): JSX.Element {
  const [limit, setLimit] = useState<number>(100);
  const [disableLoadMore, setDisableLoadMore] = useState<boolean>(false);
  const { t } = useTranslation('zapier', { keyPrefix: 'build' });
  const { appSlug, setAppSlug } = useZapierContext();

  const {
    data: dataZapTemplates,
    loading: loadingZapTemplates,
    fetchMore: fetchMoreZapTemplates,
  } = useQuery<{
    zapierZapTemplates: ZapierZapTemplatesData[];
  }>(LIST_ZAP_TEMPLATES_GQL, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
    variables: {
      ...(appSlug && { apps: appSlug.slug }),
      limit,
      offset: 0,
    },
    onError: (error: ApolloError) => {
      console.log('[LIST_ZAPIER_APPS_GQL]:', error);
    },
  });

  const templates = useMemo(() => {
    return dataZapTemplates?.zapierZapTemplates ?? [];
  }, [dataZapTemplates?.zapierZapTemplates]);

  const loadMore = () => {
    const currentLength = templates.length;
    fetchMoreZapTemplates({
      variables: {
        offset: templates.length,
        limit: 10,
      },
    }).then((fetchMoreResult) => {
      setLimit(currentLength + fetchMoreResult.data.zapierZapTemplates.length);
      setDisableLoadMore(fetchMoreResult.data.zapierZapTemplates.length === 0);
    });
  };

  return (
    <section className={styles['wrapper-templates']}>
      <Paragraph size={2} weight={FontWeight.extraBold}>
        {t('Choose a Zap Templates')}
      </Paragraph>
      {!!appSlug?.name && (
        <div>
          <Tags color={TagsColor.primary} onClick={() => setAppSlug()}>
            {appSlug.name}
          </Tags>
        </div>
      )}

      {loadingZapTemplates ? (
        <section className={styles['wrapper-skeletons']}>
          {Array.from({ length: 6 }, () => '').map((_, index) => (
            <Skeleton key={index} height={14} />
          ))}
        </section>
      ) : templates.length > 0 ? (
        <>
          <ListZapTemplates templates={templates} />
          <LoadMoreButton
            total={templates.length}
            limit={limit}
            disableLoadMore={disableLoadMore}
            loadMore={loadMore}
          />
        </>
      ) : (
        <EmptyZapTemplates />
      )}
    </section>
  );
}
