import { useMutation } from '@apollo/client';
import { useHubspotConversations } from '../../providers/HubspotConversationsProvider';
import { CREATE_TOKEN_HUBSPOT } from './graphql';
import { CreateToken, CreateTokenDataInput, OpenHubspotChatProps } from './interface';

export const OpenHubspotChat = ({
  email,
  firstName,
  lastName,
}: OpenHubspotChatProps): { openHubspotChat: () => void } => {
  const { toggleWidget, openConversation, getStatus } = useHubspotConversations();

  const [createTokenMutation] = useMutation<{ createToken: CreateToken }, { data: CreateTokenDataInput }>(
    CREATE_TOKEN_HUBSPOT,
    {
      onCompleted: (response) => {
        openConversation(response.createToken.token);
      },
    },
  );

  const openHubspotChat = () => {
    const status = getStatus();
    if (status && !status.loaded) {
      createTokenMutation({
        variables: {
          data: {
            email,
            firstName,
            lastName,
          },
        },
      });
    } else {
      toggleWidget();
    }
  };

  return { openHubspotChat };
};
