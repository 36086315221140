import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SpinnerAllScreen from '../../components/_molecules/SpinnerAllScreen';

export default function Home(): JSX.Element {
  const history = useHistory();

  useEffect(() => {
    history.push('/signin');
  }, []);

  return <SpinnerAllScreen />;
}
