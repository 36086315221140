import Icon from '../Icon';
import { PillsProps } from './interface';
import styles from './styles.module.scss';

export default function Pills({
  size,
  color,
  firstIcon,
  lastIcon,
  children,
  propRef,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
}: PillsProps): JSX.Element {
  return (
    <div
      ref={propRef}
      className={[
        styles['pills'],
        size ? styles[size] : styles['sm'],
        color ? styles[color] : styles['primary'],
        className,
      ].join(' ')}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {firstIcon?.children && <Icon {...firstIcon} />}
      <span>{children}</span>
      {lastIcon?.children && <Icon {...lastIcon} />}
    </div>
  );
}
