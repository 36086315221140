import Modal from '../../../../componentsV2/DesignSystem/Modal';
import SpinnerBox from '../../../../componentsV2/DesignSystem/SpinnerBox';
import useAccount from '../../../../hooks/useAccount';
import styles from './styles.module.scss';
import { useFetchSenderMessage } from './useFetchSenderMessage';

interface SendEmailModalProps {
  setMessageIdPreview: (messageIdPreview?: number) => void;
  messageId: number;
}

export default function PreviewEmailModal({ setMessageIdPreview, messageId }: SendEmailModalProps): JSX.Element {
  const { getAccount } = useAccount();
  const { data, loading } = useFetchSenderMessage(getAccount.accountId, messageId);
  return (
    <Modal
      title="Preview Email"
      callback={() => setMessageIdPreview(undefined)}
      size="big"
      leftButton={{
        children: 'Close',
        variant: 'secondary',
        size: 'sm',
        onClick: () => setMessageIdPreview(undefined),
      }}
      customClass={styles['modal-container']}
    >
      {loading ? (
        <div className={styles['loading']}>
          <SpinnerBox />
        </div>
      ) : (
        <>{!!data?.content && <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>}</>
      )}
    </Modal>
  );
}
