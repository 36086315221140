import { ApolloError, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ACCOUNT_PERMISSION_LIST } from '../config/Constants';
import { REACT_APP_SENTRY_DSN } from '../config/Env';
import { GET_REFRESH_TOKEN_GQL } from '../config/Graphql/auth.gql';
import { AuthenticationData } from '../ts';
import { getAuthCookie, getRefreshAuthCookie, removeAuthCookie, setAuthCookie } from '../utils/authCookie';
import { removeZapierAccessToken } from '../utils/zapierStorage';

interface AuthJwtPayload extends JwtPayload, Me {}

interface Roles {
  roles: string[];
}

interface ResourceAccess {
  account: Roles;
}

export interface Me {
  user_id: number;
  agency_id: number;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  account_permissions: Record<string, string>;
  realm_access: Roles;
  resource_access: ResourceAccess;
  accounts: string[];
  superAdmin: boolean;
  email_verified: boolean;
}

interface AuthContext {
  logged: AuthenticationData;
  setAuthorization(authenticationData: AuthenticationData): void;
  clearAuthorization(): void;
  getMe: Me;
  isSystem: () => boolean;
  denyEditorAccess: (accountId: number) => boolean;
  isAccountAdmin: (accountId: number) => boolean;
}

const AuthContext = createContext({});

export function AuthProvider({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
  const history = useHistory();
  const [logged, setLogged] = useState<string | undefined>(getAuthCookie());

  const getMe = useMemo(() => {
    if (!logged) {
      return;
    }

    const payload = jwtDecode<AuthJwtPayload>(logged);

    if (REACT_APP_SENTRY_DSN) {
      Sentry.setUser({ user_id: payload.user_id, email: payload.email });
    }

    const account_permissions = Object.fromEntries(
      Object.entries(payload.account_permissions).map(([key, value]) => [key, ACCOUNT_PERMISSION_LIST[value] || value]),
    );

    return {
      user_id: payload.user_id,
      agency_id: payload.agency_id,
      name: payload.name,
      preferred_username: payload.preferred_username,
      given_name: payload.given_name,
      family_name: payload.family_name,
      email: payload.email,
      account_permissions,
      realm_access: payload.realm_access,
      resource_access: payload.resource_access,
      accounts: Object.keys(payload.account_permissions),
      superAdmin: payload.realm_access?.roles?.indexOf('super_admin') > -1,
      email_verified: payload.email_verified,
    };
  }, [logged]);

  const setAuthorization = (authenticationData: AuthenticationData) => {
    setAuthCookie(authenticationData);
    setLogged(authenticationData.access_token);
  };

  const clearAuthorization = () => {
    setLogged(undefined);
    removeAuthCookie();
    removeZapierAccessToken();
  };

  const [refreshToken] = useMutation<{ refresh: AuthenticationData }>(GET_REFRESH_TOKEN_GQL, {
    onCompleted: (response) => {
      setAuthorization(response?.refresh);
    },
    onError: (error: ApolloError) => {
      console.log('[ERROR] Refresh token:', error.message);
    },
  });

  useEffect(() => {
    if (!getMe) {
      return;
    }

    let timeoutId: NodeJS.Timeout | null = null;

    const refresh = async () => {
      try {
        const refresh_token = getRefreshAuthCookie();
        if (!refresh_token) {
          console.log('Connection lost, please try logging in again!');
          history.push('/');
        } else {
          refreshToken({
            variables: {
              refresh_token,
            },
          });
        }
      } catch (e) {
        console.log('Catch error refresh token:', e);
      } finally {
        timeoutId = setTimeout(refresh, 1000 * 60 * 3);
      }
    };

    timeoutId = setTimeout(refresh, 1000 * 60 * 3);

    return () => {
      clearTimeout(typeof timeoutId == 'number' ? timeoutId : undefined);
    };
  }, [getMe]);

  const isSystem = () => {
    return getMe?.email.endsWith('@beeliked.com');
  };

  const isAccountAdmin = (accountId: number) =>
    !!getMe?.superAdmin ||
    (getMe?.account_permissions?.[accountId] &&
      getMe.account_permissions[accountId].toLocaleUpperCase() === 'ACCOUNT_ADMIN');

  const denyEditorAccess = (accountId: number) => {
    if (!getMe) {
      return;
    }

    return (
      !getMe.superAdmin &&
      getMe.account_permissions[accountId] &&
      getMe.account_permissions[accountId].toLocaleUpperCase() === 'ACCOUNT_EDITOR'
    );
  };

  return (
    <AuthContext.Provider
      value={{
        logged,
        setAuthorization,
        clearAuthorization,
        getMe,
        isSystem,
        denyEditorAccess,
        isAccountAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default function useAuthContext(): AuthContext {
  const context = useContext(AuthContext) as AuthContext;
  return context;
}
