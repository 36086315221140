import { ApolloError, gql, useMutation } from '@apollo/client';

const CREATE_WIDGET_USER = gql`
  mutation createWidgetUser($accountId: ID!, $widgetId: ID!, $username: String!, $password: String!) {
    createWidgetUser(accountId: $accountId, widgetId: $widgetId, username: $username, password: $password) {
      id
      username
      password
    }
  }
`;

interface WidgetUserResult {
  id: number;
  username: string;
  password: string;
}

export function useMutationCreateWidgetUser(): {
  loading: boolean;
  createWidgetUser?: (variables: { accountId: number; widgetId: number; username: string; password: string }) => void;
  data: WidgetUserResult | null;
  error?: ApolloError;
} {
  const [createWidgetUserMutation, { loading, data, error }] = useMutation<{ createWidgetUser: WidgetUserResult }>(
    CREATE_WIDGET_USER,
    {
      onError: (error: ApolloError) => {
        console.log({
          gql: 'CREATE_WIDGET_USER',
          apolloError: error,
        });
      },
    },
  );

  const createWidgetUser = (variables: { accountId: number; widgetId: number; username: string; password: string }) => {
    createWidgetUserMutation({ variables });
  };

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
      createWidgetUser,
    };
  }

  return {
    loading,
    createWidgetUser,
    data: data?.createWidgetUser || null,
  };
}
