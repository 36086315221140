import { ApolloError, gql, useQuery } from '@apollo/client';

const WIDGET = gql`
  query widget($accountId: ID!, $widgetId: ID!) {
    widget(accountId: $accountId, widgetId: $widgetId) {
      id
      account_id
      promo_id
      name
      slug
      image_file
      header_message
      header_message_font_color
      message1
      message1_font_color
      message2
      message2_font_color
      expire_time
      widget_background
      page_background
      created_at
      updated_at
    }
  }
`;

export interface Widget {
  id: number;
  account_id: number;
  promo_id: number;
  name: string;
  slug: string;
  image_file: string;
  header_message: string;
  header_message_font_color: string;
  message1: string;
  message1_font_color: string;
  message2: string;
  message2_font_color: string;
  expire_time: number;
  widget_background: string;
  page_background: string;
  created_at: string;
  updated_at: string;
}

export function useFetchWidget(
  accountId: number,
  widgetId: number,
): {
  loading: boolean;
  data: Widget | null;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<{ widget: Widget }>(WIDGET, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      widgetId,
    },
    skip: !widgetId,
    onError: (error: ApolloError) => {
      console.log({
        gql: 'WIDGET',
        apolloError: error,
      });
    },
  });

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error,
    };
  }

  return {
    loading: false,
    data: data?.widget || null,
  };
}
