import { useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import ButtonTooltip from '../../ButtonTooltip';
import { Button } from '../../DesignSystem/Inputs/Buttons/ButtonAction';
import Pills from '../../DesignSystem/Pills';
import Popover from '../../DesignSystem/Popover';
import { Tags } from '../../DesignSystem/Tags';
import { TagsColor } from '../../DesignSystem/Tags/interface';
import { FontWeight, Paragraph } from '../../DesignSystem/Typography';
import { TableCellProps } from './interface';
import styles from './styles.module.scss';
import { Avatar } from '../../DesignSystem/Avatar';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import { FormatDateDayLongMonthYearShortTime } from '../../DesignSystem/Utils/Format';

export function TableCell({ type, hide, value, callback }: TableCellProps): JSX.Element {
  const buttonRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 1000);
  };

  if (hide) {
    return (
      <div className={styleModuleClasses(styles, 'hide-data', 'wrapper')} onClick={callback}>
        <div></div>
      </div>
    );
  } else {
    switch (type) {
      case 'tag':
        if (!!value.tag?.length) {
          return (
            <div onClick={callback} className={styleModuleClasses(styles, 'wrapper', 'tags')}>
              {value.tag.length > 1 && typeof value?.tag[0] == 'object' ? (
                <>
                  <Tags {...value?.tag[0]} />
                  <Tags {...value?.tag[1]} />
                  {!!(value.tag.length - 2) && <Tags color={TagsColor.gray}>{`+${value.tag.length - 2}`}</Tags>}
                </>
              ) : (
                <Tags {...value.tag[0]} />
              )}
            </div>
          );
        } else {
          return (
            <div onClick={callback} className={styleModuleClasses(styles, 'wrapper', 'tags')}>
              -
            </div>
          );
        }
      case 'qr_code':
        return (
          <div className={styleModuleClasses(styles, 'qr-code-cell', 'wrapper')} onClick={callback}>
            <div>
              <QRCode value={value.message} size={77} />
            </div>
            <Paragraph size={2} weight={FontWeight.semiBold} ellipsis={1}>
              {value.message}
            </Paragraph>
          </div>
        );
      case 'pill':
        return (
          <div onClick={callback} className={styleModuleClasses(styles, 'wrapper', 'pills')}>
            {value.pill ? <Pills {...value.pill} size="lg" /> : <>-</>}
          </div>
        );
      case 'tooltip':
        return (
          <div className={styleModuleClasses(styles, 'cell-with-button', 'wrapper')} onClick={callback}>
            <ButtonTooltip tooltip={value.buttonTooltipProps?.tooltip || ''} {...value.buttonTooltipProps}>
              {value.message}
            </ButtonTooltip>
          </div>
        );
      case 'popover':
        return (
          <div className={styleModuleClasses(styles, 'cell-with-button', 'wrapper')} onClick={callback}>
            <Button
              firstIcon={
                value.enable
                  ? { children: 'mark_email_read' }
                  : { children: 'cancel_schedule_send', color: 'red-block' }
              }
              ref={buttonRef}
              variant="tertiary"
              size="sm"
              onMouseEnter={() => setShowPopover(true)}
              onMouseLeave={() => setShowPopover(false)}
            />
            <Popover
              callback={() => setShowPopover(false)}
              hiddenOverlay
              showPopover={showPopover}
              propRef={buttonRef}
              color="dark"
              placement="left-start"
              mainStyle={{ display: 'grid', gap: '.25rem', padding: '.75rem' }}
              size="sm"
            >
              <>
                <Paragraph size={3} weight={FontWeight.extraBold}>
                  {value.header}
                </Paragraph>
                <Paragraph size={3} weight={FontWeight.semiBold} color={'gray-24'}>
                  {value.message}
                </Paragraph>
              </>
            </Popover>
          </div>
        );

      case 'copy':
        return (
          <div onClick={callback} className={styleModuleClasses(styles, 'wrapper', 'no-gap')}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              {value.message}
            </Paragraph>
            {value.message.trim() != '-' && (
              <Button
                variant="tertiary"
                size="sm"
                firstIcon={copyStatus ? { children: 'check', color: 'green-status-hover' } : { children: 'copy_all' }}
                text={{ color: 'dark' }}
                onClick={() => handleCopy(value.message)}
              />
            )}
          </div>
        );
      case 'name':
        return (
          <div className={styleModuleClasses(styles, 'cell-name', 'wrapper')} onClick={callback}>
            {value.message.trim() != '-' && <Avatar name={value.message} image={value.src} />}
            <Paragraph size={2} weight={FontWeight.semiBold} ellipsis={1}>
              {value.message}
            </Paragraph>
          </div>
        );
      case 'date':
        return (
          <div className={styleModuleClasses(styles, 'cell-text', 'wrapper')} onClick={callback}>
            <Paragraph size={2} weight={FontWeight.semiBold} ellipsis={1}>
              {FormatDateDayLongMonthYearShortTime(Date.parse(value.message))}
            </Paragraph>
          </div>
        );

      default:
        return (
          <div className={styleModuleClasses(styles, 'cell-text', 'wrapper')} onClick={callback}>
            <Paragraph size={2} weight={FontWeight.semiBold} ellipsis={1}>
              {value.message}
            </Paragraph>
          </div>
        );
    }
  }
}
