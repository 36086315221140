import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ButtonNavigation from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import { Heading } from '../../../../componentsV2/DesignSystem/Typography';
import { useFetchAgreementByUser } from '../../Settings/EditYourDetails/Terms/useFetchAgreementByUser ';
import AllAccounts from './AllAccounts';
import FavoriteAccounts from './FavoriteAccounts';
import { SwitchAccountContextProvider } from './SwitchAccountContextProvider';
import styles from './styles.module.scss';

interface Props {
  accountHighlight?: boolean;
  agreementAccepted?: boolean;
  parent?: 'AccountManager';
}

export default function ChooseAnAccount({ accountHighlight = true, parent, agreementAccepted }: Props): JSX.Element {
  const { path, url } = useRouteMatch();

  const { data: dataAgreement } = useFetchAgreementByUser(parent === 'AccountManager');

  const agreement = useMemo(
    () => (parent === 'AccountManager' ? agreementAccepted : dataAgreement?.accepted),
    [parent, dataAgreement],
  );

  return (
    <SwitchAccountContextProvider accountHighlight={accountHighlight} agreementAccepted={agreement}>
      <div className={styles['wrapper']}>
        <Heading size={2}>Choose an Account</Heading>

        <nav>
          <ButtonNavigation
            activeClassName={styles.active}
            to={url}
            exact={true}
            navlink={true}
            variant="tertiary"
            firstIcon={{ children: 'view_carousel' }}
          >
            All Accounts
          </ButtonNavigation>
          {/*
            @TODO: it will enable when favorite feature endpoint is done
            <ButtonNavigation
              activeClassName={styles.active}
              to={`${url}/favorite`}
              exact={true}
              navlink={true}
              variant="tertiary"
              firstIcon={{ children: 'favorite', fill: true }}
            >
              Favorite Accounts
            </ButtonNavigation>
          */}
        </nav>
        <section>
          <Switch>
            <Route exact path={path} component={AllAccounts} />
            <Route exact path={`${path}/favorite`} component={FavoriteAccounts} />
          </Switch>
        </section>
      </div>
    </SwitchAccountContextProvider>
  );
}
