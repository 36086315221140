import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Button } from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import ButtonNavigation from '../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonNavigation';
import Modal from '../../../../../componentsV2/DesignSystem/Modal';
import styles from './styles.module.scss';

export default function Empty(): JSX.Element {
  const [showModal, setShowModal] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer | null>(null);

  return (
    <>
      {showModal && (
        <Modal title="Tutorial coupon collections" callback={() => setShowModal((prev) => !prev)}>
          <ReactPlayer
            url="https://vimeo.com/817678310"
            width="100%"
            style={{ maxHeight: 440 }}
            height="50vw"
            controls
            ref={playerRef}
          />
        </Modal>
      )}
      <div className={styles['img-wrapper']}>
        <img
          src="https://s3.eu-west-1.amazonaws.com/assets.beeliked.com/imgs/there-are-no-coupon-collections.png"
          alt="There are no coupon collections"
        />
      </div>

      <div className={styles['main-content']}>
        <h3>You have no collections yet</h3>
        <span>
          Purchase unique coupon and vouchers or upload your own to use as rewards and distribute to your winning
          players
        </span>
      </div>

      <footer className={styles['buttons-wrapper']}>
        <ButtonNavigation fullWidth to="/rewards/coupons/new-collection" variant="primary" size="sm">
          Create your first collection
        </ButtonNavigation>
        <Button
          fullWidth
          variant="tertiary"
          size="sm"
          firstIcon={{ children: 'ondemand_video' }}
          onClick={() => setShowModal((prev) => !prev)}
        >
          See what you can do
        </Button>
      </footer>
    </>
  );
}
