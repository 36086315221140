import { useState } from 'react';
import SectionCollapsibleFull from '../../../../../../componentsV2/DesignSystem/Sections/CollapsibleFull';
import { StatusBadge } from '../../../../../../componentsV2/DesignSystem/Sections/interface';
import { Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import ShowCode from '../../../../Campaign/Content/Share/PublicMode/ShowCode';
import useShareLinks from '../../ShareLinksProvider';
import standard from './standard.svg';
import styles from './styles.module.scss';

export default function EmbedPage(): JSX.Element {
  const [showCode, setShowCode] = useState<boolean>(false);

  const { promotionSelected } = useShareLinks();

  const toggleCode = () => setShowCode((showCode) => !showCode);

  return (
    <SectionCollapsibleFull
      badge={{
        status: StatusBadge.icon,
        icon: { name: 'add_to_queue', color: 'enabled' },
        number: 2,
      }}
      expanded={true}
      header={{ title: 'Embed Page' }}
      containerClassname={styles['container-section']}
    >
      <>
        <Paragraph size={2}>Choose Embed options to add your promotion to your website</Paragraph>
        <div className={styles['embed-list']}>
          <div className={styles['card']} onClick={toggleCode}>
            <img src={standard} alt="Our logo image" width={144} />
            <Paragraph size={3}>Standard</Paragraph>
          </div>
        </div>
        {showCode && (
          <ShowCode
            toggleModal={toggleCode}
            promote={{
              id: promotionSelected.id,
              external_domain: promotionSelected.externalDomain,
              render: promotionSelected.render,
            }}
            micrositeUrl={promotionSelected.micrositeUrl}
          />
        )}
      </>
    </SectionCollapsibleFull>
  );
}
