import { ApolloError, gql, useQuery } from '@apollo/client';

const PLAN_PERMISSIONS = gql`
  query planPermissions($accountId: ID!) {
    planPermissions(accountId: $accountId) {
      permissions {
        parameter
        permission {
          hasPermission
          allowedPlans
          value
        }
      }
    }
  }
`;

export interface PlanPermissions {
  parameter: string;
  permission: PlanPermissionsPermission;
}

export interface PlanPermissionsPermission {
  hasPermission: boolean;
  allowedPlans: string[];
  value: number;
}

export function useFetchPlanPermissions(accountId?: number): {
  loading: boolean;
  data: PlanPermissions[];
  refetch?: (variables?: { accountId: number }) => void;
} {
  const { data, loading, refetch } = useQuery<{ planPermissions: { permissions: PlanPermissions[] } }>(
    PLAN_PERMISSIONS,
    {
      fetchPolicy: 'no-cache',
      skip: !accountId,
      variables: {
        accountId,
      },
      onError: (error: ApolloError) => {
        console.log({
          gql: 'PLAN_PERMISSIONS',
          variables: {
            accountId,
          },
          apolloError: error,
        });
      },
    },
  );

  if (loading) {
    return {
      loading: true,
      data: [],
    };
  }

  return {
    loading: false,
    data: data?.planPermissions?.permissions || [],
    refetch,
  };
}
