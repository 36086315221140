import { ApolloError, gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { InviteData, SimplePaginationInfo, paginatorInfo } from '../../../../ts';

const INVITELINKS_BY_ACCOUNT = gql`
  query inviteLinksByAccount($accountId: ID!, $page: Int, $filters: InviteLinksByAccountFilter, $limit: Int) {
    inviteLinksByAccount(accountId: $accountId, page: $page, filters: $filters, limit: $limit) {
      data {
        id
        agency_id
        account_id
        code
        url
        promotion_id
        generator_id
        uid_field_slug
        uid
        data
        expires_at
        created_at
        updated_at
        used_at
        is_used
        promotion
        generator_name
        type
        expired
        user_id
        created_by
        message_id
        message_request_timestamp
        message_request_status
        message_request_id
        expire_date
      }
      paginatorInfo {
        current_page
        per_page
        total
      }
    }
  }
`;

interface InvitesFilters {
  code?: string;
  entry_search?: string;
  order?: string;
  sort?: string;
  user_id?: string;
  promotion_id?: string;
  created_at?: string;
  invite_type?: string;
  invite_used?: string;
  generator_id?: string;
}

export interface InviteLinksVariables {
  accountId: number;
  page: number;
  filters?: InvitesFilters;
}

interface InvitesResult {
  loading: boolean;
  data: InviteData[];
  pagination: SimplePaginationInfo;
  fetchInviteLinks: (variables: InviteLinksVariables) => void;
}

export function useFetchInviteLinksByAccount(
  accountId: number,
  page: number,
  filters?: InvitesFilters,
  skip?: boolean,
): InvitesResult {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: 10,
  });

  const { data, loading, refetch } = useQuery<{
    inviteLinksByAccount: { data: InviteData[]; paginatorInfo: paginatorInfo };
  }>(INVITELINKS_BY_ACCOUNT, {
    fetchPolicy: 'no-cache',
    skip: !(accountId && page) && skip,
    variables: {
      accountId,
      page,
      filters,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
      // alert(error.message);
    },
  });

  const fetchInviteLinks = (variables: InviteLinksVariables) => {
    refetch(variables);
  };

  useEffect(() => {
    if (data?.inviteLinksByAccount.paginatorInfo) {
      setPagination({
        total: data.inviteLinksByAccount.paginatorInfo.total,
        currentPage: data.inviteLinksByAccount.paginatorInfo.current_page,
        totalPerPage: Number(data.inviteLinksByAccount.paginatorInfo.per_page),
      });
    }
  }, [data?.inviteLinksByAccount.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: [],
      pagination,
      fetchInviteLinks,
    };
  }

  return {
    loading: false,
    data: data?.inviteLinksByAccount?.data || [],
    pagination,
    fetchInviteLinks,
  };
}
