import styleModuleClasses from '../../../utils/styleModuleClasses';
import { AvatarProps } from './interface';
import styles from './styles.module.scss';

export function Avatar({ name, image, color, size }: AvatarProps): JSX.Element {
  const renderImage = () => {
    let startLetters;
    if (image) {
      return <img src={image} />;
    } else {
      const nameSplited = name.split(' ');
      if (nameSplited.length > 1)
        startLetters = nameSplited[0].slice(0, 1) + nameSplited[nameSplited.length - 1].slice(0, 1);
      else startLetters = nameSplited[0].slice(0, 1) + nameSplited[0].slice(1, 2);
      return startLetters;
    }
  };

  return (
    <div
      className={styleModuleClasses(styles, 'avatar-wrapper', size)}
      style={{ backgroundColor: color ?? 'rgba(255,255,255,0.20)' }}
    >
      {renderImage()}
    </div>
  );
}
