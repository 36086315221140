import { ApolloError, gql, useMutation } from '@apollo/client';

const DELETE_PLUGIN = gql`
  mutation deleteRewardsPlugin($accountId: ID!, $pluginId: ID!) {
    deleteRewardsPlugin(accountId: $accountId, pluginId: $pluginId) {
      id
      message
    }
  }
`;

interface DeletePlugin {
  id: number;
  message: string;
}

export function useMutationDeletePlugin(): {
  loading: boolean;
  requestDeleteRewardsPlugin?: (accountId: number, pluginId: number) => void;
  data: DeletePlugin | null;
} {
  const [calculate, { loading, data, error }] = useMutation<{ deleteRewardsPlugin: DeletePlugin }>(DELETE_PLUGIN, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      console.log({
        gql: 'DELETE_PLUGIN',
        apolloError: error,
      });
    },
  });

  const requestDeleteRewardsPlugin = (accountId: number, pluginId: number) => {
    calculate({
      variables: {
        accountId,
        pluginId,
      },
    });
  };

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
    };
  }

  return {
    loading: false,
    data: data?.deleteRewardsPlugin || null,
    requestDeleteRewardsPlugin,
  };
}
