import { DotProps } from './interface';

import styles from './styles.module.scss';

export default function Dot({ bgColor }: DotProps): JSX.Element {
  return (
    <div
      className={styles['dot']}
      style={{
        backgroundColor: bgColor,
      }}
    />
  );
}
