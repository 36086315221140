import { useEffect } from 'react';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import SectionCollapsibleHalf from '../../../../componentsV2/DesignSystem/Sections/CollapsibleHalf';
import { StatusBadge } from '../../../../componentsV2/DesignSystem/Sections/interface';
import { Heading, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import VideoPlayerResponsive from '../../../../componentsV2/VideoPlayerResponsive';
import {
  FeatureKey,
  useMutationCreateUserFeatureFlag,
} from '../../../../graphql/mutation/useMutationCreateUserFeatureFlag';
import { RefetchUserFeatureFlagFindByKey } from '../../../../graphql/queries/useFetchUserFeatureFlagFindByKey';
import useAccount from '../../../../hooks/useAccount';
import styles from './styles.module.scss';

interface OverviewProps {
  refetch?: RefetchUserFeatureFlagFindByKey;
}

export default function Overview({ refetch }: OverviewProps): JSX.Element {
  const { getAccount } = useAccount();
  const { requestUserFeatureFlag, loading, data } = useMutationCreateUserFeatureFlag();

  useEffect(() => {
    if (data?.id) {
      refetch?.({ accountId: getAccount.accountId, featureKey: FeatureKey.ShareCenter });
    }
  }, [data]);

  return (
    <SectionCollapsibleHalf
      badge={{ icon: { name: 'school', color: 'enabled' }, status: StatusBadge.icon }}
      expanded={true}
      header={{
        title: 'Overview',
      }}
      containerClassname={styles['container-section']}
    >
      <div className={styles['video-wrapper']}>
        <div className={styles['video-content']}>
          <div className={styles['display-video']}>
            <VideoPlayerResponsive urlVideo="https://vimeo.com/1005055380" />
          </div>
        </div>
        <div className={styles['update-plan-content']}>
          <Heading size={4}>See how to share your promotion</Heading>
          <Paragraph size={2}>
            To get entries into your promotion you’ll need to share a link to it with others. Watch the video above to
            learn all the ways you can do this.
          </Paragraph>

          <div>
            <Button
              firstIcon={{ children: 'play_arrow' }}
              size="md"
              loading={loading}
              disabled={loading}
              onClick={() =>
                requestUserFeatureFlag({ accountId: getAccount.accountId, featureKey: FeatureKey.ShareCenter })
              }
            >
              Start Sharing
            </Button>
          </div>
        </div>
      </div>
    </SectionCollapsibleHalf>
  );
}
