import React from 'react';
import Card from '../../../../../componentsV2/DesignSystem/Card';
import { CardAnimations } from '../../../../../componentsV2/DesignSystem/Card/interface';
import TutorialsCarousel from './TutorialsCarousel';
import styles from './styles.module.scss';

export default function Videos(): JSX.Element {
  return (
    <Card
      identify="videos"
      size="md"
      animation={{ type: CardAnimations.top, duration: '1.75s' }}
      header={{ title: 'Videos' }}
      footer={{
        buttons: [
          {
            children: 'View All',
            variant: 'tertiary',
            lastIcon: { children: 'chevron_right' },
            to: 'https://www.youtube.com/beeliked',
            target: '_blank',
          },
        ],
      }}
    >
      <div className={styles['news-wrapper']}>
        <TutorialsCarousel />
      </div>
    </Card>
  );
}
