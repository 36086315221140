import { gql } from '@apollo/client';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Pagination from '../../../../../componentsV2/Pagination';
import useAuth from '../../../../../hooks/useAuth';
import useFakeQuery from '../../../../../hooks/useFakeQuery';
import { paginatorInfo } from '../../../../../ts';
import styleModuleClasses from '../../../../../utils/styleModuleClasses';
import AccountItem from '../AccountItem';
import stylesCard from '../AccountItem/styles.module.scss';
import AccountsEmpty from '../AccountsEmpty';
import { AccountData } from '../AllAccounts/useFetchAccountWithPlanByUser';
import Filters from '../Filters';
import SkeletonLoading from '../SkeletonLoading';
import useSwitchAccount, { TypeOfList } from '../SwitchAccountContextProvider';
import styles from '../styles.module.scss';

const FAVORITE_ACCOUNTS_GQL = gql`
  query accounts($agency_id: ID!, $user_id: ID) {
    accounts(agency_id: $agency_id, user_id: $user_id) {
      data {
        id
        name
        plan
        entries {
          current
          total
          percentual
        }
        favorite
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
`;

const DEFAULT_VALUES = {
  accounts: {
    data: [
      {
        id: 2,
        name: 'Hello BeeLiked',
        allowance: { planType: 'premium', entryAllowanceUsed: 500, entryAllowance: 1000, entriesRemaining: 35 },
      },
      {
        id: 4,
        name: 'Hello BeeLiked',
        allowance: { planType: 'essentials', entryAllowanceUsed: 500, entryAllowance: 1000, entriesRemaining: 50 },
      },
      {
        id: 5,
        name: 'Hello BeeLiked',
        allowance: { planType: 'essentials', entryAllowanceUsed: 500, entryAllowance: 1000, entriesRemaining: 5 },
      },
    ] as AccountData[],
    paginatorInfo: {
      total: 100,
      current_page: 1,
      per_page: '3',
    } as paginatorInfo,
  },
};

export default function FavoriteAccounts(): JSX.Element {
  const { getMe } = useAuth();
  const { getTypeFavorite, handleViewOption } = useSwitchAccount();
  const history = useHistory();

  const { data, loading, refetch } = useFakeQuery<{ accounts: { data: AccountData[]; paginatorInfo: paginatorInfo } }>(
    FAVORITE_ACCOUNTS_GQL,
    {
      defaultValue: DEFAULT_VALUES,
      variables: {
        agency_id: getMe.agency_id,
        user_id: getMe.user_id,
      },
    },
  );

  const dataAccounts = useMemo(() => {
    return data?.accounts?.data || [];
  }, [data]);

  const dataAccountsPagination = useMemo(() => {
    return data?.accounts?.paginatorInfo;
  }, [data]);

  const handleTypeOfList = (typeOfList: TypeOfList) => {
    handleViewOption('favorite', typeOfList);
  };

  const handlePagination = (page: number) => {
    refetch({
      agency_id: getMe.agency_id,
      user_id: getMe.user_id,
    });
    history.push('?page=' + page);
  };

  return (
    <>
      <Filters typeOfList={getTypeFavorite} handleTypeOfList={handleTypeOfList} />
      <main
        className={styleModuleClasses(styles, {
          [getTypeFavorite]: (data && dataAccounts.length > 0) || loading || false,
        })}
      >
        {loading ? (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <section className={styleModuleClasses(stylesCard, 'section', getTypeFavorite)} key={`loading-${index}`}>
                <SkeletonLoading type={getTypeFavorite} />
              </section>
            ))}
          </>
        ) : (
          <>
            {dataAccounts.length === 0 ? (
              <AccountsEmpty />
            ) : (
              dataAccounts.map((account) => (
                <AccountItem key={`card-${account.id}`} {...account} type={getTypeFavorite} />
              ))
            )}
          </>
        )}
      </main>
      {dataAccountsPagination && dataAccountsPagination.total > 0 && (
        <footer>
          <Pagination
            total={dataAccountsPagination.total}
            currentPage={1}
            totalPerPage={parseInt(dataAccountsPagination.per_page, 10)}
            setPage={handlePagination}
          />
        </footer>
      )}
    </>
  );
}
