export interface TagsProps {
  children: string | JSX.Element;
  color?: TagsColor;
  onClick?: () => void;
  size?: 'sm' | 'md';
}

export enum TagsColor {
  primary = 'primary',
  primaryDark = 'primary-dark',
  gray = 'gray',
  grayDark = 'gray-dark',
  disabled = 'disabled',
}
