import { ApolloError, gql, useQuery } from '@apollo/client';
import { PromotionVisibility } from '../../ts';

const PROMOTIONS_SIMPLE_LIST = gql`
  query promotionsSimpleList($accountId: ID!, $filters: PromotionsSimpleFilter) {
    promotionsSimpleList(accountId: $accountId, filters: $filters) {
      data {
        id
        name
        visibility
        micrositeUrl
        externalDomain
        render
        status
      }
    }
  }
`;

export interface PromotionSimpleData {
  id: number;
  name: string;
  visibility: PromotionVisibility;
  micrositeUrl: string;
  externalDomain: string;
  render: string;
  status: string;
}

interface Filters {
  status?: string;
  visibility?: PromotionVisibility;
}

export default function useFetchPromotionsSimpleList(
  accountId: number,
  filters?: Filters,
): {
  loading: boolean;
  data: PromotionSimpleData[] | null;
  error?: string;
} {
  const { data, loading, error } = useQuery<{ promotionsSimpleList: { data: PromotionSimpleData[] } }>(
    PROMOTIONS_SIMPLE_LIST,
    {
      fetchPolicy: 'no-cache',
      variables: {
        accountId,
        filters,
      },
      onError: (error: ApolloError) => {
        console.log({
          gql: 'PROMOTIONS_SIMPLE_LIST',
          apolloError: error,
        });
      },
    },
  );

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
      error: error.message,
    };
  }

  return {
    loading: false,
    data: data?.promotionsSimpleList?.data || null,
  };
}
