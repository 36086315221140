import React from 'react';
import { Heading } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { FontWeight } from '../../../../../../../componentsV2/DesignSystem/Typography';
import { TestimonialProps } from './interface';
import styles from './styles.module.scss';
import Pills from '../../../../../../../componentsV2/DesignSystem/Pills';

export default function Testimonial({
  background,
  image,
  description,
  title,
  pill,
  animation,
}: TestimonialProps): JSX.Element {
  return (
    <div className={[styles['testimonial-wrapper'], animation].join(' ')}>
      <img className={styles['background']} {...background} />
      {image?.src && <img className={styles['image']} {...image} />}
      <aside>
        {pill.children && <Pills {...pill} size="lg" />}
        <div>
          <Heading size={2} weight={FontWeight.extraBold}>
            {title}
          </Heading>
          <Heading size={5} weight={FontWeight.regular}>
            {description}
          </Heading>
        </div>
      </aside>
    </div>
  );
}
