import { ApolloError, gql, useMutation } from '@apollo/client';

const UPDATE_WIDGET_USER = gql`
  mutation updateWidgetUser($accountId: ID!, $widgetId: ID!, $widgetUserId: ID!, $password: String) {
    updateWidgetUser(accountId: $accountId, widgetId: $widgetId, widgetUserId: $widgetUserId, password: $password) {
      id
      username
      password
    }
  }
`;

interface UpdateWidgetUser {
  id: number;
  message: string;
}

export function useMutationUpdateWidgetUser(): {
  loading: boolean;
  updateWidgetUser?: (accountId: number, widgetId: number, widgetUserId: number, password: string) => void;
  data: UpdateWidgetUser | null;
} {
  const [updateWidgetUserMutation, { loading, data, error }] = useMutation<{ updateWidgetUser: UpdateWidgetUser }>(
    UPDATE_WIDGET_USER,
    {
      fetchPolicy: 'no-cache',
      onError: (error: ApolloError) => {
        console.log({
          gql: 'UPDATE_STAFF_LINK',
          apolloError: error,
        });
      },
    },
  );

  const updateWidgetUser = (accountId: number, widgetId: number, widgetUserId: number, password: string) => {
    updateWidgetUserMutation({
      variables: {
        accountId,
        widgetId,
        widgetUserId,
        password,
      },
    });
  };

  if (loading) {
    return {
      loading: true,
      data: null,
    };
  }

  if (error) {
    return {
      loading: false,
      data: null,
    };
  }

  return {
    loading: false,
    data: data?.updateWidgetUser || null,
    updateWidgetUser,
  };
}
