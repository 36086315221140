import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ButtonPopoverConfirm from '../../../../../../../componentsV2/ButtonPopoverConfirm';
import { Button } from '../../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Select from '../../../../../../../componentsV2/DesignSystem/Inputs/Selects/Select';
import { SelectNewFieldProps, SelectedField } from './interface';
import styles from './styles.module.scss';

export default function SelectNewField({ listFields, appendField }: SelectNewFieldProps): JSX.Element {
  const [selectedFields, setSelectedFields] = useState<SelectedField>({});
  const [error, setError] = useState<string>('');

  const { control } = useFormContext();

  const addNewField = () => {
    const newIndex = Object.keys(selectedFields).length;
    setSelectedFields({ ...selectedFields, [newIndex]: '' });
  };

  const removeField = (fieldIndex: string) => {
    const updatedFields: SelectedField = {};
    let counter = 0;
    Object.keys(selectedFields).forEach((key) => {
      const index = parseInt(key, 10);
      if (index !== parseInt(fieldIndex, 10)) {
        updatedFields[counter] = selectedFields[index];
        counter++;
      }
    });
    setSelectedFields(updatedFields);
  };

  const saveField = (fieldIndex: string) => {
    const field = selectedFields[parseInt(fieldIndex, 10)];

    if (field) {
      appendField(selectedFields[parseInt(fieldIndex, 10)]);
      removeField(fieldIndex);
    } else {
      setError('Please provide a valid value.');
    }
  };

  return (
    <>
      <div className={styles['content-new-field']}>
        {Object.keys(selectedFields).map((fieldIndex) => (
          <div className={styles['select-content']} key={`select-new-field-${fieldIndex}`}>
            <Controller
              control={control}
              name={`selectField-${fieldIndex}`}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <Select
                  label="Select new field"
                  placeholder="Select"
                  onChange={(value) => {
                    onChange(value);
                    setSelectedFields({ ...selectedFields, [fieldIndex]: value });
                    setError('');
                  }}
                  options={listFields}
                  size="sm"
                  defaultValue={selectedFields[parseInt(fieldIndex, 10)]}
                  {...(!!error && {
                    helperText: error,
                    color: 'red-block',
                  })}
                />
              )}
            />
            <div className={styles['wrapper-new-buttons']}>
              <Button
                variant="secondary"
                firstIcon={{ children: 'check' }}
                size="sm"
                onClick={() => saveField(fieldIndex)}
              />
              <ButtonPopoverConfirm
                buttonProps={{
                  variant: 'secondary',
                  firstIcon: { children: 'delete', color: 'red-block' },
                  size: 'sm',
                }}
                popoverProps={{ header: { title: 'Are you sure you want to delete this field?' } }}
                onClick={() => removeField(fieldIndex)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={styles['button-content']}>
        <Button
          variant="secondary"
          firstIcon={{ children: 'add_circle_outline' }}
          size="sm"
          onClick={addNewField}
          id="add-new-field"
        >
          Add New Field
        </Button>
      </div>
    </>
  );
}
