import { useRef, useState } from 'react';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import { ActionProps } from '../DesignSystem/Inputs/Buttons/ButtonAction/interface';
import Popover from '../DesignSystem/Popover';

export interface ButtonTooltipProps extends ActionProps {
  tooltip: string;
}

export default function ButtonTooltip({ tooltip, ...buttonProps }: ButtonTooltipProps): JSX.Element {
  const buttonTooltipCopyRef = useRef<HTMLButtonElement>(null);
  const [showPopoverCopy, setShowPopoverCopy] = useState<boolean>(false);

  return (
    <>
      <Button
        ref={buttonTooltipCopyRef}
        onMouseEnter={() => setShowPopoverCopy(true)}
        onMouseLeave={() => setShowPopoverCopy(false)}
        {...buttonProps}
      />

      <Popover
        propRef={buttonTooltipCopyRef}
        showPopover={showPopoverCopy}
        callback={() => setShowPopoverCopy(false)}
        showArrow
        color="dark"
        hiddenOverlay
        mainStyle={{ textAlign: 'center', minWidth: '8.75rem' }}
      >
        {tooltip}
      </Popover>
    </>
  );
}
