import React, { useMemo } from 'react';
import Pills from '../../../../../../componentsV2/DesignSystem/Pills';
import { NewsItemProps } from './interface';
import { PillsColors } from '../../../../../../componentsV2/DesignSystem/Pills/interface';
import styles from '../styles.module.scss';

export default function NewsItem({ item }: NewsItemProps): JSX.Element {
  const color = useMemo(() => {
    switch (item.label.title.toLocaleLowerCase()) {
      case 'blog':
        return PillsColors.yellow;
      default:
        return PillsColors.primary;
    }
  }, [item]);

  return (
    <>
      <a href={item.url} className={styles['card-news']} target="_blank" rel="noreferrer">
        <div key={`card-${item.id}`}>
          <aside>
            <img src={item.media?.image} alt={item.media?.image_alt} />
          </aside>
          <main>
            <Pills size="sm" color={color}>
              {item.label.title}
            </Pills>
            <h3>{item.title}</h3>
            <span>{item.description}</span>
          </main>
        </div>
      </a>
      <hr />
    </>
  );
}
