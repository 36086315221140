import { useLocation } from 'react-router-dom';
import ButtonNavigation from '../Inputs/Buttons/ButtonNavigation';
import { TabNavigationProps } from './interface';
import styles from './styles.module.scss';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import { Button } from '../Inputs/Buttons/ButtonAction';
import { useState } from 'react';

export default function TabNavigation({ tabItems }: TabNavigationProps): JSX.Element {
  const location = useLocation();
  const [showPopovers, setShowPopovers] = useState<boolean[]>(Array.from({ length: tabItems.length }, () => false));

  return (
    <nav className={styleModuleClasses(styles, 'tab-wrapper')}>
      {tabItems
        .filter((item) => !item.hide)
        .map((item, index) => (
          <div key={`tab-item-${index}`} className={styleModuleClasses(styles, { disabled: !!item.disabled })}>
            {!!item.disabled ? (
              <Button
                variant="tertiary"
                disabled
                {...(item.icon && { firstIcon: { children: item.icon } })}
                onMouseEnter={() => {
                  const newArray = [...showPopovers];
                  newArray.splice(index, 1, true);
                  setShowPopovers(newArray);
                }}
                onMouseLeave={() => {
                  const newArray = [...showPopovers];
                  newArray.splice(index, 1, false);
                  setShowPopovers(newArray);
                }}
                popover={{
                  color: 'dark',
                  placement: 'bottom-end',
                  showPopover: showPopovers[index],
                  callback: () => undefined,
                  hiddenOverlay: true,
                  children: 'This feature is coming soon.',
                  propRef: null,
                }}
              >
                {item.name}
              </Button>
            ) : (
              <ButtonNavigation
                {...(!!location.pathname.includes(item.link) && { activeClassName: styles.active })}
                to={item.link}
                exact={true}
                navlink={true}
                variant="tertiary"
                text={{ color: 'dark' }}
                {...(item.icon && { firstIcon: { children: item.icon } })}
              >
                {item.name}
              </ButtonNavigation>
            )}
          </div>
        ))}
    </nav>
  );
}
