import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Button from '../../../../../../../components/_atoms/Button';
import getHostnameFromUrl from '../../../../../../../utils/getHostnameFromUrl';
import getProtocolFromUrl from '../../../../../../../utils/getProtocolFromUrl';
import { ShowCodeProps } from './interface';
import styles from './styles.module.scss';

export default function ShowCode({ toggleModal, promote, micrositeUrl }: ShowCodeProps): JSX.Element {
  const { t } = useTranslation('promotionsContentShare');
  const [modal, setModal] = useState<Modal | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    const modal = new Modal(modalRef.current, { keyboard: false, backdrop: 'static' });
    setModal(modal);
    modal?.show();
  }, []);

  const hideModal = () => {
    modal?.hide();
    setModal(null);
    setTimeout(() => toggleModal(), 1000);
  };

  const url = useMemo(
    () =>
      promote.external_domain
        ? promote.external_domain
        : `${getProtocolFromUrl(micrositeUrl)}://${getHostnameFromUrl(micrositeUrl)}`,
    [micrositeUrl, promote.external_domain],
  );

  const codeContent = useMemo(() => {
    if (url) {
      if (promote.render === 'nextjs') {
        return `<!-- BeeLiked Microsite Code -->
<div class="blkd_microsite" id="blkd-microsite-${promote.id}" style="position:relative;width:100%;height:800px"></div>
<script>window.addEventListener("blkdIframeCtrlLoaded", function() { blkdIframeCtrl(document.getElementById('blkd-microsite-${promote.id}'), '${micrositeUrl}'); });</script>
<script src="${url}/embed/v1/script.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.6/iframeResizer.min.js"></script>
<script>window.addEventListener("load", () => {iFrameResize({log: false}, "#blkd-microsite-${promote.id}-iframe")})</script>
<!-- End BeeLiked Microsite Code -->`;
      }

      return `<!-- BeeLiked Microsite Code -->
<div class="blkd_microsite" id="blkd-microsite-${promote.id}" style="position:relative;width:100%;height:500px;">
    <div class="blkd-loader" style="padding: 10px 0px; width: 100px; margin-left:-50px; background:#333;position:absolute; left:50%; top:160px; text-align:center; border-radius:5px; opacity:0.8;">
        <img src="${url}/imgs/microsites/loader-dark.gif" alt="" style="margin-bottom: 20px; width: 64px; height: 64px;">
        <span style="color:#ddd; font-size:14px">Loading...</span>
    </div>
</div>
<script src="${url}/min/beeliked.iframeCtrl3.min.js"></script>
<script>blkdIframeCtrl(document.getElementById('blkd-microsite-${promote.id}'), '${micrositeUrl}', { loaderClass: 'blkd-loader' });</script>
<!-- End BeeLiked Microsite Code -->`;
    }
  }, [url]);

  const codeOpenGraph = `<meta property="og:url" content="${micrositeUrl}" />\n<meta property="og:type" content="website" />`;

  return (
    <div className="modal fade" tabIndex={-1} ref={modalRef}>
      <div className={['modal-dialog modal-dialog-centered', styles['modal-dialog']].join(' ')}>
        <div className="modal-content">
          <div className="modal-header">
            <ul className={['breadcrumb', styles['breadcrumb']].join(' ')}>
              <li>{t('public-mode.modal-show-code.Show the embed code')}</li>
            </ul>
            <button type="button" className="btn-close" onClick={hideModal} />
          </div>
          <div className="modal-body">
            <p>
              {t(
                'public-mode.modal-show-code.This HTML embed code will allow you to insert this campaign as an iframe in your own website and will ensure it remains responsive to different screen sizes.',
              )}
            </p>
            <div className={styles['content-share-code']}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="mb-0">
                  Copy this code to the <code>&lt;body&gt;</code> section of your web page
                </h3>
                <div className="text-nowrap ms-2">
                  <Button
                    type="button"
                    className="btn-outline-secondary"
                    size="sm"
                    onClick={() => {
                      navigator.clipboard.writeText(codeContent ?? '');
                    }}
                  >
                    <i className="fas fa-copy" /> {t('public-mode.modal-show-code.Copy Code')}
                  </Button>
                </div>
              </div>
              {!!codeContent && (
                <SyntaxHighlighter language="htmlbars" style={vs2015}>
                  {codeContent}
                </SyntaxHighlighter>
              )}
            </div>

            <div className={styles['content-share-code']}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="mb-0">
                  Remember to include opengraph and twitter card metadata to your page. Copy this code to the{' '}
                  <code>&lt;head&gt;</code> section of your web page
                </h3>
                <div className="text-nowrap ms-2">
                  <Button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(codeOpenGraph);
                    }}
                    className="btn-outline-secondary"
                    size="sm"
                  >
                    <i className="fas fa-copy" /> {t('public-mode.modal-show-code.Copy Code')}
                  </Button>
                </div>
              </div>
              <SyntaxHighlighter language="htmlbars" style={vs2015}>
                {codeOpenGraph}
              </SyntaxHighlighter>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end">
            <Button type="button" color="secondary" onClick={hideModal}>
              {t('public-mode.modal-show-code.Close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
