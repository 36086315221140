import React, { useEffect, useRef, useState } from 'react';

export default function Builder(): JSX.Element {
  const iframe = useRef<HTMLIFrameElement | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const messageListener = (e: any) => {
    if (typeof e.data !== 'string' || !e.data.startsWith('[blkd-edit]')) return;
    console.log('REC' + e.data);
  };

  const sendMessage = (message: string) => {
    if (iframe?.current) {
      console.log('SEND[blkd-edit]' + message);
      iframe.current.contentWindow?.postMessage('[blkd-edit]' + message, '*');
    }
  };

  const iframeLoading = () => {
    setLoading(false);
    sendMessage('on');
  };

  useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => window.removeEventListener('message', messageListener);
  }, []);

  useEffect(() => {
    iframe?.current?.addEventListener('load', iframeLoading);
    return () => iframe?.current?.removeEventListener('load', iframeLoading);
  }, []);

  return (
    <main className="p-6">
      <h1>Builder - just for testing</h1>
      {!!loading && <p>Loading...</p>}
      <iframe
        id="blkd-microsite-preview-iframe"
        className="blkd-microsite-iframe"
        width="100%"
        height="100%"
        src="https://preview.beeliked.local/19706/pagePreview/start?accountRef=3227&amp;agencyRef=1"
        style={{ border: '0px', height: '500px' }}
        scrolling="yes"
        data-blkd-iframe-resizer-loaded="true"
        ref={iframe}
      ></iframe>
    </main>
  );
}
