import React from 'react';
import classNames from 'classnames';
import { UncontrolledAlert } from 'reactstrap';

interface Props {
  type?: 'alert-info' | 'alert-error' | 'success' | string;
  children?: JSX.Element | string;
  disableClose?: boolean;
}

export default function AlertMessage({ type, children, disableClose }: Props): JSX.Element {
  return (
    <UncontrolledAlert
      color={classNames({
        success: type === 'alert-success',
        warning: type === 'alert-info',
        danger: type === 'alert-error',
      })}
      {...(disableClose && { toggle: undefined })}
    >
      <i
        className={classNames('me-2 fas', {
          'fa-check-circle': type === 'alert-success',
          'fa-exclamation-triangle': type !== 'alert-success',
        })}
      />
      {typeof children === 'string' ? <span dangerouslySetInnerHTML={{ __html: children }} /> : <span>{children}</span>}
    </UncontrolledAlert>
  );
}
