import { ApolloError, gql, useMutation } from '@apollo/client';

const UPDATE_WIDGET = gql`
  mutation updateWidget($accountId: ID!, $widgetId: ID!, $data: WidgetInputData) {
    updateWidget(accountId: $accountId, widgetId: $widgetId, data: $data) {
      id
      account_id
      promo_id
      name
      slug
      image_file
      created_at
      updated_at
    }
  }
`;

interface UpdateWidgetResult {
  id: number;
  account_id: number;
  name: string;
  promo_id: number;
  image_file: string;
  slug: string;
}

interface UpdateWidgetDataVariables {
  promo_id: number;
  name: string;
  slug: string;
  header_message?: string;
  header_message_font_color?: string;
  message1?: string;
  message1_font_color?: string;
  message2?: string;
  message2_font_color?: string;
  expire_time?: number;
  widget_background?: string;
  page_background?: string;
  image_file?: string;
}

export function useMutationUpdateWidget(): {
  loading: boolean;
  updateWidget: (variables: { accountId: number; widgetId: number; data: UpdateWidgetDataVariables }) => void;
  data: UpdateWidgetResult | null;
} {
  const [updateWidgetMutation, { loading, data }] = useMutation<{ updateWidget: UpdateWidgetResult }>(UPDATE_WIDGET, {
    onError: (error: ApolloError) => {
      console.log({
        gql: 'UPDATE_WIDGET',
        apolloError: error,
      });
    },
  });

  const updateWidget = (variables: { accountId: number; widgetId: number; data: UpdateWidgetDataVariables }) => {
    updateWidgetMutation({ variables });
  };

  return {
    loading,
    updateWidget,
    data: data?.updateWidget || null,
  };
}
