import Icon from '../../../Icon';
import { ButtonShowHideProps } from './interface';

import styles from './styles.module.scss';

export default function ButtonShowHide({ onClick, show }: ButtonShowHideProps): JSX.Element {
  return (
    <button className={styles['button-show-hide']} onClick={(show) => onClick(!show)} type="button">
      <Icon customClass={styles['eye-icon']} color="gray-92">
        {show ? 'visibility' : 'visibility_off'}
      </Icon>
    </button>
  );
}
