import { ChangeEvent, useState } from 'react';
import Alert from '../../../../../componentsV2/DesignSystem/Alert';
import Icon from '../../../../../componentsV2/DesignSystem/Icon';
import { Paragraph } from '../../../../../componentsV2/DesignSystem/Typography';
import { UploadProps } from './interface';
import styles from './styles.module.scss';

export default function Upload({ filename, setFile, extesions }: UploadProps): JSX.Element {
  const [errorValidatingExtension, setErrorValidatingExtension] = useState<boolean>(false);

  const extensionsString = extesions.map((ext) => `.${ext}`).join(',');

  const handleChangeUploadField = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorValidatingExtension(false);
    if (e.target.files?.length) {
      const fileExtension = e.target?.files[0]?.name.split('.').pop();
      if (!fileExtension) {
        setErrorValidatingExtension(true);
        return;
      }
      if (!extesions.includes(fileExtension)) {
        setErrorValidatingExtension(true);
        return;
      }

      setFile({
        name: e.target?.files[0]?.name,
        file: e.target.files[0],
      });
    }
  };

  return (
    <div className={styles['wrapper-upload']}>
      <div className={styles['upload']}>
        {errorValidatingExtension && <Alert color="danger-light">File extension is not allowed.</Alert>}

        <div className={styles['center-button']}>
          <label className={styles['button-upload']}>
            <input type="file" className="d-none" onChange={handleChangeUploadField} accept={extensionsString} />
            <Icon>file_upload</Icon> Upload Logo
          </label>
        </div>

        {!!filename && (
          <Paragraph size={2} color="primary">
            {filename}
          </Paragraph>
        )}
        <Paragraph size={2}>The image must be in JPG, PNG or SVG format, and be at least 200x200 pixels</Paragraph>
      </div>
    </div>
  );
}
