import { ApolloError, useMutation } from '@apollo/client';
import { FormEvent, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../../../../../../componentsV2/DesignSystem/Icon';
import { IconAnimated } from '../../../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import { Heading, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import useToast from '../../../../../../hooks/useToast';
import styleModuleClasses from '../../../../../../utils/styleModuleClasses';
import { passwordConfirmation, validatePassword } from '../../../../../../utils/validations';
import useSigninContext, { StepsEnum } from '../../../SigninContextProvider';
import { RESET_PASSWORD_GQL } from '../../../graphql';
import animate from '../../styles.module.scss';
import { RecoveryProps } from './interface';
import styles from './styles.module.scss';

export default function RecoveryPassword({ code, email }: RecoveryProps): JSX.Element {
  const { animations, changeStep, setEnableSteps, enableSteps } = useSigninContext();
  const history = useHistory();
  const { toast } = useToast();
  const formRef = useRef<HTMLFormElement>(null);

  const [types, setTypes] = useState<{ password: boolean; passwordConfirmation: boolean }>({
    password: true,
    passwordConfirmation: true,
  });
  const [passwords, setPasswords] = useState<{ password: string; passwordConfirmation: string }>({
    password: '',
    passwordConfirmation: '',
  });
  const [errors, setErrors] = useState<{ password: string; password_confirmation: string }>({
    password: '',
    password_confirmation: '',
  });
  const [passwordRules, setPasswordRules] = useState({
    size: false,
    upper: false,
    lower: false,
    special: false,
    equal: false,
  });

  const handlePassword = (value: string) => {
    const { rules } = validatePassword(value, passwords, passwordRules);
    setPasswords({ ...passwords, password: value });
    setErrors({ ...errors, password: '' });
    setPasswordRules({ ...rules });
  };

  const handlePasswordConfirmation = (value: string) => {
    const { rules } = passwordConfirmation(value, passwords, passwordRules);
    setPasswords({ ...passwords, passwordConfirmation: value });
    setErrors({ ...errors, password_confirmation: '' });
    setPasswordRules({ ...rules });
  };

  function goToInitial() {
    setEnableSteps({ ...enableSteps, initial: true });
    setTimeout(() => {
      changeStep(StepsEnum.forgotPassword, false);
      history.push('/signin');
    }, 500);
  }

  const [reset, { loading }] = useMutation(RESET_PASSWORD_GQL, {
    onCompleted: () => {
      toast('success', 'Password updated.');
      goToInitial();
    },
    onError: (error: ApolloError) => {
      if (error?.graphQLErrors?.[0].extensions?.code === 'BAD_USER_INPUT') {
        const validations = error?.graphQLErrors?.[0].extensions?.errors as Record<string, string>;
        Object.keys(validations).forEach((key: string) => {
          const fieldName = key.toLocaleLowerCase() as 'password' | 'password_confirmation' | 'exception';
          if (['password', 'password_confirmation'].includes(fieldName)) {
            setErrors({ ...errors, [fieldName]: validations[key].toString() });
          }

          if (fieldName === 'exception') {
            toast('error', validations[key].toString());
          }
        });
      } else {
        toast('error', 'There was an error changing your password. Please try again');
      }
    },
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    reset({
      variables: {
        code,
        email,
        ...passwords,
      },
    });
  };

  return (
    <div className={styleModuleClasses(styles, 'form-wrapper', animate[animations.forgotPassword])}>
      <IconAnimated size="md" icon={{ name: 'lock' }} wrapper={{ color: 'primary' }} />
      <Heading size={3}>New password</Heading>
      <form onSubmit={onSubmit} ref={formRef}>
        <Input
          value={passwords.password}
          onChange={(e) => handlePassword(e.target.value)}
          type={types.password ? 'password' : 'text'}
          label="Password"
          placeholder="Your password"
          icon={{
            name: types.password ? 'visibility' : 'visibility_off',
            onClick: () => setTypes({ ...types, password: !types.password }),
            style: { marginRight: '.25rem' },
          }}
          {...(errors.password && {
            helperText: errors.password,
            color: 'danger',
          })}
        />
        <Input
          value={passwords.passwordConfirmation}
          onChange={(e) => handlePasswordConfirmation(e.target.value)}
          type={types.passwordConfirmation ? 'password' : 'text'}
          label="Confirm your password"
          placeholder="Confirm your password"
          disabled={
            !(passwordRules.lower && passwordRules.size && passwordRules.special && passwordRules.upper) &&
            passwords.password.length == 0
          }
          icon={{
            name: types.passwordConfirmation ? 'visibility' : 'visibility_off',
            onClick: () => setTypes({ ...types, passwordConfirmation: !types.passwordConfirmation }),
            style: { marginRight: '.25rem' },
          }}
          {...(errors.password_confirmation && {
            helperText: errors.password_confirmation,
            color: 'danger',
          })}
        />
        <ul className={styles['password-rules-wrapper']}>
          <li className={passwordRules.size ? styles['approved'] : ''}>
            <Icon>{passwordRules.size ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 8 characters</Paragraph>
          </li>
          <li className={passwordRules.upper ? styles['approved'] : ''}>
            <Icon>{passwordRules.upper ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 in uppercase</Paragraph>
          </li>
          <li className={passwordRules.lower ? styles['approved'] : ''}>
            <Icon>{passwordRules.lower ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 in lowercase</Paragraph>
          </li>
          <li className={passwordRules.special ? styles['approved'] : ''}>
            <Icon>{passwordRules.special ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Must be at least 1 special character (allowed: @#!$*&?)</Paragraph>
          </li>
          <li className={passwordRules.equal ? styles['approved'] : ''}>
            <Icon>{passwordRules.equal ? 'Check_Circle' : 'circle'}</Icon>
            <Paragraph size={3}>Passwords must be equal</Paragraph>
          </li>
        </ul>
        <Button fullWidth size="md" type="submit" loading={loading}>
          Set new password
        </Button>
      </form>
      <div className={styles['sign-text']}>
        <Paragraph size={2}>{`Remember your password?`}</Paragraph>
        <Button variant="tertiary" onClick={goToInitial}>
          Sign in
        </Button>
      </div>
    </div>
  );
}
