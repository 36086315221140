const queryString = {
  parse: (queryValue: string): Record<string, string> => {
    return queryValue?.split('&').reduce((acc: Record<string, string>, value: string) => {
      const variable = value.split('=');
      acc[variable[0]] = variable[1];
      return acc;
    }, {});
  },
};

export default queryString;
